import React from 'react';
import I18n from 'i18n-js';
import Button from '../cubeButton/CubeBtn';
import ExcelJs from 'exceljs';

/**
 * @param {{
 *  sheetName: string,
 *  sheetRef?: string,
 *  data: any[],
 *  columns: {
 *      id: string | number,
 *      text: string,
 *  }[],
 *  headerColor?: string,
 *  fillRowsColumns?: {
 *      type: 'row' | 'column',
 *      filter: (item: any, index: number) => boolean,
 *      color: string,
 *  }[],
 *  fileType?: 'xlsx' | 'csv',
 * }}
 */
export default ({
    sheetName,
    sheetRef = 'A1',
    data,
    columns,
    headerColor = 'ffffff',
    fillRowsColumns = [],
    fileType = 'xlsx',
}) => {
    /**
     * @param {ExcelJs.Row | ExcelJs.Column} cell
     * @param {string} color
     */
    const fillCell = (cell, fgColor, fontColor = '000000') => {
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: fgColor },
        };

        cell.font = {
            color: { argb: fontColor },
        }

        // Setting fills to cells kinda clear out the borders, so I have to add them back.
        const resetBorder = { style: 'thin', color: { argb: 'cccccc' } };
        cell.border = {
            top: resetBorder,
            left: resetBorder,
            right: resetBorder,
            bottom: resetBorder,
        };
    };

    const onClick = async () => {
        const workbook = new ExcelJs.Workbook();

        const sheet = workbook.addWorksheet(sheetName);
        sheet.addTable({
            name: sheetName,
            ref: sheetRef,
            headerRow: true,
            columns: columns.map((column) => ({ name: column.text })),
            rows: data.map((item) => columns.map((column) => item[column.id])),
        });

        fillCell(sheet.getRow(1), headerColor);
        fillRowsColumns.map(({ type, filter, color }) => {
            if (type === 'row') {
                data.forEach((item, index) => {
                    if (!filter(item, index)) return;

                    fillCell(sheet.getRow(index + 2), color);
                });
            } else if (type === 'column') {
                columns.forEach((item, index) => {
                    if (!filter(item, index)) return;

                    fillCell(sheet.getColumn(index + 1), color);
                });
            }
        });

        const content = await workbook[fileType].writeBuffer();
        const link = document.createElement('a');
        const blob = new Blob([content], {
            type: 'application/vnd.ms-excel;charset=utf-8;'
        });

        link.download = `${sheetName}.${fileType}`;
        link.href = URL.createObjectURL(blob);
        link.click();
    };

    return (
        <Button
            iconType='export'
            text={I18n.t('Export')}
            bgColor='#2e65c9'
            textColor='#fff'
            disabled={data.length === 0}
            onClick={onClick}
        />
    );
};