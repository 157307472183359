/** @jsx h */
import { h } from "preact";
import { route } from "preact-router";
import I18n from "i18n/i18n";

import { CollapseTable } from "components/molecules/collapseTable/CollapseTable";
import CubeBtn from "components/atoms/cubeButton/CubeBtn";

const DETAIL_COLS = [
  { id: "machine", sortable: true, width: '33%' },
  { id: "quantity", sortable: true, width: '33%' },
  { id: "price", sortable: true, width: '33%' }
]

const OrdersTable = ({ filteredData, setOrderDetails, fetched, pageResetKey }) => {
  const COLUMNS = [
    { id: "orderNum", sortable: true },
    { id: "salesman", sortable: true },
    { id: "client", sortable: true },
    { id: "date", sortable: true},
    { id: "currency_symbol", sortable: true },
    { id: "exchange_rate", sortable: true },
    { id: "cost", sortable: true },
    { id: "expect_buy_date", sortable: true },
    { id: "rate", sortable: true },
    {
      id: "status", sortable: false, Cell: ({ is_finish }) => (
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <div
            style={{
              color: is_finish ? 'rgb(0, 180, 0)' : 'rgb(30, 143, 255)',
              backgroundColor: is_finish ? 'rgba(0, 255, 0, 0.05)' : 'rgba(30, 143, 255, 0.05)',
              padding: 3,
            }}
          >
            {I18n.t(is_finish ? 'closed' : 'notClosed')}
          </div>
        </div>
      )
    },
    {
      id: "Action",
      Cell: (row) => (
        <CubeBtn
          bgColor="#2E65C9"
          textColor="#FFFFFF"
          iconType="search"
          onClick={() => {
            setOrderDetails(row);
            route(`${location.pathname}?isDetail=true`);
          }}
        />
      ),
    }]

  return (
    <CollapseTable
      isCustomRow
      customColumns={DETAIL_COLS}
      columns={COLUMNS}
      data={filteredData}
      canDropDrag={false}
      skeletonColumns={COLUMNS.length}
      isFetched={fetched}
      pageResetKey={pageResetKey}
    />
  )
}

export default OrdersTable;
