import { useState, useEffect, useContext } from "preact/hooks";
import { getUserList, getClient, getClientBranch, getImage, getClientContact, getTopFiftyClients, getClientMachineRanking } from "../api";
import { errHandler, debounce } from "../util";
import Context from "../context";

const DEFAULT_API_PARAMETER = {
    limit: 10,
    offset: 0,
}

export const useClients = ({ route, isTopFifty = false }) => {
    const { setUserList, setError } = useContext(Context);
    const [refresh, setRefresh] = useState(false);
    const [groupFetched, setGroupFetched] = useState(false);
    const [branchFetched, setBranchFetched] = useState(false);
    const [clientDataFetched, setClientDataFetched] = useState(false);
    const [clientGroup, setClientGroup] = useState([]); // group list
    const [clientBranch, setClientBranch] = useState([]); // branch list
    const [contactsData, setContactsData] = useState([]); // contact list
    const [topFiftyList, setTopFiftyList] = useState([]); // top fifty list
    const [clientData, setClientData] = useState({});
    const [machineRanking, setMachineRanking] = useState([]);
    const [searchText, setSearchText] = useState({ group: '', branch: '' });
    const [selectedDataIndex, setSelectedDataIndex] = useState({
        group: 0,
        branch: null
    });
    // inifite scroll
    const [groupPage, setGroupPage] = useState(1);
    const [branchPage, setBranchPage] = useState(1);
    const [hasMoreGroup, setGroupHasMore] = useState(true);
    const [hasMoreBranch, setBranchHasMore] = useState(true);

    useEffect(() => {
        getUserList({ avoid_brilltek_testing: true }).then(res => setUserList(res.data));
        getTopFiftyList();
    }, []);

    // init
    useEffect(() => {
        loadClientGroupData();
        loadClientBranchData();
    }, [refresh]);

    useEffect(() => {
        if (isTopFifty) {
            loadTopFiftyClients();
        } else {
            setRefresh(!refresh);
        }
    }, [isTopFifty]);

    // select group or branch
    const onChangeType = (type, value) => {
        if (type === 'group') {
            setSelectedDataIndex({ group: value, branch: null });
        } else {
            setSelectedDataIndex(prev => ({ ...prev, branch: value }));
        }
    }

    useEffect(() => {
        if (clientGroup.length && selectedDataIndex.group === 0) {
            loadMachineRanking({ type: 'group' });
        }
    }, [clientGroup, selectedDataIndex.group]);

    useEffect(() => {
        onChangeGroup();
    }, [selectedDataIndex.group]);

    useEffect(() => {
        onChangeBranch();
    }, [selectedDataIndex.branch]);

    // change selected group or selected branch
    const onChangeGroup = () => {
        const group = selectedDataIndex.group;
        if (group !== null && clientGroup[group]) {
            loadClientBranchData({ ...DEFAULT_API_PARAMETER, id_client: clientGroup[group]?.id });
            loadMachineRanking({ type: 'group' });
        } 
    }

    const onChangeBranch = () => {
        const group = selectedDataIndex.group;
        const branch = selectedDataIndex.branch;
        if (branch !== null && clientBranch[branch]) {
            loadContactData({ branch });
            loadClientData({ group, branch });
            loadMachineRanking({ type: 'branch' })
        }
    }

    // load top 50 clients
    const getTopFiftyList = async () => {
        try {
            if (topFiftyList.length > 0) return;
            const { data } = await getTopFiftyClients();

            if (data.length === 0) {
                setClientGroup([]);
                setClientBranch([]);
                setSelectedDataIndex({ group: 0, branch: null });
                setMachineRanking([]);
                return;
            }

            setTopFiftyList(data);
        } catch (err) {
            errHandler(err, setError, route);
        }
    }

    const loadTopFiftyClients = async (text) => {
        try {
            if (topFiftyList.length === 0) {
                await getTopFiftyList();
            }

            let groupList = [...new Set(topFiftyList.map(item => item.id_client))];
            groupList = groupList.map(id => {
                const info = topFiftyList.find(item => item.id_client === id).info_client
                return {
                    ...info,
                    originalName: info.name,
                }
            });
            // search
            if (text) {
                groupList = groupList.filter(item => item.name.toLowerCase().includes(text.toLowerCase()));
                setSelectedDataIndex({ group: 0, branch: null });
            }
            setClientGroup(groupList);
            const id_client = groupList[0]?.id;

            if (clientGroup[selectedDataIndex.group]?.id !== id_client)
                loadTopFiftyBranches({ id_client });
        } catch (e) {
            console.log(e);
            errHandler(e, setError, route)
        } finally {
            setGroupFetched(true);
        }
    }

    const loadTopFiftyBranches = async ({ id_client, text }) => {
        const groupId = id_client ?? clientGroup[selectedDataIndex.group]?.id;
        let branches = topFiftyList?.filter(item => item.id_client === groupId)?.map(item => item.info_branch);
        // search
        if (text) {
            branches = branches.filter(item => item.name.toLowerCase().includes(text.toLowerCase()));
        }
        setClientBranch(branches);
        setBranchFetched(true);
    }

    // load client group data
    const loadClientGroupData = async (params, isScroll = false) => {
        setGroupFetched(false)

        try {
            const query = params ?? DEFAULT_API_PARAMETER;
            const { data } = await getClient(query)
            setGroupHasMore(data.length === query.limit);

            const isFirstFeching = data[0]?.originalName === undefined
            if (isFirstFeching) {
                const processGroup = await Promise.all(data.map(async (group) => {
                    let groupImage = null;
                    try {
                        groupImage = await getImage('client', group.id);
                    } catch (error) {
                        console.log(error.message);
                    }
                    return {
                        ...group,
                        originalName: group.name,
                        imgUrl: groupImage ? groupImage.data.url : null,
                    }
                }));
                setClientGroup(isScroll ? clientGroup.concat(processGroup) : processGroup);
                const id_client = isScroll ? clientGroup[0]?.id : processGroup[0]?.id;

                if (clientGroup[selectedDataIndex.group]?.id !== id_client)
                    loadClientBranchData({ ...DEFAULT_API_PARAMETER, id_client });
            }
        } catch (e) {
            errHandler(e, setError, route)
        } finally {
            setGroupFetched(true);
        }
    }

    const loadClientBranchData = async (params, isScroll = false) => {
        setBranchFetched(false)

        try {
            const query = params ?? DEFAULT_API_PARAMETER;
            const { data } = await getClientBranch(query);
            setBranchHasMore(data.length === query.limit);

            const isFirstFeching = data[0]?.originalName === undefined;
            if (isFirstFeching) {
                const processBranch = await Promise.all(data.map(async (branch) => {
                    let branchImage = null;
                    try {
                        branchImage = await getImage('client_branch', branch.id);
                    } catch (error) {
                        console.log(error.message);
                    }
                    return {
                        ...branch,
                        originalName: branch.name,
                        imgUrl: branchImage ? branchImage.data.url : null,
                    }
                }))
                setClientBranch(isScroll ? clientBranch.concat(processBranch) : processBranch);
            }
        } catch (e) {
            console.log(e);
            errHandler(e, setError, route)
        } finally {
            setBranchFetched(true);
        }
    }

    // load group detail data or branch detail data
    const loadClientData = async ({ group, branch }) => {
        let selectedGroupData = null;
        if (branch !== null) {
            selectedGroupData = (await getClient({ id: clientBranch[branch]?.id_client })).data[0];
        } else {
            selectedGroupData = clientGroup[group];
        }

        let groupImage = null;
        if (selectedGroupData?.id) {
            try {
                groupImage = await getImage('client', selectedGroupData.id);
            } catch (error) {
                console.log(error.message);
            }
        }

        if (selectedDataIndex.client_id == undefined) {
            setClientData({
                group: selectedGroupData ? selectedGroupData.name : '',
                branch: clientBranch[branch]?.name || '',
                address: clientBranch[branch]?.address || '',
                group_id: selectedGroupData ? selectedGroupData.id : '',
                branch_id: clientBranch[branch]?.id || '',
                zip_code: clientBranch[branch]?.zip_code || '',
                city: clientBranch[branch]?.city || '',
                country: clientBranch[branch]?.country || '',
                branches: selectedGroupData ? selectedGroupData.branches : '',
                added_by: clientBranch[branch]?.added_by || selectedGroupData?.added_by,
                imgUrl: clientBranch[branch]?.imgUrl || groupImage ? groupImage.data.url : null,
                first_industry_level: clientBranch[branch]?.info_first_industry_level?.id,
                second_industry_level: clientBranch[branch]?.info_second_industry_level?.id,
                info_first_industry_level: clientBranch[branch]?.info_first_industry_level,
                info_second_industry_level: clientBranch[branch]?.info_second_industry_level,
                id_salesman: clientBranch[branch]?.id_salesman,
                product_manufactured: clientBranch[branch]?.product_manufactured,
                name_of_salesman: clientBranch[branch]?.name_of_salesman,
            });
            setClientDataFetched(true);
        }
    }

    const formatStringToJSON = (key, data) => {
        if (data[key] && typeof data[key] === 'string' && data[key].includes('[')) {
            data[key] = JSON.parse(data[key])
            const regex = /"|\\|\[|\]/ig;
            data[key] = data[key].map(item => item.replaceAll(regex, ''));
        }
        return data[key];
    }

    const loadContactData = async ({ branch }) => {
        try {
            const contacts = (await getClientContact({
                id_client_branch: clientBranch[branch].id,
                limit: -1,
            }));
            const processContacts = await Promise.all(contacts.data.map(async (contact) => {
                let contactImg = null;
                try {
                    contactImg = await getImage('client_contact', contact.id)
                } catch (err) {
                    console.log(err)
                }

                const phone = formatStringToJSON('phone', contact);
                const email = formatStringToJSON('email', contact);

                return {
                    ...contact,
                    imgUrl: contactImg ? contactImg.data.url : null,
                    icon: contact.first_name + contact.last_name,
                    name: contact.full_name,
                    sub: phone,
                    phone,
                    email,
                }
            }))
            setContactsData(processContacts);
        } catch (err) {
            console.log(err);
            errHandler(err, setError, route);
        }
    }

    const loadMachineRanking = async ({ type = 'group' }) => {
        try {
            let query = { limit: 3 };
            if (type === 'group' && clientGroup.length) {
                query.id_client = clientGroup[selectedDataIndex.group].id;
            } else if (type === 'branch' && clientBranch.length) {
                query.id_client_branch = clientBranch[selectedDataIndex.branch].id;
            }
            if (!query.id_client && !query.id_client_branch) return;

            const ranking = (await getClientMachineRanking(query)).data.map((item, index) => ({
                bestSeller: index + 1,
                name: item.USE_MACH_SA,
                quantity: item.COUNT
            }));
            const filler = Array(3 - ranking.length).fill(null).map((_, index) => ({
                bestSeller: ranking.length + index + 1,
                name: '-',
                quantity: '-'
            }));
            setMachineRanking(ranking.concat(filler));            
        } catch (err) {
            console.log(err);
            errHandler(err, setError, route);
        }
    }

    const queryData = ({ type, text, isScroll = false, isSearch = false, setPage }) => {
        if (type === 'group' && (hasMoreGroup || isSearch)) {
            if (isTopFifty) {
                loadTopFiftyClients(text);
            } else {
                loadClientGroupData({ offset: !isScroll ? 0 : groupPage * 10, limit: 10, name: text }, isScroll);
            }
        } else if (type === 'branch' && (hasMoreBranch || isSearch)) {
            if (isTopFifty) {
                loadTopFiftyBranches({ text });
            } else {
                let payload = { offset: !isScroll ? 0 : branchPage * 10, limit: 10, name: text };
                if (clientGroup[selectedDataIndex.group]) {
                    payload = { ...payload, id_client: clientGroup[selectedDataIndex.group].id };
                }
                loadClientBranchData(payload, isScroll);
            }
        }
        if (isScroll) {
            setPage();
        }
    }

    const onSearch = debounce((type, text) => {
        setSearchText({ ...searchText, [type]: text });
        if (type === 'group') {
            setGroupPage(1);
        } else {
            setBranchPage(1);
        }
        queryData({ type, text, isSearch: true });
    }, 300);

    const onEndReached = (key, setPage) => {
        queryData({ type: key, text: searchText[key], isScroll: true, setPage });
    }

    const reload = () => {
        setRefresh(!refresh);
    }

    return {
        clientGroup,
        setClientGroup,
        clientBranch,
        groupFetched,
        branchFetched,
        clientDataFetched,
        setClientDataFetched,
        selectedDataIndex,
        setSelectedDataIndex,
        clientData,
        setClientData,
        contactsData,
        setContactsData,
        machineRanking,
        groupPage,
        branchPage,
        setGroupPage,
        setBranchPage,
        onSearch,
        reload,
        onChangeType,
        onEndReached
    }
}