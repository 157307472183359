import { makeStyles } from '@material-ui/core/styles'; 

const useStyles = makeStyles({
    body: {
        overflowY: 'scroll'
    },
    title: {
        marginBottom: '16px',
        fontSize: '20px',
        fontWeight:' bold',
        color: 'rgba(0,0,0,.54)'
    },
    wrap:{
        flex: 1,
        cursor: 'default',
        height: 'fit-content',
        padding: '16px 16px',
        backgroundColor: 'white',
        border: '1px solid #E0E0E0',
        borderRadius: '8px',
        '& ::-webkit-scrollbar': {
            backgroundColor: 'transparent',
            width: 0
        },
        '& ::-webkit-scrollbar-track': {
            backgroundColor: 'transparent'
        }
    }
})
export default useStyles;