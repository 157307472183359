import { cloneElement } from "preact";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "rgba(0, 0, 0, 0.54)",
    cursor: "pointer",
    marginRight: theme.spacing(1),
    "&:hover": {
      color: "rgba(0, 0, 0, 0.38)",
    },
    "&:last-child": {
      marginRight: 0,
    },
  },
  disabled: {
    color: "transparent",
    cursor: "inherit",
    "&:hover": {
    color: "transparent",
    },
  },
}));

export const Icon = ({ children, disabled }) => {
  const classes = useStyles();
  return cloneElement(children, {
    className: clsx(classes.icon, disabled && classes.disabled),
  });
};
