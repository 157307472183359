/** @jsx h */
import { h } from "preact";
import { forwardRef } from "preact/compat";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";

import I18n from "i18n/i18n";

const useStyles = makeStyles((theme) => ({
  head: {
    color: "rgba(0, 0, 0, 0.38)",
  },
  hideSmall: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

export const SortableTableHead = forwardRef(
  ({ columns, orderBy, order, onRequestSort, backgroundColor }, ref) => {
    const classes = useStyles();
    return (
      <TableHead ref={ref} style={{ backgroundColor }}>
        <TableRow>
          {columns.map(({ id, label, sortable, className, disableLabel }) =>
            sortable ? (
              <TableCell
                key={id}
                className={`${id === "size" ? classes.hideSmall : ""} ${className ? className : ''}`}
                sortDirection={orderBy === id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === id}
                  direction={orderBy === id ? order : "asc"}
                  onClick={() => onRequestSort(id)}
                  className={classes.head}
                  classes={{ active: classes.head }}
                >
                  {label || I18n.t(id)}
                </TableSortLabel>
              </TableCell>
            ) : (
              <TableCell className={`${classes.head} ${className ? className : ''}`}>
                {disableLabel ? '' : (label || I18n.t(id))}
              </TableCell>
            )
          )}
        </TableRow>
      </TableHead>
    );
  }
);

SortableTableHead.propTypes = {
  columns: PropTypes.array,
  orderBy: PropTypes.string,
  order: PropTypes.oneOf(["asc", "desc"]),
  onRequestSort: PropTypes.func,
  editColumn: PropTypes.bool,
  backgroundColor: PropTypes.string,
};

SortableTableHead.defaultProps = {
  columns: [],
  orderBy: undefined,
  order: "asc",
  onRequestSort: () => { },
  editColumn: false,
  backgroundColor: "white",
};
