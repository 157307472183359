import { h } from 'preact';
import { createTheme, ThemeProvider } from '@material-ui/core/styles'

const ThemeCustomProvider = ({ children }) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#2E65C9',
      },
    },
    typography: {
      fontFamily: [
        '-apple-system',
        'Arial',
        'Roboto',
        '"Helvetica Neue"',
        'sans-serif',
      ].join(','),
    },
  })

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default ThemeCustomProvider
