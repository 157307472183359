/** @jsx h */
import { h } from "preact";
import { useStyles } from './TimelineItem.style';
import I18n from "i18n-js";

import MuiTimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import PhoneIcon from '@material-ui/icons/Phone';
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Link from "@material-ui/core/Link";
import { Row } from "components/atoms/row/Row";

import { Skeleton } from "@material-ui/lab";

export const TimelineItem = ({ data, onClick }) => {
  const classes = useStyles();

  if (!data) {
    return (
      <MuiTimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Row>
            <Skeleton variant="rect" height={26} width={73} style={{
              borderRadius: "15px",
              position: 'relative',
              height: 26,
              width: 73,
              marginRight: "5px",
            }} />
            <Skeleton variant="rect" height={56} width={450} />
          </Row>
        </TimelineContent>
      </MuiTimelineItem>
    )
  } else {
    const contentType = {
      location:
        <Row
          flex={1}
          justifyContent='space-between'
          alignItems='center'
          className={`${classes.activity} location`}
          onClick={onClick}
        >
          <Typography variant='body2'>{I18n.t("at")} {data.location}</Typography>
          <Row
            justifyContent='space-between'
            alignItems='center'
            className={classes.timeRange}
          >
            <Card>{data.start}</Card>
            <span>-</span>
            <Card>{data.end}</Card>
          </Row>
        </Row>,

      nopunch:
        <Row
          flex={1}
          justifyContent='space-between'
          alignItems='center'
          className={`${classes.activity} nopunch`}
          onClick={onClick}
        >
          <Typography variant='body2'>{I18n.t("at")} {data.location}</Typography>
          <Typography variant='body2' className='warningText'>{I18n.t("noPunch").toUpperCase()}</Typography>
        </Row>,

      phone:
        <Row
          flex={1}
          justifyContent='space-between'
          alignItems='center'
          className={`${classes.activity} phone`}
        >
          <Row>
            <PhoneIcon />
            <Typography variant='body2' className={classes.marginLeft1}>{I18n.t("to")} {data.location}</Typography>
          </Row>
          <Row
            justifyContent='flex-end'
            alignItems='center'
            className={classes.timeRange}
          >
            <Card>{data.start}</Card>
          </Row>
        </Row>,

      now: <Row alignItems='center' className={classes.marginLeft1}><Typography variant='body2'>{data.content}</Typography></Row>
    }

    return (
      <MuiTimelineItem className={data.type + ' ' + classes.root}>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Row>
            <div className={'timeLabel ' + classes.timeLabel}>
              <span>{data.time}</span>
            </div>
            {contentType[data.type]}
          </Row>
        </TimelineContent>
      </MuiTimelineItem>
    )
  }
}