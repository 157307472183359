/*
    Generates a random number between min and max
*/
export const RNG = (min = 0, max = 1, returnInt = true) => {
    let result = Math.random() * (max - min) + min;
    return returnInt ? Math.floor(result) : result;
}

/*
    Generates an array with n amount of zeros, useful for
    when generating skeleton components
*/
export const zeroArray = (n) => {
    let array = [];
    for(let i = 0; i < n; i++)
        array[i] = 0;
    return array;
} 

/**
 * Error handler that shows meaningful I18n error messages via snackbar,
 * or redirects the user to an error page of a given status code
 * @param {Error} err - the error object caught by .catch() or try/catch
 * @param {Function} fn - the function that sets the display message for snackbar
 * @param {Function} route - the router function that redirects to a given page
 */
import I18n from "i18n-js";
import moment from "moment";
import zhTw from "i18n/zh-tw";
import en from "i18n/en";
export const errHandler = (err = {}, fn, route) => {
    const errData = err.response?.data
    if(errData.errors?.length) {
        const errObj = errData.errors[0]
        const msg = errObj.msg;
        const param = errObj.param;
        const type = msg.match(/NOT_FOUND|ALREADY_FOUND|Invalid/)?.[0];
        const route = msg.split("_")[0];

        if(type === "Invalid") {
            let name = I18n.t(param);
            if (zhTw[`FIELD_${param}`] || en[`FIELD_${param}`]) {
                name = I18n.t(`FIELD_${param}`);
            }
            fn(I18n.t(type, { name }));
        } else if (type === "NOT_FOUND" || type === "ALREADY_FOUND") {
            fn(I18n.t(type, { name: I18n.t(`ROUTE_${route}`) }));
        } else {
            fn(I18n.t(msg));
        }
    } else if(err?.message){
        // route to error page
        const statusCode = err.message.match(/\d+/);
        if(statusCode){
            route(`/page/${statusCode[0]}`);
        }
    } else {
        console.log('unexpected error:', err)
    }
}

/**
 * @param {Date | string} date1
 * @param {Date | string} date2
 * @returns {boolean}
 */
export const compareDate = (date1, date2) => moment(date1).format('yyyy-MM-DD') === moment(date2).format('yyyy-MM-DD');

/**
 * 
 * @param {*} value - the value to be checked
 * @returns {boolean} - true if the value is null, undefined or an empty string
 */
export const isEmptyValue = (value) => value === null || value === undefined || (typeof value === 'string' && !value.trim().length);

/**
 * @pram {Object} obj - the object to be checked
 * @returns {Object} - the object with empty fields removed
 */
export const removeEmptyFields = (obj) => {
    const result = { ...obj };
    Object.keys(result).forEach((key) => {
        if (isEmptyValue(result[key])) {
            delete result[key];
        }
    });

    return result;
}

/**
 * @param {string} str - string to be converted to number
 * @returns {string | number}
 */
export const stringToNumber = (str) => {
    if (typeof str === 'string' && /^\d{1,3}(,\d{3})*$/.test(str)) {
        return Number(str.replaceAll(',', ''));
    }
    return str;
}

/**
 * debounce function
 * @param {*} fn - function to be debounced
 * @param {*} delay - delay in milliseconds
 * @returns 
 */
export const debounce = (fn, delay = 300) => {
    let callback = fn;    
    let timerId = null;

    function debounced() {
        let context = this;
        let args = arguments;        

        clearTimeout(timerId);        
        timerId = setTimeout(function() {            
            callback.apply(context, args);
        }, delay);
    }

    return debounced;
}

/**
 * function to sort an array of objects in ascending order
 * @param {*} a - the first object to be compared
 * @param {*} b - the second object to be compared
 * @param {*} orderBy - the field to be compared
 * @returns 
 */
export const descendingComparator = (a, b, orderBy) => {
    const aValue = stringToNumber(a[orderBy]);
    const bValue = stringToNumber(b[orderBy]);

    if (bValue < aValue) {
        return -1;
    }
    if (bValue > aValue) {
        return 1;
    }
    return 0;
};

export const getComparator = (order, orderBy) => {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
};

export const getMonthWithZero = (monthNumber) => {
  return monthNumber < 10 ? `0${monthNumber}` : monthNumber.toString();
};

export const downloadXlsx = (data, filename) => {
  const blob = new Blob([data], { type: 'application/octet-stream' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${filename}.xlsx`;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};