/** @jsx h */
import { h } from "preact";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import I18n from "i18n/i18n";

export const DeleteModal = ({ open, handleClose, name, submit = () => {} }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle style={{ color: "rgba(0, 0, 0, 0.58)" }}>
        <b>{I18n.t("DeleteConfirmTitle")}</b>
      </DialogTitle>
      <DialogContent>
        <Typography style={{ color: "rgba(0, 0, 0, 0.54)" }}>
          {I18n.t("DeleteConfirm")}
          <span style={{ color: "rgba(0, 0, 0, 0.87)" }}>{name}</span>
          {I18n.t("Questionmark")}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} style={{ color: "rgba(0, 0, 0, 0.26)" }}>
          {I18n.t("Cancel")}
        </Button>
        <Button onClick={submit} color="secondary">
          {I18n.t("Delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
