import { useState, useEffect, useContext } from "preact/hooks";
import { getUserList } from "api";
import context from "context";

const useFormatOptions = (data) =>
    data.map(({ first_name, last_name, id }) => ({ label: `${last_name}${first_name}`, value: id }))

export const useUserList = () => {
    const { userList = [], setUserList } = useContext(context);
    const [list, setList] = useState([]);
    const formattedList = useFormatOptions(userList);

    useEffect(() => {
        if (!userList.length) {
            getUserList({ avoid_brilltek_testing: true }).then((data) => {
                setUserList(data);
            });
        } else {
            setList(formattedList);
        }
    }, [userList]);

    const searchUser = (searchValue) => {
        if (!searchValue) {
            if (searchValue === '') {
                setList(formattedList);
            }
            return;
        }

        const data = userList.filter(item => `${item.last_name}${item.first_name}`.includes(searchValue));
        setList(useFormatOptions(data));
    }

    return {
        userList: list,
        setUserList: setList,
        searchUser
    }
}