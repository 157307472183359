/** @jsx h */
import { h, Fragment } from "preact";
import { useState, useCallback, useMemo, useEffect } from "preact/hooks";
import PropTypes from "prop-types";
import { useDrag, useDrop } from "react-dnd";
import I18n from "i18n-js";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Collapse from "@material-ui/core/Collapse";

import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
	fileIcon: {
		marginRight: theme.spacing(1),
	},
	hideSmall: {
		[theme.breakpoints.down("sm")]: {
			display: "none",
		},
	},
}));

export const CollapseRow = ({
    isCustomRow = false,
	columns,
	customColumns,
	row,
	rowId,
	index,
	level,
	selectRow,
	setSelectRow,
	border,
	canDropDrag,
	handleDrop,
	alwaysOpen,
}) => {
	const classes = useStyles();
	const [open, setOpen] = useState(false);

	const onClick = useCallback(() => {
		const newOpen = !open;
		if (!row.children) {
			if (selectRow === rowId) {
				setSelectRow();
			} else {
				setSelectRow(rowId);
			}
		} else setOpen(newOpen);
	}, [open, row.children, rowId, selectRow, setSelectRow]);

	const backgroundColor = useMemo(() => {
        if (isCustomRow && open) {
            return "#DBDFF8";
        } else if (selectRow === rowId) {
            return "#EFF5FF";
        } else if (index !== undefined && index % 2 === 0) {
            return "#fafafa";
        } else {
            return undefined;
        }
    }, [isCustomRow, open, selectRow, rowId, index]);

    let isDragging, dragRef, hovered, dropRef = null;
	if(canDropDrag) {
    [{ isDragging }, dragRef] = useDrag(
        useMemo(() => ({
            type: "row",
            item: row,
            collect: (monitor) => ({ isDragging: monitor.isDragging() }),
            canDrag: canDropDrag,
        }), [row, canDropDrag])
    );

    [{ hovered }, dropRef] = useDrop(
        useMemo(() => ({
            accept: "row",
            drop: (item) => handleDrop(item, row),
            canDrop: ({ id }) => canDropDrag && id !== row.id,
            collect: (monitor) => ({
              hovered: monitor.isOver(),
            }),
        }), [row, canDropDrag, handleDrop])
    );
    }
    useEffect(() => {
        if (canDropDrag) {
            dragRef(dropRef);
        }
    }, [canDropDrag, dragRef, dropRef]);

    const cursor = useMemo(() => isDragging ? "grabbing" : "grab", [isDragging]);

    const rowStyle = useMemo(() => {
        let style = { backgroundColor };
        if (canDropDrag) {
          style = { ...style, cursor: isDragging ? "grabbing" : "grab" };
        }
        return style;
      }, [backgroundColor, canDropDrag, isDragging]);

    return (
		<Fragment>
			<TableRow ref={dropRef} onClick={onClick} style={rowStyle}>
				{columns.map((col, index) => {
					const firstCol = index === 0;
					return (
						<TableCell
							key={index}
							ref={firstCol ? dragRef : undefined}
							style={{
								width: col.width || (!firstCol ? "200px" : undefined),
								cursor: firstCol && canDropDrag ? cursor : undefined,
								border: open ? "none" : border,
								...(col.rowCellStyle || {}),
							}}
						>
							<div
								style={{
									// paddingLeft: firstCol ? `${level * 30}px` : undefined,
									display: "flex",
									alignItems: "center",
									...(col.rowLabelStyle || {}),
								}}
							>
								{!alwaysOpen && !isCustomRow && firstCol &&
									row.children &&
									(open ? (
										<ExpandMoreIcon color="action" className={classes.fileIcon} />
									) : (
										<ChevronRightIcon color="action" className={classes.fileIcon} />
									))
								}
								{col.onlyFirstLevel && level !== 1 
                                    ? null
                                    : (col.Cell ? col.Cell(row, col) : row[col.id])}
							</div>
						</TableCell>
					);
				})}
			</TableRow>
			{row.children && (
                <TableRow style={{ backgroundColor: isCustomRow ? "#FAFCFF" :  backgroundColor }}>
                    <TableCell
                        colSpan={columns.length + 1}
                        style={{ padding: 0, borderWidth: isCustomRow && open ? 1 : 0, borderColor: '#ddd' }}
                    >
                        <Collapse in={alwaysOpen || open}>
                            <Table>
                                <TableBody>
                                    {isCustomRow && (
                                        <TableRow
                                            onClick={onClick}
                                            style={{ backgroundColor: alwaysOpen || open ? "#EFF5FF" : undefined }}
                                        >
                                            {customColumns.map(({ id }) => (
                                                <TableCell key={id} component="th">
                                                    {I18n.t(id)}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    )}
                                    {row.children.map((item, i) => (
                                        <CollapseRow
                                            key={i}
                                            rowId={`${index}-${i}`}
                                            columns={isCustomRow ? customColumns : columns}
                                            row={item}
                                            level={level + 1}
                                            selectRow={selectRow}
                                            setSelectRow={setSelectRow}
                                            border={i === row.children.length - 1 ? border : "none"}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </Collapse>
                    </TableCell>
                </TableRow>
			)}
		</Fragment>
	);
};

CollapseRow.propTypes = {
	columns: PropTypes.array,
	row: PropTypes.shape({}),
	level: PropTypes.number.isRequired,
	handleIconClick: PropTypes.func,
	backgroundColor: PropTypes.string,
	cursor: PropTypes.string,
};

CollapseRow.defaultProps = {
	columns: [],
	row: {},
	display: true,
	level: 0,
	cursor: "grab",
	dragRef: undefined,
	dropRef: undefined,
};
