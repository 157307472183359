import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
	select: {
		cursor: "pointer",
		display: "inline-block",
		width: "180px",
		padding: "8px",
		backgroundColor: "white",
		borderRadius: "8px",
		transition: "all 300ms ease",
		"& span": {},
		"& div:first-child": {
			"& span": {
				margin: "0 10px",
			},
		},
	},
	list: {
		maxHeight: "50vh",
		overflow: "auto",
		cursor: "pointer",
		position: "absolute",
		zIndex: "100",
		width: "100%",
		bottom: 0,
		left: 0,
		padding: "8px",
		color: "rgba(0,0,0,.54)",
		borderRadius: "8px",
		backgroundColor: "white",
		boxShadow: "2px 2px 2px rgb(0 0 0 / 10%)",
		transform: "translateY(102%)",
		"& span:nth-child(n)": {
			textAlign: "left",
			padding: "8px 4px",
		},
		"& span:nth-child(n):hover": {
			color: "#2E65C9",
			backgroundColor: "#f2faff",
		},
	},
});
export default useStyles;
