/** @jsx h */
import React from 'react';
import { h } from "preact";
import { useState, useEffect, useContext, useMemo } from "preact/hooks";
import { route } from "preact-router";
import I18n from "i18n-js";

import MuiButton from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { Row } from 'components/atoms/row/Row';
import { List } from 'components/molecules/list/List';
import MachineProfile from "components/molecules/machineProfile/MachineProfile";
import EmptyIcon from 'components/atoms/emptyIcon/emptyIcon';
import CubeBtn from 'components/atoms/cubeButton/CubeBtn';

import context from 'context';
import { errHandler } from 'util';
import { formatPrice } from 'helper/format';
import { getProductSpecificationSeries, getProductSpecificationMachineList } from 'api';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    width: "100%",
    color: "rgba(0, 0, 0, 0.54)",
  },
  listContainer: {
    '& > div + div': {
      marginTop: theme.spacing(3)
    }
  },
  container: {
    flex: 1,
    cursor: 'default',
    height: 'fit-content',
    padding: '16px 16px',
    backgroundColor: 'white',
    border: '1px solid #E0E0E0',
    borderRadius: '8px',

    '& .title': {
      fontSize: '20px',
      fontWeight: ' bold',
      color: 'rgba(0,0,0,.54)'
    },

    '& .textButton': {
      color: '#2E65C9',
      padding: theme.spacing(0, 2)
    },

    '& ::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      width: 0
    },

    '& ::-webkit-scrollbar-track': {
      backgroundColor: 'transparent'
    }
  },
  'mb2': {
    marginBottom: theme.spacing(2)
  },
  'ml2': {
    marginLeft: theme.spacing(2)
  },
  box: {
    backgroundColor: '#F5F5F5',
    padding: theme.spacing(1, 2),
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(1),
    cursor: 'pointer',
  }
}));

const Overview = () => {
  const classes = useStyles();
  const { machineList, setMachineList, machineSeriesList, setMachineSeriesList, setError } = useContext(context);
  const [selected, setSelected] = useState({ series: null, machine: null });
  const [searchText, setSearchText] = useState({ series: '', machine: '' });
  const [refresh, setRefresh] = useState(false);
  const [openMachineProfile, setOpenMachineProfile] = useState(false);

  useEffect(() => {
    loadMachineFromOracle()
  }, [refresh]);

  const loadMachineFromOracle = async () => {
    try {
      const [seriesList, machineList] = await Promise.all([
        getProductSpecificationSeries({ fcsOnly: true }),
        getProductSpecificationMachineList()
      ]);
      const series = seriesList.data?.map((item) => ({ name: item.SERIES_NAME }));
      const machines = machineList.data?.map((item) => ({
        series: item.SERIES_NAME,
        name: item.USA_SCREW,
        price: item.PRICE,
        priceUSD: item.PRICE_USD,
        typeId: item.TYPE_ID
      }));
      setMachineSeriesList(series);
      setMachineList(machines);
      setSelected({ series: series.length ? series[0] : null, machine: null });
    } catch (err) {
      errHandler(err, setError, route);
    }
  }

  const onSearch = (key, value) => {
    setSearchText({ ...searchText, [key]: value });
  }

  const filteredSeries = useMemo(() => {
    const searchValue = searchText.series.toLowerCase()
    return machineSeriesList.filter((series) => !!searchValue.length
      ? series.name?.toLowerCase().includes(searchValue)
      : series
    ).sort((a, b) => a.name > b.name ? 1 : -1)
  }, [machineSeriesList, searchText.series])

  const filteredMachines = useMemo(() => {
    if (!selected.series) return [];
    const searchValue = searchText.machine.toLowerCase()
    const seriesMachines = machineList.filter(({ series }) => series === selected.series.name)
    return seriesMachines.filter((machine) => !!searchValue.length
      ? machine.name?.toLowerCase().includes(searchValue)
      : machine
    ).sort((a, b) => a.name > b.name ? 1 : -1)
  }, [machineList, searchText.machine, selected.series])

  if (openMachineProfile) return (
    <MachineProfile
      data={selected.machine}
      goBack={() => {
        setOpenMachineProfile(false);
        setSelected({ ...selected, machine: null })
      }}
    />
  );

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={5} className={classes.listContainer}>
          <List
            noAvatar
            isFetched={!!filteredSeries.length || !!searchText.series.length}
            data={filteredSeries}
            search={true}
            title={I18n.t("machineSeries")}
            width='100%'
            contentHeight='258px'
            searchId='series'
            searchText={searchText.series}
            placeholder={I18n.t("machineSeries")}
            selectable={true}
            overideSelect={selected.series ? filteredSeries.indexOf(selected.series) : null}
            onChange={(value) => onSearch('series', value)}
            onClick={(i) => setSelected({ series: filteredSeries[i], machine: null })}
          />
        </Grid>
        <Grid item xs={12} md={7}>
          <Container
            title={selected.series?.name || I18n.t('Information')}
            searchable={true}
            searchText={searchText.machine}
            onSearch={(val) => onSearch('machine', val)}
          >
            {(selected.series && !!filteredMachines.length)
              ? filteredMachines?.map((row, i) => (
                  <MachineItem
                    key={i}
                    data={row}
                    setRefresh={setRefresh}
                    onClick={() => {
                      setSelected({ ...selected, machine: row });
                      setOpenMachineProfile(true);
                    }}
                  />
                ))
              : <EmptyIcon />
            }
          </Container>
        </Grid>
      </Grid>
    </div>
  )
}

const MachineItem = ({ data, onClick }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Row justifyContent='space-between' alignItems='center' className={classes.box} onClick={onClick}>
      <Row alignItems='center' style={{ width: "33%", paddingTop: 8, paddingBottom: 8 }}>
        <Typography variant='body2'>{data.name}</Typography>
      </Row>
      <Row alignItems='center' style={{ width: "33%" }}>
        <Typography variant='body2'>
          <>
            NTD{' '}
            <span style={{ color: theme.palette.text.primary }}>$
              {formatPrice(data.price)}
            </span>
          </>
        </Typography>
      </Row>
      <Row alignItems='center' style={{ width: "33%" }}>
        <Typography variant='body2'>
          <>
            USD{' '}
            <span style={{ color: theme.palette.text.primary }}>$
              {formatPrice(data.priceUSD)}
            </span>
          </>
        </Typography>
      </Row>
    </Row>
  )
}

const Container = ({ title, seeMore, searchable = false, searchText = '', onSearch = () => { }, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Row justifyContent='space-between' alignItems='center' className={classes.mb2}>
        <Row>
          <Typography variant="h6" color="textSecondary" className='title'>{title}</Typography>
          {seeMore && <MuiButton variant='text' className='textButton ml2' onClick={seeMore}>{I18n.t('seeMore').toUpperCase()}</MuiButton>}
        </Row>
        {searchable && (
          <CubeBtn
            bgColor="#F5F5F5"
            textColor="rgba(0, 0, 0, .54)"
            iconType="search"
            expandable
            searchText={searchText}
            placeholder={I18n.t("machine")}
            onChange={(e) => onSearch(e)}
          />
        )}
      </Row>
      {children}
    </div>
  )
};

export default Overview;
