/** @jsx h */
import React from 'react';
import { h } from "preact";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { Row } from 'components/atoms/row/Row';
import EquipmentOption from "components/atoms/equipmentOption/EquipmentOption";
import EmptyIcon from 'components/atoms/emptyIcon/emptyIcon';
import { formatPrice } from 'helper/format';

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    cursor: 'default',
    height: 'fit-content',
    padding: '16px',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    width: '100%',
    overflow: 'auto',

    '& .title': {
      marginBottom: theme.spacing(2),
      color: theme.palette.text.disabled
    },
  },
}));

const Category = ({ name, statements, style }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.container} style={style}>
      <Typography variant="body1" className='title'>{name}</Typography>
      {
        statements?.length > 0
          ? statements.map((row) => (
            <Row justifyContent='space-between' alignItems='center'>
              <Row alignItems='center' style={{ width: 150, marginBottom: 10 }}>
                <EquipmentOption
                  id={row?.id}
                  type={row?.type}
                />
                <Typography
                  variant='body2'
                  style={{ color: theme.palette.text.disabled, marginLeft: 8 }}
                >
                  {row?.name}
                </Typography>
              </Row>
              <Typography
                variant='body2'
                color='textPrimary'
                style={{ width: 80 }}
              >
                ${formatPrice(row?.price)}
              </Typography>
              <Typography
                variant="body2"
                color='textPrimary'
                style={{ width: 400, textAlign: 'right' }}
              >
                {row?.statement}
              </Typography>
            </Row>
          ))
          : <Row justifyContent='space-between' alignItems='center'>
            <EmptyIcon />
          </Row>
      }
    </div>
  )
}
export default Category;