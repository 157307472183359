import moment from "moment"
import { getImage } from "api"

const CURRENT_DATE = moment().format('yyyy-MM-DD')

export const useUserImage = () => {
  const checkIsImageExpired = async (date) => {
    if (moment().diff(date, 'days') >= 3) {
      return true
    }
    return false
  }

  const getUserImageFromAPI = async (id) => {
    const image = (await getImage('user', id)).data
    setUserImage(id, image.url)
    return image.url
  }

  const getUserImage = async (id) => {
    const users = JSON.parse(localStorage.getItem('userImages'))
    if (!!users?.length) {
      const user = users.find(image => image.id === id)
      if (!user || await checkIsImageExpired(user.date)) {
        return await getUserImageFromAPI(id)
      }
      return user?.image ?? null
    }
    return await getUserImageFromAPI(id)
  }

  const setUserImage = (id, image) => {
    let users = JSON.parse(localStorage.getItem('userImages'))
    const newData = { id, image, date: CURRENT_DATE }
    if (!!users?.length) {
      let existData = users.find(user => user.id === id)
      if (!!existData) {
        existData = newData
      } else {
        users.push(newData)
      }
    } else {
      users = [newData]
    }
    localStorage.setItem('userImages', JSON.stringify(users))
  }

  const removeUserImage = (id) => {
    let users = JSON.parse(localStorage.getItem('userImages'))
    if (!!users?.length) {
      users = users.filter(user => user.id !== id)
      localStorage.setItem('userImages', JSON.stringify(users))
    }
  }

  return {
    getUserImage,
    setUserImage,
    removeUserImage
  }
}