import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
	info: {
		display: "flex",
		color: "rgba(0, 0, 0, 0.54)",
		fontWeight: 300,
		marginBottom: "15px",
	},
	infoItem: {
		marginRight: "15px",
		"& span": {
			color: "black",
		},
	},
	note: {
		color: "black",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		padding: "10px",
		border: "1px solid rgb(224, 224, 224)",
		backgroundColor: "#FAFAFA",
		borderRadius: "8px",
		"& .content": {
			display: "flex",
			flexDirection: "row",
			fontSize: "18px",
		},
		"& .left, .right": {
			fontSize: "24px",
			fontStyle: "italic",
			fontWeight: 900,
		},
		"& .left": {
			alignSelf: "flex-start",
		},
		"& .right": {
			alignSelf: "flex-end",
		},
		"& .message": {
			alignSelf: "center",
			margin: "40px 15px",
		},
	},
});
export default useStyles;
