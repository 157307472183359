import { h } from "preact";
import { makeStyles } from "@material-ui/core/styles";
import ErrorPage  from "./errorPage";
import Icon from "../../assets/Frame.svg";

const useStyles = makeStyles({
    message: {
        fontSize: "24px",
        color: "rgba(0,0,0,0.54)",
        height: "130px",
    },
    description: {
        fontSize: "20px"
    },
    bold: {
        fontWeight: 600,
    },
});

const Page502 = () => {
    const classes = useStyles();

    return (
        <ErrorPage
            statusCode="502"
            icon={Icon}
        >
            <p className={classes.message}>
                Bad gateway. <br/>
                The server encounter an error and could not complete your request
            </p> 
            <p className={classes.description}>
                <span className={classes.bold}>
                    Please try again in a moment
                </span>
            </p>
        </ErrorPage>
    );   
}
export default Page502;