/** @jsx h */
import { h } from 'preact';
import { useState, useEffect, useRef } from 'preact/hooks';
// import classes from './CubeBtn.style'
import useStyles from './CubeBtn.style'

import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import FileCopyOutlineIcon from '@material-ui/icons/FileCopyOutlined';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import InputIcon from '@material-ui/icons/Input';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import GetAppIcon from '@material-ui/icons/GetApp';
import EjectIcon from '@material-ui/icons/Eject';
import DownloadIcon from "@material-ui/icons/GetApp"
import AddIcon from '@material-ui/icons/Add';
import BlockIcon from '@material-ui/icons/Block';

import I18n from 'i18n/i18n';

/** @typedef {'search' | 'edit' | 'delete' | 'lock-open' | 'back' | 'arrow-back' | 'arrow-next' | 'check' | 'close' | 'import' | 'input' | 'export' | 'add'} IconType */

/**
 * @param {{
 *  textColor: string,
 *  type: IconType,
 *  onClick: () => void,
 * }} param0
 */
const Icon = ({ textColor, type, onClick }) => {
    const [color, setColor] = useState({ color: 'rgba(0, 0, 0, .54)' })

    useEffect(() => {
        if (textColor)
            setColor({ color: textColor })
        else
            setColor({ color: 'rgba(0, 0, 0, .54)' })
    }, [textColor])

    if (type === 'search')
        return <SearchIcon style={color} onClick={onClick} />
    if (type === 'edit')
        return <EditIcon style={color} onClick={onClick} />
    if (type === 'delete')
        return <DeleteOutlineIcon style={color} onClick={onClick} />
    if (type === 'save')
        return <FileCopyOutlineIcon style={color} onClick={onClick} />
    if (type === 'lock-open')
        return <LockOpenIcon style={color} onClick={onClick} />
    if (type === 'back')
        return <ArrowBackIcon style={color} onClick={onClick} />
    if (type === 'arrow-back')
        return <NavigateBeforeIcon style={color} onClick={onClick} />
    if (type === 'arrow-next')
        return <KeyboardArrowRightIcon style={color} onClick={onClick} />
    if (type === 'check')
        return <CheckIcon style={color} onClick={onClick} />
    if (type === 'close')
        return <CloseIcon style={color} onClick={onClick} />
    if (type === 'import')
        return <GetAppIcon style={color} onClick={onClick} />
    if (type === 'input')
        return <InputIcon style={color} onClick={onClick} />
    if (type === 'export')
        return <EjectIcon style={color} onClick={onClick} />
    if (type === 'download')
        return <DownloadIcon style={color} onClick={onClick} />
    if (type === 'add')
        return <AddIcon style={color} onClick={onClick} />
    if (type === 'disabled')
        return <BlockIcon style={color} onClick={onClick} />
    else
        return null
}

/**
 * @param {{
 *  expandable: boolean,
 *  bgColor: string,
 *  iconType: IconType,
 *  text: string,
 *  textColor: string,
 *  onClick: (active: boolean) => void,
 *  onChange: (text: string) => void,
 *  searchId: string,
 *  searchText: string,
 *  className: string
 *  style: object
 *  placeholder: string
 * }} param0 
 */
const Button = ({ expandable, bgColor, iconType, text, textColor, disabled = false, onClick, onChange, searchId, searchText = '', className, style = {}, placeholder = '' }) => {
    const locale = localStorage.getItem('locale');
    const [actived, setActived] = useState(false);
    const classes = useStyles()
    const inputRef = useRef(null)
    const onComposition = useRef(false)

    if (!searchId) searchId = 'search-input';
    function trigger() {
        setActived(!actived)
        const placeholderLength = inputRef.current.getAttribute('placeholder').length;
        const inputWidth = locale === 'en' 
            ? placeholderLength <= 12 ? '130px' : placeholderLength * 10 + 'px'
            : placeholderLength <= 7 ? '130px' : placeholderLength * 15 + 'px';

        document.getElementById(searchId).style.width = actived ? '0px' : inputWidth;
        document.getElementById(searchId).style.padding = actived ? '0px' : '0 8px';
        // onClick('active')
    }

    /**
     * @description https://github.com/facebook/react/issues/3926 
     */
    const handleChange = (event) => {
        // IME method start
        if (event.type === 'compositionstart') {
            onComposition.current = true
            return
        }
    
        // IME method end
        if (event.type === 'compositionend') {
            onComposition.current = false
        }

        // handle parent onChange
        if(onChange && !onComposition.current){
            onChange(event.target.value)
        }
    }
    return (
        <div className={`al-center ${classes.cubeBtn} ${className}`} style={{ background: bgColor ? bgColor : 'white', ...style }} onClick={() => (!expandable && !disabled) && onClick(!actived)}>
            <Icon type={iconType} textColor={textColor} onClick={() => expandable && trigger()} />
            {expandable && 
                <input 
                    id={searchId} 
                    ref={inputRef}
                    style="color:rgba(0,0,0,.58);font-size:16px" 
                    value={searchText}
                    placeholder={placeholder}
                    onCompositionStart={handleChange}
                    onCompositionUpdate={handleChange}
                    onCompositionEnd={handleChange}
                    onChange={handleChange}
                />}
            {text && <span style={{ color: textColor }}>{I18n.t(text)}</span>}
        </div>
    )
}
export default Button;
