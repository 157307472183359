import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    maxWidth: 760
  },
  input: {
    '& .rs-picker-toggle.rs-btn-lg': {
      fontSize: 32,
      height: 80,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#f5f5f5!important',
      paddingRight: 60,

      '& .rs-picker-toggle-caret': {
        height: 'auto',
        fontSize: 40,
        top: 21,
        right: 8
      },

      '& .rs-picker-toggle-value': {
        overflow: 'visible',
        color: theme.palette.text.secondary
      }
    },

    '& .rs-input': {
      fontSize: 32,
      height: 80,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#F5F5F5'
    }
  }
}));