import { makeStyles } from '@material-ui/core/styles'; 

const useStyles = makeStyles({
    listWrap:{
        cursor: 'default',
        padding: '16px 16px',
        backgroundColor: 'white',
        border: '1px solid #E0E0E0',
        borderRadius: '8px'
    },
    listBody: {
      overflowY: 'scroll'    
    },
    title: {
        marginBottom: '16px',
        fontSize: '20px',
        fontWeight:' bold',
        color: 'rgba(0,0,0,.54)'
    }
})
export default useStyles;