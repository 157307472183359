/** @jsx h */
import { h } from "preact";
import { useState } from "preact/hooks";
import PropTypes from "prop-types";
import { useDrop } from "react-dnd";

import { SortableTableHead } from "components/atoms/sortableTableHead/SortableTableHead";
import { CollapseRow } from "components/atoms/collapseRow/CollapseRow";
import { TablePagination } from "components/atoms/tablePagination/TablePagination";

import { TableChild, TableWrapper } from "components/atoms/table/Table";

/* DRAG_AND_DROP_TABLE */
export const CollapseTable = ({
  disableHeader = false,
  disablePagination = false,
  columns,
  data,
  isFetched,
  skeletonColumns,
	canDropDrag = false,
  disableSelection,
  handleRootDrop = () => {},
  handleDrop = () => {},
  containerStyle,
  isCustomRow = false,
  customColumns = [],
  alwaysOpen = false,
  rowsPerPageOptions,
  defaultRowsPerPage,
  pageResetKey,
}) => {
  let dropRef = null
  if(canDropDrag) {
    [ {}, dropRef ] = useDrop({
      accept: "row",
      canDrop: canDropDrag,
      drop: handleRootDrop,
    });
  }
	const [selectRow, setSelectRow] = useState(null);

  return (
    <TableWrapper
      containerStyle={containerStyle}
      disableHeader={disableHeader}
      disablePagination={disablePagination}
      defaultRowsPerPage={defaultRowsPerPage}
      pageResetKey={pageResetKey}
      head={
        <SortableTableHead
          ref={dropRef}
          columns={columns}
        />
      }
      body={
        <TableChild
          data={data}
          isFetched={isFetched}
          skeletonColumns={skeletonColumns}
        >
					<CollapseRow
						columns={columns}
            isCustomRow={isCustomRow}
            customColumns={customColumns}
						handleDrop={handleDrop}
						selectRow={selectRow}
            setSelectRow={disableSelection ? () => { } : setSelectRow}
						canDropDrag={canDropDrag}
            alwaysOpen={alwaysOpen}
					/>
        </TableChild>
      }
      pageSelector={
        <TablePagination
          count={data.length}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      }
    />
  )
}

CollapseTable.propTypes = {
	columns: PropTypes.array,
	data: PropTypes.array,
};

CollapseTable.defaultProps = {
	columns: [],
	data: [],
};
