/** @jsx h */
import { h } from 'preact';
import { useState, useEffect, useRef } from 'preact/hooks';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from '@material-ui/core/Switch';
import I18n from "i18n/i18n";
import useStyles from './List.style'
import ListItem from "../../atoms/listItem/ListItem";
import CubeBtn from "../../atoms/cubeButton/CubeBtn";
import { AddButton } from '../../atoms/addButton/AddButton';
import { zeroArray, debounce } from "../../../util";
import Empty from "../../../assets/empty.svg";

const REACH_END_PREFIX = 10

export const List = (
    {
        add,
        ctaText,
        contentHeight,
        data = [],
        delet,
        deleteIcon,
        edit,
        editIcon,
        label,
        search,
        open,
        seeDetail,
        selectable,
        title,
        onClick,
        onChange,
        width,
        roundedAvatar,
        noAvatar,
        searchId,
        searchText = '',
        overideSelect,
        className,
        showEmptyIcon,
        isFetched,
        hasSwitch = false,
        switchTitle = '',
        isChecked = false,
        handleSwitch = () => {},
        parent,
        onEndReached = () => {},
        placeholder = ''
    }) => {
    const classes = useStyles()
    const [select, setSelect] = useState(null);
    const [scrollPosition, setScrollPosition] = useState(0);

    const inputFile = useRef(null);
    const listInnerRef = useRef(null);

    useEffect(() => {
        if (listInnerRef.current) {
            listInnerRef.current.scrollTop = scrollPosition;
        }
    }, [data?.length]);

    const click = (val) => {
        setSelect(val);
        onClick(val);
    }

    const isClient = (title === 'Group' || title === 'Branch') ? true : false;

    // use overideSelect(index) props to make the list selection controllable
    useEffect(() => {
        if (overideSelect !== null) setSelect(overideSelect);
        else setSelect(null);
    }, [overideSelect]);

    const update = debounce(() => {
        const { scrollTop, clientHeight, scrollHeight } = listInnerRef.current;
        if (scrollTop + clientHeight + REACH_END_PREFIX >= scrollHeight) {
            onEndReached();
        }
        if (scrollTop > scrollPosition) {
            setScrollPosition(scrollTop);
        }
    }, 400);

    const onScroll = () => {
        if (listInnerRef.current) {
            update();
        }
    };

    return (
        <div className={`${classes.listWrap} ${className && className}`} style={{ width: width ? width : '350px' }}>
            {(title || search || add) && <div className={'jc-space-btw al-center ' + classes.title}>
                <span>{I18n.t(title)}</span>
                <div class="d-flex">
                    {hasSwitch && 
                        <FormControlLabel
                            control={<Switch color='primary' checked={isChecked} onChange={handleSwitch} />}
                            label={I18n.t(switchTitle)}
                        />
                    }
                    <div style={{ width: 24 }} />
                    {search &&
                        <CubeBtn
                            iconType="search"
                            bgColor="#F5F5F5"
                            textColor="rgba(0, 0, 0, .54)"
                            expandable={true}
                            onClick={onClick}
                            onChange={onChange}
                            searchId={searchId}
                            searchText={searchText}
                            placeholder={placeholder}
                        />}
                    {add && <AddButton style={{ height: 39, width: 39, marginLeft: 16 }} onClick={open} />}                    
                </div>
            </div>}
            <div ref={listInnerRef} onScrollCapture={onScroll} className={`column ${contentHeight && classes.listBody}`} style={{ height: contentHeight }}>
                {
                    isFetched ?
                        (
                            data && data.length ?
                                (
                                    data.map((item, i) =>
                                        <ListItem
                                            key={i}
                                            data={item}
                                            ctaText={ctaText}
                                            deleteIcon={deleteIcon}
                                            editIcon={editIcon}
                                            expandable={true}
                                            label={label}
                                            noBorder={i == data.length - 1}
                                            selectable={selectable}
                                            selected={selectable && (select == i)}
                                            onClick={() => selectable && click(i)}
                                            delet={() => delet(i)}
                                            edit={() => edit(i)}
                                            seeDetail={() => seeDetail(i)}
                                            roundedAvatar={roundedAvatar}
                                            noAvatar={noAvatar}
                                            showLockIcon={(isClient && !item.added_by)}
                                        />)
                                )
                                :
                                (
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}
                                    >
                                        <img
                                            src={Empty}
                                            style={{
                                                width: "250px",
                                                height: "250px"
                                            }}
                                        />
                                    </div>
                                )
                        )
                        :
                        zeroArray(5).map(() => <ListItem skeleton />)
                }
            </div>
        </div>
    )
}
