import React from 'react';
import {
  GroupingState,
  IntegratedGrouping,
  ViewState,
} from '@devexpress/dx-react-scheduler';
import {
  Scheduler as RScheduler,
  DayView,
  Appointments,
  Resources,
  GroupingPanel,
  CurrentTimeIndicator,
  WeekView,
  AppointmentTooltip,
} from '@devexpress/dx-react-scheduler-material-ui';
import moment from 'moment';
import { Column } from 'components/atoms/column/Column';
import { memo, useState } from 'preact/compat';
import I18n from 'i18n-js';
import { ChevronRight, Phone } from '@material-ui/icons';
import { Rating } from '@material-ui/lab';
import { Row } from 'components/atoms/row/Row';
import { Button } from '@material-ui/core';
import { route } from 'preact-router';

const AppointmentContainer = memo(({ style, ...restProps }) => {
  const [isHover, setIsHover] = useState(false);

  const hoverStyle = isHover ? {
    filter: 'brightness(0.95)',
    zIndex: 100,
  } : {};

  return (
    <Appointments.Container
      {...restProps}
      style={{
        ...style,
        ...hoverStyle,
      }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    />
  );
});

const Appointment = memo((props) => {
  return (
    <Appointments.Appointment
      {...props}
      style={{
        backgroundColor: props.data.color,
        borderColor: props.data.status === 'noPunchRecord' ? '#ff9800' : '#eee',
        borderWidth: 1,
        height: 'fit-content',
      }}
    />
  );
});

const AppointmentContent = memo(({ children, ...restProps }) => {
  return (
    <Appointments.AppointmentContent
      {...restProps}
      style={{
        color: 'rgba(0, 0, 0, 0.87)',
        overflowX: 'scroll',
        scrollbarWidth: 'none',
      }}
    >
      <Column>
        <div>
          {restProps.data.client_branch_name}
        </div>
        {
          restProps.data.status === 'noPunchRecord' ? (
            <div>{I18n.t('noPunchRecord')}</div>
          ) : (
            <div style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
              <div style={{ display: 'flex' }}>
                {restProps.data.is_phone ? <Phone fontSize='small' /> : null}
                {moment(restProps.data.startDate).format('h:mm A') + ' - ' + moment(restProps.data.endDate).format('h:mm A')}
              </div>
              <div>{restProps.data.report?.note || I18n.t('empty')}</div>
            </div>
          )
        }
      </Column>
    </Appointments.AppointmentContent>
  );
});

const AppointmentTooltipContent = memo(({ appointmentData }) => {
  return (
    <Column style={{ padding: 10, color: 'rgba(0, 0, 0, 0.54)' }}>
      {appointmentData.report ? (
        <>
          <Row justifyContent='space-between'>
            <Rating value={appointmentData.report.star} readOnly />
            <div>{I18n.t(`ratingText_${Math.floor(appointmentData.report.star)}`)}</div>
          </Row>
          <Row justifyContent='space-between' style={{ marginTop: 5 }}>
            <div>{I18n.t('quotationChance')}</div>
            <div>{I18n.t(appointmentData.report.id_quotation ? 'yes' : 'no')}</div>
          </Row>
          <Row justifyContent='space-between' style={{ marginTop: 5 }}>
            <div>{I18n.t('clientType')}</div>
            <div>
              {I18n.t(`meeting_type_${appointmentData.report.meeting_type}`) 
                + '-' + I18n.t(`client_type_${appointmentData.report.client_type}`)}
            </div>
          </Row>
          <Row justifyContent='space-between' style={{ marginTop: 5 }}>
            <div>{I18n.t('competitors')}</div>
            <Row>
              {appointmentData.competitors.map((name, index) => {
                return (
                  <div
                    style={{
                      backgroundColor: '#eff5ff',
                      paddingInline: 3,
                      borderRadius: 3,
                      marginLeft: index > 0 ? 5 : 0,
                    }}
                  >
                    {name}
                  </div>
                )
              })}
            </Row>
          </Row>
          <Row justifyContent='space-between' style={{ marginTop: 5 }}>
              <div>{I18n.t('note')}</div>
              <div style={{ maxWidth: '60%' }}>
                {appointmentData.report.note}
              </div>
          </Row>
          {
            appointmentData.report.id_quotation ? (
              <Row justifyContent='flex-end' style={{ marginTop: 5 }}>
                <Button
                  variant='contained'
                  style={{ color: 'white', backgroundColor: 'red' }}
                  endIcon={<ChevronRight />}
                  onClick={() => {
                    route(`/page/latestReport/quotation?qid=${appointmentData.report.id_quotation}&bid=${appointmentData.id_client_branch}`);
                  }}
                >
                  {I18n.t('Quotation')}
                </Button>
              </Row>
            ) : null
          }
        </>
      ) : I18n.t('NoReport')}
    </Column>
  );
});

const DEFAULT_VIEW_PROPS = {
  startDayHour: 7,
  endDayHour: 19,
  cellDuration: 60,
  timeTableCellComponent: memo((props) => (
    <DayView.TimeTableCell
      {...props}
      style={{ height: '5em' }}
    />
  )),
  timeScaleLabelComponent: memo((props) => (
    <DayView.TimeScaleLabel
      {...props}
      style={{ paddingTop: '0.5em', height: props.time === undefined ? '2.5em' : '5em' }}
    />
  )),
};

const DEFAULT_DAY_VIEW_PROPS = {
  ...DEFAULT_VIEW_PROPS,
  timeTableCellComponent: memo((props) => (
    <DayView.TimeTableCell
      {...props}
      style={{ width: '15em', height: '5em' }}
    />
  )),
};

const Scheduler = ({
    data,
    currentDate,
    onCurrentDateChange = () => {},
    currentViewName,
    resources = [],
    instances = [],
    groupCellComponent: GroupCellComponent,
}) => {
    return !!resources.length ? (
      <RScheduler id='scheduler' data={data}>
        {/* States */}
        <ViewState
          currentDate={currentDate}
          onCurrentDateChange={onCurrentDateChange}
          currentViewName={currentViewName}
        />
        <GroupingState
          grouping={resources.map(({ fieldName }) => ({ resourceName: fieldName }))}
        />

        {/* Views */}
        <DayView
          {...DEFAULT_DAY_VIEW_PROPS}
          name='DayView'
          dayScaleRowComponent={() => null}
        />
        <WeekView
          {...DEFAULT_VIEW_PROPS}
          name='WeekView'
        />

        {/* Appointments */}
        <Appointments
          containerComponent={AppointmentContainer}
          appointmentComponent={Appointment}
          appointmentContentComponent={AppointmentContent}
        />
        <AppointmentTooltip
          headerComponent={() => null}
          contentComponent={AppointmentTooltipContent}
        />

        {/* Tools */}
        <CurrentTimeIndicator />

        {/* Resources and Groupings */}
        <Resources data={resources} />
        <IntegratedGrouping />
        <GroupingPanel
          cellComponent={(props) => (
            !GroupCellComponent ? <GroupingPanel.Cell {...props} /> : (
              <GroupCellComponent {...props} instances={instances} />
            )
          )}
        />
      </RScheduler>
    ) : (
      <RScheduler data={[]}>
        <ViewState currentDate={currentDate} />
        <DayView
          {...DEFAULT_DAY_VIEW_PROPS}
          dayScaleRowComponent={() => null}
        />
        <Appointments />
        <CurrentTimeIndicator />
      </RScheduler>
    );
};

export default Scheduler;