/** @jsx h */
import { Fragment, h } from "preact";
import React from "react";
import { useState, useEffect, useContext } from "preact/hooks";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import LayersIcon from "@material-ui/icons/Layers";
import I18n from "i18n-js";

import { Row } from "components/atoms/row/Row";
import { Column } from "components/atoms/column/Column";
import { CollapseTable } from "components/molecules/collapseTable/CollapseTable";
import CubeBtn from "components/atoms/cubeButton/CubeBtn";

import { getProductSpecificationCompetitorMachine, getProductSpecificationPower, getProductSpecificationSeries } from "api";
import { AddCircle as AddCircleIcon } from "@material-ui/icons";
import Context from "context";
import { route } from "preact-router";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(4),
		width: "100%",
		color: "rgba(0, 0, 0, 0.54)",
	},
	mb2: {
		marginBottom: theme.spacing(2),
	},
	mr2: {
		marginRight: theme.spacing(2),
	},
	ml2: {
		marginLeft: theme.spacing(2),
	},
	p0: {
		padding: 0,
	},
	addBtn: {
		color: "#2E65C9",
		fontSize: "40px",
		marginLeft: "16px",
		cursor: "pointer",
	},
	avatar: {
		width: 40,
		height: 40,
		marginRight: 10,
	},
	icon: {
		color: "rgba(0, 0, 0, 0.54)",
		marginRight: 10,
	},
}));

const Competitor = () => {
	const classes = useStyles();
	const { setCompareData, setMessage } = useContext(Context);
	const [tableData, setTableData] = useState([]);
	const [isFetched, setFetched] = useState(false);
	const [searchText, setSearchText] = useState('');

	useEffect(() => {
		(async () => {
			setFetched(false);

			try {
				const machineHandler = (machine) => {
					return {
						...machine,
						id_machine: machine.USE_MACH_SA,
						machines:  machine[I18n.locale === 'en' ? 'POWER_NAME_ENAME': 'POWER_NAME'],
						type: machine.SERIES_NAME,
						pounds: machine.USE_MACH_SA,
					};
				};

				const competitor_machines = (await getProductSpecificationCompetitorMachine()).data;
				const competitors = competitor_machines.reduce((prev, current) => {
					if (!!prev.find(({ COMP_ID }) => COMP_ID === current.COMP_ID)) return prev;

					const company = {
						COMP_ID: current.COMP_ID,
						COMP_ID_NAME: current.COMP_ID_NAME,
						COMP_ID_ENAME: current.COMP_ID_ENAME || current.COMP_ID_NAME,
					};
					return [...prev, company];
				}, []);
				const displayData = competitors.map((competitor) => {
					const machines = competitor_machines.filter(({ COMP_ID }) => COMP_ID === competitor.COMP_ID);
					const handledMachines = machines.map(machineHandler);
					return {
						name: competitor[I18n.locale === 'en' ? 'COMP_ID_ENAME' : 'COMP_ID_NAME'],
						machines: handledMachines.length,
						children: handledMachines,
						data: competitor,
						machineList: handledMachines,
					};
				});

				setTableData(displayData);
			} catch (error) {
				console.log('Get Competitor Error: ', error);
				if (!!error.response) console.log(error.response.data);
			}

			setFetched(true);
		})();
	}, []);

	const columns = [
		{
			id: "name",
			label: I18n.t("competitorName"),
			sortable: true,
		},
		{
			id: "machines",
			label: I18n.t("colorType"),
			sortable: true,
			Cell: (data) => {
				return (
					<Fragment>
						{!data.id_machine && <LayersIcon className={classes.icon} />}
						{data.machines}
					</Fragment>
				);
			},
		},
		{
			id: "type",
			label: I18n.t("machineType"),
			sortable: true,
		},
		{
			id: "pounds",
			label: I18n.t("pounds"),
			sortable: true,
		},
		{
			id: "addCompare",
			label: I18n.t("addCompare"),
			Cell: (data) => {
				return (
					<Fragment>
						{!!data.id_machine && (
							<AddCircleIcon
								style={{
									color: '#2E65C9',
									cursor: 'pointer'
								}}
								onClick={async () => {
									try {
										const seriesData = (await getProductSpecificationSeries({
											COMP_ID: data.COMP_ID,
											SERIES_NAME: data.SERIES_NAME,
										})).data;
										const powersData = (await getProductSpecificationPower({
											DSCR: data.POWER_NAME_ENAME,
											REM: data.POWER_NAME,
										})).data;

										const newCompareData = {
											competitor: data.COMP_ID,
											colorType: powersData[0].POWERED_ID,
											series: seriesData[0].SERIES_ID,
											machine: data.TYPE_ID,
										}

										setCompareData(prev => {
											const indexToInsert = prev.findIndex((data) => {
												const dataWithNonEmptyValues = Object.values(data).filter((value) => !!value);
												return dataWithNonEmptyValues.length === 0;
											});
	
											if (indexToInsert === -1) {
												prev.push(newCompareData);
											} else {
												prev[indexToInsert] = newCompareData;
											}
	
											return prev;
										});

										route('/page/competitor/scale');
										setMessage(I18n.t('addedToCompare'));
									} catch (error) {
										console.log('Get Competitor Machine Data Error: ', error);
										if (!!error.response) console.log(error.response.data);
									}
								}}
							/>
						)}
					</Fragment>
				);
			},
		},
	];

	const filteredData = tableData.filter((item) => {
		return item.name?.toLowerCase().includes(searchText.toLowerCase());
	});

	return (
		<div className={classes.root}>
			<Row justifyContent="space-between" alignItems="center" className={classes.mb2}>
				<Typography variant="h5" color="inherit">
					{I18n.t("competitorTitle")}
				</Typography>
				<Row alignItems="center">
					<CubeBtn
						iconType="search"
						expandable
						searchText={searchText}
						placeholder={I18n.t("competitorName")}
						onChange={(text) => setSearchText(text)}
					/>
				</Row>
			</Row>
			<Column className={classes.body}>
				<DndProvider backend={HTML5Backend}>
					<CollapseTable
						columns={columns}
						data={filteredData}
						canDropDrag={false}
						skeletonColumns={4}
						isFetched={isFetched}
					/>
				</DndProvider>
			</Column>
		</div>
	);
};
export default Competitor;
