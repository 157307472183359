/** @jsx h */
import { Fragment, h, cloneElement } from "preact";
import { useState, useEffect, useContext } from "preact/hooks";
import I18n from "i18n-js";
import moment from "moment";
import { route } from "preact-router";

import Rating from "@material-ui/lab/Rating";
import {
	TableBody,
	TableRow,
	Typography,
	TableCell,
  	TableContainer,
	Box,
} from "@material-ui/core";

import CubeBtn from "components/atoms/cubeButton/CubeBtn";
import { Row } from "components/atoms/row/Row";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TableBase from "@material-ui/core/Table";
import { SortableTableHead } from "components/atoms/sortableTableHead/SortableTableHead";
import { TablePagination } from "components/atoms/tablePagination/TablePagination";
import Select from "components/atoms/select/Select";
import { MultiSelect } from "components/atoms/multiSelect/MultiSelect";
import ExportExcelButton from "components/atoms/exportExcelButton/ExportExcelButton";
import LatestReportsDetails from "./Details";

import { getReport, getImage, getClientBranch, getReportExcel } from "api";

import { DateRangePicker } from "rsuite";

/* SKELETON IMPORT */
import { zeroArray, getComparator, compareDate, downloadXlsx } from "../../util";
import Skeleton from "@material-ui/lab/Skeleton";
import EmptyIcon from "assets/empty.svg"
import { enUS, zhTW } from "rsuite/esm/locales";
import { useRole } from "hooks";
import Context from "context";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(4),
		width: "100%",
		color: "rgba(0, 0, 0, 0.54)",
	},
	marginBottom2: {
		marginBottom: theme.spacing(2),
	},
	dealCircle: {
		border: "5px solid",
		borderRadius: "50%",
		display: "inline-block",
		marginRight: theme.spacing(1),
	},
	dealSuccess: {
		color: theme.palette.success.main,
	},
	dealFailed: {
		color: theme.palette.error.main,
	},
	hideOnSmall: {
		[theme.breakpoints.down(998)]: {
			display: "none",
		},
	},
	striped: {
		backgroundColor: '#FAFAFA'
	},
	hovered: {
		cursor: 'pointer',
	
		'&:hover': {
		  backgroundColor: '#EEEEEE'
		}
	},
    DatePicker: {
      '& .rs-picker-toggle': {
        backgroundColor: '#FFFFFF',
        padding: '9px 12px',
        paddingRight: 36,
        border: 'none',
        borderRadius: 8,
        fontSize: 15
      },
      '& .rs-picker-toggle-value': {
        color: '#0000008a !important',
      }
    }
}));

const SortableRow = ({
	row,
	columns,
	index,
	striped,
	hovered,
  }) => {
	const classes = useStyles();
	// Table without getRowProps use defaultClassName as fallback
	const defaultClassName = `${striped && index % 2 === 0 ? classes.striped : ''} ${hovered ? classes.hovered : ''}`;

	return (
	  <TableRow className={defaultClassName} >
		{columns.map((columnRow) => {
		  let cell = (row[columnRow['id']] === 'clientDeleted') ? I18n.t(row[columnRow['id']]) : row[columnRow['id']];
		  if (columnRow['cell']) cell = columnRow['cell'](row);
		  return (
			<TableCell className={columnRow['className']} style={{ color: row[columnRow['id']] === 'clientDeleted' ? "#BDBDBD" : "black",}}>
			  {cell}
			</TableCell>
		  )
		})}
	  </TableRow>
	)
}

const stableSort = (array, comparator) => {
	const stabilizedThis = array.map((el, index) => [el, index]);
	const sort = (arr) => {
	  arr.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	  });
	};
	sort(stabilizedThis);
	return stabilizedThis.map((el) => el[0]);
};
  
export const TableWrapper = ({
	head,
	body,
	pageSelector,
	disableHeader,
	disablePagination,
	pageResetKey,
	setPage,
	setRowsPerPage,
	setOrder,
	setOrderBy,
	page,
	rowsPerPage,
	order,
	orderBy
  }) => {
	const classes = useStyles();
  
	const handleRequestSort = (property) => {
	  const isAsc = orderBy === property && order === "asc";
	  setOrder(isAsc ? "desc" : "asc");
	  setOrderBy(property);
	};
  
	const handlePageChange = (_, newPage) => {
	  if (setPage) setPage(newPage)
	};
  
	const handleRowsPerPageChange = (event) => {
	  setRowsPerPage(parseInt(event.target.value, 10));
	  setPage(0);
	};
  
	// Add this to do a pagination reset from parent component
	useEffect(() => {
        if (!!pageResetKey) {
            handlePageChange('', 0)
        }
    }, [pageResetKey])
  
	return (
	  <Fragment>
		<Paper variant="outlined" className={classes.table}>
		  <TableContainer>
			<TableBase>
			  {
				!disableHeader &&
				cloneElement(head, {
				  order,
				  orderBy,
				  onRequestSort: handleRequestSort,
				})
			  }
			  {
				cloneElement(body, {
				  order,
				  orderBy,
				  getComparator,
				  stableSort,
				  page,
				  rowsPerPage
				})
			  }
			</TableBase>
		  </TableContainer>
		</Paper>
		{
		  !disablePagination &&
		  cloneElement(pageSelector, {
			rowsPerPage,
			page,
			handlePageChange,
			handleRowsPerPageChange
		  })
		}
	  </Fragment>
	)
}
  
export const TableChild = ({
	data,
	isFetched,
	order,
	orderBy,
	stableSort,
	getComparator,
	skeletonColumns,
	page,
	rowsPerPage,
	children,
  }) => {
	return (
	  <TableBody>
		{
		  isFetched ?
			data && data.length > 0 ?
			  (
				stableSort(data, getComparator(order, orderBy))
				  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
				  .map((row, index, arr) =>
					cloneElement(children, {
					  row,
					  index,
					  border: index === arr.length - 1 ? "none" : undefined,
					})
				  )
			  )
			  :
			  <TableRow>
				<TableCell
				  colSpan={skeletonColumns || 6}
				>
				  <div
					style={{
					  width: "100%",
					  height: "100%",
					  display: "flex",
					  justifyContent: "center",
					  alignItems: "center"
					}}
				  >
					<img src={EmptyIcon} />
				  </div>
				</TableCell>
			  </TableRow>
			:
			zeroArray(10).map(() =>
			  <TableRow>
				{zeroArray(skeletonColumns || 6).map(() =>
				  <TableCell>
					<Skeleton variant="text" style={{ width: "100%" }} />
				  </TableCell>
				)}
			  </TableRow>
			)
		}
	  </TableBody>
	)
}

const DEFAULT_DATE_RANGE = {
	start: moment().subtract(7, 'd').toDate(),
	end: moment().toDate()
}

const RATE_TEXT = ['D', 'C', 'B', 'A', 'A+'];

const LatestReportsOverview = (props) => {
	const classes = useStyles();
    const { groupList } = useContext(Context);
		const { roleId } = useRole();
	const [report, setReport] = useState(null);
	const [image, setImage] = useState(null);
	const [reportData, setReportData] = useState([]); 
    const [allReports, setAllReports] = useState([]);
	const [order, setOrder] = useState("asc");
  	const [orderBy, setOrderBy] = useState("name");
  	const [page, setPage] = useState(0);
  	const [rowsPerPage, setRowsPerPage] = useState(10);

    const [isFetched, setFetched] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dateRange, setDateRange] = useState(DEFAULT_DATE_RANGE);
    const [searchText, setSearchText] = useState(localStorage.getItem('selectedBranch') || '');
    const [options, setOptions] = useState({
        group: [],
        deal: [
            { id: '', text: I18n.t("All", { name: I18n.t("dealStatus") }) },
            { id: true, text: I18n.t("deal") },
            { id: false, text: I18n.t("noDeal") },
        ]
    });
    const [selectedFilter, setSelectedFilter] = useState({ group: [0], deal: '' });
    const urlParams = new URLSearchParams(location.search);

    useEffect(() => {
        if (!urlParams.has('isDetail')) {
            setReport(null);
        }
    }, [urlParams.has('isDetail')]);

	useEffect(() => {
        fetchNewReport();
	}, [dateRange.start, dateRange.end]);
	
	useEffect(() => { 
		if (report) { loadImage(); }
	}, [report])

    useEffect(() => {
        setOptions((prev) => ({
            ...prev,
            group: groupList.map(({ id, name }) => ({ id, text: I18n.t(name) }))
        }));
    }, [groupList]);

	const columns = [
		{
			id: "report_number",
			sortable: true,
		},
		{
			id: "salesman",
			sortable: true,
		},
		{
			id: "client",
			sortable: true,
		},
		{
			id: "branch",
			sortable: true,
		},
		{
			id: "deal",
			cell: (row) =>
				row.deal ? (
					<span className={classes.dealSuccess}>
						<span className={classes.dealCircle} />
						{I18n.t("deal")}
					</span>
				) : (
					<span className={classes.dealFailed}>
						<span className={classes.dealCircle} />
						{I18n.t("noDeal")}
					</span>
				),
			sortable: true,
		},
		{
			id: "date",
			sortable: true,
		},
		{
			id: "rate",
			className: classes.hideOnSmall,
			cell: (row) => (
				<Box component="fieldset" borderColor="transparent" p={0} m={0}>
					<Rating name="read-only" value={row.star} readOnly />
				</Box>
			),
			sortable: true,
		},
		{
			id: "Action",
			cell: (row) => (
				<CubeBtn
					bgColor="#2E65C9"
					textColor="#FFFFFF"
					iconType="search"
					onClick={() => {
                        handleOpenDetails(row);
                        route(`${location.pathname}?isDetail=true`);
                    }}
				/>
			),
		},
	];

	const handleData = async (reportData) => {
        let sortedData = stableSort(reportData, getComparator(order, orderBy));
		if (sortedData.length) {
            sortedData = await Promise.all(sortedData.map(async data => {
                let branchData = data.client_branch;
                try {
                    if (!branchData && data.id_client_branch) {
                        branchData = (await getClientBranch({ id: data.id_client_branch })).data[0];
                    }
                } catch (error) {
                    console.log(error);
                }
                const clientData = branchData?.client;
                const userData = data.user;
                const reportDetail = {
                    ...data,
                    salesman: `${userData?.last_name}${userData?.first_name}` || "",
                    client: clientData?.name || "clientDeleted",
                    branch: branchData?.name || "clientDeleted",
                    deal: !!data.id_quotation,
                    date: moment(data.updated_at).local().format("YYYY-MM-DD, HH:mm"),
                    rate: data.star,
                    group: userData?.group?.name || "",
                    getUser: () => userData,
                    getClient: () => clientData,
                    getClientBranch: () => [branchData],
                    getGroup: () => userData?.group
                }
                const correspondIndex = reportData.findIndex((object) => {
                    return object.id === data.id;
                });
                reportData[correspondIndex] = reportDetail;
                return reportDetail;
            }))
            setReportData(reportData);
		}
        setAllReports(sortedData)
        setFetched(true)
	};

    const fetchNewReport = async () => {
        try {
            setFetched(false);
            const reports = (await getReport({
				from: moment(dateRange.start).startOf('d').format('YYYY-MM-DD HH:mm:ss'),
				to: moment(dateRange.end).endOf('d').format('YYYY-MM-DD HH:mm:ss'),
				offset: 0,
				limit: -1
			}, roleId)).data;
			const addNumberReports = reports.map((x) => {
				return {
					...x,
					report_number: x.id,
				}
			})
            setReportData(addNumberReports);
            handleData(addNumberReports);
        } catch (error) {
            console.log(error);
            setFetched(true);
        }
    }

	const loadImage = async () => {
		setImage(null);
		const image = await getImage("report", report.id);
		if (!image.data || image.data?.msg === 'IMAGE_NOT_FOUND') return;
		setImage(image.data);
	};

    const filteredData = stableSort(allReports.filter((item) => {
        const searchCols = ['salesman', 'client', 'branch', 'id'];
		const isSearch = searchCols.some((key) => {
			return item[key]?.toString().toLowerCase().includes(searchText.toLowerCase());
		});
        const isGroup = selectedFilter.group.includes(0) || !selectedFilter.group || selectedFilter.group.includes(item.user?.id_group);
        const isDeal = selectedFilter.deal === '' || item.deal === selectedFilter.deal;
        return isSearch && isGroup && isDeal;
	}), getComparator(order, orderBy));

    const goBack = () => {
        route(location.pathname);
        const isParamsExist = localStorage.getItem('report_detail')
        const params = !!isParamsExist ? JSON.parse(isParamsExist) : {}

        if (!!isParamsExist) {
            if (params?.searchText !== searchText) {
                setSearchText(params?.searchText)
            }
            if (!compareDate(params?.dateRange?.start, dateRange.start) || !compareDate(params?.dateRange?.end, dateRange.end)) {
                setDateRange({
                    start: moment(params?.dateRange?.start).toDate() ?? DEFAULT_DATE_RANGE.start,
                    end: moment(params?.dateRange?.end).toDate() ?? DEFAULT_DATE_RANGE.end
                })
            }
            setSelectedFilter({
                group: params?.group ?? [0],
                deal: params?.deal ?? ''
            })
            setPage(params.page)
            localStorage.removeItem('report_detail')
        }
    }

    const handleOpenDetails = (reportDetails) => {
        const filterParams = {
            ...selectedFilter,
            searchText,
            dateRange,
            page
        }
        localStorage.setItem('report_detail', JSON.stringify(filterParams))
        setReport(reportDetails);
    }

    const onDownload = async () => {
        try {
            setIsLoading(true);
            const res = await getReportExcel({
                from: moment(dateRange.start).startOf('d').format('YYYY-MM-DD HH:mm:ss'),
                to: moment(dateRange.end).endOf('d').format('YYYY-MM-DD HH:mm:ss'),
                limit: -1
            })
            const filename = `${moment(dateRange.start).format('YYYY-MM-DD')}~${moment(dateRange.end).format('YYYY-MM-DD')} ${I18n.t("Salesreport")}`;
            downloadXlsx(res.data, filename);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    if (report) return <LatestReportsDetails data={report} setReport={setReport} imageData={image} back={goBack} />;
    else if (props.id) return <LatestReportsDetails urlParams={props} back={goBack} />;
    return (
		<div className={classes.root}>
			<Row justifyContent="space-between" alignItems="center" className={classes.marginBottom2}>
				<Typography variant="h5" color="inherit">
					{I18n.t("Salesreport")}
				</Typography>
				<Row style={{ columnGap: "1rem" }}>
                    <CubeBtn
                        iconType='import'
                        text={I18n.t('Download')}
                        bgColor='#2e65c9'
                        textColor='#fff'
                        disabled={filteredData.length === 0 || isLoading}
                        onClick={onDownload}
                    />
                    <ExportExcelButton
                        sheetName={`${moment(dateRange.start).format('YYYY-MM-DD')}~${moment(dateRange.end).format('YYYY-MM-DD')} ${I18n.t("Salesreport")}`}
                        columns={columns.filter(col => col.id !== 'Action').map(col => ({ ...col, text: I18n.t(col.id) }))}
                        data={filteredData.map(row => ({ ...row, rate: RATE_TEXT[row.rate - 1], deal: row.deal ? I18n.t("deal") : I18n.t("noDeal") }))}
                        headerColor='EBF1DE'
                        fileType="xlsx"
                    />
                    <DateRangePicker
						format="yyyy-MM-dd"
						defaultValue={[dateRange.start, dateRange.end]}
						size="lg"
						preventOverflow
						className={classes.DatePicker}
						onChange={(e) => {
							if (e) {
								setDateRange({ start: e[0], end: e[1] });
							} else {
								setDateRange(DEFAULT_DATE_RANGE);
							}
						}}
						locale={localStorage.getItem('locale') === 'en' ? enUS.DateRangePicker : zhTW.DateRangePicker}
					/>
                    <MultiSelect
                        data={options.group}
                        title="team"
                        value={selectedFilter.group}
                        placeholder={I18n.t("team")}
                        labelKey="text"
                        valueKey="id"
                        onChange={(val) => setSelectedFilter({ ...selectedFilter, group: val })}
                    />
                    <Select
                        className={classes.mr2}
                        data={options.deal}
                        filterIcon
                        onClick={(val) => setSelectedFilter({ ...selectedFilter, deal: val.id })}
                    />
					<CubeBtn
						iconType="search"
						expandable
						searchText={searchText}
                        placeholder={`${I18n.t("report_number")}, ${I18n.t("salesman")}, ${I18n.t("client")}, ${I18n.t("branch")}`}
						onChange={(text) => {
                            setSearchText(text)
							if (text && page !== 0) setPage(0)
                        }}
					/>
				</Row>
			</Row>
			<TableWrapper
        	    disableHeader={false}
        	    disablePagination={false}
        	    setPage={setPage}
        	    setRowsPerPage={setRowsPerPage}
        	    setOrder={setOrder}
        	    setOrderBy={setOrderBy}
        	    page={page}
        	    rowsPerPage={rowsPerPage}
        	    order={order}
        	    orderBy={orderBy}
        	    head={
        	      <SortableTableHead columns={columns} />
        	    }
        	    body={
        	      <TableChild
        	        data={filteredData}
        	        isFetched={isFetched}
        	        skeletonColumns={columns.length}
        	      >
        	        <SortableRow
    		  		  columns={columns}
    		  		  striped
    		  		  hovered
    		  		  getRowProps
    		  		/>
        	      </TableChild>
        	    }
        	    pageSelector={
        	      <TablePagination
        	        count={filteredData.length}
        	      />
        	    }
        	/>
		</div>
	);
};

export default LatestReportsOverview;
