/** @jsx h */
import { h } from "preact";
import { styled } from "@material-ui/core/styles";
import { CircularProgress } from '@material-ui/core';

const BlueBtn = styled("button")({
    fontSize: "1rem",
    color: "white",
    backgroundColor: "#2e65c9",
    width: "100%",
    height: "4rem",
    textAlign: "center",
    borderRadius: "5px",
});

const BlueSubmitButton = ({ onClick, loading, children }) => {
    return (
        <BlueBtn 
            className={`center al-center`}
            type="submit"
            disabled={loading}
            style={{ backgroundColor: loading ? "#9C9C9C" : undefined }}
            onClick={onClick}
        >
            {loading ? <CircularProgress size={20} style={{ marginRight: 8 }}/> : null}
            {children}
        </BlueBtn>
    )
};

export default BlueSubmitButton;