import { h } from "preact";
import I18n from "i18n-js";

import { Row } from 'components/atoms/row/Row';
import { Column } from 'components/atoms/column/Column';
import { LabelData } from 'components/atoms/labelData/LabelData';

import { Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(4, 6),

		"& > div + div": {
			marginTop: theme.spacing(2),
		},
	},
	button: {
		color: "#2E65C9",
		padding: 0,
	},
	colorDisabled: {
		color: theme.palette.text.disabled,
	},
}));

const OverallData = ({ data, title, note, onClick, seeOptions }) => {
	const classes = useStyles();
	const hasValidNote = !!(note && note.length)
	return (
		<Column className={classes.root}>
			<Row justifyContent='space-between'>
				{title && <Typography variant='h5' color='textPrimary'>{I18n.t(title)}</Typography>}
				{onClick &&
					<Button variant="text" className={classes.button} onClick={onClick} disabled={!hasValidNote}>
						{I18n.t(seeOptions ? "seeOptions" : "seeNote").toUpperCase()}
					</Button>}
			</Row>
			<LabelData data={data} byRow style={{ padding: 0 }} isFetched={true} />
		</Column>
	)
};

export default OverallData;
