/** @jsx h */
import { h } from "preact";
import { forwardRef } from "react";
import useStyles from "./Modal.style";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import I18n from "i18n/i18n";
import { DialogTitle } from "@material-ui/core";

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const Modal = ({ open, close, children, style, title, jsxTitle, dialog }) => {
	const classes = useStyles();

	// add this for open modal as dialog
	if (dialog) {
		return (
			<Dialog
				open={open}
				onClose={close}
				maxWidth="md"
				TransitionComponent={Transition}
				keepMounted
			>
				<DialogTitle>
					<div className={`jc-space-btw al-center`}>
						{jsxTitle ? jsxTitle : typeof title === "string" ? (
							<Typography variant="h6" color="textSecondary">
								{I18n.t(title)}
							</Typography>
						) : (
							<Typography variant="h6" color="textSecondary">
								{I18n.t(title[0], { name: I18n.t(title[1]) })}
							</Typography>
						)}
						<IconButton
							onClick={() => close()}
							style={{ color: "rgba(0,0,0,.54)", margin: -12, cursor: "pointer" }}
						>
							<CloseIcon style={{ fontSize: "32px" }} />
						</IconButton>
					</div>
				</DialogTitle>
				<DialogContent style={style}>{children}</DialogContent>
			</Dialog>
		);
	} else {
		return (
			<Slide direction="up" in={open} mountOnEnter unmountOnExit>
				<div className={classes.scrim}>
					<div className={classes.dialogBg} style={style}>
						<div className={`jc-space-btw al-center ${classes.head}`}>
							{typeof title === "string" ? (
								<span style={{ fontSize: "24px" }}>{I18n.t(title)}</span>
							) : (
								<span style={{ fontSize: "24px" }}>
									{I18n.t(title[0], { name: I18n.t(title[1]).toLowerCase() })}
								</span>
							)}
							<CloseIcon
								style={{ color: "rgba(0,0,0,.54)", fontSize: "32px", cursor: "pointer" }}
								onClick={() => close()}
							/>
						</div>
						<div className={classes.body}>{children}</div>
					</div>
				</div>
			</Slide>
		);
	}
};
export default Modal;
