import axios from "axios";

import { getToken, removeToken } from "helper/token";

export const baseURL =
	process.env.PREACT_APP_BASE_API ||
	`${window.location.protocol}//api.${window.location.hostname}`;

// export const baseURL = "https://api.dev.crm.fcs.brtest.club/";

export const request = axios.create({
	baseURL,
});

const noTokenRequiredAPI = ['/user/login', '/gps/load'];
request.interceptors.request.use(
	(config) => {
		const token = getToken()
		if (!token && !noTokenRequiredAPI.includes(config.url)) {
			return Promise.resolve({
				data: {
					error: 'Unauthorized'
				}
			});
		} else { 
			config.headers.authorization = token
			
			return config;
		}
	},
	(error) => {
		return Promise.reject(error);
	}
);

request.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if (error.response?.status === 401) {
			removeToken()
			window.location.href = "/login";
		}
		return Promise.reject(error);
	}
);

// user
export const postUserCreate = (data) => request.post("/user/create", data);
export const postUserLogin = (data) => request.post("/user/login", data);
export const getUser = (params) => request.get("/user", { params });
export const getUserList = (params) => request.get("/user/list", { params });
export const putUserUpdateID = (id, data) => request.put(`/user/update/${id}`, data);
export const deleteUser = (id) => request.delete(`/user/${id}`);
export const getUserSorting = (params) => request.get("/user/sorting", { params });
export const putUserSorting = (data) => request.put("/user/sorting", data);

// group
export const postGroup = (data) => request.post("/group", data);
export const getGroup = (params) => request.get("/group", { params });
export const putGroup = (id, data) => request.put(`/group/${id}`, data);
export const deleteGroup = (id) => request.delete(`/group/${id}`);

//schedule
export const getSchedule = (params) => request.get("/schedule", { params });
export const postSchedule = (userID, data) => request.post(`/schedule/${userID}`, data);
export const putSchedule = (userID, data) => request.put(`/schedule/${userID}`, data);
export const deleteSchedule = (id) => request.delete(`/schedule/${id}`);

// client
export const getClient = (params) => {
    return request.get("/client", { params })
};
export const postClient = (data) => request.post("/client", data);
export const putClient = (clientID, data) => request.put(`/client/${clientID}`, data);
export const deleteClient = (id) => request.delete(`/client/${id}`);
export const getTopFiftyClients = (params) => request.get("/client/top_50_clients", { params });
export const getClientBranch = (params) => {
    return request.get("/client_branch", { params })
};
export const postClientBranch = (data) => request.post("/client_branch", data);
export const putClientBranch = (branchID, data) => request.put(`/client_branch/${branchID}`, data);
export const deleteClientBranch = (id) => request.delete(`/client_branch/${id}`);
export const postClientContact = (data) => request.post("/client_contact", data);
export const getClientContact = (params) => request.get("/client_contact", { params });
export const putClientContact = (contactID, data) =>
	request.put(`/client_contact/${contactID}`, data);
export const deleteClientContact = (id) => request.delete(`/client_contact/${id}`);

// report
export const getReport = (params, role = 2) => {
  if (role === 4) return request.get('/report/group', { params })
  if (role === 3) return request.get('/report/group', { params })
  if (role === 2) return request.get('/report/region', { params })
  if (role === 1) return request.get('/report', { params })
}
export const getRoleString = (id) => request.get(`/role_string/`, { id });
export const getWeeklyReport = (params) => request.get("/report/summary", { params });
export const getSalesmanReportRank = (params) => request.get("/report/rank", { params });
export const getDashboardReportAvgStars = () => request.get("/report/dashboard/average_stars");
export const getImage = (path, id) => request.get(`/image/${path}/${id}`);
export const postImage = (path, id, data) => request.post(`/image/${path}/${id}`, data);

// region
export const getRegion = (params) => request.get("/region", { params });
export const postRegion = (data) => request.post("/region", data);
export const putRegion = (id, data) => request.put(`/region/${id}`, data);
export const deleteRegion = (id) => request.delete(`/region/${id}`);

// competitor
export const getCompetitor = (params) => request.get("/competitor", { params });
export const getCompetitorById = (id) => request.get(`/competitor/${id}`);
export const getCompetitorMachine = (params) => request.get("/competitor_machine", { params });
export const postCompetitor = (params) => request.post("/competitor", params);
export const deleteCompetitor = (id) => request.delete(`/competitor/${id}`);
export const putCompetitor = (id, params) => request.put(`/competitor/${id}`, params);

// competitor machines
export const deleteCompetitorMachine = (id) => request.delete(`/competitor_machine/${id}`);
export const postCompetitorMachine = (params) => request.post("/competitor_machine", params);
export const putCompetitorMachine = (id, params) => request.put(`/competitor_machine/${id}`, params);

// abbreviation
export const getAbbreviation = (params) => request.get("/abbreviation", { params });

// quotation
export const getQuotation = (params, role = 2) => {
  if (role === 4) return request.get('/quotation/group', { params })
  if (role === 3) return request.get('/quotation/group', { params })
  if (role === 2) return request.get('/quotation/region', { params })
  if (role === 1) return request.get('/quotation', { params })
}
export const getMonthlySales = () => request.get("/quotation/sales");
export const getCurrencies = (data) => request.get('/currency', { params: data })

// order
export const getOrder = (params) => request.get('/order', { params });
export const getOrderPrevision = (params) => request.get("/order/prevision", { params });
export const getOrderStatus = (params) => request.get("/order/status", { params });

// product specification
export const getProductSpecification = (params) => request.get('/product_specification', { params });
export const getProductSpecificationManufacturer = (params) => request.get('/product_specification/manufacturer', { params });
export const getProductSpecificationPower = (params) => request.get('/product_specification/power', { params });
export const getProductSpecificationSeries = (params) => request.get('/product_specification/series', { params });
export const getProductSpecificationMachine = (params) => request.get('/product_specification/machine', { params });
export const getProductSpecificationCompetitorMachine = (params) => request.get('/product_specification/competitor_machine', { params });
export const getProductSpecificationMachineList = (params) => request.get('/product_specification/machine_list', { params });
export const getProductSpecificationMachineSpec = (params) => request.get('/product_specification/machine_spec', { params });

// abc rate
export const getABCRate = (params) => request.get("/abc_rate", { params });
export const getABCRateTotal = (params) => request.get("/abc_rate/total", { params });
export const getABCRateMonthly = (params) => request.get("/abc_rate/monthly", { params });

// my_day
export const getMyDay = (params) => request.get("/my_day", { params });
// objective
export const getObjectiveGroup = (params) => request.get("/objective/group", { params });
export const getObjectiveSalesman = (params) => request.get("/objective/salesman", { params });
export const setObjectiveGroup = (data) => request.put("/objective/group", data);
export const setObjectiveSalesman = (data) => request.put("/objective/salesman", data);
export const setObjective = (data) => request.post("/objective/upload", data);

// announcement
export const getAnnouncement = (params) => request.get('/announcement', { params });
export const postAnnouncement = (formData, params) => 
  request.post("/announcement", formData, {
    headers: { "Content-Type": "multipart/form-data" },
    params: { ...params },
  }, 
);
export const setAnnouncement = (id, data) => request.put(`/announcement/${id}`, data);
export const deleteAnnouncement = (id) => request.delete(`/announcement/${id}`);

// industry level
export const getFirstIndustryLevel = (params) => request.get("/first_industry_level", { params });
export const getSecondIndustryLevel = (params) => request.get("/second_industry_level", { params });

// business card
export const getLandList = (params) => request.get("/land", { params });
export const getCountryList = (params) => request.get("/country", { params });
export const getExhibitionList = (params) => request.get("/exhibition", { params });
export const getBusinessCard = (params) => request.get("/business_card", { params });
export const putBusinessCard = (id, data) => request.put(`/business_card/${id}`, data);
export const deleteBusinessCard = (id) => request.delete(`/business_card/${id}`);

// erp
export const deleteErpCache = (table) => request.delete(`/erp/cache/${table}`);

// order amount
export const getClientMachineRanking = (params) => request.get("/order_amount/client_machine_ranking", { params });

// division
export const getDivision = (params) => request.get("/division", { params });

// excel
export const getMessageOverviewExcel = (params) => request.get("/excel/messageOverview", { params });
export const getOrderSummaryExcel = (params) => request.get("/excel/orderSummary", { params, responseType: 'arraybuffer' });
export const getReportExcel = (params) => request.get("/excel/report", { params, responseType: 'arraybuffer' });
export const postImportClient = () => request.post("/import/client");