import { useContext, useEffect, useMemo, useState } from 'preact/hooks';
import React from 'react';
import I18n from 'i18n-js';
import { DatePicker, DateRangePicker } from 'rsuite';
import { enUS, zhTW } from 'rsuite/esm/locales';
import { GroupingPanel } from '@devexpress/dx-react-scheduler-material-ui';
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Avatar } from '@material-ui/core';
import { getCompetitor, getMyDay, getUserList } from 'api';
import moment from 'moment';
import { useRole } from 'hooks';
import Context from 'context';
import { Row } from 'components/atoms/row/Row';
import Select from 'components/atoms/select/Select';
import CubeBtn from "components/atoms/cubeButton/CubeBtn";
import { MultiSelect } from 'components/atoms/multiSelect/MultiSelect';
import Scheduler from 'components/organisms/modalContent/punch/Scheduler';
import { compareDate } from '../../util';
import { useUserImage } from 'hooks';

const VIEWS = ['DayView', 'WeekView'];
const RESOURCES = [{ fieldName: 'id_user', title: 'User' }];
const STATUSES_AND_COLORS = [
  { id: 'upcoming', color: '#fafafa' },
  { id: 'working', color: '#a7c8e7' },
  { id: 'alreadyLeft', color: '#a2ce9b' },
  { id: 'noPunchRecord', color: '#ffcb7f' },
];

const useStyles = makeStyles((theme) => ({
  marginArea: {
    '& > div + div': {
      marginLeft: theme.spacing(2),
    },
  },
  DatePicker: {
    '& .rs-picker-toggle': {
			backgroundColor: '#FFFFFF',
      border: 'none',
      borderRadius: 8,
      fontSize: 15
		},
    '& .rs-picker-toggle-value': {
      color: '#0000008a !important',
    }
  }
}))

const Overview = () => {
  const { groupList } = useContext(Context)
  const { getUserImage } = useUserImage()
  const classes = useStyles();
  const role = useRole();

  const [data, setData] = useState([]);
  const [date, setDate] = useState([new Date(), new Date()]);

  const [group, setGroup] = useState([0]);
  const [users, setUsers] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    (async () => {
      try {
        const mydays = (
          await getMyDay({
            from: moment(date[0]).set({ h: 0, m: 0, s: 0 }).utc().format(),
            to: moment(date[1]).set({ h: 23, m: 59, s: 59 }).utc().format(),
          })
        ).data;

        const filteredMydays = mydays.filter(({ is_active }) => typeof is_active !== 'boolean');

        const handler = async (item) => {
          const now = moment(new Date());
          const started = moment(item.start_time).isBefore(now);
          const status = !started && item.punch.length === 0 ? 'upcoming' : (
            item.is_punch && item.punch.length === 1 ? 'working' : (
              item.punch.length === 2 ? 'alreadyLeft' : 'noPunchRecord'
            )
          );
          const competitorIds = item.report?.id_erp_competitor || [];
          let competitors = await Promise.all(competitorIds.map(async (id) => {
            const competitorName = (await getCompetitor({ id })).data
            return competitorName?.[0]?.name
          }));
          competitors = competitors.filter((competitor) => !!competitor);

          return {
            ...item,
            title: item.client_name + ` (${item.client_branch_name})`,
            startDate: item.start_time,
            endDate: item.end_time,
            status,
            color: STATUSES_AND_COLORS.find(({ id }) => id === status).color,
            competitors,
          };
        };

        const handledMydays = await Promise.all(filteredMydays.map(handler));

        setData(handledMydays);
      } catch (error) {
        console.log('Punch Overview getMyDay Error: ', error);
      }
    })();
  }, [date]);

  useEffect(() => {
    (async () => {
      try {
        let userList

        if (group.includes(0)) {
          const result = await Promise.all(
            groupList
              .map(async (group) => {
                return (await getUserList({ avoid_brilltek_testing: true, id_group: group.id })).data
              })
          )
          userList = result.flat()
        } else userList = (await getUserList({ avoid_brilltek_testing: true, id_group: group })).data

        userList = await Promise.all(
          userList.map(async (user) => {
            const imgUrl = await getUserImage(user.id)

            return {
              ...user,
              id_user: user.id,
              text: user.last_name + user.first_name,
              imgUrl,
            }
          })
        )

        setUsers(userList)
      } catch (error) {
        console.log('Punch Overview Getting Users Error: ', error)
      }
    })()
  }, [group, groupList])

  const currentViewName = useMemo(() => {
    return compareDate(date[0], date[1]) ? VIEWS[0] : VIEWS[1];
  }, [date]);

  const filteredUsers = useMemo(() => {
    return users.filter(({ first_name = '', last_name = '' }) => (
      !searchValue
      || `${last_name}${first_name}`.search(searchValue) !== -1
    ));
  }, [users, searchValue]);

  const instances = useMemo(() => {
    return RESOURCES.reduce((prev, current) => {
      return { ...prev, [current.fieldName]: current.fieldName === 'id_user' ? filteredUsers : [] };
    }, {});
  }, [filteredUsers]);

  const resources = useMemo(() => {
    return RESOURCES.map((resource) => {
      return {
        ...resource,
        instances: instances[resource.fieldName],
      };
    }).filter(({ instances }) => !!instances.length);
  }, [instances]);

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: 32,
      }}
    >
      <Row justifyContent="space-between" style={{ marginBottom: 20 }}>
        <Row>
          <Select
            style={{ marginRight: 10 }}
            value={currentViewName}
            data={VIEWS.map((name) => ({
              text: I18n.t(name),
              id: name,
            }))}
            onClick={(e) => {
              const currentDate = new Date()
              if (e.id === VIEWS[0]) {
                setDate([currentDate, currentDate])
              } else {
                const weekStartMoment = moment(currentDate).subtract(
                  currentDate.getDay(),
                  'days'
                )
                const weekStartDate = new Date(
                  weekStartMoment.format('YYYY-MM-DD')
                )
                const weekEndMoment = weekStartMoment.add(6, 'days')
                const weekEndDate = new Date(weekEndMoment.format('YYYY-MM-DD'))
                setDate([weekStartDate, weekEndDate])
              }
            }}
          />
          {currentViewName === VIEWS[0] ? (
            <DatePicker
              size="lg"
              cleanable={false}
              ranges={[]}
              oneTap
              className={classes.DatePicker}
              value={date[0]}
              onChange={(value) => setDate([value, value])}
              locale={
                localStorage.getItem('locale') === 'en'
                  ? enUS.DatePicker
                  : zhTW.DatePicker
              }
            />
          ) : (
            <DateRangePicker
              size="lg"
              cleanable={false}
              hoverRange="week"
              ranges={[]}
              oneTap
              className={classes.DatePicker}
              value={date}
              onChange={setDate}
              locale={
                localStorage.getItem('locale') === 'en'
                  ? enUS.DatePicker
                  : zhTW.DatePicker
              }
            />
          )}
        </Row>
        <Row style={{ gap: 10 }}>
          {(role.isBigBoss || role.isManager) && 
            <MultiSelect
              title="team"
              data={groupList}
              labelKey="name"
              valueKey="id"
              value={group}
              placeholder={I18n.t('team')}
              onChange={(val) => setGroup(val)}
            />
          }
          <CubeBtn
            iconType="search"
            expandable
            placeholder={I18n.t('FullName')}
            searchText={searchValue}
            onChange={setSearchValue}
          />
        </Row>
      </Row>
      <Paper style={{ flexGrow: 1, borderRadius: 5, overflow: 'hidden' }}>
        <Scheduler
          data={data}
          resources={resources}
          instances={instances}
          currentDate={date[0]}
          onCurrentDateChange={(currentDate) =>
            setDate([currentDate, currentDate])
          }
          currentViewName={currentViewName}
          groupCellComponent={(props) => {
            const { group, instances, ...otherProps } = props

            return group.fieldName === 'id_user' ? (
              <GroupingPanel.Cell
                {...otherProps}
                group={{ ...group, text: '' }}
                style={{ width: '15em' }}
              >
                <Row>
                  <Avatar
                    src={
                      instances[group.fieldName].find(
                        (user) => user.id === group.id
                      )?.imgUrl || ''
                    }
                    style={{ marginRight: 5 }}
                  />
                  <div style={{ paddingTop: 6 }}>{group.text}</div>
                </Row>
              </GroupingPanel.Cell>
            ) : null
          }}
        />
      </Paper>
      <Row justifyContent="flex-end" style={{ paddingTop: '1em' }}>
        {STATUSES_AND_COLORS.map(({ id, color }) => (
          <Row style={{ marginLeft: '1em' }} alignItems="center">
            <div
              style={{
                width: '1em',
                height: '1em',
                backgroundColor: color,
                marginRight: '1em',
                borderRadius: '50%',
              }}
            />
            <div>{I18n.t(id)}</div>
          </Row>
        ))}
      </Row>
    </div>
  )
};

export default Overview;
