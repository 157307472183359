/** @jsx h */
import { h } from "preact";
import { forwardRef } from "preact/compat";
import Dialog from "@material-ui/core/Dialog/Dialog";
import Slide from "@material-ui/core/Slide/Slide";
import CubeButton from "../../atoms/cubeButton/CubeBtn";
import I18n from "i18n-js";

const OrderDetailTransition = forwardRef((props, ref) =>
  <Slide direction="up" ref={ref} {...props} />
);

const SeeDescriptionModal = ({ openWhen, optionID, machineName, price, remark, onClose }) =>
  <Dialog
    open={openWhen}
    TransitionComponent={OrderDetailTransition}
    fullWidth
    maxWidth="md"
  >
    <div
      style={{
        height: "350px",
        padding: "0 1rem 1rem 1.25rem",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingTop: "1.25rem"
      }}>
        <div
          style={{
            fontSize: "1.25rem",
            color: "#757575",
            fontWeight: "600",
          }}
        >
          {I18n.t('noteForOption', { optionId: optionID })}
        </div>
        <CubeButton
          iconType="close"
          bgColor="#eeeeee"
          onClick={ (e) => onClose(e) }
          style={{ padding: "3px" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          margin: "30px 0",
          flexDirection: "row",
          columnGap: "1rem"
        }}
      >
        <div style={{ fontWeight: 600 }}><span style={{ color: "#757575" }}>
          {I18n.t('machine') + ': '}
          </span>{machineName}</div>
        <div style={{ fontWeight: 600 }}><span style={{ color: "#757575" }}>
          {I18n.t('price') + ': '}
          </span>{price}</div>
      </div>
      <div
        style={{
          flexGrow: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "1px #ededed solid",
          borderRadius: "5px",
          backgroundColor: "#fafafa",
          overflow: "hidden",
        }}
      >
        <span
          style={{
            display: "flex",
            height: "110%",
            fontSize: "100px",
            alignItems: "flex-start"
          }}
        >
          <div>&ldquo;</div>
        </span>
        <div
          style={{
            maxWidth: "70%",
            fontWeight: 'bold',
            fontSize: 20,
          }}
        >
          {remark}
        </div>
        <span
          style={{
            display: "flex",
            height: "150%",
            fontSize: "100px",
            alignItems: "flex-end"
          }}
        >&rdquo;</span>
      </div>
    </div>
  </Dialog>;

export default SeeDescriptionModal;

