import { useEffect, useRef } from 'preact/hooks';

/**
 * An useEffect that blocks the first render.
 * 
 * @param {() => void} callback
 * @param {any[]} dependencies
 */
export const useUpdateEffect = (callback, dependencies) => {
    const isFirst = useRef(true);

    useEffect(() => {
        if (isFirst.current) return isFirst.current = false;

        callback();
    }, dependencies);
};