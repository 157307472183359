/** @jsx h */
import { h } from "preact";
import I18n from "i18n-js";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { Table } from "components/atoms/table/Table";

const Row = ({ columns, row, index, border, currentUserId, handleAction }) => {
    return (
        <TableRow
            align="center"
            style={{ backgroundColor: index % 2 === 0 ? "#fafafa" : undefined }}
        >
            {columns
                .filter(({ sortable }) => sortable)
                .map(({ id, width }) => (
                    <TableCell key={id} style={{ border, width }}>
                        {I18n.t(row[id])}
                    </TableCell>
                ))}
            <TableCell component="th" style={{ border }}>
                <EditIcon
                    htmlColor='#757575'
                    style={{ cursor: 'pointer', marginRight: 20 }}
                    onClick={() => currentUserId === row.created_by ? null : handleAction('edit', row)}
                />
                <DeleteIcon
                    htmlColor='#757575'
                    style={{ cursor: 'pointer' }}
                    onClick={() => currentUserId === row.created_by ? null : handleAction('delete', row)}
                />
            </TableCell>
        </TableRow>
    );
};

export const AnnouncementTable = ({ data, isFetched, currentUserId, handleAction }) => {
    const columns = [
        { id: "created_at", label: I18n.t("AnnouncementDate"), sortable: true, width: '15%' },
        { id: "context", label: I18n.t("AnnouncementContent"), sortable: true, width: '70%' },
        { id: "Action", label: I18n.t("Action"), sortable: false, width: '15%' },
    ];

    return (
        <Table data={data} columns={columns} isFetched={isFetched}>
            <Row columns={columns} currentUserId={currentUserId} handleAction={handleAction} />
        </Table>
    );
};
