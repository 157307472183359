import { h } from "preact";
import { makeStyles } from "@material-ui/core/styles";
import ErrorPage  from "./errorPage";
import Icon from "../../assets/PageNotFound.svg";
import I18n from "i18n-js";

const useStyles = makeStyles({
    message: {
        fontSize: "24px",
        color: "rgba(0,0,0,0.54)",
        height: "130px",
    },
    description: {
        fontSize: "20px"
    },
    bold: {
        fontWeight: 600,
    },
    list: {
        margin: "1.25rem 0 0 0",
        maxWidth: "90%"
    }
});

const Page404 = () => {
    const classes = useStyles();

    return (
        <ErrorPage
            statusCode="404"
            icon={Icon}
            back
        >
            <p className={classes.message}>
                {I18n.t("notFound")}
            </p> 
            <p className={classes.description}>
                <span className={classes.bold}>
                    {I18n.t("notFoundListTitle")}
                </span>
                <ul className={classes.list}>
                    <li>{I18n.t("notFoundList1")}</li>
                    <li>{I18n.t("notFoundList2")}</li>
                    <li>{I18n.t("notFoundList3")}</li>
                    <li>{I18n.t("notFoundList4")}</li>
                </ul>
            </p>
        </ErrorPage>
    );   
}
export default Page404;