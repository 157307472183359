/** @jsx h */
import { h } from "preact";
import I18n from "i18n-js";
import { useContext, useEffect, useState } from "preact/hooks";

import IconButton from "@material-ui/core/IconButton";
import MuiButton from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import Modal from "components/atoms/modal/Modal";
import BlueBtn from "components/atoms/blueButton/BlueBtn";
import { Row } from "components/atoms/row/Row";
import { Column } from "components/atoms/column/Column";

import { useStyles } from "./ClientBranchInfo.style";

import { getClientBranch, getClient } from "api";
import context from "context";

const ClientBranchInfo = ({ data, isDetail, showHistory, seeGroup, refresh, setRefresh, onClick = () => {} }) => {
	const classes = useStyles({ isDetail });
	const { setClientGroupList, setClientBranchList } = useContext(context);
	const [modal, setModal] = useState(false);
	const [detail, setDetail] = useState({ ...data, originalName: data.branch});

	const isClientDeleted = detail.group === 'clientDeleted' || detail.branch === 'clientDeleted';
	const clientName = isClientDeleted ? I18n.t('clientDeleted') : `${detail?.group ?? ''} (${detail?.branch ?? ''})`;

	useEffect(() => {
		setDetail({...data, originalName: data.branch});
	},[data]);

	useEffect(async () => {
		if (refresh) {
			// get new data from API
			const newBranchList = (await getClientBranch()).data;
			const newGroupList = (await getClient()).data;
			// set new data to context
			setClientGroupList(newGroupList);
			setClientBranchList(newBranchList);
			setRefresh(!refresh);
		}
	}, [refresh]);

	return (
    <div className={classes.root}>
      {isDetail && (
        <Row justifyContent="space-between" className={classes.logoArea}>
          <Column justifyContent="center" className={classes.description}>
            <Typography
              variant="h5"
              color="textPrimary"
              style={{ color: isClientDeleted ? '#BDBDBD' : 'default' }}
            >
              {clientName}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {detail.address}
            </Typography>
          </Column>
          <Column>
              {showHistory && (
                  <BlueBtn
                      label="ouline"
                      text={I18n.t("SalesHistory").toUpperCase()}
                      arrow
                      style={{
                          display: "flex",
                          alignSelf: "center",
                          marginLeft: "auto",
                          padding: "8px 16px",
                      }}
                  />
              )}
              <MuiButton variant="text" className="gray" style={{ marginTop: 10 }} onClick={seeGroup}>
                  {I18n.t("seeGroup")}
              </MuiButton>
          </Column>
        </Row>
	  )}
	  {!isDetail && (
	  	<div className={classes.bgSection}>
            <Column justifyContent="center" style={{ padding: 10 }}>
                <Typography
                    variant="body1"
                    color="textPrimary"
                	style={{ color: isClientDeleted ? '#BDBDBD' : 'default' }}
                >
                	{clientName}
            	</Typography>
                <Typography variant="body2" color="textSecondary">
                    {detail.address}
                </Typography>
            </Column>
            <DetailRow title="Salesman" value={data?.name_of_salesman} isFirst />
            <DetailRow title='firstIndustryLevel' value={detail.first_industry_level} />
            <DetailRow title='secondBusinessLevel' value={detail.second_industry_level} />
            <DetailRow title="productManufactured" value={data?.product_manufactured} isBottom />
        </div>
	  )}
      <Modal
        dialog
        title={"machine"}
        open={modal === "machine"}
        close={() => setModal(false)}
      >
        <span>
          Machine Modal content here, I'm not sure what's the content.
        </span>
      </Modal>
    </div>
  );
};

const DetailRow = ({ title, value, isFirst = false, isBottom = false }) => {
  const classes = useStyles({ isFirst, isBottom });

  return (
    <Row className={classes.logoArea}>
      <div style={{ flex: 1, textAlign: 'left' }}>
        <Typography variant="body2" color="textSecondary">
          {I18n.t(title)}
        </Typography>
      </div>
      <div style={{ flex: 1, textAlign: 'right', paddingRight: 16 }}>
        <Typography variant="body2" color="textPrimary">
          {value || '-'}
        </Typography>
      </div>
    </Row>
  );
}

export default ClientBranchInfo;
