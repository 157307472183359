/** @jsx h */
import { h } from 'preact';
import { useState, useEffect, useContext } from "preact/hooks";
import useStyles from './EmployeeInfo.style';
import BlueBtn from '../../../atoms/blueButton/BlueBtn';
import 'rsuite/dist/rsuite.min.css';
import { Form, SelectPicker } from 'rsuite';
import Avatar from '@material-ui/core/Avatar';
import I18n from "i18n/i18n";
import context from 'context';

// FORM VALIDATION
import { Schema } from "rsuite";
import { useRef } from "preact/hooks";
import BlueSubmitButton from 'components/atoms/blueSubmitButton/blueSubmitButton';
import { Jdenticon } from "components/atoms/Jdenticon";
import { getRoleString, postImage } from 'api';
import { enUS, zhTW } from 'rsuite/esm/locales';

const formatOptions = (options) => {
    return options.map((option) => ({
        label: option.name,
        value: option.id
    }));
}

//FORM VALIDATION
const { NumberType, StringType } = Schema.Types;

const EmployeeInfo = ({ label, style, onClick, userData, loading }) => {
    const classes = useStyles();
    const [type, setType] = useState(label || 'add');
    const [role, setRole] = useState()
    const [formValues, setFormValues] = useState({});
    const [user, setUser] = useState(userData);
    const { groupList } = useContext(context)
    const { roleList, setRoleList, regionList, divisionList } = useContext(context);
    // FORM VALIDATION VARIABLES
    const isRequired = I18n.t("required");
    const notEmail = I18n.t("notEmail");
    const digits = I18n.t("atleastNineDigits");
    const notNumber = I18n.t("notNumber");
    const inputFile = useRef(null);

    const initModel = {
        employeeRoles: NumberType(notNumber).isRequired(isRequired),
        lastname: StringType().isRequired(isRequired),
        firstname: StringType().isRequired(isRequired),
        emailAddress: StringType().isRequired(isRequired).isEmail(notEmail),
        phoneNumber: NumberType(notNumber).isRequired(isRequired).pattern(/^\d{9,}$/, digits),
        team: NumberType(notNumber),
        workingTeam: NumberType(notNumber),
        division: NumberType(notNumber).isRequired(isRequired),
        id_erp: StringType().isRequired(isRequired),
        erp_symbol: StringType().isRequired(isRequired),
    };

    const [model, setModel] = useState(initModel);
    const formRef = useRef(null);
    
    const changePic = async(e) => {
        const newImgUrl = URL.createObjectURL(e.target.files[0]);
        setUser({...user, imgUrl: newImgUrl})
        setFormValues({ ...formValues, tempAvatar: e.target.files[0] })
    }

    const openUploadWindow = () => {
      inputFile.current.click();
    }

    const roleOptionsList = [
        {
            // label: 'Salesman roles',
            label: "Bigboss roles",
            value: 0
        },
        {
            // label: 'Team leader roles',
            label: "Manager roles",
            value: 1
        },
        {
            // label: 'Manager roles',
            label: "Team leader roles",
            value: 2
        },
        {
            // label: 'Bigboss roles',
            label: "Salesman roles",
            value: 3
        }
    ]

    const divisionsList = formatOptions(divisionList);
    const teamList = formatOptions(groupList);
    const regionsList = formatOptions(regionList);

    useEffect(() => {
        if (roleList[role]?.name === "ROLE_MANAGER") {
            setModel({ ...model, workingTeam: NumberType().isRequired("required"), team: NumberType() });
        } else if (roleList[role]?.name === "ROLE_SALES" || roleList[role]?.name === "ROLE_LEADER") {
            setModel({ ...model, team: NumberType().isRequired("required"), workingTeam: NumberType() });
        }
    }, [role]);

    useEffect(async () => {
        if (roleList.length === 0) {
            const roles = (await getRoleString()).data.map((n) => n.name);
            setRoleList(roles);
        }
    }, []);

    useEffect(() => {
        /* 
        prefill form values when user data exist,
        i.e. when editing the info of an existing user
        */
        if (userData) {
            // console.log(userData)
            const {
                id_role,
                last_name,
                first_name,
                email,
                phone,
                address,
                zip_code,
                city,
                id_group,
                id_region,
                id_division,
                id_erp,
                erp_symbol
            } = userData;

            const initValue = {
                employeeRoles: id_role - 1,
                lastname: last_name,
                firstname: first_name,
                emailAddress: email,
                phoneNumber: phone,
                zipCode: zip_code,
                team: id_group,
                workingTeam: id_region,
                city,
                address,
                division: id_division,
                id_erp,
                erp_symbol
            };
            setRole(id_role - 1);
            setFormValues(initValue);
        }
    }, []);

    return (
        <div className="column al-center">
            <div className="al-center" style={{ marginBottom: '16px' }}>
                {user?.imgUrl ?
                    <Avatar className={classes.avatar} src={user.imgUrl} /> :
                    <div className={classes.avatar}>
                        <Jdenticon size="100" value={user?.username} />
                    </div>}
                <BlueBtn
                    label="outline"
                    text="ChangePic"
                    style={{ padding: '8px 16px' }}
                    onClick={ openUploadWindow } />
            </div>
            <input type='file' accept=".jpg" ref={inputFile} style={{ display: 'none' }} onChange={(e) => { changePic(e) }} />
            <div className="w-60" style={style}>
                <Form fluid formValue={formValues} onChange={(e) => setFormValues(e)} model={Schema.Model(model)} ref={formRef}>
                    <Form.Group>
                        <Form.ControlLabel className={classes.inputLabel}>{I18n.t("EmployeeRole")}：</Form.ControlLabel>
                        <Form.Control
                            placeholder={I18n.t("SelectA", { name: I18n.t("EmployeeRole") })}
                            name="employeeRoles"
                            accepter={SelectPicker}
                            data={roleOptionsList}
                            style={{ width: '100%' }}
                            onChange={(e) => setRole(e)}
							locale={localStorage.getItem('locale') === 'en' ? enUS.Picker : zhTW.Picker}
                        />
                    </Form.Group>
                    {/* id_erp */}
                    <div className="d-flex">
                        <Form.Group style={{ width: '150px' }}>
                                <Form.ControlLabel className={classes.inputLabel}>{I18n.t("ERPSymbol")}</Form.ControlLabel>
                                <Form.Control name="erp_symbol" placeholder={I18n.t("InsertA", { name: I18n.t("ERPSymbol")?.toLowerCase() })} />
                        </Form.Group>
                        <Form.Group style={{ width: '100%', margin: '0 0 0 8px' }}>
                                <Form.ControlLabel className={classes.inputLabel}>{I18n.t("IDERP")}</Form.ControlLabel>
                                <Form.Control name="id_erp" placeholder={I18n.t("InsertA", { name: I18n.t("IDERP")?.toLowerCase() })} />
                        </Form.Group>
                    </div>
                    {/* name */}
                    <Form.Group>
                        <Form.ControlLabel className={classes.inputLabel}>{I18n.t("LastName")}：</Form.ControlLabel>
                        <Form.Control
                            name="lastname"
                            placeholder={I18n.t("InsertA", { name: I18n.t("LastName") })} />
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel className={classes.inputLabel}>{I18n.t("FirstName")}：</Form.ControlLabel>
                        <Form.Control
                            name="firstname"
                            placeholder={I18n.t("InsertA", { name: I18n.t("FirstName")?.toLocaleLowerCase() })} />
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel className={classes.inputLabel}>{I18n.t("email")}：</Form.ControlLabel>
                        <Form.Control
                            name="emailAddress"
                            type="email"
                            placeholder={I18n.t("InsertAn", { name: I18n.t("email")?.toLocaleLowerCase() })} />
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel className={classes.inputLabel}>{I18n.t("phone")}:</Form.ControlLabel>
                        <Form.Control
                            name="phoneNumber"
                            placeholder={I18n.t("InsertA", { name: I18n.t("phone")?.toLocaleLowerCase() })} />
                    </Form.Group>
                    {(roleList[role]?.name !== "ROLE_BIGBOSS") &&
                        <Form.Group>
                            <Form.ControlLabel className={classes.inputLabel}>{I18n.t("Address")}：</Form.ControlLabel>
                            <Form.Control
                                name="address"
                                placeholder={I18n.t("InsertAn", { name: I18n.t("Address")?.toLocaleLowerCase() })} />
                        </Form.Group>}
                    {(roleList[role]?.name !== "ROLE_BIGBOSS") &&
                        <div className="d-flex">
                            <Form.Group style={{ width: '150px' }}>
                                <Form.ControlLabel className={classes.inputLabel}>{I18n.t("ZipCode")}</Form.ControlLabel>
                                <Form.Control
                                    name="zipCode"
                                    placeholder={I18n.t("InsertA", { name: I18n.t("ZipCode")?.toLowerCase() })} />
                            </Form.Group>
                            <Form.Group style={{ width: '100%', margin: '0 0 0 8px' }}>
                                <Form.ControlLabel className={classes.inputLabel}>{I18n.t("City")}</Form.ControlLabel>
                                <Form.Control
                                    name="city"
                                    placeholder={I18n.t("InsertA", { name: I18n.t("City")?.toLowerCase() })} />
                            </Form.Group>
                        </div>}
                    <Form.Group>
                        <Form.ControlLabel className={classes.inputLabel}>{I18n.t("Division")}：</Form.ControlLabel>
                        <Form.Control
                            placeholder={I18n.t("SelectA", { name: I18n.t("Division")?.toLocaleLowerCase() })}
                            name="division"
                            classPrefix="form-control"
                            placement="topStart"
                            accepter={SelectPicker}
                            data={divisionsList}
                            style={{ width: '100%' }}
                            locale={localStorage.getItem('locale') === 'en' ? enUS.Picker : zhTW.Picker}
                        />
                    </Form.Group>
                    {
                        (roleList[role]?.name === "ROLE_LEADER" || roleList[role]?.name === "ROLE_SALES") &&
                        <Form.Group>
                            <Form.ControlLabel className={classes.inputLabel}>{I18n.t("Team")}：</Form.ControlLabel>
                            <Form.Control
                                placeholder={I18n.t("SelectA", { name: I18n.t("Team")?.toLocaleLowerCase() })}
                                name="team"
                                classPrefix="form-control"
                                placement="topStart"
                                accepter={SelectPicker}
                                data={teamList}
                                style={{ width: '100%' }}
                                locale={localStorage.getItem('locale') === 'en' ? enUS.Picker : zhTW.Picker}
                            />
                        </Form.Group>
                    }
                    {
                        (roleList[role]?.name === "ROLE_MANAGER") &&
                        <Form.Group>
                            <Form.ControlLabel className={classes.inputLabel}>{I18n.t("ManagingRegion")}：</Form.ControlLabel>
                            <Form.Control
                                placeholder={I18n.t("SelectA", { name: I18n.t("ManagingRegion")?.toLocaleLowerCase() })}
                                name="workingTeam"
                                classPrefix="form-control"
                                placement="topStart"
                                accepter={SelectPicker}
                                data={regionsList}
                                style={{ width: '100%' }}
                                locale={localStorage.getItem('locale') === 'en' ? enUS.Picker : zhTW.Picker}
                            />
                        </Form.Group>
                    }
                    <Form.Group>
                        <BlueSubmitButton loading={loading} onClick={() => formRef.current.check() ? onClick(formValues) : null}>
                            {I18n.t(type == "add" ? "AddAn" : "EditAn", { name: I18n.t("Employee") })?.toUpperCase()}
                        </BlueSubmitButton>
                    </Form.Group>
                </Form >
            </div >
        </div >
    );
}
export default EmployeeInfo;