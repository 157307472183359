/** @jsx h */
import { h } from 'preact';
import { useState, useEffect, useContext } from "preact/hooks";
import useStyles from './Visit.style';
import BlueBtn from '../../../atoms/blueButton/BlueBtn';
import 'rsuite/dist/rsuite.min.css';
import { DatePicker, Form, SelectPicker } from 'rsuite';
import moment from "moment";
import I18n from "i18n/i18n";
import { getClient, getClientBranch } from '../../../../api';
import Context from '../../../../context'

// FORM VALIDATION
import { Schema } from "rsuite";
import { useRef } from "preact/hooks";
import BlueSubmitButton from "../../../atoms/blueSubmitButton/blueSubmitButton";
import { enUS, zhTW } from 'rsuite/esm/locales';

const { NumberType, ObjectType } = Schema.Types;
const Visit = ({ data = {}, label, style, loading, onClick, visitType }) => {
    // console.log('data', data)
    const classes = useStyles();
    const [type, setType] = useState(label || 'add');
    const [formValues, setFormValues] = useState(data);
    const { clientGroupList, setClientGroupList, clientBranchList, setClientBranchList, userList, setUserList } = useContext(Context)
    const [clientSelected, setClientSelected] = useState({});
    const [clientBranchAvailable, setClientBranchAvailable] = useState([]);
	const [clientGroup, setClientGroup] = useState([]); 

    const [formDefaultValues, setFormDefaultValues] = useState(data);

    const branch = [
        {
            label: 'XiangShang Factory',
            value: 0
        },
        {
            label: 'XiangShang Factory_1',
            value: 1
        }
    ]

    const searchGroup = (searchValue) => {
        if (searchValue !== '') {
            getClient({name: searchValue, limit: 10}).then(({data}) => {
                setClientGroup(data.map((client) => {
                    client.label = client.name
                    client.value = client.id
                    return client;
                }))
            }).catch(err => errHandler(err, setError, route))
        }
    }

    useEffect(() => {
        // getClient().then(({ data }) => {
        //     setClientGroupList(data.map((client, i) => {
        //         client.label = client.name
        //         client.value = client.id
        //         return client;
        //     }))
        //     setFormDefaultValues({
        //         ...formDefaultValues,
        //         groupName: clientGroupList.find((item, i) => {
        //             if (item.id === formDefaultValues.groupName)
        //                 return i
        //         }),
        //     })
        //     setFormValues({
        //         ...formValues,
        //         ...formDefaultValues,
        //     })
        // })
        // getClientBranch().then(({ data }) => {
        //     setClientBranchList(data.map((client, i) => {
        //         client.label = client.name
        //         client.value = client.id
        //         return client;
        //     }))
        //     setFormDefaultValues({
        //         ...formDefaultValues,
        //         branchName: clientBranchList.find((item, i) => {
        //             if (item.id === data.branchName)
        //                 return i
        //         }),
        //     })
        //     setFormValues({
        //         ...formValues,
        //         ...formDefaultValues,
        //     })
        // })
        if(data.groupName) {
            getClient({ id: data.groupName, limit: 10 }).then(({data}) => {
                setClientGroup(data.map((client) => {
                    client.label = client.name
                    client.value = client.id
                    return client;
                }))
            }).catch(err => errHandler(err, setError, route))
            getClientBranch({ id: data.branchName, limit: 10 }).then(({data}) => {
                setClientBranchAvailable(data.map((branch) => {
                    branch.label = branch.name
                    branch.value = branch.id
                    return branch;
                }))
            }).catch(err => errHandler(err, setError, route))
        }
        else {
            setClientGroup(clientGroupList.map((client) => {
                client.label = client.name
                client.value = client.id
                return client;
            }))
            setClientBranchAvailable(clientBranchList.map((branch) => {
                branch.label = branch.name
                branch.value = branch.id
                return branch;
            }))
        }
        
        if (visitType === 'salesman') {
            setUserList(userList.map((item) => ({
                ...item,
                label: item.last_name + item.first_name,
                value: item.id
            })))
        }
    }, [])

    useEffect(() => {
        // console.log(formValues);
        const [client] = clientGroup?.filter((item) => item.value === formValues.groupName)
        if (client) {
            setClientBranchAvailable(client.branches.map((branch) => {
            branch.label = branch.name
            branch.value = branch.id
            return branch;
            }))
        }
    }, [formValues])


    // FORM VALIDATION VARIABLES
    const isRequired = I18n.t("required");
    const initModel = {
        groupName: NumberType().isRequired(isRequired),
        branchName: NumberType().isRequired(isRequired),
        day: ObjectType().isRequired(isRequired),
        startTime: ObjectType().isRequired(isRequired),
        endTime: ObjectType().isRequired(isRequired),
    }
    const [model, setModel] = useState(Schema.Model(initModel));
    const formRef = useRef(null);
    return (
        <div className="column al-center" style={{ marginTop: '24px' }}>
            <div className="w-60" style={style}>
                <Form fluid formDefaultValue={formDefaultValues} onChange={(e) => setFormValues(e)} model={model} ref={formRef}>
                    <Form.Group>
                        <Form.ControlLabel className={classes.inputLabel}>{I18n.t("GroupName")}：</Form.ControlLabel>
                        <Form.Control
                            accepter={SelectPicker}
                            name="groupName"
                            data={clientGroup}
                            placeholder={I18n.t("SelectA", { name: I18n.t("GroupName").toLocaleLowerCase() })}
                            style={{ width: '100%' }}
                            menuStyle={{ zIndex: 10000 }} 
                            labelKey="label"
                            onSearch={(searchValue) => searchGroup(searchValue)}
							locale={localStorage.getItem('locale') === 'en' ? enUS.Picker : zhTW.Picker}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel className={classes.inputLabel}>{I18n.t("BranchName")}：</Form.ControlLabel>
                        <Form.Control
                            accepter={SelectPicker}
                            name="branchName"
                            data={clientBranchAvailable}
                            placeholder={I18n.t("SelectA", { name: I18n.t("BranchName").toLocaleLowerCase() })}
                            style={{ width: '100%' }}
                            menuStyle={{ zIndex: 10000 }} 
                            labelKey="label"
							locale={localStorage.getItem('locale') === 'en' ? enUS.Picker : zhTW.Picker}
                        />
                    </Form.Group>
                    {
                        visitType === 'salesman' &&
                        <Form.Group>
                            <Form.ControlLabel className={classes.inputLabel}>{I18n.t("Salesman")}：</Form.ControlLabel>
                            <Form.Control
                                accepter={SelectPicker}
                                name="userID"
                                data={userList}
                                placeholder={I18n.t("SelectA", { name: I18n.t("Salesman").toLocaleLowerCase() })}
                                style={{ width: '100%' }}
                                menuStyle={{ zIndex: 10000 }}
                                locale={localStorage.getItem('locale') === 'en' ? enUS.Picker : zhTW.Picker}
                            />
                        </Form.Group>
                    }
                    <Form.Group>
                        <Form.ControlLabel className={classes.inputLabel}>{I18n.t("AvaliableTime")}：</Form.ControlLabel>
                        <Form.Control
                            accepter={DatePicker}
                            format='yyyy-MM-dd'
                            name="day"
                            style={{ width: '100%' }}
                            menuStyle={{ zIndex: 10000 }}
                            disabledDate={(date) => {
                                const today = new Date();
                                today.setDate(today.getDate() - 1);
                                return date < today;
                            }}
                            locale={localStorage.getItem('locale') === 'en' ? enUS.DatePicker : zhTW.DatePicker}
                        />
                    </Form.Group>
                    <div className="d-flex" style={{ marginTop: '-16px' }}>
                        <Form.Group style={{ width: '100%' }}>
                            <Form.Control
                                accepter={DatePicker}
                                format='HH:mm'
                                name="startTime"
                                style={{ width: '100%' }}
                                menuStyle={{ zIndex: 10000 }}
                                disabledHours={(hour) => hour < (new Date()).getHours()}
                                disabledMinutes={(minute) => minute < (new Date()).getMinutes()}
                                locale={localStorage.getItem('locale') === 'en' ? enUS.DatePicker : zhTW.DatePicker}
                            />
                        </Form.Group>
                        <div style={{ width: '16px' }} />
                        <Form.Group style={{ width: '100%' }}>
                            <Form.Control
                                accepter={DatePicker}
                                format='HH:mm'
                                //defaultValue={moment(new Date()).format('hh:ss')}
                                name="endTime"
                                style={{ width: '100%', zIndex: 10000 }}
                                menuStyle={{ zIndex: 10000 }}
                                disabledHours={(hour) => hour < (new Date()).getHours()}
                                disabledMinutes={(minute) => minute < (new Date()).getMinutes()}
                                locale={localStorage.getItem('locale') === 'en' ? enUS.DatePicker : zhTW.DatePicker}
                            />
                        </Form.Group>
                    </div>
                    <Form.Group style={{ width: '100%' }}>
                        <Form.ControlLabel className={classes.inputLabel}>{I18n.t("VisitReason")}：</Form.ControlLabel>
                        <Form.Control
                            rows={5}
                            name="visitReason"
                            componentClass="textarea"
                            placeholder={I18n.t("VisitReasonPlaceholder")}
                            // Add this to make reason editable when editing modal
                            onChange={(e) => setFormDefaultValues(prev => ({ ...prev, visitReason: e }))}
                            style={{ width: '100%' }} />
                    </Form.Group>
                    <Form.Group>
                        <BlueSubmitButton
                            loading={loading}
                            onClick={() => formRef.current.check() ? onClick(formValues) : null}
                        >
                            {I18n.t(type == "add" ? "AddA" : "EditA", { name: I18n.t("Visit") }).toUpperCase()}
                        </BlueSubmitButton>
                    </Form.Group>
                </Form>
            </div>
        </div>
    );
}
export default Visit;
