import { makeStyles } from '@material-ui/core/styles'; 

const useStyles = makeStyles({
    inputLabel: {
        color:'#B0B0B0',
        fontWeight: 'bold'
    }
})
export default useStyles;

