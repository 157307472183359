import { makeStyles } from '@material-ui/core/styles'; 

const useStyles = makeStyles({
    avatar: {
        width: '104px',
        height: '104px',
        borderRadius: '50%',
        overflow: 'hidden',
        background:'white',
        marginRight: '24px'
    },
    head: {
        marginBottom: '24px',
        padding: '0 16px',
        '& div span:first-child': {
            lineHeight: 1.5,
            fontSize:'24px',
            marginBottom: '8px'
        }
    },
    headBtn: {
        color: '#2E65C9',
        marginLeft: '10px',
        fontSize: '14px'
    },
    body: {
        padding: '16px 32px',
        '& > div': {
            marginBottom: '16px',
            fontSize: '14px',
            textAlign:'right',
            '& span:nth-child(1)': {
                // backgroundColor: 'red',
                color: 'rgba(0,0,0,.38)'
            }
        }
    },
    seeLocation: {
        cursor: 'pointer'
    }
})
export default useStyles;