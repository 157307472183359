/* eslint-disable no-else-return */
import { useState, useMemo } from 'preact/hooks'
import {
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableSortLabel,
} from '@material-ui/core'
import Skeleton from "@material-ui/lab/Skeleton"
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import I18n from 'i18n-js'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Card } from 'components/atoms/card/Card'
import { TablePagination } from 'components/atoms/tablePagination/TablePagination'
import ExportExcelButton from 'components/atoms/exportExcelButton/ExportExcelButton'
import { getComparator, zeroArray } from '../../../util';
import { formatPrice } from 'helper/format'

//目前組別預設排序 1.台灣區北、2.中、3.南
const colorPalette = ['#fffde7', '#e8f5e9', '#e8eaf6', '#fafafa']

const useStyles = makeStyles({
  table: {
    overflow: 'hidden',
    borderRadius: 10,

    '& .MuiTableSortLabel-root': {
      display: 'flex',
    },

    '& .MuiTableRow-root:last-child > .MuiTableCell-body': {
      borderBottom: 'none',
    },
  },
  head: {
    minWidth: 85,
    textAlign: 'left',
    color: 'rgba(0, 0, 0, 0.38)',
    whiteSpace: 'nowrap',
  },
  striped: {
    backgroundColor: '#FAFAFA',
  },
  hovered: {
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: '#EEEEEE',
    },
  },
  selectedRow: {
    backgroundColor: '#EFF5FF !important',
  },
  groupedRow: {
    backgroundColor: (props) => props.groupedRowBgColor,
  },
  competitorCell: {
    fontSize: 16,
    display: 'inline-block',
    border: 'solid 1px #E0E0E0',
    borderRadius: 4,
    backgroundColor: '#FAFAFA',
    padding: '4px 8px 4px 8px',
    margin: 4,
    whiteSpace: 'nowrap',
  },
})

const COLUMNS = [
  {
    id: 'salesmanName',
    text: 'salesman',
    sortable: true,
  },
  {
    id: 'reportCount',
    text: 'reportCounts',
    sortable: true,
  },
  {
    id: 'quotationCount',
    text: 'quotationCount',
    sortable: true,
  },
  {
    id: 'totalPrice',
    text: 'quotationPrice',
    sortable: true,
  },
]

const stableSort = (array = [], comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index])
  const sort = (arr) => {
    arr.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
  }
  sort(stabilizedThis)
  return stabilizedThis.map((el) => el[0])
}

const StatisticsTopTable = ({
  data = [],
  pagination,
  onClickRow,
  selectedRow,
  isFetched,
  skeletonRows = 10,
  sheetName,
  onDragEnd,
  handlePageChange,
  handleRowsPerPageChange,
}) => {
  const classes = useStyles()

  const [orderBy, setOrderBy] = useState('')
  const [order, setOrder] = useState('')

  const sorttedData = useMemo(
    () =>
      data.map((group) => ({
        ...group,
        sequence: stableSort(group.sequence, getComparator(order, orderBy)),
      })),
    [data, order, orderBy]
  )

  const handleSortData = (_orderBy) => {
    const isAsc = orderBy === _orderBy && order === 'asc'

    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(_orderBy)
  }

  const handleClickRow = (index) => {
    if (onClickRow) {
      onClickRow(index)
    }
  }

  const handleDragEnd = (result) => {
    const isValid = !!result.destination && !!result.source

    if (isValid) {
      const groupId = Number(result.type)
      const currentGroup = sorttedData.find(({ id }) => id === groupId)
      const fromIndex = result.source.index
      const toIndex = result.destination.index

      const from = currentGroup.sequence[fromIndex].salesmanId
      const to = currentGroup.sequence[toIndex].salesmanId

      if (onDragEnd) {
        onDragEnd({ groupId, from, to })
      }
    }
  }
  
  const getExcelData = () => {
    return sorttedData?.map(group => group.sequence)?.flat()
      ?.map(row => ({
        ...row,
        totalPrice: formatPrice(row.totalPrice),
      })) ?? []
  }

  return (
    <Card
      title=" "
      wrapStyle={{ marginBottom: 16 }}
      actionComponent={(
        <ExportExcelButton
          sheetName={sheetName}
          columns={COLUMNS.map(column => ({ ...column, text: I18n.t(column.text) }))}
          data={getExcelData()}
          headerColor='EBF1DE'
          fileType="xlsx"
        />
      )}
    >
      <Paper variant="outlined" className={classes.table}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {COLUMNS.map((item) => (
                  <TableCell key={item.id}>
                    <TableSortLabel
                      active={orderBy === item.id}
                      direction={orderBy === item.id ? order : 'asc'}
                      onClick={() => {
                        handleSortData(item.id)
                      }}
                    >
                      <div className={classes.head}>{I18n.t(item.text)}</div>
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {isFetched 
            ? sorttedData.map((group, groupIndex) => (
              <DragDropContext key={group.id} onDragEnd={handleDragEnd}>
                <Droppable droppableId={String(group.id)} type={String(group.id)}>
                  {(provided, snapshot) => (
                    <TableBody
                      innerRef={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {group.sequence.map((row, index) => {
                        return (
                          <NewOverviewItem
                            key={row.salesmanId}
                            row={row}
                            colorPalette={
                              groupIndex < 3 ? colorPalette[groupIndex] : colorPalette[3]
                            }
                            index={index}
                            isClicked={selectedRow === row.salesmanId}
                            onClickRow={handleClickRow}
                          />
                        )
                      })}
                      {provided.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </DragDropContext>
            ))
            : zeroArray(skeletonRows).map(() =>
              <TableRow>
                {zeroArray(4).map(() =>
                  <TableCell>
                    <Skeleton variant="text" style={{ width: "100%" }} />
                  </TableCell>
                )}
              </TableRow>
            )}
          </Table>
        </TableContainer>
      </Paper>
      <TablePagination
        count={pagination.count}
        rowsPerPage={pagination.rowsPerPage}
        page={pagination.pageIndex}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
      />
    </Card>
  )
}

const NewOverviewItem = ({
  row,
  index,
  onClickRow,
  isClicked,
  colorPalette,
}) => {
  const classes = useStyles({ groupedRowBgColor: colorPalette })
  const handleClickRow = () => {
    if (onClickRow) {
      onClickRow(row.salesmanId)
    }
  }

  return (
    <Draggable draggableId={String(row.salesmanId)} index={index}>
      {(provided, snapshot) => (
        <TableRow
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          innerRef={provided.innerRef}
          className={`${classes.groupedRow} ${
            isClicked ? classes.selectedRow : ''
          } ${classes.hovered}`}
          onClick={handleClickRow}
        >
          {COLUMNS.map((item) => (
            <TableCell key={item.id}>
              {item.id === 'totalPrice' ? formatPrice(row[item.id]) : row[item.id]}
            </TableCell>
          ))}
        </TableRow>
      )}
    </Draggable>
  )
}

export default StatisticsTopTable
