import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: '#FFFFFF',
    border: '1px solid #E0E0E0',
    borderRadius: '8px',
    height: '100%'
  },

  pickerArea: {
    '& > div:not(.MuiFormControl-root)': {
      padding: 3
    }
  },

  datePicker: {
    '&.rs-picker-toggle-wrapper': {
      margin: theme.spacing(0, 2)
    },

    '&.rs-picker-toggle-wrapper .rs-btn': {
      borderRadius: theme.spacing(1),
      zIndex: 0
    },

    '&.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value': {
      color: theme.palette.text.secondary
    }
  },

  teamLabel: {
    margin: theme.spacing(3, 0),

    '& span': {
      color: theme.palette.text.primary
    },

    '& > p + p': {
      marginLeft: theme.spacing(2)
    }
  },

  container: {
    overflowY: 'auto',
  },

  timeLine: {
    '&.MuiTimeline-root': {
      margin: 0,
      padding: 0,
      paddingLeft: 5
    },

    '& .MuiTimelineItem-missingOppositeContent:before': {
      content: 'none'
    },

    '& > .MuiTimelineItem-root': {
      '&:last-child': {
        '& .MuiTimelineConnector-root': {
          opacity: 0.4
        }
      },

      '&:first-child, &:last-child': {
        minHeight: 45,
      },

      '&:first-child > .MuiTimelineContent-root': {
        marginTop: 0
      },

      '& .MuiTimelineDot-root': {
        margin: 0
      },

      '& .MuiTimelineSeparator-root': {
        minWidth: 12,

        '& > .MuiTimelineConnector-root': {
          backgroundColor: '#2E65C9'
        }
      },

      '& .MuiTimelineContent-root': {
        padding: theme.spacing(0, 0, 0, 2),
        marginTop: -7
      }
    }
  },

  noData: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 500,

    '& > img': {
      width: 250,
      height: 250
    }
  }
}));

export { useStyles };