/** @jsx h */
import { h } from "preact";
import React from "react";
import { useState, useContext } from "preact/hooks";
import I18n from "i18n-js";
import moment from "moment";
import Context from "context";

import AddCircleIcon from '@material-ui/icons/AddCircle';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import Modal from "components/atoms/modal/Modal";
import { Card } from 'components/atoms/card/Card';
import { Row } from 'components/atoms/row/Row';
import Button from 'components/atoms/cubeButton/CubeBtn';
import { List } from 'components/molecules/list/List';
import { NextVisitList } from "components/molecules/nextVisitList/NextVisitList";
import ClientBranchInfo from 'components/molecules/clientBranchInfo/ClientBranchInfo';
import ContactForm from 'components/organisms/modalContent/client/ContactForm';

import { postClientContact } from "api";

// context snackbar error handling
import { errHandler } from "../../util";
import { route } from "preact-router";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    cursor: 'default',
    height: 'fit-content',
    padding: '16px 16px',
    backgroundColor: '#FAFAFA',
    border: '1px solid #E0E0E0',
    borderRadius: '8px',

    '& > .header': {
      marginBottom: theme.spacing(2),

      '& .title': {
        color: theme.palette.text.disabled
      },
    },

    '& .textButton': {
      color: '#2E65C9',
      padding: theme.spacing(0, 2)
    },

    '& ::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      width: 0
    },

    '& ::-webkit-scrollbar-track': {
      backgroundColor: 'transparent'
    }
  },
}));

const ClientDetail = ({ data, contactsData, setContactsData, setClient, machine, loading, setLoading, seeGroup, open, refresh, setRefresh, isFetched, setModalOrigin, editDeleteAllow }) => {
  const [modal, setModal] = useState(false);
  const [popModal, setPopModal] = useState(false);
  const [select, setSelect] = useState({});
  const { userList } = useContext(Context)

  const handleOpen = (modal, select = { children: [] }) => {
    setSelect(select);
    if (modal.includes("delete")) {
      setPopModal(modal);
    } else {
      setModal(modal);
    }
  };

  const handleClose = () => {
    setSelect({});
    setLoading(false);
    if (popModal) {
      setPopModal(false);
    } else {
      setModal(false);
    }
  };

  /* Contact */
  const addContact = (formValues) => {
    setLoading(true);
    if (modal === "add_contact") {
      postClientContact({
        id_client_branch: data.branch_id,
        full_name: `${formValues.last_name}${formValues.first_name}`,
        position: formValues.position,
        email: formValues.email ? [formValues.email] : [],
        phone: formValues.phone ? [formValues.phone] : [],
      })
        .then((res) => {
          setLoading(false);
          setModal(false);
          setRefresh(!refresh)
        })
        .catch((err) => {
          setLoading(false);
          errHandler(err, setError, route);
        });
    }
  }

  const seeSalesReport = (data) => {
    localStorage.setItem('selectedBranch', data.branch);
    route('/page/latestReport/salesreport');
  }

  return (
    <div>
      <ToolBar
        title={`${data.branch} ${I18n.t('Information')}`}
        back={() => setClient(null)}
        doEdit={() => setModalOrigin('edit_branch')}
        doDelete={() => open('deleteBranch', data)}
        editDeleteAllow={editDeleteAllow}
      />
      <Card noTitle wrapStyle={{ marginTop: '16px' }} contentStyle={{ overflowY: 'inherit' }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ClientBranchInfo
              isFetched={isFetched}
              data={data}
              machine={machine}
              isDetail
              showHistory
              seeGroup={seeGroup}
              refresh={refresh}
              setRefresh={setRefresh}
              onClick={seeSalesReport}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Container
              iconType='search'
              title={I18n.t('contactsList')}
              add={() => {
                handleOpen("add_contact")
              }}
              onSearchChange={(searchValue) => searchValue
                ? setContactsData(contactsData.filter((item) => item.name?.toLowerCase().includes(searchValue.toLowerCase())))
                : setContactsData(contactsData)}
            >
              <List
                noAvatar={true}
                isFetched={true}
                label="contact"
                data={contactsData}
                width="100%"
                contentHeight="350px"
              />
            </Container>
          </Grid>
          <Grid item xs={12} sm={6}>
            <NextVisitList
              type='salesman'
              loading={loading}
              setLoading={setLoading}
              addPrefill={{
                groupName: data.group_id,
                branchName: data.branch_id,
              }}
              filterOptions={{ id_client_branch: data.branch_id }}
              renderList={async (d) => {
                if (moment().isSameOrBefore(d.startTime, 'second')) {
                  const [data] = await userList.filter(item => d.userID === item.id)
                  const {
                    first_name,
                    last_name,
                  } = data;
                  const timeRangeString
                    = `${moment(d.startTime).format("HH:mm")} - ${moment(d.endTime).format("HH:mm")}`;
                  return {
                    id: d.id,
                    group: last_name + first_name,
                    note: d.remarks,
                    time: [moment(d.startTime).utc().format("YYYY/MM/DD"), timeRangeString],
                    icon: last_name + first_name,
                    ...d
                  }
                }
              }}
            />
          </Grid>
        </Grid>
      </Card>
      <Modal
        title={["AddA", "contact"]}
        open={modal == "add_contact"}
        close={handleClose}
        style={{ paddingBottom: '64px' }}
      >
        <ContactForm
          standalone
          setShow={setModal}
          addContact={addContact}
          contacts={contactsData}
          setContacts={setContactsData}
          loading={loading}
          type="add"
        />
      </Modal>
      <Modal
        title={["EditA", "contact"]}
        open={modal == "edit_contact"}
        close={handleClose}
        style={{ paddingBottom: '64px' }}
      >
        <ContactForm
          standalone
          setShow={setModal}
          addContact={addContact}
          select={select}
          type="edit"
          loading={loading}
        />
      </Modal>
    </div>
  )
}

const ToolBar = ({ title, back, doEdit, doDelete, editDeleteAllow }) => {
  return (
    <div className="d-flex jc-space-btw">
      <div className="d-flex al-center">
        <Button iconType="back" onClick={back} />
        <div style={{ width: '24px' }} />
        <Typography variant="h5" color="inherit">{title}</Typography>
      </div>
      <div className="d-flex">
        {
          editDeleteAllow &&
          <>
            <Button iconType="edit" text="Edit" onClick={doEdit} />
            <div style={{ width: '24px' }} />
            <Button iconType="delete" bgColor="red" textColor="white" onClick={doDelete} />
          </>
        }
        </div>
    </div>
  )
}

const Container = ({ title, iconType, add, onSearchChange, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Row justifyContent='space-between' alignItems='center' className='header'>
        <Typography variant="body1" className='title'>{title}</Typography>
        <Row>
          {
            iconType === 'search' &&
            <Button
              bgColor="#FFFFFF"
              textColor="rgba(0, 0, 0, .54)"
              iconType='search'
              onChange={onSearchChange}
              expandable={true}
              searchId='searchContact'
              placeholder={I18n.t("contact")}
            />
          }
          <AddCircleIcon style="color:#2E65C9;font-size:40px;margin-left:16px;cursor:pointer" onClick={add} />
        </Row>
      </Row>
      {children}
    </div>
  )
}

export default ClientDetail;
