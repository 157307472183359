import React from 'react';
import { Fragment } from 'preact';
import { useContext, useEffect, useMemo, useState } from 'preact/hooks';
import { SelectPicker } from 'rsuite';
import I18n from 'i18n-js';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { RemoveCircle } from '@material-ui/icons';
import { Row } from 'components/atoms/row/Row';
import { CollapseTable } from 'components/molecules/collapseTable/CollapseTable';
import { Column } from 'components/atoms/column/Column';
import CubeBtn from 'components/atoms/cubeButton/CubeBtn';
import { getProductSpecification, getProductSpecificationMachine, getProductSpecificationManufacturer, getProductSpecificationPower, getProductSpecificationSeries } from 'api';
import Context from 'context';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4),
		color: 'rgba(0, 0, 0, 0.54)',
    },
    mb2: { marginBottom: theme.spacing(2) },
}));

const DEFAULT_NUMBER_OF_COMPARE_DATA = 3;
const OPTIONS = [
    'competitor',
    'colorType',
    'series',
    'machineTypeAndPound',
];
const UNIT_TYPES = ['Types', 'INJECTION UNIT', 'MOULD CLAMPING UNIT', 'ELECTRICAL EQUIOMENT', 'OTHERS'];
const DETAIL_NAMES = [
    [
        'DATA_TYPE',
        'CLAMPING_NAME',
        'DRIVE_NAME',
        'INDUSTRY_NAME',
    ],
    [
        'SCREW DIAMETER',
        'SCREW STROKE',
        'THEORETICAL SHOT VOLUME',
        'SHOT WEIGHT OF INJECTION (PS)',
        'INJECTION PRESSURE',
        'INJECTION SPEED',
        'INJECTION RATE',
        'PLASTICIZING CAPACITY(PS)',
        'SCREW ROTATION SPEED',
    ],
    [
        'MOULD CLAMPING FORCE',
        'MOULD CLAMPING STROKES',
        'MOULD THICKNESS',
        'SUGGESTED MINIMUM MOLD DIM (H×V)',
        'DISTANCE BETWEEN TIE BAR',
        'MOULD PLATEN',
        'EJECTOR STROKE',
        'EJECTOR FORCE',
    ],
    [
        'PUMP DRIVING MOTOR',
        'TEMPERTURE CONTROLLER',
        'Heater capacity',
    ],
    [
        'MACHINE DIMENSION  ( L×W×H )',
        'OIL TANK CAPACITY',
        'MACHINE WEIGHT',
        'Max system pressure',
    ],
];
const DETAIL_UNITS = {
    DATA_TYPE: '',
    CLAMPING_NAME: '',
    DRIVE_NAME: '',
    INDUSTRY_NAME: '',
    'SCREW DIAMETER': 'mm',
    'SCREW STROKE': 'mm',
    'THEORETICAL SHOT VOLUME': 'cm³',
    'SHOT WEIGHT OF INJECTION (PS)': 'gram',
    'INJECTION PRESSURE': 'kgf/cm²',
    'INJECTION SPEED': 'mm/sec',
    'INJECTION RATE': 'cm3/sec',
    'PLASTICIZING CAPACITY(PS)': 'g/sec',
    'SCREW ROTATION SPEED': 'rpm',
    'MOULD CLAMPING FORCE': 'kN(tonf)',
    'MOULD CLAMPING STROKES': 'mm',
    'MOULD THICKNESS': 'mm',
    'SUGGESTED MINIMUM MOLD DIM (H×V)': 'mm',
    'DISTANCE BETWEEN TIE BAR': 'mm',
    'MOULD PLATEN': 'mm',
    'EJECTOR STROKE': 'mm',
    'EJECTOR FORCE': 'kN(tonf)',
    'PUMP DRIVING MOTOR': 'HP (kw)',
    'TEMPERTURE CONTROLLER': 'set',
    'Heater capacity': 'kW',
    'MACHINE DIMENSION  ( L×W×H )': 'mm',
    'OIL TANK CAPACITY': 'liter',
    'MACHINE WEIGHT': 'ton',
    'Max system pressure': 'kgf/cm2',
};

const INITIAL_COMPARE_DATA = {
    competitor: '',
    colorType: '',
    series: '',
    machineTypeAndPound: '',
};

// Since machine value should reset when series changes and series should reset when competitor and power changes,
// I arranged some conditions to identify which action should we take when a option value changes.
/**
 * @param {string} option
 * @returns {string[]} Array of option names to reset.
 */
const getOptionsToReset = (option) => {
    const index = OPTIONS.indexOf(option);
    return index < 2 ? OPTIONS.slice(2) : index === 2 ? OPTIONS.slice(3) : [];
};

const filterOptions = (options, properties, values) => {
    if (values.reduce((prev, current) => prev && !current, true)) return [];
    return options.filter((option) => {
        return properties.reduce((prev, current, index) => {
            return prev && (
                !values[index] || option[current] === values[index]
            );
        }, true);
    });
};

const ScaleCompare = () => {
    const classes = useStyles();
    const { compareData, setCompareData } = useContext(Context);

    const [competitors, setCompetitors] = useState([]);
    const [powers, setPowers] = useState([]);
    const [series, setSeries] = useState([]);
    const [machines, setMachines] = useState([]);
    const [details, setDetails] = useState(Array(DEFAULT_NUMBER_OF_COMPARE_DATA).fill({}));

    // options formatting
    const competitorOptions = useMemo(() => {
        return competitors.map((competitor) => ({ ...competitor, value: competitor.COMP_ID, label: competitor.COMP_ID_NAME }));
    }, [competitors]);
    const powerOptions = useMemo(() => {
        return powers.map((power) => ({ ...power, value: power.POWERED_ID, label: power.REM }));
    }, [powers]);
    const seriesOptions = useMemo(() => {
        return series.map((series) => ({ ...series, value: series.SERIES_ID, label: series.SERIES_NAME }));
    }, [series]);
    const machineOptions = useMemo(() => {
        return machines.map((machine) => ({ ...machine, value: machine.TYPE_ID, label: machine.USE_MACH_SA }));
    }, [machines]);

    // organize options
    const optionData = useMemo(() => {
        return [competitorOptions, powerOptions, seriesOptions, machineOptions];
    }, [competitorOptions, powerOptions, seriesOptions, machineOptions]);

    // true when all options have data
    const isFetched = useMemo(() => {
        return optionData.reduce((prev, current) => prev && !!current.length, true);
    }, [optionData]);

    const firstTableData = useMemo(() => {
        const rows = OPTIONS.concat(DETAIL_NAMES[UNIT_TYPES.indexOf('Types')]);
        return rows.map((option) => {
            const isOption = OPTIONS.includes(option);
            // handle language display since i18n strings are from api
            if (
                I18n.locale === 'en' && (
                    option === 'CLAMPING_NAME'
                    || option === 'DRIVE_NAME'
                    || option === 'INDUSTRY_NAME'
                )
            ) {
                option = option.split('_').join('_E');
            }
            return {
                id: option,
                option: I18n.t(option),
                ...(isOption ? compareData : details).map((data) => data[option] || null),
                isOption,
                options: optionData[OPTIONS.indexOf(option)],
            };
        });
    }, [compareData, optionData, details]);

    const dataColumns = useMemo(() => {
        return compareData.reduce((prev, _, index) => [...prev, { id: index.toString() }], []);
    }, [compareData.length]);

    const dataCell = (row, col) => {
        const optionIndex = OPTIONS.indexOf(row.id);
        const columnCompareData = compareData[col.id];
        const options = optionIndex < 2 ? row.options : (
            filterOptions(
                row.options,
                optionIndex === 2 ? ['COMP_ID', 'POWERED_ID'] : ['SERIES_ID'],
                optionIndex === 2 ? [columnCompareData.competitor, columnCompareData.colorType] : [columnCompareData.series],
            )
        );
        return (
            <Fragment>
                {row.isOption ? (
                    <>
                        <SelectPicker
                            cleanable={false}
                            data={options}
                            value={row[col.id]}
                            onChange={(value) => setCompareData((prev) => {
                                const optionsToReset = getOptionsToReset(row.id).reduce((prev, current) => ({ ...prev, [current]: '' }), {});
                                return prev.map((item, index) => {
                                    if (index !== parseInt(col.id)) return item;
                                    else return { ...item, [row.id]: value, ...optionsToReset };
                                });
                            })}
                            style={{
                                width: '100%',
                                ...(compareData.length > 5 ? { fontSize: 12 } : {}),
                            }}
                        />
                        {row.id === 'competitor' && (
                            <RemoveCircle
                                color='error'
                                style={{ cursor: 'pointer', marginLeft: 5, ...(compareData.length > 5 ? { fontSize: 14 } : {}) }}
                                onClick={() => {
                                    if (compareData.length <= 2) return;
                                    setCompareData(prev => prev.filter((_, index) => index !== parseInt(col.id)));
                                }}
                            />
                        )}
                    </>
                ) : row[col.id] || '-'}
            </Fragment>
        );
    };

    const indexesOfDataToFetch = useMemo(() => {
        return compareData.reduce((prev, current, index) => !!current.machineTypeAndPound ? [...prev, index] : prev, []);
    }, [compareData]);

    // options initial fetching
    useEffect(() => {
        (async () => {
            try {
                setCompetitors((await getProductSpecificationManufacturer({ oracleAsPrimary: true })).data);
                setPowers((await getProductSpecificationPower()).data);
                setSeries((await getProductSpecificationSeries()).data);
                setMachines((await getProductSpecificationMachine()).data);
            } catch (error) {
                console.log('Get Options Error: ', error);
                if (!!error.response) console.log(error.response.data);
            }
        })();
    }, []);

    // fetch details based on if select data is completed
    useEffect(() => {
        (async () => {
            try {
                if (!indexesOfDataToFetch.length || !machines.length) {
                    setDetails(new Array(compareData.length).fill({}));
                    return;
                }

                const typeIds = indexesOfDataToFetch.map((index) => compareData[index].machineTypeAndPound);

                const detailsHandler = (detail) => {
                    if (!detail.SPEC || typeof detail.SPEC !== 'object') detail.SPEC = {};

                    // i18n-js seems cannot accept dot character in keys, so this should remove all dots in all columns.
                    const detailNames = UNIT_TYPES.filter((name) => name !== 'Types');
                    const newSPEC = detailNames.reduce((prev, current) => {
                        const handledDetail = detail.SPEC[current]?.map((columnItem) => {
                            return {
                                ...columnItem,
                                column: columnItem.column.split('.').join(''),
                            };
                        });

                        return {
                            ...prev,
                            [current]: handledDetail || [],
                        }
                    }, {});

                    return {
                        ...detail,
                        DATA_TYPE: machines.find(({ TYPE_ID }) => TYPE_ID === detail.TYPE_ID)?.DATA_TYPE,
                        SPEC: newSPEC,
                    };
                };
                const details = (await getProductSpecification({ TYPE_ID: typeIds })).data;
                const handledDetails = details.map(detailsHandler);
                const detailsData = compareData.map(({ machineTypeAndPound }, index) => {
                    if (!indexesOfDataToFetch.includes(index)) return {};
                    else {
                        const detail = handledDetails.find(({ TYPE_ID }) => TYPE_ID === machineTypeAndPound);
                        return { ...detail, ...detail.SPEC };
                    }
                });
                setDetails(detailsData);
            } catch (error) {
                console.log('Get Details Error: ', error);
                if (!!error.response) console.log(error.response.data);
            }
        })();
    }, [indexesOfDataToFetch, machines]);

    return (
        <div className={classes.root}>
            <Row justifyContent='space-between' alignItems='center' className={classes.mb2}>
                <Typography variant='h5' color='inherit'>
                    {I18n.t('ScaleCompare')}
                </Typography>
                <Row alignItems='center'>
                    <CubeBtn
                        iconType='add'
                        text={I18n.t('Create')}
                        bgColor='#2e65c9'
                        textColor='#fff'
                        onClick={() => {
                            if (compareData.length >= 6) return;
                            setCompareData(prev => prev.concat(INITIAL_COMPARE_DATA));
                        }}
                    />
                </Row>
            </Row>
            <Column>
                <CollapseTable
                    disableHeader
                    disableSelection
                    disablePagination
                    columns={[
                        { id: 'option' },
                        ...dataColumns.map((column) => ({
                            ...column,
                            Cell: dataCell,
                            rowCellStyle: { width: `${80 / (compareData.length)}%` },
                            rowLabelStyle: { justifyContent: 'center' },
                        })),
                    ]}
                    data={firstTableData}
                    isFetched={isFetched}
                    skeletonColumns={4}
                    containerStyle={{ marginBottom: '2em' }}
                />
                {
                    UNIT_TYPES.map((type) => {
                        if (type === 'Types') return null;

                        const partialDetailNames = DETAIL_NAMES[UNIT_TYPES.findIndex((_type) => _type === type)];
                        const data = partialDetailNames.map((name) => ({
                            detailName: I18n.t(name),
                            unit: DETAIL_UNITS[name],
                            ...details.map((details) => details[type]?.find(({ column }) => column === name)?.value || '-'),
                        }));

                        return (
                            <CollapseTable
                                disableSelection
                                disablePagination
                                columns={[
                                    { id: 'detailName', label: I18n.t(type) },
                                    {
                                        id: 'unit',
                                        disableLabel: true,
                                        rowCellStyle: { width: '5%' },
                                        rowLabelStyle: {
                                            color: 'rgba(0, 0, 0, 0.54)',
                                            justifyContent: 'center'
                                        }
                                    },
                                    ...dataColumns.map((column) => ({
                                        ...column,
                                        disableLabel: true,
                                        rowCellStyle: { width: `${80 / (compareData.length)}%` },
                                        rowLabelStyle: { justifyContent: 'center' },
                                    })),
                                ]}
                                data={data}
                                isFetched={isFetched}
                                skeletonColumns={4}
                                containerStyle={{ marginBottom: '2em' }}
                            />
                        );
                    })
                }
            </Column>
        </div>
    );
};

export default ScaleCompare;