/** @jsx h */
import { h } from "preact";
import { useState, useEffect, useRef } from "preact/hooks";
import useStyles from "./YearPicker.style";

import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import moment from "moment";

const YearPicker = ({ className, style, calendarIcon, onChange, width }) => {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const [select, setSelect] = useState(moment().utc().year());
	const [color, setColor] = useState("rgba(0,0,0,.54)");
	const [years, setYears] = useState([]);
	const ref = useRef(null);

	useEffect(() => {
		const arr = [];
		const range = [2022, moment().year() + 30];
		for (let i = range[0]; i < range[1]; i++) {
			arr.push(i);
		}
		setYears(arr);
	}, []);

	useEffect(() => {
		const listener = document.addEventListener("click", (e) => {
			const outsideClick = !ref.current?.contains(e.target);
			if (outsideClick) setOpen(false);
		});
		return () => {
			document.removeEventListener("click", listener);
		};
	}, []);

	const selected = (val) => {
		setSelect(val);
		onChange(val);
	};

	return (
		<div className={`${classes.select} relative ${className}`} style={({ width: width }, style)}>
			<div
				ref={ref}
				id="year-selector"
				class="al-center jc-space-btw"
				onClick={() => setOpen(!open)}
			>
				{calendarIcon && <CalendarTodayIcon style={{ color: color }} />}
				<span class="flex-1" style={{ color: color }}>
					{select}
				</span>
				<ArrowDropDownIcon style={{ color: color }} />
			</div>
			{open && (
				<div className={`${classes.list} column`}>
					{years.map((item, i) => (
						<span key={i} onClick={() => selected(item)}>
							{item}
						</span>
					))}
				</div>
			)}
		</div>
	);
};
export default YearPicker;