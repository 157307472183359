/** @jsx h */
import { h } from "preact";
import { useState, useContext } from "preact/hooks";
import clsx from "clsx";
import { route } from "preact-router";
import Match from "preact-router/match";

import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import I18n from "i18n/i18n";
import { SystemUpdateAlt, AutorenewOutlined } from "@material-ui/icons";
import { deleteErpCache, postImportClient } from "api";
import Context from "context";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    color: "rgba(0, 0, 0, 0.54)",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    paddingTop: 48,
    overflowX: "hidden",
    zIndex:0
  },
  drawerPaperClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 69,
  },
  button: {
    "&:hover": {
      color: "#2E65C9",
      backgroundColor: "#EFF5FF",
    },
    padding: 12,
  },
  buttonActive: {
    color: "#2E65C9",
    backgroundColor: "#EFF5FF",
  },
  icon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 15,
    color: "inherit",
    minWidth: "initial",
    width: 44,
  },
  collapseButton: {
    position: "absolute",
    bottom: theme.spacing(2),
    margin: 12,
    padding: 0,
    height: 44,
    width: "calc(100% - 24px)",
    borderRadius: 8,
    backgroundColor: "#eeeeee",
    cursor: "pointer",
    // Hover on collapse button was asked to be removed by Victor
    /*"&:hover": {
      backgroundColor: "#EFF5FF",
    },*/
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  collapseButtonClose: {
    width: 44,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  versionString: {
    display: "inline-block",
    position: "absolute",
    bottom: theme.spacing(10),
    margin: "auto",
    textAlign: "center",
  }
}));

export const Menu = ({ pages }) => {
  const classes = useStyles();
  const { setMessage } = useContext(Context);
  const [open, setOpen] = useState(false);

  const handleMenuItemClick = ({ to }) => {
    route(to);
  };

  const syncERPData = async (type) => {
    try {
      if (type === 'ERP') {
        await deleteErpCache('all');
      } else if (type === 'clients') {
        await postImportClient();
      }
      setMessage(I18n.t("requestSuccessful", { name: type === 'ERP' ? 'ERP' : I18n.t('clients'), verb: I18n.t("Synchronized") }));
      setTimeout(() => {
        location.reload();
      }, 3000);
    } catch (error) {
      console.log(`Sync ${type} Error: `, error);
      if (!!error.response) console.log(error.response.data);
    }
  }

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      PaperProps={{ elevation: 24 }}
      open={open}
    >
      <List component="nav" style={{ padding: 0 }}>
        <Match>
          {({ path }) =>
            pages.map(({ icon, to, name }) => (
              <ListItem
                button
                key={to}
                onClick={() => handleMenuItemClick({ icon, to, name })}
                className={clsx(
                  classes.button,
                  sliceAndCompare(path, to) && classes.buttonActive
                )}
              >
                <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
                <ListItemText primary={I18n.t(name)} />
              </ListItem>
            ))
          }
        </Match>
        <ListItem
          button
          onClick={() => syncERPData('ERP')}
          className={classes.button}
        >
          <ListItemIcon className={classes.icon}>
            <SystemUpdateAlt />
          </ListItemIcon>
          <ListItemText primary={I18n.t('syncErp')} />
        </ListItem>
        <ListItem
          button
          onClick={() => syncERPData('clients')}
          className={classes.button}
        >
          <ListItemIcon className={classes.icon}>
            <AutorenewOutlined />
          </ListItemIcon>
          <ListItemText primary={I18n.t('syncClient')} />
        </ListItem>
      </List>
        { open ? <ListItem className={classes.versionString}>v { process.env.PREACT_APP_VERSION || "local" }</ListItem> : null }
      <ListItem
        className={clsx(
          classes.collapseButton,
          !open && classes.collapseButtonClose
        )}
        onClick={() => setOpen(!open)}
      >
        <ListItemIcon className={classes.icon}>
          {open ? (
            <ArrowBackIcon fontSize="small" color="inherit" />
          ) : (
            <ArrowForwardIcon fontSize="small" color="inherit" />
          )}
        </ListItemIcon>
        <ListItemText primary={I18n.t("CollapseMenu")} />
      </ListItem>
    </Drawer>
  );
};

const sliceAndCompare = (from, to) => 
  (from.split("/").length === 4 ? from.split("/").slice(0,-1).join("/") : from) === (from.split("/").length === 4 ? to.split("/").slice(0,-1).join("/") : to);