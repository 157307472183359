/** @jsx h */
import React from 'react';
import { h } from "preact";
import { useEffect, useState } from "preact/hooks";
import I18n from "i18n-js";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from 'components/atoms/cubeButton/CubeBtn';
import { Card } from 'components/atoms/card/Card';
import { Row } from "components/atoms/row/Row";
import { optionTypes } from "components/atoms/equipmentOption/EquipmentOption";
import EmptyIcon from 'components/atoms/emptyIcon/emptyIcon';
import Category from "components/molecules/machineProfile/Category";
import { getProductSpecificationMachineSpec } from 'api';
import { formatPrice } from 'helper/format';
import { errHandler } from 'util';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    width: "100%",
    color: "rgba(0, 0, 0, 0.54)",
  },
  container: {
    flex: 1,
    cursor: 'default',
    height: 'fit-content',
    padding: '16px 16px',
    backgroundColor: '#FAFAFA',
    border: '1px solid #E0E0E0',
    borderRadius: '8px',

    '& > .header': {
      marginBottom: theme.spacing(2),

      '& .title': {
        color: theme.palette.text.disabled,
        fontWeight: 600
      },
    },

    '& .textButton': {
      color: '#2E65C9',
      padding: theme.spacing(0, 2)
    },

    '& ::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      width: 0
    },

    '& ::-webkit-scrollbar-track': {
      backgroundColor: 'transparent'
    }
  },
  titleArea: {
    '& > .MuiTypography-root': {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 600,

      '& + .MuiTypography-root': {
        marginLeft: theme.spacing(2)
      },

      '& > svg': {
        marginLeft: theme.spacing(0.5)
      }
    }
  }
}));

const MachineProfile = ({ data, goBack }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    loadCategories();
  }, [data]);

  const formatStatements = (statements) => {
    return statements.map(statement => ({
      id: statement.SASTEMENT_TYPE_ID,
      type: statement.SASTEMENT_TYPE_ID ?? 'C',
      name: statement.SASTEMENT_NO,
      statement: I18n.locale === 'en' ? statement.NAME_EN :statement. NAME_CH,
      price: statement.PRICE
    }))
  }

  const loadCategories = async () => {
    try {
      let categories = (await getProductSpecificationMachineSpec({ TYPE_ID: data.typeId.toString() })).data;
      setCategories(
        categories.map(item => ({
          id: item.CLASS_ID,
          name: I18n.locale === 'en' ? item.CLASS_NAME_EN : item.CLASS_NAME_CH,
          statements: formatStatements(item.machineSpecList)
        }))
      );
    } catch (err) {
      errHandler(err);
    }
  }

  return (
    <div className={classes.root}>
      <ToolBar
        title={`${data.name}${I18n.t('UserProfile')}`}
        back={goBack}
      />
      <Card title={I18n.t("Information")} wrapStyle={{ marginTop: 16 }}>
        <Row alignItems='center' style={{ marginBottom: 24 }}>
          <div>
            <Typography variant='h5' color='textPrimary'>
              {data.name}
            </Typography>
            <Typography variant='body1' color='textSecondary' style={{ marginTop: 8 }}>
              NTD{' '}
              <span style={{ color: theme.palette.text.primary }}>${formatPrice(data.price)}</span>
            </Typography>
            <Typography variant='body1' color='textSecondary' style={{ marginTop: 8 }}>
              USD{' '}
              <span style={{ color: theme.palette.text.primary }}>${formatPrice(data.priceUSD)}</span>
            </Typography>
          </div>
        </Row>
        <div className={classes.container}>
          <Row justifyContent='space-between' alignItems='center' className='header'>
            <Typography variant="body1" className='title'>{I18n.t('equipment')}:</Typography>
            <Row alignItems='center' className={classes.titleArea}>
              {['A', 'B', 'C'].map((type) => (
                <Typography variant="subtitle2" color="textSecondary">
                  {I18n.t(`equipment_${type}`)}
                  {optionTypes[type]}
                </Typography>
              ))}
            </Row>
          </Row>
          {categories?.length ?
            categories.map((row, index) => (
              <Category
                key={row.id}
                style={{ marginBottom: index !== categories.length - 1 ? 16 : 0 }}
                {...row}
              />
            ))
          : <EmptyIcon />}
        </div>
      </Card>
    </div>
  )
}

const ToolBar = ({ title, back }) => {
  return (
    <div className="d-flex jc-space-btw">
      <div className="d-flex al-center">
        <Button iconType="back" onClick={back} />
        <div style={{ width: '24px' }} />
        <Typography variant="h5" color="inherit">{title}</Typography>
      </div>
    </div>
  )
}

export default MachineProfile;
