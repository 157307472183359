/** @jsx h */
import { h } from "preact";
import { useEffect, useState, useContext } from "preact/hooks";
import I18n from "i18n-js";
import Context from "context";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import Button from 'components/atoms/cubeButton/CubeBtn';
import { Card } from "components/atoms/card/Card";
import { Row } from "components/atoms/row/Row";
import MachineInfo from "components/molecules/machineInfo/MachineInfo";
import ByUser from "components/molecules/byUser/ByUser";
import ClientBranchInfo from 'components/molecules/clientBranchInfo/ClientBranchInfo';
import OverallData from 'components/molecules/overallData/OverallData';
import Calculation from "components/molecules/calculation/Calculation";
import { DataCard } from "components/molecules/dataCard/DataCard";
import ReportNote from "components/organisms/modalContent/reportNote/ReportNote";
import moment from "moment/min/moment-with-locales";

import EditModal from "components/organisms/modalContent/sales/EditQuotations";
import MachineOptionsModal from 'components/organisms/modalContent/sales/MachineOptions';
import { getCompetitorMachine, getQuotation, getClientBranch, getClient } from 'api';
import { Skeleton } from "@material-ui/lab";
import { formatAddress } from "helper/format";
import { useRole } from "hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    width: "100%",
    color: "rgba(0, 0, 0, 0.54)",
  },
  marginBottom2: {
    marginBottom: theme.spacing(2),
  },
  blueColor: {
    color: '#2E65C9'
  }
}));

const separator = (numb) => {
	if (numb || numb === 0) {
	  var str = numb.toString().split(".");
	  str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	  return str.join(".");
	}
	return '';
}

const EmptyOrderDetails = () => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={5}>
        <Card noTitle>
          <Skeleton variant="rect" height={700} />
        </Card>
      </Grid>
      <Grid item xs={12} md={7}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card noTitle>
              <Skeleton variant="rect" height={300} />
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card
              title={I18n.t('dealRate')}
              wrapStyle={{ height: '100%' }}
              contentStyle={{ textAlign: 'center' }}
            >
              <Skeleton variant="rect" />
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card
              title={I18n.t('By')}
              wrapStyle={{ height: '100%' }}
              contentStyle={{ textAlign: 'center' }}
            >
              <Skeleton variant="rect" />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card title={I18n.t('details')}>
              <Skeleton variant="rect" height={150} />
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const OrderDetails = ({ orderDetails, urlParams, back }) => {
  const locale = localStorage.getItem("locale");
  const classes = useStyles();
  const { roleId } = useRole();
  const [modal, setModal] = useState(null);
  const [machineSelected, setMachineSelected] = useState(null);
  const [competitorMach, setCompetitorMach] = useState([]);
  const [data, setData] = useState(orderDetails);
  const { userList, groupList, abbreviation } = useContext(Context)

  useEffect(() => {
    getCompetitorMachine().then(res => setCompetitorMach(res.data));
  }, []);

  useEffect(() => {
    loadData();
  },[data])

  const loadData = async () => {
    if (data === undefined) {
      if (urlParams.qid !== undefined && urlParams.bid !== undefined) {
        const quotationData = (await getQuotation({ id : urlParams.qid }, roleId)).data[0]
        const branchData = (await getClientBranch({ id : urlParams.bid })).data[0]
        const groupData = (await getClient({ id : branchData.id_client })).data[0]

        const machines = quotationData.machines.map((m => {
          return {
            machine: m.machine_name,
            price: m.machine_price,
            orderNum: ' ',
            date: ' ',
            buyDate: ' ',
            rate: ' ',
            client: ' ',
          }
        }));

        const branchInfo = !!branchData ? {
          branch: branchData.name,
          country: branchData.country,
          clientAddress: formatAddress(branchData),
          name_of_salesman: branchData.name_of_salesman,
          first_industry_level: locale === 'en' ? branchData.info_first_industry_level?.industry_name_eng : branchData.info_first_industry_level?.industry_name_ch,
          second_industry_level: locale === 'en' ? branchData.info_second_industry_level?.industry_name_eng : branchData.info_second_industry_level?.industry_name_ch,
          product_manufactured: branchData.product_manufactured,
        } : {}

        const orderDetails = {
          ...quotationData,
          orderNum: quotationData.id,
          date: moment(quotationData.created_at).format("YY/MM/DD, HH:MM"),
          machine: machines.map(mac => mac.machine).join(',') || ' ',
          buyDate: moment(quotationData.expect_buy_date).format("YYYY/MM"),
          client: `${groupData?.name ?? ''}(${branchData?.name ?? ''})`,
          group: `${groupData.name}`,
          children: machines,
          original: quotationData,
          ...branchInfo
        }	
        setData(orderDetails)
      }
    }
  }

  const formatOptions = (data) => {
    return data?.map(item => {
      const { name, name_en, name_ch, price, remark } = item;

      return {
        label: name ?? (localStorage.getItem("locale") == "en" ? name_en : name_ch),
        value: price,
        description: name ?? '',
        remark
      }
    });
  }

  const formatOrderData = (data, salesman, team) => {
    const actualDiff = (Math.abs(data.suggestion_price - data.total_price) / data.suggestion_price * 100).toFixed(2)
    return {
      ...data,
      actualDiff: `${!isNaN(actualDiff) && !actualDiff.includes('Infinity') ? actualDiff : '-'}%`,
      dealRate: data.rate,
      dealIssue: data.factor,
      salesman: salesman.last_name + salesman.first_name,
      salesmanTeam: team?.name,
      salesmanRegion: salesman.id_region === 1 ? I18n.t('Taiwan') : salesman.id_region === 2 ? I18n.t('International') : '',
      createDate: moment(data.created_at).utc().format('YYYY-MM-DD'),
      expectedOrderMonth: data.expect_buy_date,
      // Calculation
      machines: data.original.machines.map(mac => {
        return {
          ...mac,
          machineId: mac.id_machine,
          label: mac.machine_name,
          value: mac.negotiation_price,
          options: formatOptions(mac.erp_options),
          otherOptions: formatOptions(mac.erp_other_options),
          additionalOptions: formatOptions(mac.additional_options),
          optionsPrice: mac.total_options_total_price,
          priceCondition: data.original.note,
          paymentMethod: data.original.pay_method,
          rate: data.rate,
          expectedBuyingDate: data.buyDate,
        }
      }),
    }
  }

  const getSource = (orderData) => {
    let source;
    if (!orderData.source) {
      source = ''
    } else if (orderData.source.length > 0) {
      const sourceContext = abbreviation.find((abObject) => {
        return abObject.type === 'source' && abObject.code === orderData.source[0];
      })
      source = sourceContext.name;
    } else if (orderData.source.length == 0) {
      source = ''
    } else {
      const sourceContext = abbreviation.find((abObject) => {
        return abObject.type === 'source' && abObject.code === orderData.source;
      })
      source = sourceContext.name;
    }
    return source;
  }

  if (!data) {
    return <EmptyOrderDetails />
  } else {
    const salesman = userList.filter(user => data.original.created_by === user.id)[0];
    const team = groupList?.filter(group => salesman.id_group === group.id)[0];

    // Data preprocess and tidy up
    const orderData = formatOrderData(data, salesman, team);
    const source = getSource(orderData);
    const factors = (orderData.dealIssue) ? 
      orderData.dealIssue.map((factor) => {
        const factorContext = abbreviation.find((abObject) => {
          return abObject.type === 'factor' && abObject.code === factor;
        })
        return I18n.t(factorContext.name);
      }) 
    : [];

    const clientData = {
      ...data,
      address: data?.clientAddress ?? '-',
      machine: competitorMach.map((mac) => ({
        brand: mac.brand,
        machinetype: mac.type,
        quantity: 1
      }))
    }

    // this is preprocess data (with i18n or some structure) for DataCard / OverallData Component
    const labelData = [
      {
        label: I18n.t("totalPaid"),
        value: orderData.is_finish === 1 ? `$${separator(orderData.total_price)}` : "$0"
      },
      {
        label: I18n.t("paymentLeft"),
        value: orderData.is_finish === 0 ? `$${separator(orderData.total_price)}` : "$0"
      },
      {
        label: I18n.t("actualDiff"),
        value: separator(orderData.actualDiff)
      }
    ];

    const overallData = [
      {
        label: 'sources',
        value: source
      },
      {
        label: 'expectedProduct',
        value: orderData.expected_product
      },
      {
        label: 'rate',
        value: orderData.dealRate,
      },
      {
        label: 'paymentMethod',
        value: typeof orderData.machines === 'object'? orderData.machines[0]?.paymentMethod: '',
      },
      {
        label: 'dealIssue',
        value: factors
      },
      {
        label: 'team',
        value: orderData.salesmanTeam
      },
      {
        label: 'Nation',
        value: orderData.salesmanRegion
      },
      {
        label: 'expectedOrderMonth',
        value: orderData.expectedOrderMonth
      },
      {
        label: 'createdDate',
        value: orderData.createDate
      }
    ];

    return (
      <div className={classes.root}>
        <ToolBar
          title={I18n.t('order', { value: orderData.orderNum })}
          back={back}
          doEdit={() => setModal("editQuot")}
          doDelete={() => console.log('doDelete')}
          className={classes.marginBottom2}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={5}>
            <Card noTitle>
              <MachineInfo orderData={orderData} seeMachineNote={(machine) => {
                setMachineSelected([machine]);
                setModal("seeOptions");
              }} />
            </Card>
            <Card title={I18n.t("seeNote")} wrapStyle={{ marginTop: 10 }}>
              <ReportNote data={{ team: orderData.salesmanTeam, salesman: orderData.salesman, note: data.note }} />
            </Card>
          </Grid>
          <Grid item xs={12} md={7}>
            <DataCard type='label' data={labelData} title={I18n.t('quotation')} content={separator(`$${orderData.total_price}`)} className={classes.marginBottom2} />
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <Card
                  title={I18n.t('dealRate')}
                  wrapStyle={{ height: '100%' }}
                  contentStyle={{ textAlign: 'center' }}
                >
                  <Typography variant='h2' className={classes.blueColor} >{orderData.dealRate}</Typography>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <ByUser name={orderData.salesman} team={orderData.salesmanTeam} />
              </Grid>
              <Grid item xs={12}>
                <Card title={I18n.t('details')}>
                  <ClientBranchInfo data={clientData} isFetched />
                  <OverallData data={overallData} title={I18n.t('overall')} />
                  <Calculation isOverall data={orderData.machines} />
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <EditModal
          open={modal === "editQuot" || modal === "deleteMachine"}
          close={() => setModal(null)}
          deleteMachine={() => setModal("deleteMachine")}
          title={I18n.t("EditAn", { name: I18n.t("quotation") })}
        />
        <MachineOptionsModal
          data={machineSelected}
          open={modal === "seeOptions"}
          close={() => setModal(null)}
        />
      </div>
    )    
  }
}

const ToolBar = ({ title, back, doEdit, doDelete, className }) => {
  return (
    <div className={"d-flex jc-space-btw " + className}>
      <div className="d-flex al-center">
        <Button iconType="back" onClick={back} />
        <div style={{ width: '24px' }} />
        <Typography variant="h5" color="inherit">{title}</Typography>
      </div>
      {/* <div className="d-flex">
        <Button iconType="edit" text="Edit" onClick={doEdit} />
        <div style={{ width: '24px' }} />
        <Button iconType="delete" bgColor="red" textColor="white" onClick={doDelete} />
      </div> */}
    </div>
  )
}

export default OrderDetails;