/* eslint-disable no-else-return */
import { CollapseTable } from "components/molecules/collapseTable/CollapseTable";

const COMMON_COLS = [
  { id: 'updateDate', sortable: true },
  { id: 'createdDate', sortable: true },
  { id: 'salesman', sortable: true },
  { id: 'clientName', sortable: true },
  { id: 'secondBusinessLevel', sortable: true },
  { id: 'customerCountry', sortable: true },
  { id: 'updateProgess', sortable: true },
  { id: 'productManufactured', sortable: true },
  { id: 'competitors', sortable: true,
    Cell: (data) => (
      data?.competitors?.map((item, index) => 
        <div style={{ backgroundColor: '#EFF5FF', borderRadius: 4, padding: 4, marginLeft: index > 0 ? 5 : 0 }}>
          {item}
        </div>
      )
    )
  },
  { id: 'factory', sortable: true },
  { id: 'totalPrice', sortable: true },
];

const DETAIL_COLS = [
  { id: 'rate', sortable: true, width: '8%' },
  { id: 'transactionMonth', sortable: true, width: '8%' },
  { id: 'quotationMachines', sortable: true, width: '10%' },
  { id: 'quantity', sortable: true, width: '5%' },
  { id: 'currency_symbol', sortable: true, width: '8%' },
  { id: 'exchange_rate', sortable: true, width: '8%' },
  { id: 'exchange_negotiation_price', sortable: true, width: '10%' },
  { id: 'unitPrice', sortable: true, width: '10%' },
  { id: 'subTotalPrice', sortable: true, width: '10%' },
  { id: 'actualPrice', sortable: true, width: '10%' },
  { id: 'percentagesLower', sortable: true, width: '10%', Cell: ({ percentagesLower }) => {
    const isInfinity = percentagesLower === 'Infinity';
    return percentagesLower ? (
      <div className="d-flex flex-row flex-nowrap">
        <span style={{ color: (!isInfinity && Number(percentagesLower) >= 0) ? 'blue' : 'red' }}>
          {isInfinity ? percentagesLower : `${percentagesLower}%`}
        </span>
      </div>
    ) : null;
  }},
]

const NewOverviewTable = ({ data = [], fetched }) => {
  return (
      <CollapseTable
        isCustomRow
        customColumns={DETAIL_COLS}
        columns={COMMON_COLS}
        data={data}
        canDropDrag={false}
        skeletonColumns={COMMON_COLS.length}
        isFetched={fetched}
      />
  )
}

export default NewOverviewTable;