/** @jsx h */
import { h } from "preact";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import I18n from "i18n/i18n";

import { Table } from "components/atoms/table/Table";

const useStyles = makeStyles((theme) => ({
  striped: {
    backgroundColor: '#FAFAFA'
  },
  hovered: {
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: '#EEEEEE'
    }
  }
}));

const SortableRow = ({
  row,
  columns,
  index,
  striped,
  hovered,
  getRowProps = () => () => { }
}) => {
  const classes = useStyles();
  // Table without getRowProps use defaultClassName as fallback
  const defaultClassName = `${striped && index % 2 === 0 ? classes.striped : ''} ${hovered ? classes.hovered : ''}`;
  const rowProps = getRowProps({
    index,
    data: row,
    className: defaultClassName
  });

  const getColor = (columnRow, value) => {
    if (columnRow.id === 'diff') {
      const newValue = Number(value.replaceAll(',', ''));
      if (newValue > 0) {
        return '#2E65C9';
      } else if (newValue < 0) {
        return 'red';
      }
    } else if (value === 'clientDeleted') {
      return '#BDBDBD';
    }
    return 'black';
  }

  return (
    <TableRow className={defaultClassName} {...rowProps}>
      {columns.map((columnRow) => {
        let cell = (row[columnRow['id']] === 'clientDeleted') ? I18n.t(row[columnRow['id']]) : row[columnRow['id']];
        if (columnRow['cell']) cell = columnRow['cell'](row);
        return (
          <TableCell className={columnRow['className']} style={{ color: getColor(columnRow, row[columnRow.id]) }}>
            {cell}
          </TableCell>
        )
      })}
    </TableRow>
  )
}

// SortableTable is a wrapper for Table,
// using cell params from columns to make custom cell, else will render data as text 
// (more reuseable in easy table, but not suitable for complex table like groupTable which need subrow)
// getRowProps call in row rendering give ability to add props like onClick/classname/any custom props to row in different condition
export const SortableTable = ({
  columns,
  data,
  striped,
  hovered,
  disableHeader,
  disablePagination,
  skeletonColumns,
  getRowProps,
  parentComponent,
  isFetched,
  pageResetKey
}) => {
  return (
    <Table
      data={data || []}
      columns={columns}
      skeletonColumns={skeletonColumns || 6}
      disableHeader={disableHeader}
      disablePagination={disablePagination}
      parentComponent={parentComponent}
      pageResetKey={pageResetKey}
      isFetched={isFetched}
    >
      <SortableRow
        columns={columns}
        striped={striped}
        hovered={hovered}
        getRowProps={getRowProps}
      />
    </Table>
  )
}

SortableTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
};

SortableTable.defaultProps = {
  columns: [],
  data: [],
};
