/** @jsx h */
import { h, Fragment, cloneElement } from "preact";
import { useEffect, useState } from "preact/hooks";

import { makeStyles } from "@material-ui/core/styles";
import TableBase from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";

import { SortableTableHead } from "components/atoms/sortableTableHead/SortableTableHead";
import { TablePagination } from "components/atoms/tablePagination/TablePagination";

/* SKELETON IMPORT */
import { zeroArray, getComparator } from "../../../util";
import { TableRow, TableCell } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import EmptyIcon from "assets/empty.svg"

const stableSort = (array, comparator) => {
  // const filteredArray = array.filter(el => el !== undefined)
  const stabilizedThis = array.map((el, index) => [el, index]);
  const sort = (arr) => {
    arr.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
  };
  sort(stabilizedThis);
  return stabilizedThis.map((el) => el[0]);
};

const useStyles = makeStyles(() => ({
  table: {
    width: "100%",
    overflow: "hidden",
    borderRadius: 10,
  },
}));

export const TableWrapper = ({
  head,
  body,
  pageSelector,
  disableHeader,
  disablePagination,
  pageResetKey,
  containerStyle,
  defaultRowsPerPage = 10,
}) => {
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handlePageChange = (_, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    handlePageChange('', 0)
  };

  // Add this to do a pagination reset from parent component
  useEffect(() => handlePageChange('', 0), [pageResetKey])

  return (
    <Fragment>
      <Paper variant="outlined" className={classes.table} style={containerStyle || {}}>
        <TableContainer>
          <TableBase>
            {
              !disableHeader &&
              cloneElement(head, {
                order,
                orderBy,
                onRequestSort: handleRequestSort,
              })
            }
            {
              cloneElement(body, {
                order,
                orderBy,
                getComparator,
                stableSort,
                page,
                rowsPerPage
              })
            }
          </TableBase>
        </TableContainer>
      </Paper>
      {
        !disablePagination &&
        cloneElement(pageSelector, {
          rowsPerPage,
          page,
          handlePageChange,
          handleRowsPerPageChange
        })
      }
    </Fragment>
  )
}

export const TableChild = ({
  data,
  isFetched,
  order,
  orderBy,
  stableSort,
  getComparator,
  skeletonColumns,
  page,
  rowsPerPage,
  children,
}) => {
  return (
    <TableBody>
      {
        isFetched ?
          data && data.length > 0 ?
            (
              stableSort(data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index, arr) =>
                  cloneElement(children, {
                    row,
                    index,
                    border: index === arr.length - 1 ? "none" : undefined,
                  })
                )
            )
            :
            <TableRow>
              <TableCell
                colSpan={skeletonColumns || 6}
              >
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <img src={EmptyIcon} />
                </div>
              </TableCell>
            </TableRow>
          :
          zeroArray(10).map(() =>
            <TableRow>
              {zeroArray(skeletonColumns || 6).map(() =>
                <TableCell>
                  <Skeleton variant="text" style={{ width: "100%" }} />
                </TableCell>
              )}
            </TableRow>
          )
      }
    </TableBody>
  )
}

export const Table = ({
  columns,
  data,
  children,
  isFetched,
  skeletonColumns,
  disableHeader,
  disablePagination,
  pageResetKey,
}) => {
  return (
    <TableWrapper
      disableHeader={disableHeader}
      disablePagination={disablePagination}
      pageResetKey={pageResetKey}
      head={
        <SortableTableHead columns={columns} />
      }
      body={
        <TableChild
          data={data}
          isFetched={isFetched}
          skeletonColumns={skeletonColumns}
        >
          {children}
        </TableChild>
      }
      pageSelector={
        <TablePagination
          count={data.length}
        />
      }
    />
  )
}
