import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
  root: {
    width: 350,
    color: "white",
  },
  marginBottom2: {
    marginBottom: theme.spacing(2),
  },
  marginBottom3: {
    marginBottom: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  button: {
    backgroundColor: "#2E65C9",
    fontSize: 14,
    height: 55,
  },
  input: {
    marginRight: 15,
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    color: "white",
    fontSize: 24,
    aspectRatio: 1,
    "&:last-child": {
      marginRight: 0,
    },
  },
}));
