/** @jsx h */
import { h } from "preact";
import { useState, useMemo, useEffect } from "preact/hooks";
import I18n from "i18n-js";
import { DatePicker } from "rsuite";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import { Card } from "components/atoms/card/Card";
import CubeBtn from "components/atoms/cubeButton/CubeBtn";
import { Row } from "components/atoms/row/Row";
import ExportExcelButton from "components/atoms/exportExcelButton/ExportExcelButton";
import Select from "components/atoms/select/Select";
import { MultiSelect } from "components/atoms/multiSelect/MultiSelect";
import { SortableTable } from "components/molecules/sortableTable/SortableTable";
import { getOrderStatus } from "api";
import { enUS, zhTW } from "rsuite/esm/locales";

import { useUpdateEffect } from "hooks";
import moment from "moment";
import { formatPrice } from "helper/format";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(4),
		width: "100%",
		color: "rgba(0, 0, 0, 0.54)",
	},
	marginBottom2: {
		marginBottom: theme.spacing(2),
	},
	marginArea: {
		'& > div + div': {
			marginLeft: theme.spacing(2)
		}
	},
	DatePicker: {
		'& .rs-picker-toggle': {
			backgroundColor: '#FFFFFF',
			padding: '9px 12px',
			paddingRight: 36,
            border: 'none',
            borderRadius: 8,
            fontSize: 15
		},
        '& .rs-picker-toggle-value': {
            color: '#0000008a !important',
        }
	},
	fixedWidthCell: {
		width: (100 / 9 + '%')
	},
	colorDisabled: {
		color: theme.palette.text.disabled
	},
	hiddenOnDepartment: {
		display: "none",
	},
	selectedRow: {
		backgroundColor: '#EFF5FF !important',
		'& > td': {
			color: '#2E65C9'
		}
	}
}));

const Plan = () => {
	const classes = useStyles();
	const [rawData, setRawData] = useState({});
	const [date, setDate] = useState(new Date());
    const [selectedGroup, setSelectedGroup] = useState([0]);
	const [searchValue, setSearchValue] = useState('');
	const [filterBy, setFilterBy] = useState('group');
	const [selectedRow, setSelectedRow] = useState(null);
	const [fetched, setFetched] = useState(false);

	const groupOptions = useMemo(() => {
		const allRegions =  Object.keys(rawData);
		const allGroups = allRegions.reduce((prev, current) => {
			return prev.concat(Object.keys(rawData[current]));
		}, []);
		const allGroupOptions = allGroups.map((group) => ({ value: group, label: group }));
		return allGroupOptions;
	}, [rawData]);

	const filterOptions = useMemo(() => [
		{
			id: 'group',
			text: I18n.t('filterBy', { text: I18n.t('department').toLowerCase() }),
		},
		{
			id: 'salesman',
			text: I18n.t('filterBy', { text: I18n.t('Salesman').toLowerCase() }),
		},
	], [I18n.locale]);

	const columns = [
		{
			id: "salesman",
			className: classes.fixedWidthCell + ' ' + (filterBy === 'group' ? classes.hiddenOnDepartment : ''),
			sortable: true,
		},
		{
			id: "department",
			className: classes.fixedWidthCell + ' ' + (filterBy === 'salesman' ? classes.hiddenOnDepartment : ''),
			sortable: true,
		},
		{
			id: "objectivePrice",
			className: classes.fixedWidthCell,
			sortable: true,
		},
		{
			id: "expectedPrice",
			className: classes.fixedWidthCell,
			sortable: true,
		},
		{
			id: "accomplish",
			className: classes.fixedWidthCell,
			sortable: true,
		},
		{
			id: "paidAmount",
			className: classes.fixedWidthCell,
			sortable: true,
		},
		{
			id: "accomplish2",
			className: classes.fixedWidthCell,
			sortable: true,
		},
		{
			id: "unpaidAmount",
			className: classes.fixedWidthCell,
			sortable: true,
		}
	];

	const filteredData = useMemo(() => {
		let _filteredData = {};
		let _rawData = Object.assign({}, rawData);

		const regions = Object.keys(_rawData);
		regions.forEach((_region) => {
			const regionData = _rawData[_region];
			Object.keys(regionData).forEach((_group) => {
				if (!selectedGroup.includes(0) && !selectedGroup.includes(_group) && !!selectedGroup.length) return;
				_filteredData[_group] = regionData[_group];
			});
		});

		const groups = Object.keys(_filteredData);

		/** @param {string} str */
		const search = (str) => str.toLowerCase().search(searchValue.toLowerCase()) !== -1;

		const halfProcessedData = filterBy === 'group' ? (
			groups.filter((_group) => search(_group)).map((_group) => ({
				department: _group,
				..._filteredData[_group],
			}))
		) : (
			groups.reduce((prev, _group) => {
				const filteredSales = _filteredData[_group].sales.filter(({ salesman }) => search(salesman));
				return [...prev, ...filteredSales];
			}, [])
		);

		return halfProcessedData.map((data) => ({
			...data,
			objectivePrice: formatPrice(data.objective),
			expectedPrice: formatPrice(data.quotationAPlusPrice),
			paidAmount: formatPrice(data.paidAmount),
			unpaidAmount: formatPrice(data.unpaidAmount),
			accomplish: !data.objectiveAccomplished || data.objectiveAccomplished === 'NaN%' ? '0%' : data.objectiveAccomplished,
			accomplish2: !data.paidAccomplished || data.paidAccomplished === 'NaN%' ? '0%' : data.paidAccomplished,
		}));
	}, [rawData, selectedGroup, filterBy, searchValue]);

	const totalColumns = [
		{
			id: "title",
			className: classes.fixedWidthCell + ' ' + classes.colorDisabled,
		},
		{
			id: "objectivePrice",
			className: classes.fixedWidthCell,
		},
		{
			id: "expectedPrice",
			className: classes.fixedWidthCell,
			sortable: true,
		},
		{
			id: "accomplish",
			className: classes.fixedWidthCell,
		},
		{
			id: "paidAmount",
			className: classes.fixedWidthCell,
		},
		{
			id: "accomplish2",
			className: classes.fixedWidthCell,
		},
		{
			id: "unpaidAmount",
			className: classes.fixedWidthCell,
		}
	];

	const totalData = useMemo(() => {
		let total = {
			title: I18n.t('total'),
			objectivePrice: 0,
			expectedPrice: 0,
			accomplish: 0,
			paidAmount: 0,
			accomplish2: 0,
			unpaidAmount: 0,
		};

		/** @param {string} percentString */
		const percentFormat = (percentString) => {
			return parseInt(percentString.slice(0, percentString.length - 1));
		};

		const convertPriceStrToNumber = (priceStr) => {
			if (typeof priceStr === 'number') return priceStr;
			return parseInt(priceStr.replace(/,/g, ''));
		};
	
		const formatAccomplish = (accomplish) => {
			return accomplish > 0 ? parseFloat(accomplish).toFixed(2) : 0;
		}

		filteredData.forEach((data) => {
			total.objectivePrice += convertPriceStrToNumber(data.objectivePrice);
			total.expectedPrice += convertPriceStrToNumber(data.expectedPrice);
			total.accomplish += percentFormat(data.accomplish);
			total.paidAmount += convertPriceStrToNumber(data.paidAmount);
			// total.accomplish2 += percentFormat(data.accomplish2);
			total.unpaidAmount += convertPriceStrToNumber(data.unpaidAmount);
		});

		total.accomplish = `${total.accomplish}%`;
		total.accomplish2 = `${formatAccomplish(total.paidAmount / total.objectivePrice)}%`;
		total.objectivePrice = formatPrice(total.objectivePrice);
		total.expectedPrice = formatPrice(total.expectedPrice);
		total.paidAmount = formatPrice(total.paidAmount);
		total.unpaidAmount = formatPrice(total.unpaidAmount);
		return [total];
	}, [filteredData]);

	useEffect(() => {
		(async () => {
			try {
				setFetched(false);
				const orderStatus = (await getOrderStatus({ from: moment(date).format('YYYY-MM') })).data;
				setRawData(orderStatus);
			} catch (error) {
				console.log('Get Order Status Error: ', error);
				if (!!error.response) console.log(error.response.data);
			} finally {
				setFetched(true);
			}
		})();
	}, [date]);

	const getExportColumns = () => {
		if (filterBy === 'group') {
			return columns.filter(item => item.id !== 'salesman');
		} else {
			return columns.filter(item => item.id !== 'department');
		}
	}

	const getExportData = () => {
		const key = filterBy === 'group' ? 'department' : 'salesman';
		const total = { ...totalData[0], [key]: I18n.t('total') };
		return filteredData.concat(total);
	}

	return (
		<div className={classes.root}>
			<Row justifyContent="space-between" alignItems="center" className={classes.marginBottom2}>
				<Typography variant="h5" color="inherit">
					{I18n.t("Plan")}
				</Typography>
				<Row alignItems='center' className={classes.marginArea}>
					<DatePicker
						size="lg"
						format='yyyy/MM'
						cleanable={false}
						className={classes.DatePicker}
						value={date}
						onChange={setDate}
						locale={localStorage.getItem('locale') === 'en' ? enUS.DatePicker : zhTW.DatePicker}
					/>
                    <MultiSelect
                        data={groupOptions}
                        value={selectedGroup}
                        title="department"
                        onChange={setSelectedGroup}
                    />
					<CubeBtn
						iconType="search"
						expandable
						searchText={searchValue}
						placeholder={filterBy === 'group' ? I18n.t("department") : I18n.t("Salesman")}
						onChange={setSearchValue}
					/>
					<Select
            data={filterOptions}
						onClick={(e) => setFilterBy(e.id)}
					/>
                    <ExportExcelButton
                        sheetName={moment(date).format('YYYY-MM') + I18n.t("Plan")}
                        columns={getExportColumns().map((column) => {
                            if (column.text === undefined) {
                                return { ...column, text: I18n.t(column.id) };
                            }
                            return column;
                        })}
                        data={getExportData()}
                        headerColor='EBF1DE'
												fileType="xlsx"
                    />
				</Row>
			</Row>
			<SortableTable
				columns={columns}
				data={filteredData}
				striped
				hovered
				getRowProps={({ data, className }) => {
					const selected = filterBy === 'group' ?
						selectedRow?.department === data?.department ? classes.selectedRow : '' :
						selectedRow?.salesman === data?.salesman ? classes.selectedRow : '';
					return {
						onClick: () => setSelectedRow(data),
						className: className + ' ' + selected
					}
				}}
				isFetched={fetched}
				skeletonColumns={8}
				pageResetKey={!!searchValue}
			/>
			<Card title={I18n.t("total")} wrapStyle={{ marginTop: 16 }}>
				<SortableTable
					columns={totalColumns}
					data={totalData}
					disableHeader
					disablePagination
					striped
					isFetched={fetched}
					skeletonColumns={8}
					pageResetKey={!!searchValue}
				/>
			</Card>
		</div>
	);
};

export default Plan;
