import { makeStyles } from '@material-ui/core/styles'; 

const useStyles = makeStyles({
    chipWrap:{
        cursor: 'default',
        display: 'inline-block',
        padding: '6px 8px',
        backgroundColor: '#2E65C9',
        borderRadius: '8px',
        color: 'white',
        '& span':{
            fontSize: '14px',
            verticalAlign: 'middle'
        },
        // '&:hover .closeWrap': {
        //     // transform: 'scale(2)'
        //     display: 'block!important',
        //     transition: 'all 900ms ease'
        // }
    },
    close: {
        cursor: 'pointer',
        fontSize: '16px',
        color: 'white',
        marginLeft: '8px',
        verticalAlign: 'middle'
    },
})
export default useStyles;