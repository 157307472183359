/** @jsx h */
import { h } from "preact";
import BaseTablePagination from "@material-ui/core/TablePagination";
import I18n from "i18n/i18n";

export const TablePagination = ({
  count,
  rowsPerPage,
  rowsPerPageOptions = [5, 10, 25],
  page,
  handlePageChange = () => {},
  handleRowsPerPageChange = () => {},
}) => {
  return (
    <BaseTablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      component="div"
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleRowsPerPageChange}
      labelRowsPerPage={I18n.t("RowsPerPage")}
      labelDisplayedRows={({ from, to, count }) => I18n.t('Pagination', { from: from, to: to, count: count})}
      backIconButtonProps={{
        style: { backgroundColor: "white", margin: "0 25px" },
        size: "small",
      }}
      nextIconButtonProps={{
        style: { backgroundColor: "white" },
        size: "small",
      }}
    />
  );
};
