/** @jsx h */
import { h } from "preact";
import React from "react";
import { useState, useEffect, useContext } from "preact/hooks";
import I18n from "i18n-js";
import moment from "moment/min/moment-with-locales";
import Context from "context";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import Modal from "components/atoms/modal/Modal";
import { List } from "components/molecules/list/List";
import { PunchTimeline } from "components/molecules/punchTimeline/PunchTimeline";
import Visit from "components/organisms/modalContent/punch/Visit";

import { getSchedule, getClient, getClientBranch, getMyDay } from "api";
import { useUserImage } from "hooks";
// context snackbar error handling
import { errHandler } from "../../util";
import { route } from "preact-router";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(4),
		width: "100%",
		color: "rgba(0, 0, 0, 0.54)",
	},
	listContainer: {
		"& > div + div": {
			marginTop: theme.spacing(3),
		},
	},
}));

const Punch = () => {
	const classes = useStyles();
	const { getUserImage } = useUserImage();
	const [teamList, setTeamList] = useState([]);
	const [salesmenList, setSalesmenList] = useState([]);
	const [teams, setTeams] = useState([]);
	const [salesmen, setSalesmen] = useState([]);
	const [isFetched, setFetched] = useState(false);
	// schedule/punch/trip state
	const [scheduleList, setScheduleList] = useState(null);
	const [schedule, setSchedule] = useState([]);
	const [timelineData, setTimelineData] = useState([]);
	const [date, setDate] = useState(moment());
	const [selectedDataIndex, setSelectedDataIndex] = useState({
		team: null,
		salesman: null,
	});
	const [selectedData, setSelectedData] = useState({
		team: "",
		salesman: "",
	});
	const [modal, setModal] = useState({
		name: "",
		data: {},
	});
	const [salesmanSelected, setSalesmanSelected] = useState(false);
	const [canDisplay, setDisplay] = useState(false);

	const {
		abbreviation,
		groupList: groups,
		userList: users,
		setError
	} = useContext(Context);
	const [salesmenFetched, setSalesmenFetched] = useState(false);
	const [teamFetched, setTeamFetched] = useState(false);
	const [searchText, setSearchText] = useState({
		team: "",
		salesman: "",
	});

	// get team and user data
	useEffect(async () => {
		if (!groups.length || !users.length) return;
		try {
			let modifiedGroups = groups.map((group) => {
				// WILL CHANGE WITH GET REGION NAME FROM API AND CONTEXT
				group.sub = group.id_region == 1 ? I18n.t("Taiwan") : I18n.t("International");
				return {
					...group,
					sub: group.id_region == 1 ? I18n.t("Taiwan") : I18n.t("International"),
				};
			});
			let modifiedUsers = await Promise.all(users.map(async(user) => {
				let userImage = await getUserImage(user.id);
				return {
					...user,
					imgUrl: !!userImage ? userImage: null,
					originalName: user.last_name + user.first_name,
				};
			}));
			setTeamList(modifiedGroups);
			setTeams(modifiedGroups);
			setSalesmenList(modifiedUsers);

			setSalesmenFetched(true);
			setTeamFetched(true);
		} catch (err) {
			errHandler(err, setError, route);
		}
	}, [users, groups]);

	const onSearch = (type, value) => {
		setSearchText((prev) => ({ ...prev, [type]: value }));
		if (type === "team") {
		    value ? searchTeam(value) : setTeams(teamList);
		} else {
			value
                ? searchUser(value)
                : setSalesmen(
                    salesmenList.filter(
                        (item) => item.id_group == teams[selectedDataIndex.team]?.id
                    )
                )
		}
	}

	// get user in the team
	useEffect(() => {
		setSelectedDataIndex((prev) => ({ team: prev.team, salesman: null }));
		if (teams.length > 0 && salesmenList.length > 0 && selectedDataIndex.team !== null) {
			let sales = salesmenList.filter((item) => item.id_group == teams[selectedDataIndex.team]?.id);
			sales.map((item, i) => {
				item.name = `${item.last_name}${item.first_name}`;
			});
			setSalesmen(sales);
		} else {
			setSalesmen([]);
		}
	}, [teams, salesmenList, selectedDataIndex.team]);

	// get schedule
	useEffect(async () => {
		try {
			setDisplay(false);
			setScheduleList(null);

			// get schedule list
			const apiParams = {
				id_user: selectedData.salesman?.id,
				field: "start_time",
				pending: false,
				from: moment(date).set({ 'h': 0, 'm': 0, 's': 0 }).utc().format('YYYY-MM-DD HH:mm'),
				to: moment(date).set({ 'h': 23, 'm': 59, 's': 59 }).utc().format('YYYY-MM-DD HH:mm'),
			};
			const schedules = (await getSchedule(apiParams)).data;
			const filteredSchedules = schedules.filter((s) => {
				return moment(s.start_time).isBefore(moment().toString(), 'second')
			})	
			setScheduleList(filteredSchedules);

			if (selectedData.salesman) {
				const my_schedules = (await getMyDay({
					id_user: selectedData.salesman?.id,
					from: moment(date).set({ 'h': 0, 'm': 0, 's': 0 }).utc().format(),
					to: moment(date).set({ 'h': 23, 'm': 59, 's': 59 }).utc().format(),
				})).data;

				setSchedule(my_schedules.filter(r => r.is_active !== false));
			}

			setFetched(true);
		} catch (err) {
			errHandler(err, setError, route);
		}
	}, [selectedData, date]);

	useEffect(() => {
		(async () => {
			let scheduleData = [];
			if (isFetched && selectedData.team && selectedData.salesman?.id) {
				await Promise.all(schedule?.map(async (item, i) => {
					const time = moment(item.actual_start_time).format("HH:mm");
					const start =
						item.is_punch && item.punch[0] ? moment(item.punch[0]).format("HH:mm") : "--:--";
					const end =
						item.is_punch && item.punch[1] ? moment(item.punch[1]).format("HH:mm") : "--:--";

					const visitedClient = (await getClientBranch({ id: item.id_client_branch })).data[0]
					const visitedGroup = (await getClient({ id: visitedClient.id_client })).data[0]

					// scheduleType for different UI (location/nopunch/phone/now)
					const scheduleType =
						item.is_phone ? 'phone' :
								item.is_punch ? "location" :
									"nopunch";

                    // visit reason
					const visitReason = abbreviation.filter(item => item.type === 'visit_reason');
					const reason = visitReason.find(reason => reason.id == item.description);
        
					scheduleData.push({
						type: scheduleType,
						time: time,
						originalTime: item.actual_start_time,
						location: `${visitedGroup?.name} (${visitedClient?.name})`,
						address: (visitedClient?.address || '-') + ',' + (visitedClient?.city || '-') + ',' + (visitedClient?.country || '-'),
						start: start,
						end: end,
						dayStart: moment(item.actual_start_time).format("HH:mm"),
						dayEnd: moment(item.actual_end_time).format("HH:mm"),
						reason: reason ? I18n.t(reason.name) : '-',
					});
				}));

				// Sort timeline because api fetching will break the order
				scheduleData.sort((a, b) => moment(a.originalTime, 'YYYY-MM-DD HH:mm:ss').isBefore(moment(b.originalTime, 'YYYY-MM-DD HH:mm:ss')) ? -1 : 1);

				// If selected date is today, push Now as last timeline item
				// else push end of day by using last item as time
				if (scheduleData.length > 0) {
					if (date.isSame(moment(), 'd')) {
						scheduleData.push({
							type: "now",
							time: moment().format("HH:mm"),
							content: I18n.t("Now"),
						});
						// Sort again when is today because time now maybe will break the order
						scheduleData.sort((a, b) => (a.time >= b.time ? 1 : -1));
					} else {
						const lastEndTime = scheduleData[scheduleData.length - 1].dayEnd;
						scheduleData.push({
							type: "now",
							time: lastEndTime,
							content: I18n.t('endOfDay'),
						});
					}
				}		

				setTimelineData(scheduleData);
				setDisplay(true);
			}
		})()
	}, [schedule]);

	// search
	const searchTeam = (value) => {
		setSalesmen([]);
		const teams = teamList.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()));
		setTeams(teams);
	};

	const searchUser = (value) => {
		setSalesmen((prev) =>
			prev.filter((item) => item.name?.toLowerCase().includes(value.toLowerCase()))
		);
	};

	const onClose = () => {
		setModal((prev) => ({
			name: "",
			data: prev.data,
		}));
	};

	return (
		<div className={classes.root}>
			<Grid container spacing={3}>
				<Grid item xs={12} md={5} className={classes.listContainer}>
					<List
						isFetched={teamFetched}
						width="100%"
						contentHeight="250px"
						title="group"
						data={teams.length == 0 ? undefined : teams}
						search={true}
						searchId="TeamSearch"
						add={false}
						selectable
						label="punchGroup"
						overideSelect={selectedDataIndex.team}
						searchText={searchText.team}
						placeholder={I18n.t("TeamName")}
						onClick={(index) => {
							setSelectedData(prev => {
								if (selectedDataIndex.team === index) {
									return {
										team: "",
										salesman: "",
									}
								} else {
									return {
										team: teams[index].name,
										salesman: "",
									}
								}
							});
							setSelectedDataIndex((prev) => {
								setSalesmanSelected(false);
								if (prev.team === index) {
									return {
										team: null,
										salesman: null,
									};
								}
								return {
									team: index,
									salesman: null,
								};
							});
						}}
						onChange={(value) => onSearch('team', value)}
					/>
					<List
						isFetched={salesmenFetched}
						width="100%"
						contentHeight="250px"
						roundedAvatar
						title="Employees"
						data={salesmen.length == 0 ? undefined : salesmen}
						search={true}
						searchId="EmployeeSearch"
						add={false}
						selectable
						overideSelect={selectedDataIndex.salesman}
						searchText={searchText.salesman}
						placeholder={I18n.t("username")}
						onClick={(index) => {
							setSalesmanSelected(true);
							setSelectedData((prev) => {
								if (selectedDataIndex.salesman === index) {
									return {
										team: prev.team,
										salesman: "",
									}
								} else {
									return {
										team: prev.team,
										salesman: salesmen[index],
									}
								}
							});
							setSelectedDataIndex((prev) => {
								if (prev.salesman === index) {
									setSalesmanSelected(false);
									return {
										team: prev.team,
										salesman: null,
									};
								}
								return {
									team: prev.team,
									salesman: index,
								};
							});
						}}
						onChange={(value) => onSearch('salesman', value)}
					/>
				</Grid>
				<Grid item xs={12} md={7}>
					<PunchTimeline
						data={selectedData}
						timelineData={timelineData}
						date={date}
						setDate={setDate}
						setModal={setModal}
						canDisplay={canDisplay}
						salesmanSelected={salesmanSelected}
						selectedTeam={teams[selectedDataIndex.team]}
						salesmanList={salesmen}
						scheduleList={scheduleList}
						abnormalClick={(d, i, clickonSalesman) => {
							setSelectedData(prev => ({ ...prev, ...d }));
							setSelectedDataIndex(prev => ({ ...prev, ...i }));
							if (clickonSalesman) setSalesmanSelected(true);
						}}
					/>
				</Grid>
			</Grid>
			<Modal
				jsxTitle={(
					<Typography variant="h6" color="textSecondary">
						{I18n.t("visitModalTitleLocation", { location: modal.data.location })}
						{
							modal.data.type === 'location' &&
							<>
								{I18n.t("from")}
								<span style={{ color: '#2E65C9' }}>
									{I18n.t("visitModalTitleTime", {
										start: modal.data.dayStart,
										end: modal.data.dayEnd,
									})}
								</span>
							</>
						}
					</Typography>
				)}
				open={modal.name === "visit"}
				close={onClose}
				dialog
			>
				<Visit data={modal.data} team={selectedData.team} salesman={selectedData.team} />
			</Modal>
		</div>
	);
};

export default Punch;
