import { h } from "preact";
import { makeStyles } from "@material-ui/core/styles";
import ErrorPage  from "./errorPage";
import Icon from "../../assets/Lock.svg";
import I18n from "i18n-js";

const useStyles = makeStyles({
    message: {
        fontSize: "24px",
        color: "rgba(0,0,0,0.54)",
        height: "130px",
    },
});

const Page403 = () => {
    const classes = useStyles();

    return (
        <ErrorPage
            statusCode="403"
            icon={Icon}
            back
        >
            <p className={classes.message}>
                {I18n.t("noPermission")}
            </p> 
        </ErrorPage>
    );   
}
export default Page403;