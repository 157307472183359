/** @jsx h */
import { h } from 'preact';
import { Card } from '../../atoms/card/Card';
import useStyles from './UserInfo.style';
import Avatar from '@material-ui/core/Avatar';
import { Jdenticon } from 'components/atoms/Jdenticon';
import Button from '../../atoms/cubeButton/CubeBtn';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import PinDropIcon from '@material-ui/icons/PinDrop';
import I18n from "i18n/i18n";

import Skeleton from "@material-ui/lab/Skeleton";
import { useRole } from 'hooks';
import { RNG } from "../../../util";

// color array for non-salesman profiles
const colors = {
    "ROLE_BIGBOSS": "#FF2C2C",
    "ROLE_MANAGER": "#A067D9",
    "ROLE_LEADER": "#4FAFCD"
};

const TransportType = ({ type }) => {
    if (type == 'car') {
        return <DriveEtaIcon style={{ color: 'rgba(0,0,0,.54)' }} />
    } else {
        return null
    }
}

export const UserInfo = ({ data, style, title, isOwnProfile = false, handleDisable }) => {
    const classes = useStyles();
    const role = useRole();

    return (
        (data ? <Card title={title} wrapStyle={{ border: `1px ${colors[data.roleStr] || "#e0e0e0"} solid`, ...style }}>
            {/* head */}
            {data.main && <div className={`d-flex ${classes.head}`}>
                {/* avatar */}
                {data.main.image ?
                    <Avatar className={classes.avatar} src={data.main.image} /> :
                    <div className={classes.avatar}>
                        <Jdenticon size="104" value={data.main.name} />
                    </div>}
                {/* info */}
                <div className="column jc-center">
                    <span>{data.main.name}</span>
                    {
                        data.roleStr !== "ROLE_SALES" && (
                            <div
                                style={{
                                    backgroundColor: colors[data.roleStr],
                                    color: "white",
                                    margin: "0 auto 0 0",
                                    borderRadius: "5px",
                                    padding: "5px"
                                }}
                            >
                                {I18n.t(data?.roleStr) || ""}
                            </div>
                        )
                    }
                </div>
            </div>}
            {/* body */}
            {data.list && <div className={`column ${classes.body}`}>
                {data.list.map((item, key) => (
                    <div className="jc-space-btw al-center" key={key}>
                        <span>{I18n.t(item.title)}</span>
                        <span>{item.value}</span>
                    </div>
                ))}
                {role.isBigBoss && !isOwnProfile && (
                    <div className="jc-end">
                        <Button
                            iconType="disabled"
                            text={I18n.t("Disable")}
                            bgColor="red"
                            textColor="white"
                            onClick={handleDisable}
                        />
                    </div>
                )}
            </div>}
        </Card>
            :
            /* placeholder skeleton */
            <Card {...{ title, style }}>
                <div>
                    <div className={`d-flex ${classes.head}`}>
                        <div className={classes.avatar}>
                            <Skeleton
                                variant="circle"
                                style={{
                                    height: "100%",
                                    width: "100%"
                                }}
                            />
                        </div>
                        <div className="column jc-center">
                            <Skeleton
                                variant="text"
                                style={{
                                    fontSize: "1.5rem",
                                    width: "5rem",
                                }}
                            />
                            <Skeleton variant="text"
                                variant="text"
                                style={{
                                    fontSize: "1.5rem",
                                    width: "2rem",
                                }}
                            />
                            <Skeleton variant="text"
                                variant="text"
                                style={{
                                    fontSize: "1.4rem",
                                    width: "10rem",
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className={`column ${classes.body}`}>
                    {
                        [
                            "First name",
                            "Last name",
                            "Email",
                            "Phone number",
                            "Address",
                            "Joined date",
                            "Experience"
                        ].map((item, key) => (
                            <div className="jc-space-btw al-center" key={key}>
                                <span>{I18n.t(item)}</span>
                                <span>
                                    <Skeleton
                                        variant="text"
                                        style={{
                                            width: `${RNG(5, 10)}rem`
                                        }}
                                    />
                                </span>
                            </div>
                        ))}
                </div>
            </Card>
        )
    )
}
