import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks'
import I18n from 'i18n-js';
import { getRegion, getGroup, getUserList, getCurrencies } from 'api';
import Context from 'context'

const ContextProvider = ({ children }) => {
  const [token, setToken] = useState()
  const [error, setError] = useState()
  const [message, setMessage] = useState()
  const [clientGroupList, setClientGroupList] = useState([])
  const [clientBranchList, setClientBranchList] = useState([])
  const [userList, setUserList] = useState([])
  const [user, setUser] = useState([])
  const [groupList, setGroupList] = useState([])
  const [roleList, setRoleList] = useState([])
  const [scheduleList, setScheduleList] = useState([])
  const [reportList, setReportList] = useState([])
  const [regionList, setRegionList] = useState([])
  const [divisionList, setDivisionList] = useState([])
  const [machineSeriesList, setMachineSeriesList] = useState([])
  const [machineList, setMachineList] = useState([])
  const [region, setRegion] = useState(0)
  const [abbreviation, setAbbreviation] = useState([])
  const [firstIndustryLevelList, setFirstIndustryLevelList] = useState([])
  const [secondIndustryLevelList, setSecondIndustryLevelList] = useState([])
  const [landList, setLandList] = useState([])
  const [countryList, setCountryList] = useState([])
  const [exhibitionList, setExhibitionList] = useState([])
  const [compareData, setCompareData] = useState(Array(3).fill({}))
  const [currencies, setCurrencies] = useState([])
  // options
  const statusOptions = [
		{ id: 0, text: I18n.t('allStatus') },
		{ id: 1, text: I18n.t('closed') },
		{ id: 2, text: I18n.t('notClosed') },
	];
	const dealRateOptions = [
		{ id: "A+", text: "A+" },
		{ id: "A", text: "A" },
		{ id: "B", text: "B" },
		{ id: "C", text: "C" }
	];

  useEffect(() => {
    if (!token) return;
    if (!regionList.length) {
        getRegion().then(res => {
            setRegionList(res.data);
        });
    }
    if (!groupList.length) {
        getGroup({ avoid_brilltek_testing: true }).then(res => {
            setGroupList(res.data);
        });
      }
    if (!userList.length) {
        getUserList({ avoid_brilltek_testing: true }).then((res) => setUserList(res.data))
    }
    if (!currencies.length) {
        getCurrencies().then(res => setCurrencies(res.data))
    }
  }, [token]);

  const context = {
    token,
    setToken,
    error,
    setError,
    message,
    setMessage,
    clientGroupList,
    setClientGroupList,
    clientBranchList,
    setClientBranchList,
    userList,
    setUserList,
    user,
    setUser,
    groupList,
    setGroupList,
    roleList,
    setRoleList,
    scheduleList,
    setScheduleList,
    reportList,
    setReportList,
    regionList,
    setRegionList,
    region,
    setRegion,
    machineSeriesList,
    setMachineSeriesList,
    machineList,
    setMachineList,
    abbreviation,
    setAbbreviation,
    firstIndustryLevelList,
    setFirstIndustryLevelList,
    secondIndustryLevelList,
    setSecondIndustryLevelList,
    compareData,
    setCompareData,
    statusOptions,
    dealRateOptions,
    landList,
    setLandList,
    countryList,
    setCountryList,
    exhibitionList,
    setExhibitionList,
    divisionList,
    setDivisionList,
    currencies,
    setCurrencies,
  }
  return <Context.Provider value={context}>{children}</Context.Provider>
}
export default ContextProvider
