/** @jsx h */
import React from "react";
import { h } from "preact";
import I18n from "i18n-js";
import { useState, useEffect, useMemo } from "preact/hooks";

import { Row } from 'components/atoms/row/Row';
import { Column } from 'components/atoms/column/Column';

import { Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import DescriptionIcon from '@material-ui/icons/Description';
import IconButton from "@material-ui/core/IconButton";
import SeeDescriptionModal from "../SeeDescriptionModal/SeeDescriptionModal";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";


const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: '1px dashed #ABC1E9',
    backgroundColor: '#FAFAFA',
    padding: theme.spacing(4, 6),

    '& > div + div': {
      marginTop: theme.spacing(2)
    }
  },

  colorDisabled: {
    color: theme.palette.text.disabled
  },

  textSlash: {
    color: theme.palette.text.disabled,
    textDecoration: 'line-through'
  }, 

  description: { 
    marginRight: 10,
    padding: '0!important',
    "&:hover": {
      cursor: 'pointer',
    },
  }
}));

const separator = (numb) => {
  if (numb || numb === 0) {
    var str = numb.toString().split(".");
    str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return str.join(".");
  }
  return '';
}

export const OptionBlock = ({optionData, setModal, setOption, setSelectedMachine}) => {
  const classes = useStyles();

  const Options = useMemo(() => 
    optionData.options?.map(r =>
      <Row justifyContent='space-between' style={{ marginTop: 5, marginLeft: 20 }}>
        <Typography variant='body2' className={classes.colorDisabled}> {r.label} </Typography>
        <Typography variant='body2' color='error'>+ $ {separator(r.value)}</Typography>
      </Row>
    )
  , [optionData.options]);
  

  const OtherOptions = useMemo(() => 
    optionData.otherOptions?.map(r =>
      <Row justifyContent='space-between' style={{ marginTop: 5, marginLeft: 20 }}>
        <div>
          {
            r.remark &&
            <IconButton
              className={classes.description}
              onClick={() => {
                setModal('seeDescription')
                setOption(r)
                setSelectedMachine(optionData)
              }}
              >
              <DescriptionIcon style={{width: 20, height: 20}} /> 
            </IconButton>
          }
          <Typography variant='body2' className={classes.colorDisabled} style={{ display: 'inline-block' }}> 
            {r.label}
          </Typography>
        </div>
        <Typography variant='body2' color='error'>+ $ {separator(r.value)}</Typography>
      </Row>
    )
  , [optionData.otherOptions]);

  const AdditionalOptions = useMemo(() => (
    <Column style={{ marginTop: 5 }}>
      <Row justifyContent='space-between' style={{ marginLeft: 20, marginBottom: 5 }}>
        <Typography variant='body2' style={{ backgroundColor: '#EEEEEE', padding: '4px 7px', borderRadius: '4px' }}>
          {I18n.t('additionalOptions')}:
        </Typography>
      </Row>
      {optionData.additionalOptions?.map(r =>
        <Row justifyContent='space-between' style={{ marginLeft: 20 }}>
          <Typography variant='body2' className={classes.colorDisabled}> {r.label} </Typography>
          <Typography variant='body2' color='error'>+ $ {separator(r.value)}</Typography>
        </Row>
      )}
    </Column>
  ), [optionData.additionalOptions]);

  return (
    <>
      <Row justifyContent='space-between'>
        <Typography variant='body2' className={classes.colorDisabled}>
          {I18n.t('optionsForSomeMachines', { count: optionData.quantity })}
        </Typography>
        <Typography variant='body2' color='error'>+ $ {separator(optionData.optionsPrice)}</Typography>
      </Row>
      <Row justifyContent='space-between' style={{ marginTop: 5, marginLeft: 20 }}>
        <Typography variant='body2' className={classes.colorDisabled}>{I18n.t('additionalCharge')}</Typography>
        <Typography variant='body2' color='error'>+ $ {separator(optionData.extra_price)}</Typography>
      </Row>
      {Options}
      {OtherOptions}
      {!!optionData.additionalOptions?.length && AdditionalOptions}
    </>
  )
}

const CollapseRow = ({row, setModal, setOption, setSelectedMachine}) => {
  const [isClicked, setIsClicked] = useState(false);
  const classes = useStyles();

  return(
    <div style={{backgroundColor: '#f0f0f0'}} onClick={() => setIsClicked(!isClicked)}>
      <Row justifyContent='space-between' style={{margin: 10}}>
        {
          isClicked ?
            <ExpandMoreIcon />
            :
            <ChevronRightIcon />
        }
        <Typography variant='body2' className={classes.colorDisabled}>
          {row.label}
          <Typography display="inline" variant='body2' color='textPrimary'> x{row.quantity}</Typography>
        </Typography>
        <Typography variant='body2' color='textPrimary'>$ {separator(row.value)}</Typography>
      </Row>
      <Collapse in={isClicked}>
        <div style={{margin: 20}}>
          {
            row.hasOption ?
              row.options?.map(option => {
                return (
                  <div style={{marginTop: 20}}>
                    <OptionBlock optionData={option}  setModal={setModal} setOption={setOption} setSelectedMachine={setSelectedMachine} />
                  </div>
                )})  
              :
              <Row justifyContent='space-between'>
                <Typography variant='body2' className={classes.colorDisabled}>
                  {I18n.t('noOption') + ': '}
                </Typography>
                <Typography variant='body2' color='error'>+ $ 0</Typography>
              </Row>
          }
          <hr className='w-100' />
          <Row justifyContent='space-between'>
            <Typography variant='body2' className={classes.colorDisabled}>{I18n.t('total')}</Typography>
            <Typography variant='body2' color='textPrimary'>$ {separator(row?.total)}</Typography>
          </Row>
        </div>
      </Collapse>
    </div>
  )

}

const Calculation = ({ data, isOverall }) => {
  const classes = useStyles(isOverall);
  const [modal, setModal] = useState(null);
  const [option, setOption] = useState({});
  const [selectedMachine, setSelectedMachine] = useState({});
  const [uniqueMachine, setUniqueMachine] = useState([]);
  const [overallTotal, setOverallTotal] = useState(0);
  const [hasMachineOption, setHasMachineOption] = useState(true);
  useEffect(() => {
      let Machine = [];
      for (var j=0;j<data?.length;j++) {
        const filteredData = data.filter((machine) => machine.label === data[j].label)
        let machineData = {
          label: data[j].label,
          value: data[j].value,
          quantity: 0,
          total: 0,
          options: [],
        }

        filteredData.forEach(item => {
          machineData.quantity += item.quantity;
          machineData.total += item.value * item.quantity + item.optionsPrice + item.extra_price;
          machineData.options.push({
            ...item,
            label: data[j].label
          });
        });
        let hasOption = false;
        machineData.options.forEach(option => {
          const { otherOptions, options, additionalOptions } = option;
          if (otherOptions?.length || options?.length || additionalOptions?.length) {
            hasOption = true;
          }
        });
        Machine.push({
          ...machineData,
          hasOption: hasOption 
        })
      }

      const uniqueMachine = Machine.filter((machine, index) => {
        return Machine.findIndex(unique => unique.label === machine.label) === index;
      })
      const overallTotal = uniqueMachine?.reduce((prev, current) => prev + current.total, 0)

      const mergeMachine = uniqueMachine.map((machine) => {
        let newOptions = [];
        machine.options.map((option) => {
          let newOption = newOptions.find(newOption => {
            return newOption.extra_price === option.extra_price && newOption.optionsPrice === option.optionsPrice && newOption.otherOptions.length === option.otherOptions.length && newOption.options.length === option.options.length;
          })
          if (newOption) {
            newOption.quantity = newOption.quantity + option.quantity
          }
          else {
            newOptions.push(option)
          }
        })
        return {
          ...machine,
          options: newOptions,
        }
      })
      setOverallTotal(overallTotal)
      setUniqueMachine(mergeMachine)
  }, []);

  useEffect(() => {
    //select data
    if(data?.options?.length) {
      if((data.options[0].optionsPrice === 0) && (data.options[0].extra_price === 0) && (data.options[0].options?.length === 0) && (data.options[0].otherOptions?.length === 0)) {
        setHasMachineOption(false);
      }
    }
  }, [data])

  return (
    <Column className={classes.root}>
      {
        isOverall ?
          <>
            {
              uniqueMachine.map((row) =>
                <CollapseRow row={row}  setModal={setModal} setOption={setOption} setSelectedMachine={setSelectedMachine}/>
              )
            }
            <hr className='w-100' />
            <Row justifyContent='space-between'>
              <Typography variant='body2' className={classes.colorDisabled}>{I18n.t('total')}</Typography>
              <Typography variant='body2' color='textPrimary'>$ {separator(overallTotal)}</Typography>
            </Row>
          </>
          :
          <>
            <Row justifyContent='space-between'>
              <Typography variant='body2' className={classes.colorDisabled}>{I18n.t('originalPrice')}</Typography>
              <Typography variant='body2' color='textPrimary'>$ {separator(data?.value)}</Typography>
            </Row>
            <Row justifyContent='space-between'>
              <Typography variant='body2' className={classes.colorDisabled}>{I18n.t('quantity')}</Typography>
              <Typography variant='body2' color='textPrimary'>{data?.quantity}</Typography>
            </Row>
            {
              hasMachineOption ? 
                data?.options?.map(option => <OptionBlock optionData={option}  setModal={setModal} setOption={setOption} setSelectedMachine={setSelectedMachine} />)  
                :
                <Row justifyContent='space-between'>
                  <Typography variant='body2' className={classes.colorDisabled}>
                    {I18n.t('noOption') + ': '}
                  </Typography>
                <Typography variant='body2' color='error'>+ $ 0</Typography>
              </Row>
            }
            <hr className='w-100' />
            <Row justifyContent='space-between'>
              <Typography variant='body2' className={classes.colorDisabled}>{I18n.t('total')}</Typography>
              <Typography variant='body2' color='textPrimary'>$ {separator(data?.total)}</Typography>
            </Row>
          </>
      }
      <SeeDescriptionModal
        openWhen={modal === "seeDescription"}
        optionID={option.label}
        machineName={selectedMachine.label}
        price={separator(option.value)}
        remark={option.remark}
        onClose={setModal}
      />
    </Column>
    
    
  )
};

export default Calculation;
