/** @jsx h */
import { h } from "preact";
import { useState, useEffect, useRef } from "preact/hooks";
import useStyles from "./Select.style";

import FilterListIcon from "@material-ui/icons/FilterList";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

const Select = ({ data, className, style, filterIcon, value, onClick, width }) => {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const [select, setSelect] = useState({ id: "", text: "" });
	const [color, setColor] = useState("rgba(0,0,0,.54)");
	const ref = useRef(null);

	useEffect(() => {
		if (value) {
			const selected = data.find((item) => item.id === value);
			setSelect(selected);
		} else {
			setSelect(data[0]);
		}
	}, [data, value]);

	const selected = (val) => {
		setSelect(val);
		onClick(val);
	};

	useEffect(() => {
		const listener = document.addEventListener("click", (e) => {
			const outsideClick = !ref.current?.contains(e.target);
			if (outsideClick) setOpen(false);
		});
		return () => {
			document.removeEventListener("click", listener);
		};
	}, []);

	return (
		<div className={`${classes.select} relative ${className}`} style={{ ...style, width: width }}>
			<div
				ref={ref}
				id="select-input"
				class="al-center jc-space-btw"
				onClick={() => setOpen(!open)}
			>
				{filterIcon && <FilterListIcon style={{ color: color }} />}
				<span class="flex-1" style={{ color: color }}>
					{select?.text}
				</span>
				<ArrowDropDownIcon style={{ color: color }} />
			</div>
			{open && (
				<div className={`${classes.list} column`}>
					{data.map((item) => (
						<span
							key={item.id}
							// style={{color: color}}
							onClick={() => selected(item)}
						>
							{item.text}
						</span>
					))}
				</div>
			)}
		</div>
	);
};
export default Select;
