import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  border: {
    border: '1px #E0E0E0 solid',
    borderRadius: '8px'
  },
  root: {
    paddingTop: theme.spacing(2)
  },
  area: {
    fontSize: '16px',
    backgroundColor: '#FAFAFA',
    padding: `${theme.spacing(4)}px ${theme.spacing(2)}px`,

    '& + div': {
      marginTop: theme.spacing(2)
    },

    '& > label': {
      width: '36px',
      marginRight: theme.spacing(2),
      wordBreak: 'break-all'
    },

    '& > span': {
      width: '36px',
      marginLeft: theme.spacing(2),
      color: '#2E65C9'
    }
  },
  gauge: {
    height: '40px',
    backgroundColor: '#FFFFFF',
    overflow: 'auto',
    
    '& > div:first-child':{
      backgroundColor: '#2E65C9',
    }
  },
  grayGauge: {
    display:'flex',
    justifyContent:'flex-end',
    alignItems:'center',
    backgroundColor:'#F0F0F0',
    borderRight: '5px solid #9B9B9B',
    fontSize :'12px',
    color:'#9B9B9B'
  },
}))

export default useStyles;