/** @jsx h */
import React from "react";
import { h } from "preact";
import { useState, useEffect } from "preact/hooks";
import useStyles from "./ListItem.style";

import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Chip from "@material-ui/core/Chip";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import EditIcon from "@material-ui/icons/Edit";
import I18n from "i18n/i18n";

import { Skeleton } from "@material-ui/lab";
import { zeroArray, RNG } from "../../../util";
import { Jdenticon } from "components/atoms/Jdenticon"; 

const ListItem = ({
	ctaText,
	data,
	delet,
	deleteIcon,
	edit,
	editIcon,
	label,
	seeDetail,
	selected,
	selectable,
	style,
	noBorder,
	onClick,
	roundedAvatar,
	noAvatar,
	skeleton,
	showLockIcon
}) => {
	const classes = useStyles({ roundedAvatar });
	const [color, setColor] = useState("white");

	useEffect(() => {
		if (selected) {
			setColor("#EFF5FF");
		} else setColor("white");
	}, [selected]);

	if (skeleton) {
		return (
			<div className={"jc-space-btw al-center " + classes.listItemWrap}>
				<div class={"d-flex jc-space-btw al-center" + classes.listItemWrap}>
					<div className={`${label == "nextVisits" ? "al-start" : "center"} ${classes.boxNV}`}>
						{label && label !== 'client' && (
							<Skeleton
								variant="circle"
								style={{
									width: "40px",
									height: "40px",
								}}
							/>
						)}
					</div>
					<div className="column" style={{ marginLeft: "10px" }}>
						{zeroArray(3).map(() => (
							<Skeleton
								variant="text"
								style={{
									width: `${RNG(3, 7)}rem`,
									marginBottom: `0.3rem`,
								}}
							/>
						))}
					</div>
				</div>
			</div>
		);
	}

	const Content = ({ label }) => (
		<>
			{label == "nextVisits" ? (
				<div className="d-flex">
					<div>
						{
							data?.imgUrl ?
								<Avatar style={{width: 40, height: 40, marginRight: '1.1vw'}} src={data.imgUrl} />
								:
							    <div className={`${classes.center} ${classes.box}`}>
									<Jdenticon size="38" value={data.originalName || data.name} />
								</div>
						}
					</div>
					<div className="column" style={{ marginLeft: "10px" }}>
						<span className={classes.main}>
							{data && data.group} {data && data.branch ? "(" + data.branch + ")" : null}
						</span>
						<div className="d-flex" style={{ margin: "4px 0" }}>
							<span style={{ color: "rgba(0,0,0,.38)" }}>{data && data.time[0]}</span>
							<div style="width: 10px" />
							<span style={{ color: "#2E65C9" }}>{data && data.time[1]}</span>
						</div>
						<div style={{ color: "rgba(0,0,0,.38)", width: "80%" }}>{data && data.description}</div>
					</div>
				</div>
			) : label === "contact" ? (
				<div className="al-center w-100">
					{!noAvatar && (
						<div style={{flex:1}}>					
							{
								data?.imgUrl ?
									<Avatar style={{width: 40, height: 40, marginRight: '1.1vw'}} src={data.imgUrl} />
									:
										<div className={`${classes.center} ${classes.box}`}>
										<Jdenticon size="38" value={data.icon} />
									</div>
							}
						</div>
					)}
					<div className="jc-space-around w-100">
						<span className={classes.subContact} style={{flex:1}}>{data && data.name}</span>
						<span className={classes.subContact} style={{flex:2}}>{data && data.sub}</span>
					</div>
				</div>
			) : label === "punchGroup" ? (
				<div className="al-center" style={{ width: '100%' }}>
					<div className="column">
						<span className={classes.main}>{data.name}</span>
					</div>
				</div>
			) : ['client', 'client_branch', 'topFiftyClient'].includes(label) ? (
				<div className="al-center" style={{ width: '100%' }}>
					{
						showLockIcon &&
						<div style={{ width: '5%' }}>
							<LockOutlinedIcon style={{ width: 20 }} />
						</div>
					}
					<div className="column" style={{ width: '80%' }}>
						<span className={classes.main}>{data.name}</span>
					</div>
					<div style={{ width: '15%' }}>
						{(label === 'client' && data.isTop50_Clients) || (label === 'client_branch' && data.isTop50_clients_branch) ? (
							<Chip variant="outlined" color="secondary" label="Top 50" style={{ marginRight: '1.1vw' }} />
						) : null}
					</div>
				</div>
			) : (
				<div className="al-center" style={{ width: '100%' }}>
					{!noAvatar && (
						<div style={{flex:1}}>
							{data?.imgUrl ?
								<Avatar style={{width: 40, height: 40, marginRight: '1.1vw'}} src={data.imgUrl} />
								:
								<div className={`${classes.center} ${classes.box}`} style={{flex:2}}>
									<Jdenticon size="38" value={data.originalName} />
								</div>}
						</div>
					)}
					<div className="column" style={{flex:9}}>
						<span className={classes.main}>{data.name}</span>
						{/* <span className={classes.sub}>{data && data.sub}</span> */}
					</div>
					{
						showLockIcon &&
						<div style={{ width: '100%', flex:1 }}>
							<LockOutlinedIcon style={{ width: 20 }} />
						</div>
					}
				</div>
			)}
		</>
	);

	return (
		<div
			className={"jc-space-btw al-center " + classes.listItemWrap}
			style={{
				background: color,
				borderBottom: !noBorder && "1px solid #E0E0E0",
				cursor: selectable ? "pointer" : "default",
			}}
			onClick={selectable && onClick}
		>
			<Content label={label} parent={parent}/>
			{ctaText && (
				<span className={classes.sd} style="cursor:pointer" onClick={seeDetail}>
					{I18n.t("SeeDetails")}
				</span>
			)}
		</div>
	);
};
export default ListItem;
