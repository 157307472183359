/** @jsx h */
import { h } from "preact";
import I18n from "i18n-js";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";

import AttachFileIcon from "@material-ui/icons/AttachFile";
import ComputerIcon from "@material-ui/icons/Computer";
import CloseIcon from "@material-ui/icons/Close";

import { Uploader } from "rsuite";

import { IconBlock } from "components/atoms/iconBlock/IconBlock";

const useStyles = makeStyles((theme) => ({
  body: {
    marginBottom: "20px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  uploader: {
    '& .rs-uploader-trigger-btn': {
      padding: 0,
      border: 'none !important'
    }
  }
}));

export const UploadModal = ({
  open,
  fileList = [],
  handleClose = () => { },
  handleUpload = () => { },
}) => {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <DialogTitle style={{ color: "rgba(0, 0, 0, 0.54)" }}>
        <b>{I18n.t("UploadA", { name: I18n.t("File") })}</b>
        <IconButton
          style={{
            float: "right",
            backgroundColor: "#eeeeee",
            borderRadius: 5,
          }}
          size="small"
          onClick={handleClose}
        >
          <CloseIcon color="action" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className={classes.body}>
          <Uploader
            action=""
            multiple
            fileListVisible={false}
            autoUpload={false}
            fileList={fileList}
            onChange={handleUpload}
          >
            <IconBlock
              icon={<ComputerIcon style={{ color: "#eeeeee" }} />}
              label={I18n.t("FromComputer")}
              borderWidth="5px"
              borderRadius="10px"
              color="rgba(0, 0, 0, 0.38)"
              backgroundColor="white"
              borderColor="#eeeeee"
            />
          </Uploader>
          <Typography
            variant="h6"
            style={{
              margin: "0px 50px",
              fontWeight: "bold",
              color: "rgba(0, 0, 0, 0.38)",
            }}
          >
            Or
          </Typography>
          <Uploader
            action=""
            draggable
            multiple
            fileListVisible={false}
            autoUpload={false}
            className={classes.uploader}
            fileList={fileList}
            onChange={handleUpload}
          >
            <div>
              <IconBlock
                icon={<AttachFileIcon style={{ color: "#eeeeee" }} />}
                label={I18n.t("DragDrop")}
                borderWidth="5px"
                borderStyle="dashed"
                borderRadius="10px"
                color="rgba(0, 0, 0, 0.38)"
                backgroundColor="white"
                borderColor="#eeeeee"
              />
            </div>
          </Uploader>
        </div>
      </DialogContent>
    </Dialog>
  );
};
