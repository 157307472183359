/** @jsx h */
import React from 'react';
import { h } from 'preact';
import I18n from "i18n-js";
import moment from "moment";
import { useState, useEffect, useContext } from "preact/hooks";
import Context from "context";

import AddCircleIcon from '@material-ui/icons/AddCircle';
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import Modal from "components/atoms/modal/Modal";
import { Row } from 'components/atoms/row/Row';
import { DeleteModal } from "components/molecules/deleteModal/DeleteModal";
import { List } from 'components/molecules/list/List';
import Visit from "components/organisms/modalContent/visit/Visit";
import { getSchedule, postSchedule, putSchedule, deleteSchedule, getClientBranch, getClient, getImage } from "api";
import { errHandler } from '../../../util';
import { useUserImage } from 'hooks';

import { route } from "preact-router";

export const NextVisitList = ({ type, loading, setLoading, filterOptions, renderList, addPrefill }) => {
  const { getUserImage } = useUserImage();
  const [refreshSchedule, setRefreshSchedule] = useState(false);
  const [nextVisitData, setNextVisitData] = useState(null);
  const [nextVisitFetched, setNextVisitFetched] = useState(false);
  const [visit, setVisit] = useState();
  const [editingVisit, setEditingVisit] = useState({});
  const [modal, setModal] = useState(false);
  const { setError, setMessage } = useContext(Context)
  if (!type) type = 'default';

  const editVisit = async (val) => {
    const tmp = (await getClientBranch({ id: nextVisitData[val].clientBranchID })).data[0] // find selected visit item by index
    const tmpGroup = (await getClient({ id: tmp.id_client })).data[0]  // find group id by branch id
    setVisit(tmp);
    setEditingVisit({
      userID: nextVisitData[val].userID,
      groupName: tmpGroup.id,
      branchName: tmp.id,
      day: new Date(nextVisitData[val].startTime),
      startTime: new Date(nextVisitData[val].startTime),
      endTime: new Date(nextVisitData[val].endTime),
      visitReason: nextVisitData[val].description,
      id: nextVisitData[val].id,
    })
    setModal('editVisit')
  }

  const deleteVisit = async (val) => {
    const [tmp] = await nextVisitData.filter((item, i) => i === val);
    setVisit(tmp);
    setModal('deleteVisit')
  }

  const submitVisit = async (val) => {
    if (modal === "deleteVisit") {
      deleteSchedule(visit.id).then(({ data }) => {
        setMessage(I18n.t("requestSuccessful", { name: I18n.t("schedule"), verb: I18n.t("deleted") }));
        setRefreshSchedule(true)
        setVisit()
      })
        // .catch((err) => {
        //   setError(err?.response ? I18n.t(err.response.data.errors[0].msg) : err.message);
        // });
        .catch((err) => setError(err, setError, route));
    }
    else {
      setLoading(true);
      // const [clientBranch] = await clientBranchList.filter((item, i) => item.id === val.branchName);const day = moment(val.day).utc().format('YYYY-MM-DD')
      const day = moment(val.day).utc().format('YYYY-MM-DD')
      const start_time = day + " " +  moment(val.startTime).utc().format('HH:mm:ss')
      const end_time = day + " " +  moment(val.endTime).utc().format('HH:mm:ss')
      if (modal === "planVisit") {
        // if val.userID exist = adding from clientDetail
        // else adding from userProfile
        const user_id = val.userID || filterOptions.id_user;
        postSchedule(user_id, {
          id_client_branch: val.branchName,
          start_time: start_time,
          end_time: end_time,
          description: val.visitReason
        }).then(({ data }) => {
          setMessage(I18n.t("requestSuccessful", { name: I18n.t("schedule"), verb: I18n.t("created") }));
          setLoading(false);
          setModal(false);
          setRefreshSchedule(true);
        })
          // .catch((err) => {
          //   setError(err?.response ? I18n.t(err.response.data.errors[0].msg) : err.message);
          // });
          .catch((err) => {
            setLoading(false);
            errHandler(err, setError, route);
          });
      }

      else if (modal === "editVisit") {
        // if val.userID exist = editing from clientDetail
        // else from userProfile (just let it undefined)
        const user_id = val.userID;
        putSchedule(val.id, {
          id_user: user_id,
          id_client_branch: val.branchName,
          start_time: start_time,
          end_time: end_time,
          description: val.visitReason
        }).then(({ data }) => {
          setMessage(I18n.t("requestSuccessful", { name: I18n.t("schedule"), verb: I18n.t("updated") }));
          setLoading(false);
          setModal(false);
          setRefreshSchedule(true)
          setVisit()
        })
          // .catch((err) => {
          //   setError(err?.response ? I18n.t(err.response.data.errors[0].msg) : err.message);
          // });
          .catch((err) => {
            setLoading(false);
            errHandler(err, setError);
          });
      }
    }
  }

  const handleClose = () => {
    setLoading(false);
    setModal(false);
  }

  useEffect(async() => {
    // try {
      // call getSchedule by filter condition (user/branch)
      const scheduleList = (await getSchedule({
        ...filterOptions,
        pending: false,
        field: "start_time",
        from: moment(new Date()).utc().format("YYYY-MM-DD"),
        //DIRTY FIX FOR API
        to: moment(new Date()).utc().add(365, 'days').format("YYYY-MM-DD")
      })).data;

      const visitList = await Promise.all(scheduleList?.map((d) => {
        return ({
          startTime: d.start_time,
          endTime: d.end_time,
          clientBranchID: d.id_client_branch,
          id: d.id,
          userID: d.id_user,
          description: d.description,
        })
        // return data object to renderList props, and then modify data to listData by requirement
      }).map(await (renderList)));
      const filteredVisitList = await Promise.all(visitList.filter(el => el).map(async(visit) => {
        let userImage = null;
        try {
          if (type === 'salesman') userImage = await getUserImage(visit.userID)
          else userImage = await getImage('client_branch', visit.clientBranchID)
        } catch (err) {
          // errHandler(err, setError, route);
          console.log(err)
        }
        return {
          ...visit,
          imgUrl: userImage ? userImage.data.url : null
        }
      }))
      // console.log('filteredVisitList', filteredVisitList)
      setNextVisitData(filteredVisitList);
      setNextVisitFetched(true);
      setRefreshSchedule(false);
    // } catch (err) {
    //   errHandler(err, setError, route);
    // }
  }, [refreshSchedule]);

  // use type props determine layout and different add/edit modal submit
  const content = {
    salesman:
      <Container
        title={I18n.t('NextVisits')}
        add={() => setModal('planVisit')}
      >
        <List
          isFetched={nextVisitFetched}
          label="nextVisits"
          data={nextVisitData}
          width="100%"
          contentHeight="350px"
          edit={(val) => editVisit(val)}
          delet={(val) => deleteVisit(val)}
          editIcon={true}
          deleteIcon={true}
          roundedAvatar
          showEmptyIcon
        />
      </Container>
    ,
    default:
      <List
        isFetched={nextVisitFetched}
        label="nextVisits"
        data={nextVisitData}
        width="100%"
        contentHeight="350px"
        add={true}
        open={() => setModal('planVisit')}
        edit={(val) => editVisit(val)}
        delet={(val) => deleteVisit(val)}
        editIcon={true}
        deleteIcon={true}
        roundedAvatar
        title={I18n.t('NextVisits')}
        showEmptyIcon
      />
  };

  return (
    <>
      {content[type]}
      <Modal
        title={["AddA", "Visit"]}
        open={modal === "planVisit"}
        close={handleClose}
        style={{ paddingBottom: '64px' }}>
        <Visit data={addPrefill} label="add" visitType={type}
          loading={loading}
          onClick={(val) => {
            submitVisit(val)
          }} />
      </Modal>
      <Modal
        title={["EditA", "Visit"]}
        open={modal === "editVisit"}
        close={handleClose}
        style={{ paddingBottom: '64px' }}>
        <Visit data={editingVisit} label="edit" visitType={type}
          loading={loading}
          onClick={(val) => {
            submitVisit(val)
          }} />
      </Modal>
      <DeleteModal
        open={modal == "deleteVisit"}
        handleClose={() => setModal(false)}
        submit={() => {
          submitVisit()
          setModal(false)
        }} />
    </>
  )
}

const Container = ({ title, iconType, add, onSearchChange, children }) => {
  const useStyles = makeStyles((theme) => ({
    container: {
      flex: 1,
      cursor: 'default',
      height: 'fit-content',
      padding: '16px 16px',
      backgroundColor: '#FAFAFA',
      border: '1px solid #E0E0E0',
      borderRadius: '8px',

      '& > .header': {
        marginBottom: theme.spacing(2),

        '& .title': {
          color: theme.palette.text.disabled
        },
      },

      '& .textButton': {
        color: '#2E65C9',
        padding: theme.spacing(0, 2)
      },

      '& ::-webkit-scrollbar': {
        backgroundColor: 'transparent',
        width: 0
      },

      '& ::-webkit-scrollbar-track': {
        backgroundColor: 'transparent'
      }
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Row justifyContent='space-between' alignItems='center' className='header'>
        <Typography variant="body1" className='title'>{title}</Typography>
        <Row>
          {
            iconType === 'search' &&
            <Button
              bgColor="#FFFFFF"
              textColor="rgba(0, 0, 0, .54)"
              iconType='search'
              onChange={onSearchChange}
              expandable={true}
              searchId='searchContact'
            />
          }
          <AddCircleIcon style="color:#2E65C9;font-size:40px;margin-left:16px;cursor:pointer" onClick={add} />
        </Row>
      </Row>
      {children}
    </div>
  )
}
