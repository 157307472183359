/** @jsx h */
import { Fragment, h } from "preact";
import { useState, useEffect } from "preact/hooks";
import { DatePicker } from 'rsuite';
import { enUS, zhTW } from 'rsuite/esm/locales';
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import RemoveIcon from "@material-ui/icons/Remove";
import I18n from "i18n-js";
import moment from "moment";

import { Row } from "components/atoms/row/Row";
import { Column } from "components/atoms/column/Column";
import { SortableTable } from "components/molecules/sortableTable/SortableTable";
import Select from "components/atoms/select/Select";
import ExportExcelButton from "components/atoms/exportExcelButton/ExportExcelButton";

import { getABCRate, getABCRateMonthly, getABCRateTotal, getOrderPrevision } from "api";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(4),
		width: "100%",
		color: "rgba(0, 0, 0, 0.54)",
	},
	mb2: {
		marginBottom: theme.spacing(2),
	},
	mr2: {
		marginRight: theme.spacing(2),
	},
	icon: {
		color: "rgba(0, 0, 0, 0.54)",
		cursor: "pointer",
		marginRight: theme.spacing(1),
		"&:hover": {
			color: "#2e65c9",
		},
		"&:last-child": {
			marginRight: 0,
		},
	},
	iconActive: {
		color: "#2e65c9",
	},
	card: {
		flex: 1,
		cursor: "default",
		height: "fit-content",
		padding: "16px 16px",
		backgroundColor: "white",
		border: "1px solid #E0E0E0",
		borderRadius: "8px",
		"& ::-webkit-scrollbar": {
			backgroundColor: "transparent",
			width: 0,
		},
		"& ::-webkit-scrollbar-track": {
			backgroundColor: "transparent",
		},
	},
	cardTitle: {
		marginBottom: "16px",
		fontSize: "20px",
		fontWeight: " 500",
		color: "rgba(0,0,0,.54)",
	},
	table: {
		overflow: "hidden",
		borderRadius: 10,

		"& .MuiTableSortLabel-root": {
			display: "flex",
		},

		"& .MuiTableRow-root:last-child > .MuiTableCell-body": {
			borderBottom: "none",
		},
	},
	firstCell: {
		color: "rgba(0,0,0,0.54)",
	},
	striped: {
		backgroundColor: "#FAFAFA",
	},
	smallText: {
		fontSize: "10px",
		marginLeft: "5px",
	},
	DateRangePicker: {
        '& .rs-picker-toggle': {
        backgroundColor: '#FFFFFF',
        padding: '9px 12px',
        paddingRight: 36,
        },
    },
}));

const availableFilter = ["department", "salesman"];
const compareIcon = {
	up: <ArrowUpwardIcon color="primary" />,
	down: <ArrowDownwardIcon color="error" />,
	same: <RemoveIcon />,
};

const TransactionRate = () => {
	const classes = useStyles();

	const [filterOptions, setFilterOptions] = useState([
		{ id: "department", text: I18n.t("filterBy", { text: I18n.t("department")}) },
		{ id: "salesman", text: I18n.t("filterBy", { text: I18n.t("salesman")}) },
	]);
	const [currFilter, setCurrFilter] = useState(availableFilter[0]);
	const [salesData, setSalesata] = useState([]);
	const [columns, setColumns] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [isFetched, setFetched ] = useState(false);

	const getSalesData = async () => {
		try {
			const data = (await getOrderPrevision({ from: moment().utc().year() })).data;
			setSalesata(Object.keys(data).map(item => 
				Object.keys(data[item]).map(nested => 
					data[item][nested]['sales'].map(sales => sales)
				).flat()
			).flat());
		} catch (err) {
			console.log(err);
		}
	}

	const getWeekTrend = (salesman) => {
		const sales = salesData.filter(item => item.salesman === salesman);
		const diff = sales.length ? Number(sales[0].total) - Number(sales[0].totalPreviousWeek) : 0;
		return diff > 0 ? 'up' : diff === 0 ? 'same' : 'down';
	}

	useEffect(() => {
		getSalesData();
	}, []);

	useEffect(() => {
		const next = {
			[availableFilter[0]]: () => {
				getABCRate()
				.then(res => {
					let departmentData = new Array();
					let departmentChartData = new Array();
					Object.keys(res.data).map(key => {
						Object.keys(res.data[key]).map(key2 => {
							let arrayData = new Array();
							let objectData = new Object();
							arrayData.push(key2);
							objectData['department'] = key2;
							objectData['A+'] = 0;
							objectData['A'] = 0;
							objectData['B'] = 0;
							objectData['total'] = 0;
							objectData['objective'] = 0; // Fixed, need confirm source
							res.data[key][key2].map(item => {
								objectData['A+'] += parseInt(item['A+']);
								objectData['A'] += parseInt(item['A']);
								objectData['B'] += parseInt(item['B']);
								objectData['total'] += parseInt(item['total']);
							})
							arrayData.push(objectData['A+']);
							arrayData.push(objectData['A']);
							arrayData.push(objectData['B']);;
							departmentChartData.push(arrayData);
							departmentData.push(objectData);
						})
					})
					setColumns(departmentColumns);
					setTableData(departmentData);
					setFetched(true);
				})
				.catch(() => {
					setColumns();
					setTableData();
					setFetched(true);
				})
			},
			[availableFilter[1]]: () => {
				getABCRate()
				.then(res => {
					let salesmanData = new Array();
					let salesmanChartData = new Array();
					Object.keys(res.data).map(key => {
						Object.keys(res.data[key]).map(key2 => {
							res.data[key][key2].map(item => {
								let arrData = new Array();
								arrData.push(item['salesman']);
								arrData.push(item['A+']);
								arrData.push(item['A']);
								arrData.push(item['B']);
								item.objective = 0;
								const compare = getWeekTrend(item.salesman);
								item.compare = compareIcon[getWeekTrend(item.salesman)];
								item.compareText = compare === 'up' ? '⭡' : compare === 'down' ? '⭣' : '-';
								salesmanData.push(item)
								salesmanChartData.push(arrData);
							})
						})
					})
					setColumns(salesmanColumns);
					setTableData(salesmanData);
					setFetched(true);
				})
				.catch(() => {
					setColumns();
					setTableData();
					setFetched(true);
				})
			},
		};
		next[currFilter]();
	}, [currFilter]);

	const departmentColumns = [
		{
			id: "department",
			sortable: true,
		},
		{
			id: "A+",
			sortable: true,
		},
		{
			id: "A",
			sortable: true,
		},
		{
			id: "B",
			sortable: true,
		},
		{
			id: "total",
			sortable: true,
		},
	];

	const salesmanColumns = [
		{
			id: "salesman",
			label: I18n.t("Salesman"),
			sortable: true,
		},
		...departmentColumns.slice(1),
		{
			id: "compare",
			label: I18n.t("comparePreviousWeek"),
			sortable: false,
		}
	];

	const onFilterType = (data) => {
		setCurrFilter(data.id);
	};

	return (
		<div className={classes.root}>
			<Row justifyContent="space-between" alignItems="center" className={classes.mb2}>
				<Typography variant="h5" color="inherit">
					{I18n.t("TransactionRate")}
				</Typography>
				<Row style={{ gap: 10 }} alignItems="center">
					<ExportExcelButton
                        sheetName={I18n.t("TransactionRate")}
                        columns={columns.map(column => ({ ...column, text: I18n.t(column.id) }))}
                        data={currFilter === 'salesman' 
							? tableData.map(data => ({ ...data, compare: data.compareText }))
							: tableData}
                        fileType="xlsx"
                    />
					<Select data={filterOptions} filterIcon onClick={onFilterType} />
				</Row>
			</Row>
			<Fragment>
				<Column className={classes.mb2}>
					<SortableTable columns={columns} data={tableData} striped isFetched={isFetched}/>
				</Column>
				<Column className={classes.mb2}>
					<MonthlyTrendTableCard filter={currFilter} />
				</Column>
				<Column className={classes.mb2}>
					<TotalTableCard filter={currFilter}/>
				</Column>
			</Fragment>
		</div>
	);
};

const MonthlyTrendTableCard = ({ filter }) => {
	const classes = useStyles();
	const [tableData, setTableData] = useState([]);
	const [isFetched, setFetched] = useState(false);
	const [date, setDate] = useState(new Date());

	const columns = [
		{
			id: "month",
			sortable: true,
		},
		{
			id: "A+",
			sortable: true,
		},
		{
			id: "A",
			sortable: true,
		},
		{
			id: "B",
			sortable: true,
		},
		{
			id: "total",
			sortable: true,
		},
		{
			id: "totalA",
			sortable: true,
		},
	];

	useEffect(() => {
		getABCRateMonthly({ year_month: moment(date).format("YYYY-MM") })
			.then(res => {
				let monthlyTrendData = new Array()
				let monthlyChartData = new Array()
				Object.keys(res.data).map(key => {
					Object.keys(res.data[key]).map(key2 => {
						Object.keys(res.data[key][key2]).map(key3 => {
							let objectData = new Object()
							objectData['APlus'] = 0
							objectData['A'] = 0
							objectData['B'] = 0
							objectData['total'] = 0
							objectData['totalA'] = 0
							objectData['month'] = key3
							res.data[key][key2][key3].map(data => {
								objectData['APlus'] += data['A+']
								objectData['A'] += data['A']
								objectData['B'] += data['B']
								objectData['total'] += data['total']
								objectData['totalA'] += data['A+'] + data['A']
							})
							monthlyTrendData.push(objectData)
						})
					})
				})
				let mergedData = Object.values([...monthlyTrendData]).reduce((item, { month, APlus, A, B, total, totalA }) => {
					item[month] = {
						month,
						A: (item[month] ? item[month].A : 0) + A,
						APlus: (item[month] ? item[month].APlus : 0) + APlus,
						B: (item[month] ? item[month].B : 0) + B,
						total: (item[month] ? item[month].total : 0) + total,
						totalA: (item[month] ? item[month].totalA : 0) + totalA,
					}
					return item
				}, {})
				monthlyTrendData = []
				Object.keys(mergedData).map(key => {
					let arrData = new Array()
					mergedData[key]['A+'] = mergedData[key]['APlus']
					delete mergedData[key]['APlus']
					arrData.push(key)
					arrData.push(mergedData[key]['A+'])
					arrData.push(mergedData[key]['A'])
					arrData.push(mergedData[key]['B'])
					monthlyTrendData.push(mergedData[key])
					monthlyChartData.push(arrData)
				})
				setTableData(monthlyTrendData);
				setFetched(true);
			})
			.catch(() => {
				setTableData();
				setFetched(true);
			})
	}, [filter, date]);

    return (
		<div className={classes.card}>
			<div className={`${classes.cardTitle} jc-space-btw al-center`}>
				<span>{I18n.t("MonthlyTrend")}</span>
				<div className="d-flex al-center" style={{ height: "40px", gap: 10 }}>
                    <ExportExcelButton
                        sheetName={moment(date).format('YYYY-MM') + I18n.t("MonthlyTrend")}
                        columns={columns.map(column => ({ ...column, text: I18n.t(column.id) }))}
                        data={tableData}
                        fileType="xlsx"
                    />
					<DatePicker
                        size="lg"
                        placement="bottomEnd"
                        format="yyyy/MM"
                        cleanable={false}
                        className={classes.DateRangePicker}
                        value={date}
                        onChange={setDate}
                        locale={
                        localStorage.getItem('locale') === 'en'
                            ? enUS.DatePicker
                            : zhTW.DatePicker
                        }
                    />
				</div>
			</div>
			<div className={`column ${classes.body}`}>
				<SortableTable columns={columns} data={tableData} striped isFetched={isFetched} />
			</div>
		</div>
	);
};

const TotalTableCard = ({ filter }) => {
	const classes = useStyles();
	const [tableData, setTableData] = useState([]);
	const [isFetched, setFetched] = useState(false);

	useEffect(() => {
		getABCRateTotal()
		.then(res => {
			let totalData = new Array()
			Object.keys(res.data).map(key => {
				let objectData = new Object()
				objectData[' '] = key
				objectData['A+'] = 0
				objectData['A'] = 0
				objectData['B'] = 0
				objectData['total'] = 0
				objectData['totalA'] = 0
				Object.keys(res.data[key]).map(key2 => {
					objectData['A+'] += res.data[key][key2]['A+']
					objectData['A'] += res.data[key][key2]['A']
					objectData['B'] += res.data[key][key2]['B']
					objectData['total'] += res.data[key][key2]['total']
					objectData['totalA'] += res.data[key][key2]['totalA']
				})
				totalData.push(objectData)
			})
			setTableData(totalData);
			setFetched(true);
		})
		.catch(() => {
			setTableData();
			setFetched(true);
		})
	}, [filter]);

	const columns = [
		{
			id: " ",
			label: " ",
			className: classes.firstCell,
		},
		{
			id: "A+",
			sortable: true,
		},
		{
			id: "A",
			sortable: true,
		},
		{
			id: "B",
			sortable: true,
		},
		{
			id: "total",
			sortable: true,
		},
		{
			id: "totalA",
			sortable: true,
		},
	];

	return (
		<div className={classes.card}>
			<div className={`${classes.cardTitle} jc-space-btw al-center`}>
				<span>{I18n.t("total")}</span>
			</div>
			<div className={`column ${classes.body}`}>
				<SortableTable columns={columns} data={tableData} striped isFetched={isFetched} />
			</div>
		</div>
	);
};

export default TransactionRate;
