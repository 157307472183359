import { TagPicker } from "rsuite";
import { useMemo } from "preact/hooks";
import I18n from "i18n-js";
import useStyles from "./MultiSelect.style";

export const MultiSelect = ({
  className,
  style,
  width,
  data = [],
  title = '',
  labelKey = 'label',
  valueKey = 'value',
  onChange,
  ...restProps
}) => {
  const classes = useStyles();

  const options = useMemo(() => {
    return [
      { label: I18n.t('All', { name: I18n.t(title) }), value: 0 },
      ...data.map((option) => ({ label: option[labelKey], value: option[valueKey] }))
    ]
  }, [JSON.stringify(data)]);

  const onMultiSelect = (values) => {
    const lastItem = values[values.length - 1]
    const otherItems = values.filter(v => v !== 0)
    if (values.length === data.length && !values.includes(0)) return []
    if (lastItem === 0 || otherItems.length === data.length) return [0, ...data.map(d => d[valueKey])]
    if (values.includes(0) && lastItem !== 0) return otherItems
    return values
  }

  return (
    <TagPicker
      {...restProps}
      className={`${classes.select} relative ${className}`}
      style={{ ...style, width }}
      data={options}
      onChange={(values) => onChange(onMultiSelect(values))}
    />
  )
}