/** @jsx h */
import { h, cloneElement } from "preact";
import I18n from "i18n/i18n"

// responsive submenu imports
import { useState } from "preact/hooks";
import Match from "preact-router/match";
import { styled } from "@material-ui/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

// styles
const SubMenuContainer = styled("div")({
  position: "absolute",
  display: "flex",
  flexDirection: "row",
  top: "48px",
  left: 0,
  width: "100%",
  height: 64,
  background: "white",
  padding: "0 56px",
  border: "1px solid #E0E0E0",
});

const Title = styled(`span`)({
  color: "black",
  fontSize: "20px",
  fontFamily: "arial",
  fontWeight: "normal",
  margin: "16px 16px 16px 0",
  flexShrink: 0,
});

const Separator = styled("div")({
  height: "100%",
  background: "#E0E0E0",
  width: "1px"
});

const TabsList = styled(Tabs)({
  margin: "0px 16px",
  textAlign: "center",
  "& *:not(.MuiTabs-indicator)": {
    height: "100%"
  }
});

const SubMenuTab = styled(Tab)({
  height: "100%",
  cursor: "pointer",
  color: "rgba(0, 0, 0, .54)",
  padding: "0 16px",
  minWidth: "156px",
  position: "relative",
  textTransform: "uppercase",
});

export const SubMenu = ({ data, title, onClick, style }) => {
  document.title = title + ' - FCS ' + I18n.t('crm')
  return (
    <SubMenuContainer>
      <Title>{title}</Title>
      {data.length ? <Separator /> : null}
      <Match>
        {({ path }) => {
          const arr = path.split("/");
          const val = data.findIndex((row) => row.id === arr[arr.length - 1]);
          return (
            <TabsList
              value={val}
              variant="scrollable"
              indicatorColor="primary"
              scrollButtons={data?.length !== 0 ? "desktop" : "off"}
            >
              {data?.map(({ id, text }, i) => (
                <SubMenuTab
                  label={I18n.t(text)}
                  onClick={() => onClick(id)}
                />)
              )}
            </TabsList>
          )
        }}
      </Match>
    </SubMenuContainer>
  );
}