/** @jsx h */
import { h } from "preact";
import I18n from "i18n-js";
import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import DetailIcon from "@material-ui/icons/Description"
import DeleteIcon from "@material-ui/icons/Delete";
import { Table } from "components/atoms/table/Table";

const useStyles = makeStyles(() => ({
  icon: {
    cursor: "pointer",
  },
}));

const columns = [
  { id: "uploadDate", sortable: true },
  { id: "customerName", sortable: true },
  { id: "company", sortable: true },
  { id: "exhibition", sortable: true },
  { id: "clientType", sortable: true },
  { id: "uploader", sortable: true },
  { id: "Edit", sortable: false },
];

const Row = ({ row, index, border, onClick }) => {
  const classes = useStyles();

  return (
    <TableRow
      key={row.id}
      align="center"
      style={{
        backgroundColor: index % 2 === 0 ? "#fafafa" : undefined,
      }}
    >
      {columns
        .filter(({ sortable }) => sortable)
        .map(({ id }) => (
          <TableCell
            key={id}
            style={{
              padding: 16,
              color: row[`${id}_color`],
              textTransform: row[`${id}_textTransform`],
              border,
            }}
          >
            {id === 'clientType' 
              ? row[id] === true ? I18n.t('client_type_2') : I18n.t('client_type_1') 
              : row[id]
            }
          </TableCell>
        ))}
      <TableCell style={{ padding: 16, border }}>
        <DetailIcon
          className={classes.icon}
          color="primary"
          style={{ fontSize: '1.6rem' }}
          onClick={() => onClick('detail', row)}
        />
        <DeleteIcon
          className={classes.icon}
          color="secondary"
          style={{ fontSize: '1.6rem' }}
          onClick={() => onClick('delete', row)}
        />
      </TableCell>
    </TableRow>
  );
};

export const CardTable = ({ data = [], pageResetKey, onClick = () => { } }) => {
  return (
    <Table data={data} columns={columns} isFetched={true} pageResetKey={pageResetKey}>
      <Row onClick={onClick} />
    </Table>
  );
};