/** @jsx h */
import { h } from "preact";
import { useContext } from "preact/hooks";
import I18n from "i18n-js";
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import Button from 'components/atoms/cubeButton/CubeBtn';
import { CardCarousel, CardInfo } from "components/molecules/cardInfo/CardInfo";
import { useCardDetail } from "hooks";
import { putBusinessCard } from "api";
import { errHandler } from "../../util";
import Context from "context";

const ALL_COLUMNS = ['id_client_branch', 'id_client_contact', 'customerName', 'company', 'phone', 'cell_phone', 'email', 'fax', 'website', 'address', 'is_visited', 'visit_purpose', 'opportunity', 'remark'];
const REQUIRED_COLUMNS = ['opportunity', 'visit_purpose', 'exhibition', 'customerName', 'company', 'email', 'phone', 'cell_phone', 'continent', 'country', 'attribute', 'firstIndustryLevel'];
const CardDetail = ({ route, data = {}, goBack, reload }) => {
  const { setError } = useContext(Context);
  const { cardData, setCardData, isEdit, setIsEdit, options, error: errorCols, setError: setErrorCols } = useCardDetail(data);

  const onChange = ({ key, value }) => {
    if (key.match(/_\d$/)) {
        const [_key, i] = key.split('_');
        let newData = cardData[_key];
        newData[i] = value;
        setCardData({ ...cardData, [_key]: newData });
        return;
    }
    setCardData({ ...cardData, [key]: value });
  }

  const doEdit = () => {
    setIsEdit(true);
  }

  const isColumnValid = (data) => {
    if (!data) return false;
    if (Array.isArray(data) && !data.filter(Boolean).length) {
      return false;
    }
    if (typeof data === 'string' && !data.trim()) {
      return false;
    }
    return true;
  }

  const checkRequiredColumns = (data) => {
    const failed = REQUIRED_COLUMNS.filter(key => {
      if (key === 'attribute' && cardData.attribute === '其他') {
        return !isColumnValid(cardData.customAttribute);
      }
      return !isColumnValid(data[key])
    });

    if (failed.length) {
      setErrorCols(failed.reduce((acc, key) => ({ ...acc, [key]: true }), {}));
      return false;
    }

    setErrorCols({});
    return true;
  }

  const doSave = () => {
    let payload = {
      ...Object.fromEntries(ALL_COLUMNS.map(key => [key, cardData[key]])),
      transform_to_official: false,
      id_second_industry_level: cardData.secondBusinessLevel,
      id_land: cardData.continent,
      id_country: cardData.country,
      exhibition: cardData.exhibition,
      type: cardData.attribute,
      is_new: cardData.clientType,
    }

    // check required
    const pass = checkRequiredColumns(cardData);
    if (!pass) {
      setError(I18n.t("FIELD_REQUIRED"));
      return;
    }

    putBusinessCard(cardData.id, payload)
      .then(() => {
        setIsEdit(false);
        reload();
      })
      .catch(err => errHandler(err, setError, route));
  }

  return (
    <div>
      <ToolBar
        title={I18n.t("CardInformation", { name: data?.customerName })}
        back={goBack}
        isEdit={isEdit}
        doEdit={doEdit}
        doSave={doSave}
      />
      <Grid container spacing={3} style={{ marginTop: '16px' }}>
        <Grid item xs={12} className="d-flex jc-center">
          <CardCarousel image={cardData?.image} />
        </Grid>
        <CardInfo
          data={cardData}
          isEdit={isEdit}
          options={options}
          errorCols={errorCols}
          onChange={onChange}
        />
      </Grid>
    </div>
  );
}

const ToolBar = ({ title, back, isEdit, doEdit, doSave }) => {
  return (
    <div className="d-flex jc-space-btw">
      <div className="d-flex al-center">
        <Button iconType="back" onClick={back} />
        <div style={{ width: '24px' }} />
        <Typography variant="h5" color="inherit">{title}</Typography>
      </div>
      <div className="d-flex">
        {isEdit
          ? <Button
            bgColor="#F44336"
            textColor="white"
            iconType="save"
            text="Save"
            onClick={doSave}
          />
          : <Button
            bgColor="#2E65C9"
            textColor="white"
            iconType="edit"
            text="Edit"
            onClick={doEdit}
          />}
      </div>
    </div>
  )
}

export default CardDetail;