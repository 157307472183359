export default {
	Pagination: `%{from}-%{to} of %{count}`,
	Action: "Action",

	//Login
	Login: "Login",
	email: "email",
	password: "password",
	employNum: "employee number",
	RememberMe: "remember me",
	SALESMAN_BLOCKING: "Permission denied: Salesman is not allow to access pages",

	// Home
	Language: "Language",
	Logout: "Logout",
	Employee: "Employee",
	LatestReports: "Latest Reports",
	Sales: "Sales",
	CollapseMenu: "Hide",
	Dashboard: "Dashboard",
	crm: "CRM",
	syncErp: "Synchronize ERP",
	syncClient: "Synchronize Clients",

	//Punch In/Out Record
	ByEmployee: 'Employee Overview',
	Employees: 'employees',
	PunchRecord: "Punch record",
	startOfDay: "The beginning of the day",
	endOfDay: 'End of the day',
	DayView: 'Day',
	WeekView: 'Weekly',
	Now: 'now',
	at: "at",
	noPunch: "No punch record",
	mapModalTitle: "From %{start} to %{end}",
	visitModalTitleLocation: "Visit %{location}",
	from: 'from ',
	visitModalTitleTime: "%{start} to %{end}",
	abnormalBehavior: "Unusual check-in",
	reason: 'Reason for visit',
	empty: 'None',
	upcoming: 'not started',
	working: 'Executing',
	alreadyLeft: 'already left',
	noPunchRecord: 'No punch record',
	'ratingText_1': 'Opportunity to purchase is slim',
	'ratingText_2': 'Low development potential, low purchase intention',
	'ratingText_3': 'Mediocre development, unclear purchase intention, please visit again at another time',
	'ratingText_4': 'Good interaction, high purchase intention, strengthen interaction',
	'ratingText_5': 'There is a possibility of purchasing a machine in the near future',
	quotationChance: 'Quotation opportunity',
	yes: 'Yes',
	no: 'no',
	'Routine visiting': 'routine visit',
	'Quotation negotiating': 'Order negotiation',
	'Bill collecting': 'Bill collecting',
	'Customer service': 'Customer processing',
	'Exchange activities': 'Industrial activities',
	StartOfDay: 'Start of the day',

	// Dashboard
	monthlySales: "Monthly Sales",
	salesFromLastMonth: "Sales from last month",
	reportsRatings: "report ratings",
	totalReports: "Total of reports",
	averageRating: "Average rating",
	quotationsEfficiency: "Quotation Efficiency",
	quotationsRateAbove: "Quotation rate A and above",
	totalPaid: "Total payment",
	paymentLeft: "balance payment",
	late: "late",
	sales: "Sales figures",
	ratio: "ratio",
	salesmenEfficiencyRankAll: "Salesmen Efficiency Ranking (All)",
	name: "name",
	quotations: "Quotation",
	machinesBestSellerRank: "Best Machine Ranking (Quantity)",
	latestUpdate: "latest status",
	ranking: "ranking",
	progress: "Description",
	clients: "clients",

	//Sales
	TransactionRate: "Potential orders",
	MonthlyTrend: "Monthly Trend",
	MonthlyStatistics: "Statistics of new messages",
	Prediction: "Potential order amount statistics",
	SubmenuStatistics: "Statistics of new messages",
	NewOverview: "Message Overview",
	Plan: "Estimated order receiving plan for the machine",
	OrderInfo: "Order information table",
	Goal: "goal setting",
	Export: "Export",
	totalValue: 'Total',
	expect_buy_date: 'expected purchase date',
	clientName: 'Client',
	Download: "Download",

	// Sales > Message Overview
	regionManager: "region manager",
	country: "country",
	updateProgess: "Update progress",
	updateDate: "update date",
	unitPrice: "Unit price (NTD)",
	subTotalPrice: "Subtotal (NTD)",
	quotationMachines: "Quotation Machines",
	source: "source",
	expectProduct: "Expect product",
	paymentMethod: "payment method",
	actualPrice: "Quotation (price list)",
	factors: "Key factors or bottlenecks for receiving orders",
	factory: "factory",
	machineQuantity: "Total %{quantity} types",
	percentagesLower: "Reserve Price",
	transactionMonth: "Transaction Month",
	currency_symbol: "Currency",
	exchange_rate: "Exchange rate",
	exchange_negotiation_price: "Original price(exchange rate)",
	id_quotation: "Quotation number",

	// Sales > Objective(Goal)
	year: 'year',
	currentMonth: 'current month',
	month: 'month',
	unit: 'unit',
	thousandDollar: 'Thousand (NTD)',
	area: 'area',
	percentage: 'achievement rate',
	firstHalf: '%{year} first half',
	secondHalf: '%{year} second half',

	//LatestReportsOverview
	report_number: "Order number",
	salesman: "salesman",
	client: "client",
	branch: "subsidiary",
	deal: "Quoted",
	noDeal: "Not quoted",
	date: "date",
	rate: "transaction",
	dealStatus: "Quote status",
	reportType: "category",

	//LatestReportsReport
	stars: "star",
	seeQuotation: "check quotation",
	byUser: "Participants",
	details: "Details",
	actualMachines: "machine",
	machinetype: "machine type",
	brand: "brand",
	quantity: "quantity",
	competitors: "competitors",
	overall: "summary",
	dateOfCreation: "Created time",
	challengeToSell: "Challenges",
	dealIssue: "Key factors or bottlenecks",
	seeNote: "Notes",
	to: "to",
	image: "images",
	'Phone': 'Phone',
  'Normal': 'Face to face',
  'clientType': 'Client type',
  'Old': 'Previous buyer',
  'New': 'New buyer',
  'factor': 'factor',
  'Price': 'Price',
  'Connections': 'Connections',
  'Quality': 'Quality',
  'Reputation': 'Reputation',
  'FactorySpace': 'Factory space',
  'Service': 'Service',
  'Specification': 'Specification',
  'Special process': 'Special Process',
  'Self-developed': 'Self-developed',
  'Internet platform': 'Internet platform',
  'Distributors': 'Distributors',
  'Industry introduction': 'Industry introduction',
  'Others': 'Others',
	'Taiwan machine': 'Taiwan machine',
	'China machine': 'China machine',

	//Client
	Client: "Customer Management",
	Group: "parent company",
	Branch: "subsidiary",
	seeMore: "More",
	seeGroup: "Check parent company",
	bestSeller: "Best Products",
	yearStatistic: "Annual Statistics",
	monthStatistic: "monthly statistics",
	contactsList: "Contact List",
	firstIndustryLevel: "Industry I",
	secondBusinessLevel: "Industry II",

	competitor: "competitor",
	clientType: "Client Type",
	associatedGroup: "parent company",
	optional: "optional",
	companyName: "Company Name",
	industry: "industry",
	sizeOfCompany: "Company size",
	ceo: "CEO",
	revenue: "Revenue",
	machine: "machine",
	machines: "machine",
	contact: "contact person",
	contacts: "Contact person",
	topFifty: 'top 50',
	VisitCounts: "Number of visits",
	QuotationCounts: "Number of quotes",
	SuccessfulQuotations: "Completed Quote",
	rank: 'Ranking',

	//Business Card
	uploader: "uploader",
	uploadDate: "Uploaded Date",
	uploaderRegion: "Uploader Region",
	CardList: "Business Card List",
	CardInformation: "%{name}'s business card",
	CommonInformation: "Basic",
	DetailInformation: "Detail",
	address: "address",
	companyAddress: "Address",
	fax: "fax",
	website: "website",
	attribute: "dealer type",
	cardFront: "Front",
	cardBack: "Backside",
	attribute_1: "Manufacturer",
	attribute_2: "Trader",
	attribute_3: "Agent",
	attribute_4: "Other",
	opportunity_1: "A (Deal)",
	opportunity_2: "B (Pending)",
	opportunity_3: "C (Efforts)",
	opportunity_4: "D (Familiar)",
	opportunity_5: "E (Archive)",

	// Employee
	EmployeeList: "Employee List",
	username: "employee name",
	phone: "phone number",
	group: "group",
	group_role: "Group/Position",
	Overview: "Overview",
	TeamsManagement: "Team Management",
	RegionsManagement: "Region Management",
	users: "member",
	transport: "Transport type",
	announcement: "Message announcement",
	Team: "group",
	TeamName: "Team Name",
	Division: "department",
	Salesman: "Salesman",
	AreaCode: "area code",
	ZipCode: "Zip code",
	City: "city",
	Country: "township",
	WorkPosition: "Position",
	EmployeeRole: "identity",
	ManagingRegion: "Management Region",
	ChangePic: "Change avatar",
	Visit: "visit",
	GroupName: "Parent Company",
	BranchName: "Subsidiary",
	AvaliableTime: "appointment time",
	VisitReason: "Reason for visit (optional)",
	VisitReasonPlaceholder: "Fill in the reason",
	IDERP: "employee number",
	ERPSymbol: "System code",

	//Employee profile
	UserProfile: "Profile",
	Information: "Basic information",
	FirstName: "name",
	LastName: "Last Name",
	FullName: "Name",
	Address: "address",

	JoinedDate: "joined date",
	Experience: "Seniority",
	NextVisits: "Visiting soon",
	Statistics: "statistics data",
	SalesHistory: "Sales Records",
	LastReports: "Latest Reports",
	// Employee profile modals
	SCHEDULE_DELETED: "Access deleted",
	SCHEDULE_CREATED: "The visit has been created",
	SCHEDULE_UPDATED: "The visit has been updated",
	START_TIME_AFTER_END_TIME: "The end time cannot be earlier than the starting time",
	MISSING_BRANCH: "Please fill up a subsidiary name",
	MISSING_TIME: "Please fill up a starting time",

	// OrderInfo
	MonthObjective: "%{month} order target",
	Objective: "%{quater} target",
	Accomplish: "Achievement rate:",

	// OrderDetails
	order: "Order %{value}",
	quotation: "quotation",
	totalPaid: "Amount paid",
	paymentLeft: "Balance payment",
	actualDiff: "Spread",
	dealRate: "deal rate",
	paid: "Amount paid",
	total: "total amount",
	originalPrice: "original price",
	expectedProduct: "desired product",
	sources: "sources",
	priceCondition: "price condition",
	paymentMethod: "payment method",
	expectedBuyingDate: "Estimated purchase date",
	expectedOrderMonth: "expected purchase month",
	createdDate: "Created date",
	noteForOption: 'note of %{optionId}',
	additionalOptions: 'Additional',

	// Sources
	'Self-developed': 'Self-developed',
	'Internet platform': 'Internet platform',
	Distributors: 'distributor',
	'Industry introduction': 'Industry introduction',
	Exhibition: 'Exhibition',
	Others: 'Others',

	// Factors
	PRICE: 'price',
	CONNECTIONS: 'Contacts',
	QUALITY: 'Quality',
	REPUTATION: 'Reputation',
	SERVICE: 'Service',
	SPECIFICATION: 'Specification',
	'SPECIAL PROCESS': 'Special process',

	//Gauge
	target: "target",
	EST: "Total amount quoted",

	//User
	UploadA: "Upload %{name}",
	CreateA: "Add %{name}",
	AddA: "Add %{name}",
	AddAn: "Add %{name}",
	EditA: "Edit %{name}",
	EditAn: "Edit %{name}",
	SelectA: "Please select %{name}",
	SelectAn: "Please select %{name}",
	InsertA: "Please enter %{name}",
	InsertAn: "Please enter %{name}",
	InsertThe: "Please enter %{name}",
	Create: "New",
	Cancel: "cancel",
	Confirm: "confirm",
	DeleteConfirmTitle: "Delete",
	DeleteConfirm: "Confirm to delete",
	Delete: "delete",
	DisableConfirmAccount: 'The account cannot be restarted after it is deactivated,',
	DisableConfirmTitle: "Disable",
	DisableConfirm: "Confirm to disable",
	Disable: "disable",
	RowsPerPage: "Rows per page:",
	Questionmark: "?",
	All: "All%{name}",
	SeeDetails: "Show more",
	Edit: "edit",
	EditThe: "Edit %{name}",
	Region: "region",
	RegionName: "Region Name",
	region: "region",
	allRegions: "All regions",
	regionAddType: "category",
	allGroups: "All groups",
	N: "North District",
	C: "Central District",
	S: "Southern District",
	Taiwan: "Taiwan District",
	International: "International",
	Nation: 'Country',

	TOKEN_NOT_FOUND: "The link has expired, please resend the email",

	// Orderdetails > form
	machineModel: "machine model",
	totalPrice: "Total amount",
	productManufactured: "Business Item",
	others: "others",
	thirtyPercentDeposit: "30% deposit",
	notesOptional: "Notes (optional)",
	inputYourNote: "Enter your notes",
	saveChanges: "Save",
	othersCompetitors: "Other Competitor",

	//HTTP status texts
	Successful: "success",
	BadRequest: "Bad request",

	//Sales > Quotations > Orders
	orderNum: "Order number",
	price: "price",
	status: "status",
	allStatus: 'all status',
	closed: 'Closed',
	notClosed: 'Open case',
	ErrSelectedTimeOverlap: "The entered time cannot exceed the current date",
	clientDeleted: "[This client was no longer exists]",
	sum: "total",
	cost: "Cost",

	//WeeklyStatistics
	total: 'total number',
	StatisticSalesman: "Business",
	StatisticTotal: "Total number of messages",
	avgTransactions: 'Average quote',
	averageWeeklyTotal: 'Average number of transactions per week',
	fromTo: 'from %{start} to %{end}',
	quotationCount: 'Total number of quotations',
	reportCounts: 'Total number of reports',
	quotationPrice: 'Total quotation amount',

	//Latest Reports
	Salesreport: "Visit Report",
	Quotation: "Quotation",
	SeeQuotations: "Check quotation",

	//Sales > ABC Rate
	totalA: "Total number of ratings above A",
	bySalesman: "By business",
	comparePreviousWeek: "Compare with last week",

	// Order Status
	filterBy: 'According to %{text}',
	department: 'department',
	objective: 'objective',
	objectivePrice: 'Target amount (NTD)',
	expectedPrice: 'Estimated amount (NTD)',
	accomplish: "achievement rate",
	accomplish2: "Achievement rate", // need this for same header in one table
	paidAmount: 'Amount of order placed',
	unpaidAmount: 'Amount of unplaced order',
	total: 'total number',

	// Sales > Accomplish
	AccomplishReports: 'Summary table of order delivery amount',
	Type: 'type',
	monthAccumulate: 'Monthly',
	yearAccumulate: 'Annual',
	actual: 'actual performance',
	salesIn: 'Receive order',
	salesOut: 'Accomplish',

	// Competitors
	CompetitorList: 'Competitor List',
	ScaleCompare: 'Specification comparison table',
	competitorTitle: "Competitor List",
	competitorName: "Competitor Name",
	machineType: "machine type",
	addMachine: "Add a new machine",
	editMachine: "Edit Machine",
	colorType: 'Single/Multiple Color',
	series: 'series',
	machineTypeAndPound: 'machine type/ton',
	pounds: 'tons',
	pound: 'tons',
	addCompare: 'Add comparison table',
	addedToCompare: 'Added to comparison table',
	'INJECTION UNIT': 'Injection unit',
	'MOULD CLAMPING UNIT': 'Clamping unit',
	'ELECTRICAL EQUIOMENT': 'Electrical unit',
	'OTHERS': 'Others',
	DATA_TYPE: 'type',
	CLAMPING_NAME: 'Clamping model',
	POWER_NAME: 'Drive type',
	INDUSTRY_NAME: 'Application Industry',
	DRIVE_NAME: 'Drive type',
	'SCREW DIAMETER': 'Screw diameter',
	'SCREW STROKE': 'Injection stroke',
	'THEORETICAL SHOT VOLUME': 'Theoretical injection volume',
	'SHOT WEIGHT OF INJECTION (PS)': 'Theoretical injection volume',
	'INJECTION PRESSURE': 'Injection pressure',
	'INJECTION SPEED': 'Injection speed',
	'INJECTION RATE': 'Injection rate',
	'PLASTICIZING CAPACITY(PS)': 'Glue amount',
	'SCREW ROTATION SPEED': 'Screw speed',
	'MOULD CLAMPING FORCE': 'Closing force',
	'MOULD CLAMPING STROKES': 'Clamping stroke',
	'MOULD THICKNESS': 'MOULD THICKNESS',
	'SUGGESTED MINIMUM MOLD DIM (H×V)': 'Suggested minimum mold size',
	'DISTANCE BETWEEN TIE BAR': 'Inner distance between large columns',
	'MOULD PLATEN': 'Mold plate size',
	'EJECTOR STROKE': 'ejector stroke',
	'EJECTOR FORCE': 'ejector force',
	'PUMP DRIVING MOTOR': 'Motor motor',
	'TEMPERTURE CONTROLLER': 'Temperature controller',
	'Heater capacity': 'Electric heating capacity',
	'MACHINE DIMENSION (L×W×H)': 'Mechanical dimensions',
	'OIL TANK CAPACITY': 'Oil tank capacity',
	'MACHINE WEIGHT': 'Mechanical weight',
	'Max system pressure': 'Maximum system pressure',

	// Announcement
	Announcement: 'Announcement',
	SelectBroadcastRecipient: 'Select the broadcast object',
	SelectBroadcaseLastDay: "Select the broadcast day",
	AnnouncementPlaceholder: 'Please enter your announcement content...',
	Announce: 'announce',
	all: 'all',
	day: "%{number}day",
	days: "%{number}days",
	MISSING_ANNOUNCEMENT_CONTENT: "You did not enter the announcement content",
	FILE_SIZE_OVER_LIMIT: "File size exceeds limit (50MB)",
	FILE_TYPE_NOT_ACCEPTED: "Supported file types: .HEIC .jpeg .jpg .png .gif .svg .pdf .doc .docx .csv .xls .xlsx .ppt .pptx .txt .zip .odp",
	FILE_NUM_OVER_LIMIT: "The number of files exceeds the limit (5)",
	AnnouncementDate: "Announcement date",
	AnnouncementContent: "Announcement content",
	File: "File",

	//Form Validation
	required: "required",
	notEmail: "Incorrect email",
	atleastNineDigits: "At least nine digits",
	notNumber: "Must be pure number",

	YearObjective: 'Year Objective',
	SetYearObjective: 'Set annual goals',
	dollar: 'dollars',
	tenthousand: '10K',
	hundredmillion: '100M',

	//machine
	equipment: 'equipment',
	compare: 'difference',
	machineSeries: 'machine series',
	originalprice: 'original price',
	additionalCharge: 'additional charge',
	standard: 'standard configuration',
	option: 'optional',
	free: 'free',
	none: 'None',
	options: 'optional',
	import: 'import',
	category: 'category',
	statement: 'description',
	machineName: 'machine name',
	seeOptions: "View options",
	optionsFor: 'Options for %{name}',
	data: 'data',
	or: 'or',
	noOption: 'No option',
	optionsForSomeMachines: 'Options for %{count} of them',
	equipment_A: 'Standard',
	equipment_B: 'Optional',
	equipment_C: 'None',

	//BusinessCardManagement
	BusinessCardManagement: 'Business Card Management',
	ExhibitionList: 'Exhibition List',
	BusinessCardList: 'Business Card List',
	offline: 'offline',
	online: 'online',
	exhibitionName: 'Exhibition name',
	land: 'area',
	start_date: 'Start date',
	end_date: 'end date',
	meetingType: 'Purpose of the visit',
	opportunity: 'customer level',
	customerName: 'customer name',
	company: 'company name',
	exhibition: 'Message Category',
	cell_phone: 'mobile phone',
	visit_purpose: 'Visit reason',
	remark: 'note',
	continent: 'Continent',
	Synchronized: "synchronized",

	// Abbreviation
	challenge_1: 'Reason 1',
	challenge_2: 'Reason 2',
	client_type_1: 'old client',
	client_type_2: 'New client',
	factor_1: 'price',
	factor_2: 'reputation',
	factor_3: 'factory size',
	factor_4: 'service',
	machine_option_1: "Standard configuration",
	machine_option_2: "Optional",
	machine_option_3: "Free",
	machine_option_4: "None",
	meeting_type_1: 'Conference call',
	meeting_type_2: 'Gerneral meeting',

	//HTTP status texts
	Successful: "success",
	BadRequest: "Bad request",

	//API CALL SUCCESS MESSAGES
	requestSuccessful: "%{name} has been successful%{verb}!",

	//SUCCESS MESSAGE NOUNS
	user: "member",
	team: "group",
	ROLE_BIGBOSS: "BIGBOSS",
	ROLE_MANAGER: "Manager",
	ROLE_LEADER: "Leader",
	ROLE_SALES: "Salesman",

	//SUCCESS MESSAGE VERBS
	created: "create",
	sent: "send",
	updated: "update",
	deleted: "remove",
	disabled: "disable",

	//ERRORS
	//ERROR TYPES
	Invalid: "The %{name} you entered is incorrect",
	NOT_FOUND: "The %{name} does not exist",
	ALREADY_FOUND: "The %{name} already exists",
	WRONG_PASSWORD: "Wrong Password",

	//ERROR ROUTES
	ROUTE_USER: "user",
	ROUTE_CLIENT: "Company",
	ROUTE_GROUP: "Group",
	ROUTE_CLIENT_BRANCH_MACHINE: "Factory Machine",
	ROUTE_CLIENT_BRANCH: "Subsidiary",
	ROUTE_CLIENT_CONTACT: "Contact",
	ROUTE_COMPETITOR_MACHINE: "Competitor Machine",
	ROUTE_COMPETITOR: "Competitor",
	ROUTE_GPS: "GPS",
	ROUTE_IMAGE: "image",
	ROUTE_NOTIFICATION: "Notification",
	ROUTE_PUNCH: "Punch Record",
	ROUTE_QUOTATION: "Quote",
	ROUTE_REGION: "Region",
	ROUTE_REPORT: "Report",
	ROUTE_ROLE: "identity",
	ROUTE_SCHEDULE: "Visit",
	ROUTE_TEMPLATE: "template",
	ROUTE_TRIP_TYPE: "Traffic Mode",

	//ERROR FIELDS
	FIELD_email: "email",
	FIELD_address: "address",
	FIELD_zip_code: "Postal code",
	FIELD_city: "city",
	FIELD_id_land: "State",
	FIELD_id_country: "Country",
	FIELD_type: "Dealer Type",
	FIELD_custom_type: "Other attributes",
	FIELD_REQUIRED: "Please fill in all required fields",

	//ERROR PAGES
	goBack: "Go back to the previous page",
	errorCode: "%{code}Error",
	//400
	badRequest: "Bad request",
	badRequestDesc: "You sent an incorrect request",
	description: "narration",
	//401
	unauthorizedAccess: "Unauthorized",
	unauthorizedAccessDesc: "You could not authenticate a request.",
	pleaseVerify: "Please re-verify your token and try again.",
	//403
	noPermission: "You do not have permission to access this page",
	//404
	notFound: "Sorry! The page you are looking for does not exist",
	notFoundListTitle: "The possible reasons for this problem:",
	notFoundList1: "We had some technical problems",
	notFoundList2: "This page have been removed",
	notFoundList3: "The URL was invalid.",
	notFoundList4: "You may have entered the wrong URL.",
	//500
	unexpectedError: "An unexpected error occurred",
	unexpectedErrorDesc: "Our tech team has been informed about this. Please try again later.",
	// map charts
	KLU: "Keelung City",
	TPH: "New Taipei City",
	TPE: "Taipei City",
	TYC: "Taoyuan City",
	HSH: "Hsinchu County",
	HSC: "Hsinchu City",
	MAL: "Miaoli County",
	TXG: "Taichung City",
	CWH: "Changhua County",
	NTO: "Nantou County",
	YLH: "Yunlin County",
	CHY: "Chiayi County",
	CHI: "Chiayi City",
	TNN: "Tainan City",
	KHH: "Kaohsiung City",
	IUH: "Pingtung County",
	ILN: "Yilan County",
	HWA: "Hualien County",
	TTT: "Taitung County",
	PEH: "Penghu County",
	KIN: "Kinmen County",
}