import { useState, useEffect, useMemo, useContext, useCallback } from "preact/hooks";
import I18n from "i18n-js";
import { getLandList, getFirstIndustryLevel, getSecondIndustryLevel, getCountryList, getExhibitionList } from "api";
import Context from "context";

const CLIENT_TYPE = [
    {
        value: false,
        name: "client_type_1"
    },
    {
        value: true,
        name: "client_type_2"
    }
];

const ATTRIBUTE_LIST = [
    { value: "製造", name: "attribute_1" },
    { value: "貿易", name: "attribute_2" },
    { value: "代理", name: "attribute_3" },
    { value: "其他", name: "attribute_4" }
];

const OPPORTUNITY_LIST = [
    { value: "A (成交)", name: "opportunity_1" },
    { value: "B (待成交)", name: "opportunity_2" },
    { value: "C (爭取)", name: "opportunity_3" },
    { value: "D (培養)", name: "opportunity_4" },
    { value: "E (存檔)", name: "opportunity_5" }
];

const getOptions = ({ locale, countryList, cardData, landList, firstIndustryLevelList, secondIndustryLevelList, exhibitionList }) => {
    const filterByLandId = item => item.LAND_ID === cardData?.continent;
    const filterByFirstIndustryLevel = item => item.id_first_industry_level === cardData?.firstIndustryLevel;

    const countries = cardData?.continent ? countryList.filter(filterByLandId) : countryList;
    const secondBusinessLevel = cardData?.firstIndustryLevel
        ? secondIndustryLevelList.filter(filterByFirstIndustryLevel)
        : [];

    const formatLabel = ({ name_eng, name_ch }) => locale === 'en' ? name_eng : name_ch;
    const formatCommonOption = ({ value, name }) => ({
        value,
        label: I18n.t(name),
    });
    const formatExhibition = ({ SHOW_ENAME, SHOW_CNAME }) => ({
        value: locale === 'en' ? SHOW_ENAME : SHOW_CNAME,
        label: formatLabel({ name_eng: SHOW_ENAME, name_ch: SHOW_CNAME }),
    });
    const formatState = ({ LAND_ID, LAND_ENAME, LAND_NAME }) => ({
        value: LAND_ID,
        label: formatLabel({ name_eng: LAND_ENAME, name_ch: LAND_NAME }),
    });
    const formatCountry = ({ COUNTRY_ID, COUNTRY_ENAME, COUNTRY_NAME }) => ({
        value: COUNTRY_ID,
        label: formatLabel({ name_eng: COUNTRY_ENAME, name_ch: COUNTRY_NAME }),
    });
    const formatIndustryLevel = ({ id, industry_name_eng, industry_name_ch }) => ({
        value: id,
        label: formatLabel({ name_eng: industry_name_eng, name_ch: industry_name_ch }),
    });

    return {
        exhibition: exhibitionList.map(formatExhibition),
        clientType: CLIENT_TYPE.map(formatCommonOption),
        continent: landList.map(formatState),
        country: countries.map(formatCountry),
        attribute: ATTRIBUTE_LIST.map(formatCommonOption),
        firstIndustryLevel: firstIndustryLevelList.map(formatIndustryLevel),
        secondBusinessLevel: secondBusinessLevel.map(formatIndustryLevel),
        opportunity: OPPORTUNITY_LIST.map(formatCommonOption),
    };
};

export const useCardDetail = (data) => {
    const locale = localStorage.getItem('locale');
    const { landList, setLandList, firstIndustryLevelList, setFirstIndustryLevelList, secondIndustryLevelList, setSecondIndustryLevelList, countryList, setCountryList, exhibitionList, setExhibitionList } = useContext(Context);
    const [cardData, setCardData] = useState(data);
    const [isEdit, setIsEdit] = useState(false);
    const [error, setError] = useState(null);

    const options = useMemo(() =>
        getOptions({ locale, countryList, cardData, landList, firstIndustryLevelList, secondIndustryLevelList, exhibitionList })
    , [locale, countryList, cardData?.continent, cardData?.firstIndustryLevel, landList, firstIndustryLevelList, secondIndustryLevelList, exhibitionList]);

    const getLandListCallback = useCallback(async () => {
        if (!landList.length) {
            const res = await getLandList();
            setLandList(res.data);
        }
    }, [setLandList]);

    const getFirstIndustryLevelCallback = useCallback(async () => {
        if (!firstIndustryLevelList.length) {
            const res = await getFirstIndustryLevel();
            setFirstIndustryLevelList(res.data);
        }
    }, [setFirstIndustryLevelList]);

    const getSecondIndustryLevelCallback = useCallback(async () => {
        if (!secondIndustryLevelList.length) {
            const res = await getSecondIndustryLevel();
            setSecondIndustryLevelList(res.data);
        }
    }, [setSecondIndustryLevelList]);

    const getCountryListCallback = useCallback(async () => {
        if (!countryList.length) {
            const res = await getCountryList();
            setCountryList(res.data);
        }
    }, [setCountryList]);

    const getExhibitionListCallback = useCallback(async () => {
        if (!exhibitionList.length) {
            const res = await getExhibitionList();
            const sortedExhibitionList = res.data.sort((a, b) => {
                if (a.STATUS === 'online') return -1;
                if (a.STATUS === 'offline') return 1;
                return 0;
            });
            setExhibitionList(sortedExhibitionList);
        }
    }, [setExhibitionList]);

    useEffect(() => {
        getLandListCallback();
        getFirstIndustryLevelCallback();
        getSecondIndustryLevelCallback();
        getCountryListCallback();
        getExhibitionListCallback();
    }, []);

    return { cardData, setCardData, isEdit, setIsEdit, options, error, setError };
}

export default useCardDetail;