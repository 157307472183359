import I18n from 'i18n-js';

export function formatPrice(price) {
	return Number(price).toLocaleString();
}

export const formatOptions = (list = [], type) => {
	const allOption = { id: '', text: I18n.t("All", { name: I18n.t(type).toLowerCase() }) };
	const optionsList = [allOption, ...list.map((option) => {
        return {
            ...option,
            id: option.id,
            text: I18n.t(option.name),
        };
	})];
	return optionsList;
}

export const formatAddress = (data) => {
    const address = data?.address && data?.address !== '-' ? data.address : '';
    const city = data?.city && data?.city !== '-' ? data.city : '';
    const country = data?.country && data.country !== '-' ? data.country : '';
    const formattedAddress = `${address} ${city} ${country}`;
    return formattedAddress.length ? formattedAddress : '-';
}