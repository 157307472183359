import { makeStyles } from '@material-ui/core/styles'; 

const useStyles = makeStyles({
    dialogBg:{
        // position: 'absolute',
        height: '100%',
        // width: 'calc(100% - 57px)',
        // bottom: 0,
        // right: 0,
        backgroundColor: 'white',
        boxShadow: '0 5px 22px rgba(0,0,0,.14)',
        padding: '32px 32px 32px 52px',
        // zIndex: 1203,
        '& ::-webkit-scrollbar': {
            backgroundColor: 'transparent',
            width: 0
        },
        '& ::-webkit-scrollbar-track': {
            backgroundColor: 'transparent'
        }
    },
    head: {
        borderBottom: '1px solid #E0E0E0',
        padding: '11px 0',
        height: '57px',
        // marginBottom: '24px',
        '& span': {
            fontWeight: 'normal',
            fontFamily: "'Robot', 'arial'"
        }
    },
    body: {
        height: 'calc(100% - 57px + 32px)',
        padding:'16px 0',
        overflowY: 'scroll'
    },
    scrim: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        left: 0,
        top: 0,
        backgroundColor: 'transparent',
        paddingTop: '48px',
        // paddingLeft: '58px',
        zIndex: 6
    }
})
export default useStyles;