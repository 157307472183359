import jdenticon from 'jdenticon/dist/jdenticon'

export const Jdenticon = ({ value, size, config, style = {} }) => {
  const jdenticonSvg = jdenticon?.toSvg(value, size, config)

  const internalstyle = style.padding
    ? {
        width: size + 2 * style.padding,
      }
    : {
        width: size,
      }
  return (
    <div style={{ ...internalstyle, ...style }}>
      <img src={`data:image/svg+xml,${encodeURIComponent(jdenticonSvg)}`} />
    </div>
  )
}
