/** @jsx h */
import { h } from "preact";
import React from "react";
import { useState, useEffect } from "preact/hooks";
import I18n from "i18n-js";
import moment from "moment/min/moment-with-locales";

import Typography from '@material-ui/core/Typography';
import Timeline from '@material-ui/lab/Timeline';
import MuiTimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';

import 'rsuite/dist/rsuite.min.css';
import { DatePicker } from 'rsuite';

import CubeBtn from 'components/atoms/cubeButton/CubeBtn';
import { TimelineItem } from 'components/atoms/timelineItem/TimelineItem';
import { Row } from "components/atoms/row/Row";
import AbnormalList from 'components/molecules/abnormalList/AbnormalList';
import { useStyles } from './PunchTimeline.style';

import { Skeleton } from "@material-ui/lab";
import { zeroArray } from "../../../util";
import emptyIcon from "assets/empty.svg";
import { enUS, zhTW } from 'rsuite/locales';

export const PunchTimeline = ({
  data,
  timelineData,
  date,
  setDate,
  setModal,
  contentHeight,
  canDisplay,
  salesmanSelected,
  selectedTeam,
  salesmanList,
  scheduleList,
  abnormalClick,
}) => {
  const classes = useStyles();
  const [filteredTimeLineData, setFilteredTimeLineData] = useState([])
  const isToday = (someDate) => {
    return moment().isSame(someDate, 'd');
  }

  useEffect(() => {
    const nowIndex = timelineData.findIndex(object => {
      return object.type === 'now';
    })
    let filteredTimeLineData = timelineData.filter((el, i) => {
      return i <= nowIndex;
    })
    setFilteredTimeLineData( filteredTimeLineData )
  }, [timelineData])

  return (
    <div className={classes.root}>
      <Row
        justifyContent='center'
        alignItems='center'
        className={classes.pickerArea}
      >
        <CubeBtn
          iconType='arrow-back'
          bgColor="#F5F5F5"
          textColor="rgba(0, 0, 0, .54)"
          onClick={() => setDate(prev => {
            let temp = moment(prev);
            temp = moment(prev).subtract(1, "days");
            return moment(temp);
          })} />
        <DatePicker
          className={classes.datePicker}
          value={moment(date).toDate()}
          onChange={(e) => setDate(moment(e))}
          disabledDate={(d) => moment(d).isAfter(new Date())}
          format='yyyy-MM-dd'
          cleanable={false}
          oneTap
          locale={localStorage.getItem('locale') === 'en' ? enUS.DatePicker : zhTW.DatePicker}
        />
        <CubeBtn
          iconType='arrow-next'
          bgColor="#F5F5F5"
          textColor={`rgba(0, 0, 0, .${isToday(date) ? '1' : '54'})`}
          onClick={() => setDate(prev => {
            if (isToday(prev)) return prev;
            else {
              let temp = moment(prev);
              temp = moment(prev).add(1, "days");
              return moment(temp);
            }
          })}
        />
      </Row>
      {
        salesmanSelected ?
          !canDisplay ?
            <>
              <Row className={classes.teamLabel}>
                <Typography variant='body2' color="textSecondary">
                  {I18n.t("group")} : <span>{data.team}</span>
                </Typography>
                <Typography variant='body2' color="textSecondary">
                  {I18n.t("Salesman")} : <span>{data.salesman?.name}</span>
                </Typography>
              </Row>
              <div className={classes.container} style={{ height: contentHeight || 520 }}>
                <Timeline align="left" className={classes.timeLine}>
                  <MuiTimelineItem>
                    <TimelineSeparator>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography variant='body2' color="textSecondary">
                        00:00 {I18n.t("StartOfDay")}
                      </Typography>
                    </TimelineContent>
                  </MuiTimelineItem>
                  {zeroArray(7).map(() => (
                    <TimelineItem />
                  ))}
                </Timeline>
              </div>
            </>
            :
            <>
              <Row className={classes.teamLabel}>
                <Typography variant='body2' color="textSecondary">
                  {I18n.t("group")} : <span>{data.team}</span>
                </Typography>
                <Typography variant='body2' color="textSecondary">
                  {I18n.t("Salesman")} : <span>{data.salesman?.name}</span>
                </Typography>
              </Row>
              {
                filteredTimeLineData.length !== 0 ?
                  <div className={classes.container} style={{ height: contentHeight || 520 }}>
                    <Timeline align="left" className={classes.timeLine}>
                      <MuiTimelineItem>
                        <TimelineSeparator>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                          <Typography variant='body2' color="textSecondary">
                            {filteredTimeLineData[0].dayStart} {I18n.t("StartOfDay")}
                          </Typography>
                        </TimelineContent>
                      </MuiTimelineItem>
                      {filteredTimeLineData?.map((row, index) => (
                        <TimelineItem key={index} data={row} onClick={() => setModal({
                          name: 'visit',
                          data: { ...row, salesman: data.salesman.name, team: data.team }
                        })} />
                      ))}
                    </Timeline>
                  </div>
                  :
                  <div className={classes.noData}>
                    <img src={emptyIcon} />
                  </div>
              }
            </>
          :
          <AbnormalList selectedTeam={selectedTeam} salesmanList={salesmanList} scheduleList={scheduleList} onClick={abnormalClick} />
      }
    </div>
  );
}
