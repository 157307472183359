/** @jsx h */
import { h } from "preact";
import { forwardRef } from "preact/compat";

export const Row = forwardRef(
  ({ justifyContent, alignItems, flex, style, ...props }, ref) => {
    return (
      <div
        {...props}
        style={{
          ...style,
          display: "flex",
          justifyContent,
          alignItems,
          flex,
        }}
        ref={ref}
      />
    );
  }
);
