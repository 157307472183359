/** @jsx h */
import { h } from "preact";
import { useState, useContext, useEffect } from "preact/hooks";

import Typography from "@material-ui/core/Typography";

import { DeleteModal } from "components/molecules/deleteModal/DeleteModal";
import { Row } from "components/atoms/row/Row";
import { RegionTable } from "components/molecules/regionTable/RegionTable";
import CubeBtn from "components/atoms/cubeButton/CubeBtn";
import { AddButton } from "components/atoms/addButton/AddButton";
import Modal from "components/atoms/modal/Modal";
import RegionInfo from "components/organisms/modalContent/region/RegionInfo";
import I18n from "i18n/i18n";
import { putGroup, deleteGroup, postGroup, putRegion, deleteRegion, postRegion, getDivision } from "api";
import { useStyles } from "style/style";
import context from "context";

// context snackbar error handling
import {  errHandler } from "../../util";
import { route } from "preact-router";

const formatGroups = async (groups) => {
  return groups.map((group) => ({
    created_at: group.created_at,
    updated_at: group.updated_at,
    id: group.id,
    name: group.name,
    id_region: group.id_region,
  }));
}

const formatRegions = async (regions, groups) => {
  return regions.map(region => {
    const regionGroups = groups.filter(group => group.id_region === region.id);
    return {
      created_at: region.created_at,
      updated_at: region.updated_at,
      id: region.id,
      name: region.name,
      children: regionGroups,
      area: regionGroups.length
    }
  });
}
const Region = () => {
  const classes = useStyles();
  const { divisionList, regionList: regions, groupList: groups, setDivisionList, setError, setMessage } = useContext(context);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isFetched, setFetched] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState({});
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    loadData();
  }, [refresh]);

  const loadData = async () => {
    try {
      const divisions = await getDivision();
      setDivisionList(divisions.data);
      const formattedGroups = await formatGroups(groups);
      const formattedRegions = await formatRegions(regions, formattedGroups);
      setData(formattedRegions);
      setFetched(true);
    } catch (err) {
      errHandler(err, setError, route);
      setFetched(true);
    }
  }

  const handleOpen = (modal, select = { type: 0, id_region: undefined }) => {
    setSelected(select);
    setModal(modal);
  };

  const handleClose = () => {
    setSelected({});
    setLoading(false);
    setModal(false);
  };

  const addRegion = (val) => {
    setLoading(true);
    // type: 0 => region, 1 => group
    if (val.type === 0) {
      postRegion({ name: val.name, id_division: val.id_division })
        .then(() => {
          setMessage(I18n.t("requestSuccessful", { name: I18n.t("region"), verb: I18n.t("created") }));
          setLoading(false);
          setRefresh(!refresh);
          handleClose();
        })
        .catch((err) => {
          setLoading(false);
          errHandler(err, setError, route);
        });
    } else {
      postGroup({
        name: val.name,
        id_region: val.id_region,
        id_user_list: [],
      })
        .then(() => {
          setMessage(I18n.t("requestSuccessful", { name: I18n.t("team"), verb: I18n.t("created") }));
          setLoading(false);
          setRefresh(!refresh);
          handleClose();
        })
        .catch((err) => {
          setLoading(false);
          errHandler(err, setError, route);
        });
    }
  }

  const updateInfo = (val) => {
    setLoading(true);
    // Region > Group
    const key = !!val.id_region ? "group" : "region";
    const obj = { group: putGroup, region: putRegion };
    obj[key](val.id, { name: val.name })
      .then(() => {
        setLoading(false);
        setRefresh(!refresh);
        setMessage(I18n.t("requestSuccessful", { name: I18n.t(key), verb: I18n.t("updated") }));
        handleClose();
      })
      .catch((err) => {
        errHandler(err, setError, route)
        setLoading(false);
      });
  }

  const handleDelete = () => {
    const key = !!selected.id_region ? "group" : "region";
    const obj = { group: deleteGroup, region: deleteRegion };
    obj[key](selected.id)
      .then(() => {
        setRefresh(!refresh);
        setMessage(I18n.t("requestSuccessful", { name: I18n.t(key), verb: I18n.t("deleted") }));
        handleClose();
      })
      .catch((err) => errHandler(err, setError, route));
  }

  const filteredData = data.filter(item => {
    const value = searchText.toLowerCase();
    const subRegions = item.children?.some(subItem => subItem.name.toLowerCase().includes(value));
    return item.name?.toLowerCase().includes(value) || subRegions;
  })

  return (
    <div className={classes.root}>
      <Row
        justifyContent="space-between"
        alignItems="center"
        className={classes.marginBottom2}
      >
        <Typography variant="h5" color="inherit">
          {I18n.t("RegionsManagement")}
        </Typography>
        <Row alignItems="center">
          <CubeBtn
            iconType="search"
            expandable
            className={classes.marginRight2}
            placeholder={`${I18n.t("RegionName")}, ${I18n.t("group")}`}
            searchText={searchText}
            onChange={(text) => setSearchText(text)}
          />
          <AddButton
            onClick={() => handleOpen("create")}
            style={{ height: 39, width: 39 }}
          />
        </Row>
      </Row>
      <RegionTable
        handleOpen={handleOpen}
        data={filteredData}
        isFetched={isFetched}
      />
      <Modal
        title={[modal == 'create' ? "AddA" : "EditThe", "Region"]} 
        open={modal === "create" || modal === "edit"} 
        close={handleClose} 
        style={{paddingBottom: '64px'}}
      >
        <RegionInfo
          label={modal == 'create' ? "add" : "edit"}
          data={selected}
          loading={loading}
          divisionList={divisionList?.map(item => ({ label: item.name, value: item.id }))}
          regionList={data?.map(item => ({ label: item.name, value: item.id }))}
          onClick={(val) => modal == 'create' ? addRegion(val) : updateInfo(val)}
        />
      </Modal>
      <DeleteModal
        open={modal === "delete"}
        name={selected.name}
        submit={handleDelete}
        handleClose={handleClose}
      />
    </div>
  );
};

export default Region;

