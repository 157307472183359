/** @jsx h */
import { h } from "preact";
import { useMemo } from "preact/hooks";
import { memo } from "preact/compat";
import I18n from "i18n-js";

import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from '@material-ui/core/Typography';
import Autocomplete from "@material-ui/lab/Autocomplete";

import { Card } from "components/atoms/card/Card";
import { Column } from "components/atoms/column/Column";
import { Row } from "components/atoms/row/Row";
import { OutlinedInput } from "components/atoms/outlinedInput/OutlinedInput";
import AddList from "../addList/AddList";
import emptyImg from "../../../assets/empty.png";

const useStyles = makeStyles(() => ({
  card: {
    width: 400,
    height: 250,
    backgroundColor: '#fff',
    borderRadius: 10,
    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
    padding: 10,
    margin: 10,
    color: 'black',
  },
  stepper: {
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
  },
  container: {
    paddingLeft: 15,
    paddingRight: 15
  },
  input: {
    fontSize: 16,
    lineHeight: 1.375,
    padding: '9px 11px',
  },
  disabled: {
    transition: 'border-color .15s ease-in-out',
    "& .MuiInputBase-root.Mui-disabled": {
      backgroundColor: '#FAFAFA',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    "& .MuiSelect-select.Mui-disabled": {
      backgroundColor: '#FAFAFA',
      color: 'rgba(0, 0, 0, 0.54)',
    }
  },
  autocomplete: {
    "& .MuiAutocomplete-inputRoot": {
      padding: '2px',
    },
    "& .MuiInputBase-root.Mui-disabled": {
      backgroundColor: '#FAFAFA',
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
  addIcon: {
    color: '#FF0000',
    cursor: 'pointer',
    marginLeft: 5,
  },
  deleteIcon: {
    color: '#FF0000',
    cursor: 'pointer',
  }
}));

const commonCols = [
  { key: "exhibition", label: 'exhibition', type: 'autocomplete', isRequired: true },
  { key: "customerName", label: 'customerName', isRequired: true },
  { key: "company", label: 'company', isRequired: true },
  { key: "email", label: 'email', isMultiple: true, isRequired: true },
  { key: "phone", label: 'phone', isMultiple: true, isRequired: true },
  { key: "cell_phone", label: 'cell_phone', isMultiple: true, isRequired: true },
  { key: "clientType", label: 'clientType', type: 'dropdown' },
  { key: "continent", label: 'continent', type: 'dropdown', isRequired: true },
];

const detailCols = [
  { key: "country", label: 'country', type: 'dropdown', isRequired: true },
  { key: "attribute", label: 'attribute', type: 'dropdown', isRequired: true },
  { key: "firstIndustryLevel", label: 'firstIndustryLevel', type: 'dropdown', isRequired: true },
  { key: "secondBusinessLevel", label: 'secondBusinessLevel', type: 'dropdown' },
  { key: "opportunity", label: 'opportunity', type: 'dropdown', isRequired: true },
  { key: "visit_purpose", label: 'visit_purpose', isRequired: true },
  { key: "fax", label: 'fax' },
  { key: "address", label: 'companyAddress' },
  { key: "website", label: 'website' },
  { key: "uploader", label: 'uploader' },
  { key: "uploadDate", label: 'uploadDate' },
  { key: "remark", label: 'remark' },
];

export const CardCarousel = ({ image }) => {
  const classes = useStyles();

  return (
    <Column>
      <Row justifyContent="center" alignItems="center">
        <Column className={classes.card}>
          <img src={image || emptyImg} style={{ width: "100%", height: "100%" }} />
        </Column>
      </Row>
    </Column>
  )
}

const CardBlock = ({ isDetail = false, isEdit = false, data = {}, options, error, onChange }) => {
  const classes = useStyles();
  const columns = isDetail ? detailCols : commonCols;

  const handleChange = (value, key) => {
    if (typeof value === 'object') {
      value = value.map((item) => item.data)
    }
    onChange({ key, value })
  }

  const { attribute, customAttribute, region, uploader } = data;
  const getValue = (fieldName) => {
    if (fieldName === 'uploader') {
      return [uploader, region];
    }
    if (fieldName === 'attribute' && attribute === '其他') {
      return [attribute, customAttribute];
    }
    return data[fieldName];
  }

  return (
    <Grid container spacing={3} className={classes.container}>
      {columns.map(({ key, label, isMultiple, type, isRequired }) =>
        isMultiple
          ? <AddList
            isRequired={isRequired}
            title={I18n.t(key)}
            placeholder={key}
            data={data[key]}
            disabled={!isEdit}
            error={error ? error[key] : false}
            onChange={(val) => handleChange(val, key)}
          />
          : <>
            <Grid item xs={2}>
              <Row v="center">
                {I18n.t(label)}{" "}
                {isRequired && <Typography style={{ color: 'red' }}>*</Typography>}
              </Row>
            </Grid>
            <Grid item xs={10}>
              <CardItem
                title={key}
                label={label}
                inputType={key === 'remark' ? 'textarea' : 'text'}
                value={getValue(key)}
                type={type}
                options={['dropdown', 'autocomplete'].includes(type) ? options[key] : []}
                disabled={!isEdit}
                error={error ? error[key] : false}
                onChange={(val, key) => handleChange(val, key)}
              />
            </Grid>
          </>
      )}
    </Grid>
  )
}

const CardWrapper = ({ title, children, contentStyle }) => {
  return (
    <Grid item xs={6}>
      <Card title={title} contentStyle={contentStyle}>
        {children}
      </Card>
    </Grid>
  );
};

export const CardInfo = ({ data = {}, isEdit, errorCols, options, onChange }) => {
  const cardBlockProps = useMemo(() => ({
    data,
    isEdit,
    error: errorCols,
    options,
    onChange
  }), [data, isEdit, errorCols, options, onChange]);

  return (
    <>
      <CardWrapper title={I18n.t("CommonInformation")} contentStyle={{ overflowY: 'inherit' }}>
        <CardBlock {...cardBlockProps} />
      </CardWrapper>
      <CardWrapper title={I18n.t("DetailInformation")} contentStyle={{ overflowY: 'inherit' }}>
        <CardBlock {...cardBlockProps} isDetail={true} />
      </CardWrapper>
    </>
  );
};

const CardInput = ({ style, type = 'text', title, disabled, placeholder, value, error, onChange = () => { } }) => {
  const classes = useStyles();
  const disabledColumns = useMemo(() => ['uploadDate', 'uploader'].includes(title), [title]);
  const disabledClass = disabled || disabledColumns ? classes.disabled : null;

  return (
    <TextField
      variant="outlined"
      fullWidth
      error={error}
      inputProps={{ className: classes.input }}
      className={disabledClass}
      style={style}
      multiline={type === 'textarea'}
      minRows={type === 'textarea' ? 3 : 1}
      placeholder={placeholder}
      disabled={disabled || disabledColumns}
      value={value}
      onChange={onChange}
    />
  )
}

const CardItem = memo(({ style, title, label, disabled, inputType = 'text', type, value, options = [], error, onChange }) => {
  const classes = useStyles();
  const placeholder = useMemo(() => I18n.t("InsertThe", { name: I18n.t(label) }), [label]);

  if (title === 'uploader') {
    const [uploader, uploaderRegion] = value;
    return (
      <Row alignItems="center">
        <Column>
          <CardInput
            style={style}
            type={type}
            title={title}
            disabled={disabled}
            value={uploader}
          />
        </Column>
        <Column style={{ paddingLeft: 15, paddingRight: 15 }}>
          /
        </Column>
        <Column>
          <CardInput
            style={style}
            type={type}
            title={title}
            disabled={disabled}
            value={uploaderRegion}
          />
        </Column>
      </Row>
    )
  } else if (type === 'dropdown') {
    return (
      <Column>
        <Select
          value={Array.isArray(value) ? value[0] : value}
          onChange={(e) => onChange(e.target.value, title)}
          displayEmpty
          fullWidth
          error={error}
          disabled={disabled}
          input={
            <OutlinedInput
              classes={{
                disabled: classes.disabled,
                input: classes.input,
              }}
            />
          }
        >
          <MenuItem value="" disabled>
            {placeholder}
          </MenuItem>
          {options.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
        {(title === 'attribute' && (Array.isArray(value) && value[0] === '其他')) && (
          <CardInput
            style={{ ...style, marginTop: 10 }}
            title={title}
            disabled={disabled}
            value={Array.isArray(value) ? value[1] : value}
            error={error}
            onChange={(e) => onChange(e.target.value, 'customAttribute')}
          />
        )}
      </Column>
    )
  } else if (type === 'autocomplete') {
    return (
      <Autocomplete
        fullWidth
        freeSolo
        disabled={disabled}
        options={options}
        getOptionLabel={(option) => option.label || ''}
        getOptionSelected={(option) => option.value === value}
        loading={options.length === 0}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            error={error}
            className={classes.autocomplete}
            variant="outlined"
            placeholder={placeholder}            
          />
        )}
        value={{ label: value, value }}
        onChange={(e, { value }) => onChange(value, title)}
      />
    )
  } else if (['company', 'address'].includes(title)) {
    return (
      <Column>
        {Array.from({ length: 2 }, (_, i) => i).map((i) => (
          <CardInput
            style={{ ...style, marginTop: i > 0 ? 24 : 0 }}
            type={inputType}
            title={title}
            disabled={disabled}
            error={error}
            value={Array.isArray(value) ? value[i] : value}
            placeholder={placeholder}
            onChange={(e) => onChange(e.target.value, title + '_' + i)}
          />
        ))}
      </Column>
    )
  }

  return (
    <CardInput
      style={style}
      type={inputType}
      title={title}
      disabled={disabled}
      value={value}
      error={error}
      placeholder={placeholder}
      onChange={(e) => onChange(e.target.value, title)}
    />
  );
});