/** @jsx h */
import { h } from "preact";
import { useContext, useState } from "preact/hooks";
import I18n from "i18n-js";
import { route } from "preact-router";

import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";

import VisibilityOff from "@material-ui/icons/VisibilityOff";
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from "@material-ui/icons/Lock";
import Visibility from "@material-ui/icons/Visibility";

import { OutlinedInput } from "components/atoms/outlinedInput/OutlinedInput";
import BlueSubmitButton from "components/atoms/blueSubmitButton/blueSubmitButton";
import { Column } from "components/atoms/column/Column";

import { removeToken, setToken as setStorageToken, getIsPersisted, setIsPersisted } from "helper/token";
import { useStyles } from "./style";
import { postUserLogin, request, getAbbreviation, getUser } from "api";
import Context from "context";

// Remember me button imports
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// context snackbar error handling
import { errHandler } from "../../../util";

export const LoginBlock = () => {
  const classes = useStyles();
  const { setToken: setContextToken , setError, setAbbreviation } = useContext(Context);
  const [employNum, setEmployNum] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [visibility, setVisibility] = useState(false);

  // state hook for remember me button
  const [rememberMe , setRememberMe] = useState(getIsPersisted());

  const handleClickShowPassword = () => {
    setVisibility(!visibility);
  };

  const login = (e) => {
    e.preventDefault();
    setLoading(true);
    if (employNum && password) {
      postUserLogin({ employ_no: employNum, password, rememberMe: rememberMe })
        .then((res) => {
          setStorageToken(res.data.token, rememberMe)
          setIsPersisted(rememberMe)

          return res.data.token
        })
        .then((_token)=>{
          getUser().then(res => {
            const isSalesman = res.data.id_role === 4
            if(isSalesman){
              removeToken();

              setError(I18n.t('SALESMAN_BLOCKING'))
              setLoading(false);
            }else{
              setContextToken(_token);
              setLoading(false);
              getAbbreviation().then((res) => setAbbreviation(res.data))    
              route("/page");
            }

          })
        })
        .catch((err) => {
          errHandler(err, setError, route);
          setLoading(false);
        });
    } else {
      setLoading(false);
      setShowError(true);
    }
  };

  return (
    <form>
      <Column
        justifyContent="space-between"
        alignItems="stretch"
        className={classes.root}
      >
        <Typography
          className={classes.marginBottom3}
          style={{ color: "white" }}
          variant="h5"
        >
          {I18n.t("Login")}
        </Typography>
        <OutlinedInput
          required
          style={{ backgroundColor: "white" }}
          size="small"
          error={showError && !employNum}
          className={classes.marginBottom2}
          placeholder={I18n.t("employNum")}
          value={employNum}
          onChange={(e) => setEmployNum(e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <PersonIcon style={{ color: "rgba(0, 0, 0, .54)" }} />
            </InputAdornment>
          }
        />
        <OutlinedInput
          required
          size="small"
          style={{ backgroundColor: "white" }}
          className={classes.marginBottom3}
          type={visibility ? "text" : "password"}
          error={showError && !password}
          placeholder={I18n.t("password")}
          value={visibility.password}
          onChange={(e) => setPassword(e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <LockIcon style={{ color: "rgba(0, 0, 0, .54)" }} />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={handleClickShowPassword}>
                {visibility ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={rememberMe}
              onChange={() => setRememberMe(prev=>!prev)}
              style={{
                color: "white"
              }}
            />}
          label={I18n.t("RememberMe")}
          style={{
            padding: "0 1rem",
            marginBottom: "1rem"
          }}
        />
        <BlueSubmitButton loading={loading} onClick={login}>
          {I18n.t("Login")}
        </BlueSubmitButton>
      </Column>
    </form>
  );
};
