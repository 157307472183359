/** @jsx h */
import { h } from "preact";
import { useEffect, useState } from "preact/hooks";

import { makeStyles } from "@material-ui/core/styles";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RemoveIcon from '@material-ui/icons/Remove';

const useStyles = makeStyles((theme) => ({
  displayOnHover: {
    '& button': {
      visibility: 'hidden',
      pointerEvents: 'none'
    },

    '&:hover': {
      '& button': {
        visibility: 'visible',
        pointerEvents: 'auto'
      },
    }
  }
}));

export const optionTypes = {
  A: <FiberManualRecordIcon style={{ fontSize: '1rem', color: '#2E65C9' }} />,
  B: <RadioButtonUncheckedIcon style={{ fontSize: '1rem', color: '#2E65C9' }} />,
  C: <RemoveIcon style={{ fontWeight: 700, fontSize: '1rem', color: '#F44336' }} />,
}

const EquipmentOption = ({ type, displayOnHover }) => {
  const classes = useStyles();

  return (
    <div
      className={displayOnHover && classes.displayOnHover}
      style={{
        display: 'inline-flex',
        alignItems: 'center'
      }}
    >
      {optionTypes[type]}
    </div>
  )
}
export default EquipmentOption;