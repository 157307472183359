import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  logoArea: ({ isFirst, isBottom }) => ({
    paddingLeft: 16,
    paddingTop: isFirst ? 16 : 8,
    paddingBottom: isBottom ? 16 : 8,
    alignItems: 'center',
    '& > div.logo': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 71,
      width: 71,
      margin: '0px 32px 0px 14px',
    },
  }),

  ExpandableArea: {
    minHeight: 200,
    color: theme.palette.text.disabled
  },

  statisticItem: ({ color, iconColor }) => ({
    backgroundColor: color,
    borderRadius: '100%',
    width: 48,
    height: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 14,
    '& > svg': {
      fontSize: 24,
      color: iconColor
    }
  })
}));