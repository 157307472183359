/** @jsx h */
import { Fragment, h } from "preact";
import { useState, useEffect, useContext, useRef } from "preact/hooks";

export const ResponsiveImage = ({ src }) => {
	const [originImageSize, setOriginImageSize] = useState({});	
	const [imageSize, setImageSize] = useState({});
	const [windowSize, setWindowSize] = useState({ 
		height: window.innerHeight - 50,
		width: window.innerWidth - 50
	});

	const Local = useRef({ newResize: throttleImage(setWindowSize, 20) }).current;
	const [scalingRatio, setScalingRatio] = useState(null);
	const [needScale, setNeedScale] = useState(false);

	useEffect(() => {
		const handleImageLoad = (event) => {
			const imageHeight = event.target.naturalHeight
			const imageWidth = event.target.naturalWidth
			const ratio = Math.ceil(imageWidth / windowSize.width > imageHeight / windowSize.height ? imageWidth / windowSize.width : imageHeight / windowSize.height) * 1.2
			const scale = imageWidth > windowSize.width || imageHeight > windowSize.height
			setOriginImageSize({
				height: imageHeight,
				width: imageWidth
			})
			setScalingRatio(ratio)
			if (scale) {
				const imageWidth = Math.floor(event.target.naturalWidth / ratio)
				const imageHeight = Math.floor(event.target.naturalHeight / ratio)
				setImageSize({
					height: imageHeight,
					width: imageWidth
				})
			}

		};

		const handleWindowResize = (event) => {
			const windowHeight = window.innerHeight - 50 
			const windowWidth = window.innerWidth - 50

			Local.newResize({
				height: windowHeight,
				width: windowWidth
			});
		};		

		const img = document.createElement("img");
		img.src = src
		img.addEventListener("load", handleImageLoad);

		window.addEventListener("resize", handleWindowResize);

		return () => {
			img.removeEventListener("load", handleImageLoad);
			window.removeEventListener("resize", handleWindowResize);
		};
	}, []);

	useEffect(() => {
		const ratio = Math.ceil(originImageSize.width / windowSize.width > originImageSize.height / windowSize.height ? originImageSize.width / windowSize.width : originImageSize.height / windowSize.height) * 1.2
		const imageWidth = Math.floor(originImageSize.width / ratio)
		const imageHeight = Math.floor(originImageSize.height / ratio)
	
		setScalingRatio(ratio)
		setImageSize({
			height: imageHeight,
			width: imageWidth
		})
	}, [windowSize]);

	function throttleImage(fn, duration) {
		let flag = true;
		let timer;
		return function () {
			if (flag) {
				flag = false;
				setTimeout(() => (flag = true), duration);
				fn(...arguments);				
			}
			else {
				clearTimeout(timer);
				timer = setTimeout(() => fn.apply(this, arguments), duration);
			}
		};
	}

	return (
		<img src={src} 
			style={{ 
				width: imageSize.width, 
				height: imageSize.height  
			}}
			resizeMode="cover"
		>
		</img> 
	);
}

export default ResponsiveImage;
