export const getToken = () => {
  const sessionStorageToken = sessionStorage.getItem('token')
  const localStorageToken = localStorage.getItem('token')

  return sessionStorageToken || localStorageToken || ''
}

export const setToken = (token, persist = false) => {
  if (persist) {
    localStorage.setItem('token', token)
  } else {
    localStorage.removeItem('token')
    sessionStorage.setItem('token', token)
  }
}

export const removeToken = () => {
  localStorage.removeItem('token')
  sessionStorage.removeItem('token')
}

export const getIsPersisted = () => {
  const persist = JSON.parse(localStorage.getItem('isPersisted'))

  const isFirstTimeVisit = typeof persist !== 'boolean'
  if (isFirstTimeVisit) {
    setIsPersisted(true)
    return true
  } else {
    return persist
  }
}

export const setIsPersisted = (isPersisted = false) => {
  localStorage.setItem('isPersisted', JSON.stringify(isPersisted))
}
