/** @jsx h */
import { h } from 'preact';
import useStyles from './Chip.style'
import CloseIcon from '@material-ui/icons/Close';

const Chip = ({ text, style, onClick }) => {
    const classes = useStyles()
    
    return(
        <div className={classes.chipWrap} style={style}>
            <span>{text}</span>
            <CloseIcon className={classes.close} onClick={onClick}/>
        </div>
    )
}
export default Chip;