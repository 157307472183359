import React, { useEffect, useRef, useState } from 'react'
import I18n from 'i18n-js'
import { makeStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/RemoveCircle'
import { Column } from 'components/atoms/column/Column'
import { Row } from 'components/atoms/row/Row'

const useStyles = makeStyles(() => ({
  input: {
    fontSize: 16,
    lineHeight: 1.375,
    padding: '9px 11px',
  },
  disabled: {
    transition: 'border-color .15s ease-in-out',
    "& .MuiInputBase-root.Mui-disabled": {
      backgroundColor: '#FAFAFA',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    "& .MuiSelect-select.Mui-disabled": {
      backgroundColor: '#FAFAFA',
      color: 'rgba(0, 0, 0, 0.54)',
    }
  },
  addIcon: {
    color: '#2E65C9',
    cursor: 'pointer',
    marginLeft: 5,
    marginTop: 9
  },
  deleteIcon: {
    color: '#FF0000',
    cursor: 'pointer',
  }
}));

const AddList = ({
  isRequired = false,
  title,
  data,
  error,
  placeholder,
  disabled,
  onChange,
}) => {
  const classes = useStyles();
  const [line, setLine] = useState([{ data: '', i: 0 }])
  const endTyping = useRef(null)

  useEffect(() => {
    if (data && typeof data === 'object') {
      //set default data
      const tmp = data?.map((item, i) => ({
        i: i + 1,
        data: item,
      }))
      setLine(tmp)
    } else {
      add()
    }
  }, [data])

  const add = () => {
    let tmp = [
      {
        i: line.length + 1,
        data: '',
      },
    ]
    setLine((prev) => prev.concat(tmp))
    onChange(line.concat(tmp))
  }

  const edit = (i, val) => {
    line.splice(i, 1, val)

    clearTimeout(endTyping.current)
    endTyping.current = setTimeout(() => {
      onChange(line)
    }, 300)
  }

  const remove = (val) => {
    if (line.length != 1) {
      setLine(line.filter((e) => e != val))
      onChange(line.filter((e) => e != val))
    }
  }

  const isError = (i) => {
    return typeof error === 'object' && error.includes(i)
  }

  return (
    <>
      <Grid item xs={2}>
        <Row justifyContent="space-between" alignItems="center">
          <Row v="center">
            {title}{" "}
            {isRequired && <Typography style={{ color: 'red' }}>*</Typography>}
          </Row>
          {!disabled && (
            <Column justifyContent="center" alignItems="center">
              <AddIcon
                className={classes.addIcon}
                onClick={add}
              />
            </Column>
          )}
        </Row>
      </Grid>
      <Grid item xs={10} style={{ textAlign: 'end' }}>
        {line.map((item, i) => (
          <Row key={i} justifyContent="space-between" alignItems="center" style={{ marginBottom: i < line.length - 1 ? 24 : 0 }}>
            <TextField
              variant="outlined"
              fullWidth
              inputProps={{ className: classes.input }}
              className={classes.disabled}
              disabled={disabled}
              value={item?.data}
              placeholder={I18n.t('InsertThe', { name: I18n.t(placeholder) })}
              error={isError(i) || ((!item.data || item.data === '') && error)}
              onChange={(e) => edit(i, { ...item, data: e.target.value })}
            />
            {(!disabled && line.length > 1) && (
              <RemoveIcon
                className={classes.deleteIcon}
                size={40}
                color="secondary"
                onClick={() => remove(item)}
              />
            )}
          </Row>
        ))}
      </Grid>
    </>
  )
}
export default AddList;