/** @jsx h */
import { h } from 'preact';
import { useState, useEffect, useContext } from "preact/hooks";
import { route } from "preact-router";
import I18n from "i18n/i18n";

import AttachFileIcon from "@material-ui/icons/AttachFile";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { Input } from "rsuite";
import Chip from "components/atoms/chip/Chip";
import { Row } from "components/atoms/row/Row";
import Select from 'components/atoms/select/Select';
import { MultiSelect } from 'components/atoms/multiSelect/MultiSelect';
import { AddButton } from "components/atoms/addButton/AddButton";
import { UploadModal } from "components/molecules/uploadModal/UploadModal";
import { AnnouncementTable } from 'components/molecules/announcementTable/AnnouncementTable';

import { deleteAnnouncement, getAnnouncement, getGroup, getUserList, postAnnouncement, setAnnouncement } from "api";
import { errHandler } from "../../util";
import context from "context";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { useUpdateEffect } from 'hooks';
import { Column } from 'components/atoms/column/Column';
import EmptyIcon from 'assets/empty.svg';
import moment from 'moment';
import { DeleteModal } from 'components/molecules/deleteModal/DeleteModal';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4),
        width: "100%",
        color: "rgba(0, 0, 0, 0.54)",
    },
    container: {
        backgroundColor: '#FAFAFA',
    },
    marginRight2: {
        marginRight: theme.spacing(2),
    },
    select: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        fontSize: 16,
        '& .rs-picker-toggle': {
            borderColor: '#FFFFFF'
        }
    },
    uploadButton: {
        backgroundColor: '#FFFFFF',
        border: '1px solid #E0E0E0',
        borderRadius: theme.spacing(1),
        padding: theme.spacing(2),
        textTransform: 'none',
        fontSize: 14,
        fontWeight: 400
    },
    chipArea: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(3),

        '& > div + div': {
            marginLeft: theme.spacing(3)
        }
    },
    marginBottom2: {
        marginBottom: theme.spacing(2),
    }
}));

const ACCEPTED_FILE_TYPE = ['heic', 'jpeg', 'jpg', 'png', 'gif', 'svg', 'pdf', 'doc', 'docx', 'csv', 'xls', 'xlsx', 'txt', 'ppt', 'pptx', 'odp', 'zip'];

const AnnounceModal = ({
    open = false,
    onClose = () => { },
    type = 'add',
    selectedAnnouncement,
    userList,
    setError = () => { },
}) => {
    const classes = useStyles();
    const { groupList } = useContext(context);
    const [loading, setLoading] = useState(false);
    const [showError, setShowError] = useState(null);
    const [uploadModal, setUploadModal] = useState(false);
    // For dropdown
    // For selected dropdown option
    const [group, setGroup] = useState(0);
    const [user, setUser] = useState('invalid');
    const [lastDay, setLastDay] = useState(7);
    const [content, setContent] = useState('');
    // Uploaded files
    const [fileList, setFileList] = useState([]);

    useUpdateEffect(() => { setUser('invalid'); }, [group]);
    // Handle data initialization for editing an announcement.
    useUpdateEffect(() => {
        if (type !== 'edit') return;

        setGroup(selectedAnnouncement.target_id_group ?? 0);
        setUser(selectedAnnouncement.target_id_user ?? 'invalid');
        setContent(selectedAnnouncement.context);
    }, [type]);

    const teamOptions = [
        { id: 0, text: I18n.t('team') },
        ...groupList.map((row) => ({
            id: row.id,
            text: row.name,
        }))
    ]

    const employeeOptions = [
        { id: 'invalid', text: I18n.t('Employee') },
        ...userList.filter((row) => row.id_group === group).map((row) => ({
            id: row.id,
            text: row.last_name + row.first_name,
        }))
    ];

    const dayOptions = [
        { id: 1, text: I18n.t('day', { number: 1 }) },
        { id: 3, text: I18n.t('days', { number: 3 }) },
        { id: 7, text: I18n.t('days', { number: 7 }) },
        { id: 14, text: I18n.t('days', { number: 14 }) },
        { id: 30, text: I18n.t('days', { number: 30 }) },
    ];

    const handleUpload = (files) => {
        let list = files;
        let flag = true;

        // Check file numer is over limit
        if (list.length > 5) {
            list = list.slice(0, 5);
            alert(I18n.t('FILE_NUM_OVER_LIMIT'));
        }

        list = list.filter(file => {
            // Check if file size is over 50MB
            if (file.blobFile.size > 1024 * 1024 * 50) {
                flag = false;
                alert(I18n.t('FILE_SIZE_OVER_LIMIT'));
                return false;
            }

            // Check if file type is not accepted
            const type = file.name.split('.').pop().toLowerCase();
            if (!ACCEPTED_FILE_TYPE.includes(type)) {
                flag = false;
                alert(I18n.t('FILE_TYPE_NOT_ACCEPTED'));
                return false;
            }
            return true;
        });

        setFileList(list);

        if (flag) {
            setUploadModal(false);
        }
    };

    const handleClose = (shouldRefresh) => {
        setGroup(0);
        setUser('invalid');
        setLastDay(7);
        setContent('');
        setFileList([]);
        onClose(shouldRefresh);
    };

    const submit = async () => {
        setLoading(true);

        if (!content.trim().length) {
            setShowError(I18n.t('MISSING_ANNOUNCEMENT_CONTENT'));
            setLoading(false);
            return;
        }

        let formData = new FormData();

        if (fileList.length) {
            Array.from(fileList).forEach(file => {
                formData.append('filename', file.blobFile);
            });
        }

        let payload = {
            context: content,
            days_to_live: lastDay,
            target_id_group: group,
            target_id_user: user,
        }

        Object.keys(payload)
            .forEach(key => !payload[key] ? delete payload[key] : null);

        if (!group || group === 0) delete payload.target_id_group;
        if (!user || user === 'invalid') delete payload.target_id_user;

        try {
            if (type === 'add') {
                await postAnnouncement(formData, payload);
            } else if (type === 'edit') {
                delete payload.days_to_live;
                await setAnnouncement(selectedAnnouncement.id, payload);
            }

            setLoading(false);
            handleClose(true);
        } catch (error) {
            setLoading(false);
            errHandler(error, setError, route);
        }

        // init showError
        if (showError) {
            setShowError("");
        }
    };

    return (
        <>
            <Dialog open={open} onClose={() => handleClose(false)} maxWidth='lg'>
                <DialogTitle className={classes.container} style={{ width: '70em' }}>
                    <Row justifyContent='space-between' alignItems='center'>
                        <Typography variant='h6' color='inherit'>
                            {I18n.t('SelectBroadcastRecipient')}
                        </Typography>
                        <Row>
                            <Select
                                value={group}
                                data={teamOptions}
                                style={{ marginInline: '1em', fontSize: 14 }}
                                onClick={(e) => setGroup(e.id)}
                            />
                            <Select
                                style={{ fontSize: 14 }}
                                value={user}
                                data={employeeOptions}
                                onClick={(e) => setUser(e.id)}
                            />
                        </Row>
                    </Row>
                </DialogTitle>
                <DialogContent className={classes.container}>
                    {type === 'add' && (
                        <>
                            <Row justifyContent="space-between">
                                <Button
                                    color='inherit'
                                    className={classes.uploadButton}
                                    size="large"
                                    startIcon={<AttachFileIcon color="action" />}
                                    onClick={() => setUploadModal(true)}
                                >
                                    {I18n.t("UploadA", { name: I18n.t("File") })}
                                </Button>
                                <Row alignItems="center">
                                    <Typography variant="inherit" color="inherit">
                                        {I18n.t("SelectBroadcaseLastDay")}：
                                    </Typography>
                                    <Select
                                        value={lastDay}
                                        onClick={(e) => setLastDay(e.id)}
                                        data={dayOptions}
                                    />
                                </Row>
                            </Row>
                            <Row className={classes.chipArea}>
                                {
                                    fileList.map(file =>
                                        <Chip text={file.name} onClick={() =>
                                            setFileList(prev => prev.filter(f => f.fileKey !== file.fileKey))
                                        } />)
                                }
                            </Row>
                        </>
                    )}
                    <Input
                        className={classes.marginBottom2}
                        as='textarea'
                        rows={17}
                        placeholder={I18n.t("AnnouncementPlaceholder")}
                        value={content}
                        onChange={setContent}
                    />
                    {showError && (
                        <Row className={classes.marginBottom2}>
                            <Typography variant="inherit" color="error">
                                {showError}
                            </Typography>
                        </Row>
                    )}
                </DialogContent>
                <DialogActions className={classes.container}>
                    <Button
                        onClick={() => handleClose(false)}
                        disabled={loading}
                        style={{ color: 'red' }}
                    >
                        {I18n.t('Cancel')}
                    </Button>
                    <Button
                        onClick={submit}
                        disabled={loading}
                        style={{ color: 'white', backgroundColor: '#2e65c9' }}
                    >
                        {type === 'add' ? I18n.t('Announce') : I18n.t('Edit')}
                    </Button>
                </DialogActions>
            </Dialog>
            <UploadModal
                open={uploadModal}
                handleClose={() => setUploadModal(false)}
                fileList={fileList}
                handleUpload={handleUpload}
            />
        </>
    );
};

const Announcement = () => {
    const classes = useStyles();
    const { user, setError, userList, groupList } = useContext(context);

    const [modal, setModal] = useState(/** @type {'closed' | 'add' | 'edit' | 'delete'} */('closed'));
    const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
    // data
    const [announcements, setAnnouncements] = useState([]);
    // states
    const [group, setGroup] = useState([0]);

    const teamOptions = groupList.map((row) => ({
        id: row.id,
        text: row.name,
    }))

    const filter = ({ target_id_region, target_id_group }) => {
        let flag = false;
        const selectedAllGroup = group.includes(0) || !group.length;

        if (selectedAllGroup || group.includes(target_id_group)) {
            flag = true;
        }
        if (user.role === 1 || (user.role === 2 && user.id_region === target_id_region) || (user.role === 3 && user.id_group === target_id_group)) {
            flag = true;
        }
        return flag;
    }

    const filteredAnnouncements = announcements.filter(filter)

    const fetchAnnouncements = async () => {
        try {
            const announcements = (await getAnnouncement()).data;
            const list = announcements.map(item => ({
                ...item,
                created_at: moment(item.created_at).format('YYYY/MM/DD hh:mmA')
            }));
            setAnnouncements(list);
        } catch (error) {
            console.log('Get Announcement Users Groups Error: ', error);
            if (error.response) console.log(error.response.data);
        }
    };

    const handleAction = (type, row) => {
        setSelectedAnnouncement(row);
        setModal(type);
    }

    const handleDelete = async () => {
        try {
            await deleteAnnouncement(selectedAnnouncement.id);

            setSelectedAnnouncement(null);
            setModal('closed');
            await fetchAnnouncements();
        } catch (error) {
            console.log('Delete Announcement Error: ', error);
            if (!!error.response) console.log(error.response.data);
        }
    };

    useEffect(() => { fetchAnnouncements(); }, []);

    return (
        <div className={classes.root}>
            <Row
                justifyContent='space-between'
                alignItems='center'
                className={classes.marginBottom2}
            >
                <Typography variant='h5' color='inherit'>
                    {I18n.t('announcement')}
                </Typography>
                <Row style={{ gap: 10 }} alignItems='center'>
                    <MultiSelect
                        data={teamOptions}
                        value={group}
                        title="team"
                        placeholder={I18n.t('team')}
                        labelKey="text"
                        valueKey="id"
                        onChange={setGroup}
                    />
                    <AddButton
                        style={{ height: 39, width: 39 }}
                        onClick={() => setModal('add')}
                    />
                </Row>
            </Row>
            {/*  */}
            {
                !!filteredAnnouncements.length ? (
                    <AnnouncementTable
                        data={filteredAnnouncements}
                        isFetched={!!announcements.length}
                        currentUserId={user.id}
                        handleAction={handleAction}
                    />
                ) : (
                    <Column style={{ backgroundColor: 'white', borderRadius: '1em', padding: 5 }}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                paddingBlock: '1em',
                            }}
                        >
                            <img src={EmptyIcon} />
                        </div>
                    </Column>
                )
            }
            <AnnounceModal
                open={modal === 'add' || modal === 'edit'}
                onClose={(shouldRefresh) => {
                    setModal('closed');
                    if (shouldRefresh) fetchAnnouncements();
                }}
                type={modal}
                selectedAnnouncement={selectedAnnouncement}
                userList={userList}
                setError={setError}
            />
            <DeleteModal
                open={modal === 'delete'}
                handleClose={() => {
                    setSelectedAnnouncement(null)
                    setModal('closed');
                }}
                name={I18n.t('Announcement')}
                submit={handleDelete}
            />
        </div>
    )
}
export default Announcement;