/** @jsx h */
import { h } from "preact";
import { useState, useMemo, useContext } from "preact/hooks";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import Public from "@material-ui/icons/Public";
import { Row } from "components/atoms/row/Row";
import Context from "context";
import logo from "assets/logo.svg";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "none",
    backgroundColor: "#2E65C9",
  },
  toolbar: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingRight: 16,
    paddingLeft: 16,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: 48,
    minHeight: "initial",
  },
  marginLeft: {
    marginLeft: 16,
  },
  icon: {
    height: 24,
  },
}));


export const Header = ({
  languages,
  logout,
  handleViewAllClick,
  empty,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(false);
  const [type, setType] = useState([]);

  const handleDropDownClick = (e, type) => {
    setType(type);
    setAnchorEl(e.target);
  };

  const setLanguage = (locale) => {
    localStorage.setItem("locale", locale);
    window.location.reload();
  };

  const PopoverList = useMemo(() => {
    return (
      <List component="nav">
        {localStorage.getItem("locale") != "zh-TW" && localStorage.getItem("locale") != "en"
        ?localStorage.setItem("locale", "en")
        :languages.map(
          ({ key, name }) => (
            <ListItem
              button
              onClick={() =>
              setLanguage(key)
              }
            >
              <ListItemText primary={name} />
            </ListItem>
          )
        )}
      </List>
    )
  }, [type]);

  return (
    <AppBar position="absolute" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Row>
          <img src={logo} className={classes.icon} />
        </Row>
        {
          !empty &&
          <Row style={{ fontSize: "20px" }}>
            <Button
              color="inherit"
              endIcon={<ExpandMoreIcon />}
              onClick={(e) => handleDropDownClick(e, "language")}
              className={classes.marginLeft}
            >
              <Public />
            </Button>
            <IconButton
              color="inherit"
              onClick={logout}
              className={classes.marginLeft}
            >
              <LogoutIcon />
            </IconButton>
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              PaperProps={{ style: { borderRadius: 10 } }}
            >
              {PopoverList}
            </Popover>
          </Row>
        }
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  languages: PropTypes.array,
  logout: PropTypes.func,
};

Header.defaultProps = {
  languages: [],
  logout: () => { },
};
