/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-else-return */
/** @jsx h */
import { h } from 'preact'
import { useEffect, useState, useContext, useMemo, useCallback } from 'preact/hooks'
import I18n from 'i18n-js'
import moment from 'moment'

import { DateRangePicker, Toggle } from 'rsuite'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import CubeBtn from 'components/atoms/cubeButton/CubeBtn'
import { Card } from 'components/atoms/card/Card'
import { Row } from 'components/atoms/row/Row'
import { MultiSelect } from 'components/atoms/multiSelect/MultiSelect'
import StatisticsTopTable from 'components/molecules/statisticsTopTable/StatisticsTopTable'
import { SortableTable } from 'components/molecules/sortableTable/SortableTable'

import { getWeeklyReport } from 'api'
import context from 'context'
import { enUS, zhTW } from 'rsuite/esm/locales'
import { useRole, useUserSequence } from 'hooks'
import ExportExcelButton from 'components/atoms/exportExcelButton/ExportExcelButton'
import { formatPrice } from 'helper/format'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    width: '100%',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  marginBottom2: {
    marginBottom: theme.spacing(2),
  },
  DateRangePicker: {
    '& .rs-picker-toggle': {
      backgroundColor: '#FFFFFF',
      padding: '9px 12px',
      paddingRight: 36,
      border: 'none',
      borderRadius: 8,
      fontSize: 15
    },
    '& .rs-picker-toggle-value': {
      color: '#0000008a !important',
    }
  },
  selectedRow: {
    backgroundColor: '#EFF5FF !important',
    '& > td': {
      color: '#2E65C9',
    },
  },
  marginArea: {
    '& > div + div': {
      marginLeft: theme.spacing(2),
    },
  },
}))
const TOTAL_COLUMNS = [
  {
    id: 'year',
    sortable: true,
  },
  {
    id: 'currentMonth',
    sortable: true,
  },
]
const ORIGIN_BOTTOM_TABLE_COLUMNS = [
  {
    id: 'salesman',
    sortable: true,
  },
  {
    id: 'newOrderCounts',
    sortable: true,
  },
  {
    id: 'averageWeeklyTotal',
    sortable: true,
  },
]

const generateBottomTableColumns = function (weeklyDates) {
  const weeklyColumns = weeklyDates.map((weeklyDate) => {
    return {
      id: moment(weeklyDate).format('MM/DD'),
      text: moment(weeklyDate).format('MM/DD'),
      sortable: true,
    }
  })
  let computedBottomTableColumns = [
    ...ORIGIN_BOTTOM_TABLE_COLUMNS,
    ...weeklyColumns,
  ]

  return computedBottomTableColumns
}

const DEFAULT_DATE_RANGE = {
  start: moment().subtract(4, 'weeks').startOf('day').toDate(),
  end: moment().endOf('day').toDate(),
}

const Statistics = () => {
  const { userList, user, groupList } = useContext(context)
  const [dateRange, setDateRange] = useState(DEFAULT_DATE_RANGE);
  const [originalData, setOriginalData] = useState({
    weekly: [],
    total: {},
  })
  const [data, setData] = useState([])
  const [detailData, setDetailData] = useState([])
  const [detailColumns, setDetailColumns] = useState([])
  const [totalData, setTotalData] = useState([])
  const [totalColumns, setTotalColumns] = useState([])
  const [selectedRow, setSelectedRow] = useState(null)
  const [showCounts, setShowCounts] = useState(true)

  // filter data by search
  const [searchVal, setSearchVal] = useState('')
  const [isFetched, setFetched] = useState(null)
  const [selectedDepartment, setSelectedDepartment] = useState([0])

  const [pagination, setPagination] = useState({
    count: data.length,
    rowsPerPage: 10,
    pageIndex: 0,
  })

  const classes = useStyles()

  const role = useRole()
  const sequence = useUserSequence()

  useEffect(() => {
    if (!userList.length || !user) return
    if (role.hasHighPermission) {
      setFetched(false)
      getWeeklyReport({
        from: moment(dateRange.start).format('GGGG-WW'),
        to: moment(dateRange.end).format('GGGG-WW')
      })
        .then((result) => {
          const { data } = result
          let filteredData = data.quotationSummary.map(report => {
            const selectUser = userList.find(
              (user) => user.id === report.salesmanId
            )
            const hasRegion = !!selectUser?.id_region
            const isSameRegion = selectUser?.id_region === user?.id_region
            return {
              ...report,
              user: selectUser,
              hasRegion,
              isSameRegion
            }
          })
            .filter((report) => {
              if (role.isManager && report.hasRegion) {
                return report.user?.id_role > 2 && report.isSameRegion
              } else if (role.isManager && !report.hasRegion) {
                return report.user?.id_role === 0
              } else return report.user?.id_role > 2
            })
          setOriginalData({
            weekly: filteredData,
            total: data.reportSummary
          })
        })
        .catch((error) => {
          console.error('weeklyReport', error)
          setOriginalData({
            weekly: [],
            total: {}
          })
        })
        .finally(() => setFetched(true))
    } else {
      setData([])
    }
  }, [dateRange.start, dateRange.end, user, userList, role.hasHighPermission, role.isManager])

  useEffect(() => {
    if (!originalData.weekly.length && !Object.keys(originalData.total).length) {
      return;
    }
    loadData();
  }, [sequence.data, originalData.total, originalData.weekly])

  useEffect(() => {
    loadData();
  }, [selectedDepartment])

  const getFilteredDataByRegion = (_data) => {
    return _data.filter(item => {
      const selectedAllDepartment = selectedDepartment.includes(0) || !selectedDepartment.length
      if (selectedAllDepartment) {
        return true
      } else {
        return selectedDepartment.includes(item.user?.id_group)
      }
    })
  }

  const loadData = () => {
    if (!groupList.length) return
    const filteredData = getFilteredDataByRegion(originalData.weekly)
    const computedTopData = organizeTopByGroup(filteredData)
    const bottomTableData = getBottomTableData(filteredData)
    const computedBottomData = organizeBottomByGroup(bottomTableData.origin)
    const totalTableData = getTotalTableData(originalData.total)

    setData(computedTopData)
    setDetailData(computedBottomData)
    setDetailColumns(bottomTableData.columns)
    setTotalColumns(totalTableData.columns)
    setTotalData([totalTableData.origin])
    setPagination((prev) => ({ ...prev, count: computedBottomData.length }))
  }

  useEffect(() => {
    if (!!searchVal) {
      setPagination((prev) => ({ ...prev, pageIndex: 0 }))

      const filterTopTableBySearchValue = (groupedData, _searchValue) => {
        const filteredData = groupedData.map((group) => {
          const newSequence = group.sequence
            .filter((item) =>
              item.salesmanName?.toLowerCase().includes(_searchValue.toLowerCase())
            )
            .map((item) => ({
              ...item,
              StatisticSalesman: item.salesmanName,
              StatisticTotal: item.reportCount,
            }))
          return {
            ...group,
            sequence: newSequence,
          }
        })
        return filteredData
      }

      const filteredData = filterTopTableBySearchValue(data, searchVal)
      const filteredDetailData = detailData?.filter((item) =>
        item.salesman?.toLowerCase().includes(searchVal.toLowerCase())
      )
      setData(filteredData)
      setDetailData(filteredDetailData)
    } else {
      loadData();
    }
  }, [searchVal])

  const handlePageChange = (_, index) => {
    setPagination((prev) => ({ ...prev, pageIndex: index }))
  }

  const handleRowsPerPageChange = (_, action) => {
    setPagination((prev) => ({ ...prev, rowsPerPage: action.key }))
  }

  const getTotalTableData = (_data) => {
    if (!Object.keys(_data).length) {
      return {
        origin: [],
        columns: [],
      }
    }

    const weekly = Object.keys(_data.weekly)
    const days = weekly.map((item) => moment(item).format('MM/DD'))
    const columns = [...TOTAL_COLUMNS, ...days.map(day => ({ id: day }))]

    let origin = {}
    weekly.forEach(day => {
      origin[moment(day).format('MM/DD')] = _data.weekly[day].count
    })
    origin.year = _data.wholeYearReportCount.count
    origin.currentMonth = _data.wholeMonthReportCount.count

    return { origin, columns }
  }

  const getBottomTableData = (_data) => {
    if (_data.length) {
      let dataArray = new Array()
      let keys = Object.keys(_data[0].weekly)
      keys.sort()
      _data.map((item) => {
        let weeklyReportCount = new Object()
        weeklyReportCount['id'] = item.salesmanId
        weeklyReportCount['salesman'] = item.salesmanName
        keys.forEach((key) => {
          weeklyReportCount[moment(key).format('MM/DD')] = item.weekly[key]
        })
        weeklyReportCount['newOrderCounts'] = item.quotationCount
        weeklyReportCount['averageWeeklyTotal'] = item.averageWeeklyCount
        dataArray.push(weeklyReportCount)
      })

      return {
        origin: dataArray,
        columns: generateBottomTableColumns(keys),
      }
    } else
      return {
        origin: [],
        columns: [],
      }
  }

  const organizeTopByGroup = (_data) => {
    const groupedData = sequence.data.map((item) => {
      const filledData = item.sequence
        .map((id) => ({
          ..._data.find(({ salesmanId }) => salesmanId === id),
          groupId: item.id,
        }))
        .filter((item) => !!item)
      return { ...item, sequence: filledData }
    })
    return groupedData
  }

  const organizeBottomByGroup = (_data) => {
    const sorttedData = sequence.flattened
      .map((userId) => _data.find(({ id }) => userId === id))
      .filter((item) => !!item)
    return sorttedData
  }

  const paginateTopData = (groupedArray, pageIndex, rowsPerPage) => {
    const startPoint = pageIndex * rowsPerPage
    const offset = (pageIndex + 1) * rowsPerPage
    const AllSalesman = groupedArray.map(item => item.sequence.map(i => i.salesmanId)).flat().filter(Boolean);
    const prunedGroupArray = AllSalesman.filter(
      (_, index) => index >= startPoint && index < offset
    )
    const filteredGroup = groupedArray.map((group) => {
      const filteredUser = group.sequence.filter(({ salesmanId }) => {
        return prunedGroupArray.includes(salesmanId)
      })

      return { ...group, sequence: filteredUser }
    })
    return filteredGroup
  }

  const paganatedTopData = paginateTopData(
    data,
    pagination.pageIndex,
    pagination.rowsPerPage
  )

  const monthlyTotalValueData = () => {
    const entries = detailColumns.filter((column) => column.id !== 'newOrderCounts').map((column) => {
      if (column.id === 'salesman') return [column.id, I18n.t('totalValue')];
      const total = detailData.reduce((prev, current) => 
        prev + parseInt(showCounts ? current[column.id].count : current[column.id].price)
      , 0)
      return [column.id, total];
    });

    return Object.fromEntries(new Map(entries));
  };

  const getDetailData = () => {
    return detailData.map(item => {
      const entries = detailColumns.map((column) => {
        if (column.id.includes('/')) {
          return [column.id, showCounts ? item[column.id].count : formatPrice(item[column.id].price)]
        }
        return [column.id, item[column.id]]
      })
      return Object.fromEntries(new Map(entries))
    }) ?? []
  }

  const startWeek = useMemo(() => {
    return `${moment(dateRange.start).format('GGGG/WW')}W`
  }, [dateRange.start])

  const endWeek = useMemo(() => {
    return `${moment(dateRange.end).format('GGGG/WW')}W`
  }, [dateRange.end])

  const BottomTableAction = (
    <Row alignItems="center">
      <Toggle
        style={{ marginRight: 8 }}
        size="lg"
        checked={showCounts}
        checkedChildren={I18n.t('quotationCount')}
        unCheckedChildren={I18n.t('quotationPrice')}
        onChange={() => setShowCounts(!showCounts)}
      />
      <ExportExcelButton
        sheetName={`${startWeek.replace('/', '-')}~${endWeek.replace('/', '-')}` + '-monthly-stats'}
        columns={detailColumns.filter((column) => column.id !== 'newOrderCounts').map((column) => {
          if (column.id === 'salesman') return { ...column, text: I18n.t('date') };
          else if (column.text === undefined) return { ...column, text: I18n.t(column.id) };

          return column;
        })}
        data={[monthlyTotalValueData()].concat(getDetailData())}
        headerColor='EBF1DE'
        fillRowsColumns={[
          { type: 'row', filter: (_, index) => index === 0, color: 'EBF1DE' },
          { type: 'row', filter: (row, index) => index !== 0 && row.newOrderCounts === 0, color: 'f5c8a6' },
          { type: 'column', filter: (column) => column.id === 'averageWeeklyTotal', color: 'DAEDF3' },
        ]}
        fileType="xlsx"
      />
    </Row>
  )

  return (
    <div className={classes.root}>
      <Row
        justifyContent="space-between"
        alignItems="center"
        className={classes.marginBottom2}
      >
        <Typography variant="h5" color="inherit">
          {I18n.t('MonthlyStatistics')}
        </Typography>
        <Row className={classes.marginArea}>
          <DateRangePicker
            defaultValue={[dateRange.start, dateRange.end]}
            size="lg"
            preventOverflow={true}
            showWeekNumbers={true}
            className={classes.datePicker}
            onChange={(e) => {
              if (e) {
                setDateRange({ start: e[0], end: e[1] });
              } else {
                setDateRange(DEFAULT_DATE_RANGE);
              }
            }}
            locale={localStorage.getItem('locale') === 'en' ? enUS.DateRangePicker : zhTW.DateRangePicker}
          />
          <MultiSelect
            data={groupList}
            title="department"
            labelKey="name"
            valueKey="id"
            placeholder={I18n.t('department')}
            value={selectedDepartment}
            onChange={setSelectedDepartment}
          />
          <CubeBtn
            expandable
            iconType="search"
            searchText={searchVal}
            placeholder={I18n.t('Salesman')}
            onChange={(e) => setSearchVal(e)}
          />
        </Row>
      </Row>
      {/* Month table */}
      <StatisticsTopTable
        data={paganatedTopData}
        pagination={pagination}
        onClickRow={(index) => setSelectedRow(index)}
        onDragEnd={(result) => sequence.reorder(result)}
        selectedRow={selectedRow}
        isFetched={isFetched}
        skeletonRows={pagination.rowsPerPage}
        sheetName={`${startWeek.replace('/', '-')}~${endWeek.replace('/', '-')}` + I18n.t('MonthlyStatistics')}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
      />
      {/* Total table */}
      <Card title={I18n.t('StatisticTotal')} wrapStyle={{ marginBottom: 16 }}>
        <SortableTable
          columns={totalColumns}
          data={totalData}
          isFetched={isFetched}
          striped={true}
          hovered={true}
          disablePagination={true}
        />
      </Card>
      {/* Week table */}
      <Card
        title={`${startWeek}~${endWeek}`}
        wrapStyle={{ marginTop: 16 }}
        actionComponent={BottomTableAction}
      >
        <SortableTable
          columns={detailColumns}
          data={getDetailData()}
          isFetched={isFetched}
          striped
          hovered
          pageResetKey={!!searchVal}
          getRowProps={({ data, index, className }) => {
            return {
              onClick: () => setSelectedRow(data.id),
              className:
                className +
                ' ' +
                (selectedRow === data.id ? classes.selectedRow : ''),
              style:
                data.newOrderCounts === 0
                  ? { backgroundColor: 'rgba(247, 211, 173, 0.5)' }
                  : {},
            }
          }}
        />
      </Card>
    </div>
  )
}
export default Statistics
