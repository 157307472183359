/** @jsx h */
import { h } from 'preact';
import React from 'react';
import { useState, useEffect, useContext } from "preact/hooks";
import I18n from "i18n/i18n";
import 'rsuite/dist/rsuite.min.css';
import { Form, SelectPicker } from 'rsuite';
import useStyles from './ClientInfo.style';

import MuiButton from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import BlueBtn from 'components/atoms/blueButton/BlueBtn';
import { DeleteModal } from 'components/molecules/deleteModal/DeleteModal';
import { List } from 'components/molecules/list/List';
import ContactForm from 'components/organisms/modalContent/client/ContactForm';

// FORM VALIDATION
import { Schema } from "rsuite";
import { useRef } from "preact/hooks";
import BlueSubmitButton from 'components/atoms/blueSubmitButton/blueSubmitButton';

// CONTEXT SNACKBAR
import context from "context"; 
import { errHandler } from "../../../../util";
import { route } from "preact-router";
import { enUS, zhTW } from 'rsuite/esm/locales';
import { getClient } from 'api';
import { useIndustryList } from 'hooks/useIndustryList';
import { useUserList } from 'hooks/useUserList';
 
const { NumberType, StringType } = Schema.Types;
const ClientInfo = ({ data, label, style, onClick, defaultClientType, setSelectType, clientGroup, machine, contactsData, loading }) => {
	const classes = useStyles();
	const [type, setType] = useState(label || 'add');
	const [clientType, setClientType] = useState(defaultClientType);
	const [formValues, setFormValues] = useState(data);
	const [modal, setModal] = useState(false);
	const [deleteBranch, setDeleteBranch] = useState([]);
	const [deleteContact, setDeleteContact] = useState([]);
	const [tempBranches, setTempBranches] = useState(data ? data.branches : [])

	const clientTypeList = [
		{
			label: I18n.t('Group'),
			value: 'group'
		},
		{
			label: I18n.t('Branch'),
			value: 'branch'
		}
	];
	const [ refresh, setRefresh ] = useState(false);
	useEffect(() => {
		setFormValues(data)
	},[data])

	useEffect(() => {
		setSelectType ? setSelectType(clientType) : null;
	}, [clientType]);

	useEffect(() => {
		if (deleteBranch) {
			setFormValues((prev) => ({
				...prev,
				deleteBranch
			}))
		}
	}, [deleteBranch]);

	//FORM VALIDATION VARIABLES
	const isRequired = I18n.t("required");

	const initModel = {}
	const addBranchModel = {
		group_id: NumberType().isRequired(isRequired),
		branch: StringType().isRequired(isRequired),
		address: StringType().isRequired(isRequired),
		zip_code: StringType().isRequired(isRequired),
		country: StringType().isRequired(isRequired),
		city: StringType().isRequired(isRequired),
	}
	const addGroupModel = {
		group: StringType().isRequired(isRequired),
	}
	const editBranchModel = addBranchModel;
	const editGroupModel = addGroupModel;

	const addBranchInnerModel = {
		last_name: StringType().isRequired(isRequired),
		first_name: StringType().isRequired(isRequired),
		email: StringType().isRequired(isRequired),
		phone: StringType().isRequired(isRequired),
		position: StringType().isRequired(isRequired),
	}
	const addGroupInnerModel = {
		branch: StringType().isRequired(isRequired),
	}
	const editBranchInnerModel = addBranchInnerModel;
	const editGroupInnerModel = addGroupInnerModel;

	const [model, setModel] = useState(initModel);
	const [showInnerForm, setShowInnerForm] = useState(false);
	const formRef = useRef(null);

	useEffect(() => {
		if(type === "add") {
			if(clientType === "group") {
				setModel(addGroupModel);
			} else {
				setModel(addBranchModel);
			}
		} else {
			if(clientType === "group") {
				setModel(editGroupModel);
			} else {
				setModel(editBranchModel);
			}
		}
	}, [clientType, type, refresh]);

	useEffect(() => {
		if(showInnerForm) {
			let innerModel;
			if(type === "add") {
				if(clientType === "group") {
					innerModel = addGroupInnerModel;
				} else {
					innerModel = addBranchInnerModel;
				}
			} else {
				if(clientType === "group") {
					innerModel = editGroupInnerModel;
				} else {
					innerModel = editBranchInnerModel;
				}
			}
			setModel({...model, ...innerModel});
		} else {
			setRefresh(!refresh);
		}
	}, [showInnerForm]);

	useEffect(() => {
		if (deleteContact) {
			setFormValues((prev) => ({
				...prev,
				deleteContact
			}))
		}
	}, [deleteContact]);

	return (
        <div className="column al-center">
			<div className="w-60" style={style}>
				<Form fluid formValue={formValues} onChange={(e) => setFormValues(e)} ref={formRef} model={Schema.Model(model)}>
					<Form.Group>
						<Form.ControlLabel className={classes.inputLabel}>{I18n.t("clientType")}：</Form.ControlLabel>
						<Form.Control
                            name="clientType"
                            accepter={SelectPicker}
                            data={clientTypeList}
                            style={{ width: '100%' }}
                            value={clientType}
                            onChange={(e) => setClientType(e)}
							locale={localStorage.getItem('locale') === 'en' ? enUS.Picker : zhTW.Picker}
						/>
					</Form.Group>
					{
						// show Group / Branch according to clientType
						clientType === 'group' ?
							<ClientGroup 
								setModal={setModal} 
								modal={modal} 
								formValues={formValues} 
								setFormValues={setFormValues}
								tempBranches={tempBranches}
								setTempBranches={setTempBranches}
								setDeleteBranch={setDeleteBranch}
								type={label}
								formRef={formRef}
								innerFormStateFn={setShowInnerForm}
							/>
							:
							<ClientBranch
								modal={modal}
								setModal={setModal} 
								formValues={formValues} 
								setFormValues={setFormValues}
								clientGroup={clientGroup} 
								clientGroupList={clientGroup}
								machine={machine}
								data={data}
								type={label}
								contactsData={contactsData}
								setDeleteContact={setDeleteContact}
								innerFormStateFn={setShowInnerForm}
							/>
					}
					<Form.Group>
						<BlueSubmitButton loading={loading} onClick={() => formRef.current.check() ? onClick(formValues) : console.log(formRef.current.check(), model)}>
							{I18n.t(type == "add" ? "AddA" : "EditA", { name: I18n.t("client") })?.toUpperCase()}
						</BlueSubmitButton>
					</Form.Group>
				</Form>
			</div>
			<DeleteModal
				open={modal == "delete"}
				handleClose={() => setModal(false)}
				submit={() => console.log('delete')}
			/>
		</div>
    );
}

const ClientGroup = ({ type, formValues, setFormValues, clientBranch, tempBranches, setTempBranches, setDeleteBranch, formRef, innerFormStateFn}) => {
	const classes = useStyles();
	const [showInnerForm, setShowInnerForm] = useState(false);
	const [modal, setModal] = useState(false);
	const [selectIndex, setSelectIndex] = useState(null);
	const [lastBranchID, setLastBranchID] = useState(null);
	const [firstIndustryLevel, setFirstIndustryLevel] = useState(0);
	const { industryList, setIndustryList } = useIndustryList({ firstIndustryLevel });
	const { userList, searchUser } = useUserList();

	if (type === 'edit') {
		setLastBranchID(tempBranches[tempBranches.length - 1].id)
	}

	useEffect(() => {
		setFormValues((prev) => ({
			...prev,
			branches: tempBranches?.map(item => formatBranchKeys(item))
		}))
	}, [tempBranches]);

    const onChangeIndustry = (type, value) => {
      if (type === 'first') {
        setFirstIndustryLevel(value);
        setIndustryList({ ...industryList, second: industryList.originalSecond.filter(item => item.id_first_industry_level == value) });
      }
    }

    const formatBranchKeys = (obj) => {
      Object.keys(obj).forEach(key => {
        if (key.includes('branch_')) {
          const newKey = key.replace('branch_', '');
          obj[newKey] = obj[key];
          delete obj[key];
        }
      });
      return obj;
    }

    const addBranch = () => {
      if(formRef.current.check()) {
        setTempBranches((prev) =>
        ([...prev, {
          ...formValues,
          name: formValues.branch,
          id: lastBranchID + 1,
        }]));
        setLastBranchID((prev) => prev + 1);
        setShowInnerForm(false);
        innerFormStateFn(false);
        clearBranch();
      }
    }

    const editBranch = (val) => {
		  setSelectIndex(val);
      const columns = ['id', 'branch', 'address', 'city', 'zip_code', 'country', 'group', 'branch_firstIndustryLevel', 'branch_secondBusinessLevel', 'branch_salesman', 'branch_productManufactured'];
		  columns.forEach(key => {
        setFormValues((prev) => ({
          ...prev,
          [key]: tempBranches[val][key]
        }));
      });
	}

	const saveEdit = () => {
		let branch = [...tempBranches];
		branch[selectIndex] = {
      ...formValues,
      name: formValues.branch,
			id: tempBranches[selectIndex].id,
			id_client: tempBranches[selectIndex].id_client,
		}
		setTempBranches(branch);
		setShowInnerForm(false);
	}

	const clearBranch = () => {
    setFormValues((prev) => ({
      ...prev,
      branch: "",
      address: "",
      city: "",
      zip_code: "",
      country: "",
      branch_firstIndustryLevel: 0,
      branch_secondBusinessLevel: 0,
      branch_salesman: "",
      branch_productManufactured: "",
    }));
	}

	const deleteBranch = (val) => {
		setModal(true);
		setSelectIndex(val);
	}

	const submitDelete = () => {
		if (type !== "add") {
			setTempBranches(tempBranches.filter((item) => item.id !== tempBranches[selectIndex].id));
			const data = clientBranch?.filter((item) => item.id === tempBranches[selectIndex].id)
			data[0] && setDeleteBranch((prev) => [...prev, data[0].id]);
		} else {
			setTempBranches(tempBranches.filter((item) => item.id !== tempBranches[selectIndex].id));
		}
	}

    return <>
        <Form.Group>
            <Form.ControlLabel className={classes.inputLabel}>{I18n.t("GroupName")}：</Form.ControlLabel>
            <Form.Control
                name="group"
                placeholder={I18n.t("InsertA", { name: I18n.t("GroupName") })} />
        </Form.Group>
        <Form.Group>
            <Form.ControlLabel className={classes.inputLabel}>{I18n.t("firstIndustryLevel")}:</Form.ControlLabel>
            <Form.Control
                name="firstIndustryLevel"
                accepter={SelectPicker}
                data={industryList.first}
                style={{ width: '100%' }}
                onChange={(e) => onChangeIndustry('first', e)}
                locale={localStorage.getItem('locale') === 'en' ? enUS.Picker : zhTW.Picker}
            />
        </Form.Group>
        <Form.Group>
            <Form.ControlLabel className={classes.inputLabel}>{I18n.t("secondBusinessLevel")}:</Form.ControlLabel>
            <Form.Control
                name="secondBusinessLevel"
                accepter={SelectPicker}
                data={industryList.second}
                style={{ width: '100%' }}
                onChange={(e) => onChangeIndustry('second', e)}
                locale={localStorage.getItem('locale') === 'en' ? enUS.Picker : zhTW.Picker}
            />
        </Form.Group>
        <Form.Group>
            <Form.ControlLabel className={classes.inputLabel}>{I18n.t('Salesman')}：</Form.ControlLabel>
            <Form.Control
                name="salesman"
                accepter={SelectPicker}
                data={userList}
                onSearch={searchUser}
                labelKey="label"
                style={{ width: '100%' }}
                locale={localStorage.getItem('locale') === 'en' ? enUS.Picker : zhTW.Picker}
            />
        </Form.Group>
        <Form.Group>
            <Form.ControlLabel className={classes.inputLabel}>{I18n.t("productManufactured")}：</Form.ControlLabel>
            <Form.Control
                name="productManufactured"
                placeholder={I18n.t("InsertA", { name: I18n.t("productManufactured") })} />
        </Form.Group>
        <Form.Group style={{ marginBottom: 0 }}>
            <Form.ControlLabel className={classes.inputLabel}>{I18n.t('Branch')}</Form.ControlLabel>
            {
                showInnerForm === 'addBranch' || showInnerForm === 'editBranch' ?
                    <div className={`column ${classes.innerForm}`}>
                        <BranchForm
                            isAddGroup={true}
                            firstIndustryLevel={firstIndustryLevel}
                            setFirstIndustryLevel={setFirstIndustryLevel}
                        />
                        <div className='jc-end'>
                            <MuiButton className={classes.textBtn} variant='text' color='inherit' onClick={() => {
                                setShowInnerForm(false);
								innerFormStateFn(false);
                                clearBranch();
                            }}
                            >
                                {I18n.t("Cancel").toUpperCase()}
                            </MuiButton>
                            <MuiButton type="submit" className={classes.textBtn} variant='text' color='primary' onClick={showInnerForm === 'addBranch' ? addBranch : 
                            () => {
                                saveEdit();
                                clearBranch();
							}}>
                                {I18n.t(showInnerForm === 'editBranch' ? "EditA" : "AddA", { name: I18n.t("branch") })?.toUpperCase()}
                            </MuiButton>
                        </div>
                    </div>
                    :
                    <BlueBtn
                        label="outline"
                        text={I18n.t("CreateA", { name: I18n.t("branch") })}
                        style={{ padding: '8px 16px', width: 'fit-content', margin: 'auto' }}
                        onClick={() => {
                          setShowInnerForm('addBranch');
                          innerFormStateFn(true);
                        }}
                    />
            }
        </Form.Group>
        <List
            data={tempBranches}
			      isFetched={true}
            className={classes.list}
            width='100%'
            noAvatar
            deleteIcon={!showInnerForm}
            editIcon={!showInnerForm}
            delet={(val) => deleteBranch(val)}
            edit={(i) => {
                setShowInnerForm('editBranch');
                editBranch(i);
            }}
        />
        <DeleteModal
            open={modal}
            handleClose={() => setModal(false)}
            submit={() => {
                submitDelete()
                setModal(false)
            }}
        />
    </>;
}

const ClientBranch = ({ modal, setModal, clientGroupList, formValues, setFormValues, type, setDeleteContact, innerFormStateFn, contactsData }) => {
	const classes = useStyles();
	const { setError } = useContext(context);
	const [showInnerForm, setShowInnerForm] = useState(false);
	const [selectIndex, setSelectIndex] = useState(null);
	const [contacts, setContacts] = useState([]);
	const [clientGroupSelect, setClientGroupSelect] = useState(clientGroupList.map(({ name, id }) => ({ label: name, value: id }))); 
  const [firstIndustryLevel, setFirstIndustryLevel] = useState(0);

	useEffect(async () => {
		if (contactsData.length) {
			const processContactData = contactsData.map((contact) => {
				return (
					{
						...contact, 
						name: contact.last_name + contact.first_name
					}
				)
			})
			setContacts(processContactData)
		}
	}, []);

	useEffect(() => {
		setFormValues((prev) => ({
			...prev,
			contacts
		}))
	}, [contacts]);

	useEffect(() => {
		if (formValues?.group_id) {
			const id = clientGroupSelect.findIndex((item) => item.id == formValues.group_id);
			formValues.group = clientGroupSelect[id]?.id;
		}
	}, [formValues]);

	// contact
	const editContact = (val) => {
		setSelectIndex(val);
		formValues.last_name = contacts[val].last_name;
		formValues.first_name = contacts[val].first_name;
		formValues.phone = contacts[val].phone;
		formValues.email = contacts[val].email;
		formValues.position = contacts[val].position;
	}

	const deleteContact = (val) => {
		setModal("deleteContact");
		setSelectIndex(val);
	}

	const submitContact = () => {
		if (type !== "add") {
			setContacts(contacts.filter((item) => item.id !== contacts[selectIndex].id));
			const data = contacts?.filter((item) => item.id === contacts[selectIndex].id)
			data[0] && setDeleteContact((prev) => [...prev, data[0].id]);
		} else {
			setContacts(contacts.filter((item) => item.id !== contacts[selectIndex].id));
		}
	}

	const searchGroup = (searchValue) => {
		if (!searchValue) {
			if (searchValue === '') {
				setClientGroupSelect(clientGroupList.map(({ name, id }) => ({ label: name, value: id })));
			}
			return;
		}

		getClient({ name: searchValue, limit: 10 }).then(({ data }) => {
			setClientGroupSelect(data.map((client) => ({
				label: client.name,
				value: client.id,
			})));
		}).catch(err => errHandler(err, setError, route));
    }

	return <>
        <BranchForm
            clientGroupSelect={clientGroupSelect}
            searchGroup={searchGroup}
            firstIndustryLevel={firstIndustryLevel}
            setFirstIndustryLevel={setFirstIndustryLevel}
        />

        <Form.Group>
            <div className='jc-space-btw al-center'>
                <Form.ControlLabel className={classes.inputLabel}>{I18n.t('contacts')}：</Form.ControlLabel>
                {showInnerForm === false && <AddCircleIcon style="color:#2E65C9;font-size:40px;margin-left:16px;cursor:pointer" onClick={() => { setShowInnerForm('addContact'); innerFormStateFn(true)}} />}
            </div>
            {
                (showInnerForm === 'addContact' || showInnerForm === 'editContact') &&
                <ContactForm 
                    data={showInnerForm === 'editContact' ? contacts[selectIndex] : null} 
                    type={showInnerForm === 'addContact' ? "add" : "edit"} 
                    setShow={setShowInnerForm}
                    selectIndex={selectIndex}
                    contacts={contacts}
                    setContacts={setContacts}
                    editContact={editContact}
					          parentStateFn={innerFormStateFn}
                />
            }
            <List
                data={contacts}
				        isFetched={true}
                className={classes.list}
				        label="contact"
                width='100%'
                noAvatar
                deleteIcon={!showInnerForm}
                editIcon={!showInnerForm}
                delet={(val) => deleteContact(val)}
                edit={(i) => {
                    setShowInnerForm("editContact");
                    editContact(i);
                }}
            />
        </Form.Group>

        <DeleteModal
            open={modal === "deleteContact"}
            handleClose={() => setModal(false)}
            submit={() => {
                submitContact();
                setModal(false);
            }}
        />
    </>;
}

const BranchForm = ({ isAddGroup = false, clientGroupSelect = [], searchGroup = () => {}, firstIndustryLevel, setFirstIndustryLevel }) => {
    const classes = useStyles();
    const { industryList, setIndustryList } = useIndustryList({ firstIndustryLevel });
    const { userList, searchUser } = useUserList();

    const onChangeIndustry = (type, value) => {
		if (type === 'first') {
			setFirstIndustryLevel(value);
			setIndustryList({ ...industryList, second: industryList.originalSecond.filter(item => item.id_first_industry_level == value) });
		}
	}
    
    return (
        <>
            {!isAddGroup && (
                <Form.Group>
                    <Form.ControlLabel className={classes.inputLabel}>{I18n.t('associatedGroup')}：</Form.ControlLabel>
                    <Form.Control
                        name="group_id"
                        accepter={SelectPicker}
                        data={clientGroupSelect}
                        onSearch={searchGroup}
                        labelKey="label"
                        style={{ width: '100%' }}
                        locale={localStorage.getItem('locale') === 'en' ? enUS.Picker : zhTW.Picker}
                    />
                </Form.Group>
            )}
            <Form.Group>
                <Form.ControlLabel className={classes.inputLabel}>{I18n.t('BranchName')}：</Form.ControlLabel>
                <Form.Control
                    name="branch"
                    placeholder={I18n.t("InsertA", { name: I18n.t('BranchName') })} />
            </Form.Group>
            <Form.Group>
                <Form.ControlLabel className={classes.inputLabel}>{I18n.t('Address')}：</Form.ControlLabel>
                <Form.Control
                    name="address"
                    placeholder={I18n.t("InsertAn", { name: I18n.t('Address') })} />
            </Form.Group>
            <div className='d-flex'>
                <Form.Group style={{ marginRight: 16, width: 90 }}>
                    <Form.ControlLabel className={classes.inputLabel}>{I18n.t('ZipCode')}：</Form.ControlLabel>
                    <Form.Control
                        name="zip_code"
                        placeholder={I18n.t("InsertA", { name: I18n.t('ZipCode') })} />
                </Form.Group>
                <Form.Group style={{ flex: 1 }}>
                    <Form.ControlLabel className={classes.inputLabel}>{I18n.t('City')}：</Form.ControlLabel>
                    <Form.Control name="city" placeholder={I18n.t("InsertA", { name: I18n.t('City') })} />
                </Form.Group>
            </div>
            <Form.Group>
                <Form.ControlLabel className={classes.inputLabel}>{I18n.t('Country')}：</Form.ControlLabel>
                <Form.Control
                    name="country"
                    placeholder={I18n.t("InsertA", { name: I18n.t('Country') })} />
            </Form.Group>
            <Form.Group>
                <Form.ControlLabel className={classes.inputLabel}>{I18n.t("firstIndustryLevel")}:</Form.ControlLabel>
                <Form.Control
                    name="branch_firstIndustryLevel"
                    accepter={SelectPicker}
                    data={industryList.first}
                    style={{ width: '100%' }}
                    onChange={(e) => onChangeIndustry('first', e)}
                    locale={localStorage.getItem('locale') === 'en' ? enUS.Picker : zhTW.Picker}
                />
            </Form.Group>
            <Form.Group>
                <Form.ControlLabel className={classes.inputLabel}>{I18n.t("secondBusinessLevel")}:</Form.ControlLabel>
                <Form.Control
                    name="branch_secondBusinessLevel"
                    accepter={SelectPicker}
                    data={industryList.second}
                    style={{ width: '100%' }}
                    onChange={(e) => onChangeIndustry('second', e)}
                    locale={localStorage.getItem('locale') === 'en' ? enUS.Picker : zhTW.Picker}
                />
            </Form.Group>
            <Form.Group>
                <Form.ControlLabel className={classes.inputLabel}>{I18n.t('Salesman')}：</Form.ControlLabel>
                <Form.Control
                    name="branch_salesman"
                    accepter={SelectPicker}
                    data={userList}
                    onSearch={searchUser}
                    labelKey="label"
                    style={{ width: '100%' }}
                    locale={localStorage.getItem('locale') === 'en' ? enUS.Picker : zhTW.Picker}
                />
            </Form.Group>
            <Form.Group>
                <Form.ControlLabel className={classes.inputLabel}>{I18n.t("productManufactured")}：</Form.ControlLabel>
                <Form.Control
                    name="branch_productManufactured"
                    placeholder={I18n.t("InsertA", { name: I18n.t("productManufactured") })} 
                />
            </Form.Group>
        </>
    );
}

export default ClientInfo;
