import { makeStyles, alpha } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    '&.location, &.phone': {
      '& .MuiTimelineDot-root, & .timeLabel': {
        backgroundColor: '#2E65C9'
      },

      '& .timeLabel::before': {
        borderRightColor: '#2E65C9'
      },
    },

    '&.nopunch': {
      '& .MuiTimelineDot-root, & .timeLabel': {
        backgroundColor: '#FFB547'
      },

      '& .timeLabel::before': {
        borderRightColor: '#FFB547'
      },
    },

    '&.now': {
      '& .MuiTimelineDot-root, & .timeLabel': {
        backgroundColor: '#F5F5F5',
        color: theme.palette.text.secondary
      },

      '& .timeLabel::before': {
        borderRightColor: '#F5F5F5'
      },
    }
  },

  timeLabel: {
    color: '#FFF',
    padding: '3px 13px 3px 3px',
    borderTopRightRadius: '15px',
    borderBottomRightRadius: '15px',
    position: 'relative',
    height: 26,
    width: 73,

    '&::before': {
      content: '""',
      display: 'inline-block',
      border: `13px solid transparent`,
      height: '100%',
      position: 'absolute',
      top: 0,
      left: -26
    }
  },

  activity: {
    height: 56,
    backgroundColor: '#F5F5F5',
    marginLeft: theme.spacing(1),
    borderRadius: theme.spacing(1),
    color: theme.palette.text.secondary,


    '&.location:hover': {
      cursor: 'pointer',
      backgroundColor: '#E5E5E5'
    },

    '&.nopunch': {
      background: `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #FF9800`,
      border: `1px solid #FFDDAA`,

      '& > .warningText': {
        color: '#C77700'
      },

      '&:hover': {
        cursor: 'pointer',
        background: `linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #FF9800`,
      }
    },

    '& > *': {
      margin: theme.spacing(0, 1)
    }
  },

  timeRange: {
    width: 170,

    '& > div.MuiCard-root': {
      padding: theme.spacing(1, 2),
      borderRadius: theme.spacing(1),
      color: theme.palette.text.secondary
    }
  },

  marginLeft1: {
    marginLeft: theme.spacing(1)
  }
}));

export { useStyles };