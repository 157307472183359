import { useContext, useState, useEffect } from "preact/hooks";
import Context from "context";

export const useIndustryList = ({ firstIndustryLevel }) => {
  const locale = localStorage.getItem('locale');
  const { firstIndustryLevelList, secondIndustryLevelList } = useContext(Context);
  const [industryList, setIndustryList] = useState({ first: [], second: [], originalSecond: [] });

  useEffect(() => {
    setIndustryList({
      first: formatIndustryOptions(firstIndustryLevelList),
      second: firstIndustryLevel > 0 ? formatIndustryOptions(secondIndustryLevelList.filter(item => item.id_first_industry_level === firstIndustryLevel)) : [],
      originalSecond: formatIndustryOptions(secondIndustryLevelList)
    });
  }, [firstIndustryLevelList, secondIndustryLevelList]);

  const formatIndustryOptions = (data) => {
    return data?.map(item => ({
      ...item,
      label: locale === 'en' ? item.industry_name_eng : item.industry_name_ch,
      value: item.id
    })) || [];
  }

  return {
    industryList,
    setIndustryList,
  }
}