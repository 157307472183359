/** @jsx h */
import { h } from "preact";
import { useEffect, useState } from "preact/hooks";
import { IconButton } from "@material-ui/core";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

const ItemPicker = ({ setState, children }) => {
    const [index, setIndex] = useState(0);
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        if (mounted) {
            setState(index);
        }
    }, [index]);

    return (
        <div style={{
            display: "flex", 
            alignItems: "center", 
            justifyContent: "space-between", 
            width: "200px"
        }}
        >
            <IconButton
                size="small"
                style={{
                    borderRadius: "5px",
                    backgroundColor: "#f5f5f5"
                }}
                onClick={() => setIndex(prev => prev - 1)}
                disabled={index === 0}
            >
                <KeyboardArrowLeft />
            </IconButton>
            <div>{children[index]}</div>
            <IconButton
                size="small"
                style={{
                    borderRadius: "5px",
                    backgroundColor: "#f5f5f5"
                }}
                onClick={() => setIndex(prev => prev + 1)}
                disabled={index === children.length - 1}
            >
                <KeyboardArrowRight />
            </IconButton>
        </div>
    )
}

export default ItemPicker;