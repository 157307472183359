/** @jsx h */
import { h } from "preact";
import { useState, useEffect, useContext } from "preact/hooks";

import Typography from "@material-ui/core/Typography";

import UserProfile from "./UserProfile";
import { AddButton } from "components/atoms/addButton/AddButton";
import { DeleteModal } from "components/molecules/deleteModal/DeleteModal";
import { Row } from "components/atoms/row/Row";
import { UserTable } from "components/molecules/userTable/UserTable";
import { MultiSelect } from "components/atoms/multiSelect/MultiSelect";
import CubeBtn from "components/atoms/cubeButton/CubeBtn";
import Modal from "../../components/atoms/modal/Modal";
import EmployeeInfo from "../../components/organisms/modalContent/employee/EmployeeInfo";
import I18n from "i18n/i18n";
import { getClientBranch, getClient, postUserCreate, getUserList, getRoleString, deleteUser, getDivision } from "api";
import { useStyles } from "style/style";
import Context from "../../context";

import { useRole } from "hooks";

// context snackbar error handling
import { errHandler } from "../../util";
import { route } from "preact-router";


const User = () => {
  const classes = useStyles();
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  //FOR DROPDOWN
  const [selectedGroup, setSelectedGroup] = useState([0]);
  //GROUP TO PASS
  const [inputFilter, setInputFilter] = useState('');
  const [user, setUser] = useState();
  const { 
    userList, 
    setUserList, 
    groupList, 
    setClientGroupList, 
    setClientBranchList, 
    setRoleList, 
    setError, 
    setDivisionList,
    setMessage
  } = useContext(Context)
  const [userListToDisplay, setUserListToDisplay] = useState([]);

  const role = useRole()
  // flag state variable used to determine fetching status
  const [isFetched, setFetched] = useState(false);

  const loadData = async () => {
    try {
      setFetched(false);
      const users = (await getUserList({ avoid_brilltek_testing: true, include_obselete: true })).data;
      const clients = (await getClient()).data;
      const branches = (await getClientBranch()).data;
      const roles = (await getRoleString()).data;
      const divisions = (await getDivision()).data;
      setUserList(users);
      setClientGroupList(clients);
      setClientBranchList(branches);
      setRoleList(roles);
      setDivisionList(divisions);
    } catch (err) {
      errHandler(err, setError, route);
    }
  }

  useEffect(() => {
    if (!isFetched) {
      loadData();
    }
  }, [isFetched]);

  const onOpen = (modal) => {
    setModal(modal);
  };

  const onClose = () => {
    setLoading(false);
    setModal(false);
  };

  const setUserInfo = async (val) => {
    // when edit user data from UserProfile, need to update User to show data correctly
    // use typeof to know calling from table or profile
    if (typeof val === 'object') {
      setUser(val)
    } else {
      try {
        // if calling from profile, set the new data by filtering through id
        setFetched(false);
        const users = (await getUserList({ avoid_brilltek_testing: true })).data;
        setUser(users.find((row) => row.id === val));
      } catch (err) {
        errHandler(err, setError, route);
      }
    }
  }

  const addEmployee = async (val) => {
    const {
      emailAddress,
      firstname,
      lastname,
      employeeRoles,
      phoneNumber,
      address,
      zipCode,
      city,
      workingTeam, //region
      team,
      division,
      id_erp,
      erp_symbol
    } = val;
    setLoading(true);

    let id_region;
    if (workingTeam) {
      id_region = workingTeam;
    } else if (team && groupList.length) {
      const group = groupList.find((group) => group.id === team);
      id_region = group.id_region;
    }

    await postUserCreate({
      email: emailAddress,
      first_name: firstname,
      last_name: lastname,
      id_role: employeeRoles + 1,
      id_group: team,
      id_region,
      id_division: division,
      phone: phoneNumber,
      id_erp,
      erp_symbol,
      //NOT CURRENTLY USED
      zip_code: zipCode,
      address: address,
      city: city,
    })
      .then(() => {
        setLoading(false);
        setMessage(I18n.t("requestSuccessful", { name: I18n.t("user"), verb: I18n.t("created")}))
        onClose()
      }).catch((err) => {
        setLoading(false);
        errHandler(err, setError, route);
      });
    // reload list by setting isFetched to false
    setFetched(false);
  }

  const disableEmployee = (id) => {
    deleteUser(id)
        .then(() => {
            setModal(false);
            setMessage(I18n.t("requestSuccessful", { name: I18n.t("user"), verb: I18n.t("disabled") }));
            setUser(null);
            setFetched(false);
        })
            .catch((err) => errHandler(err, setError, route));
    }

   useEffect(() => {
    if (userList && userList.length != 0) {
        const value = inputFilter.toLowerCase();
	    setUserListToDisplay(
            userList.filter(user => 
                user.last_name.toLowerCase().concat(user.first_name.toLowerCase())?.includes(value) || 
                // user.last_name?.includes(inputFilter) || 
                user.email?.toLowerCase().includes(value) || 
                user.phone?.toLowerCase().includes(value)
            )
        );
        setFetched(true);
      }
   }, [inputFilter, userList]);

   if (user) {
    return <UserProfile data={user} back={() => setUser(null)} handleDisable={disableEmployee} reloadUserInfo={setUserInfo}/>
  } 
    return (
      <div className={classes.root}>
        <Row
          justifyContent="space-between"
          alignItems="center"
          className={classes.marginBottom2}
        >
          <Typography variant="h5" color="inherit">
            {I18n.t("EmployeeList")}
          </Typography>
          <Row style={{ gap: 10 }} alignItems="center">
            <CubeBtn
                iconType="search"
                expandable
                placeholder={`${I18n.t("username")}, ${I18n.t("email")}, ${I18n.t("phone")}`}
                searchText={inputFilter}
                onChange={(text) => setInputFilter(text)}
            />
            {(role.isBigBoss || role.isManager) && 
                <MultiSelect
                    data={groupList}
                    value={selectedGroup}
                    title="team"
                    labelKey="name"
                    valueKey="id"
                    placeholder={I18n.t('team')}
                    onChange={setSelectedGroup}
                />
            }
            { role.isBigBoss && <AddButton
              onClick={() => onOpen('edit')}
              style={{ height: 39, width: 39 }}
            /> }
          </Row>
        </Row>
        <UserTable
            modal={modal}
            onOpen={onOpen}
            group={selectedGroup}
            setUserInfo={setUserInfo}
            isFetched={isFetched}
            userList={userListToDisplay}
            pageResetKey={inputFilter}
        />
        <Modal
          title={["AddAn", "Employee"]}
          open={modal === 'edit'}
          close={onClose}
          style={{ paddingBottom: '64px' }}>
          <EmployeeInfo label="add" onClick={(val) => addEmployee(val)} loading={loading}/>
        </Modal>
        <DeleteModal
          open={modal === "delete"}
          onClose={onClose}
          name={I18n.t("Employee")}
        />
      </div>
    );
};

export default User;
