/** @jsx h */
import { h } from 'preact';
import I18n from "i18n-js";

import Card from "@material-ui/core/Card";
import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core/styles";

import { Row } from "components/atoms/row/Row";
import { Column } from 'components/atoms/column/Column';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(2)
  },

  teamLabel: {
    marginBottom: theme.spacing(2),

    '& span': {
      color: theme.palette.text.primary
    },

    '& > p + p': {
      marginLeft: theme.spacing(2)
    }
  },

  picture: {
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2),
    minHeight: 143,
    backgroundColor: '#FAFAFA',
    border: '1px solid #E0E0E0'
  },

  timeArea: {
    backgroundColor: '#EEEEEE',
    borderRadius: theme.spacing(1),
    height: 85,
    width: 240,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& > span': {
      margin: theme.spacing(0, 2),
    },

    '& > div.MuiCard-root': {
      padding: theme.spacing(1, 2),
      borderRadius: theme.spacing(1),
      color: theme.palette.text.secondary,
      display: 'flex',
      alignItems: 'center',
      fontSize: '1.15rem'
    }
  },

  datePicker: {
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',

    '& > .rs-picker-toggle': {
      border: 'none',

      '& .rs-picker-toggle-value': {
        color: theme.palette.text.secondary
      }
    }
  },

  datePickerMenu: {
    zIndex: 1300,
    padding: theme.spacing(1, 2),
    borderRadius: theme.spacing(1),
    color: theme.palette.text.secondary
  },

  descriptionsArea: {
    marginLeft: theme.spacing(2),
    justifyContent: 'space-between',
    flex: 1,
    minWidth: 335,

    '& > div > span': {
      fontSize: 16,
      fontWeight: 500,
      color: '#B0B0B0',
      marginBottom: theme.spacing(1)
    },
  },

  description: {
    width: '100%',
    flexWrap: 'wrap',

    '& span': {
      fontSize: 16,
      color: '#B0B0B0',
    },

    '& span + span': {
      color: '#000000',
      marginBottom: theme.spacing(2.5)
    }
  }
}));

const Visit = ({ data }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Row className={classes.teamLabel}>
        <Typography variant='body2' color="textSecondary">
          {I18n.t("group")} : <span>{data.team}</span>
        </Typography>
        <Typography variant='body2' color="textSecondary">
          {I18n.t("Salesman")} : <span>{data.salesman}</span>
        </Typography>
      </Row>
      <Column justifyContent='center' alignItems='center' style={{ flexWrap: 'wrap' }}>
        <Description
          label={I18n.t('client')}
          value={data.location}
        />
        <Description
          label={I18n.t('Address')}
          value={data.address}
        />
        <Description
          label={I18n.t('reason')}
          value={data.reason}
        />
        <div className={classes.timeArea}>
          <Card>
            <span>{data.start}</span>
          </Card>
          <span>-</span>
          <Card>
            <span>{data.end}</span>
          </Card>
        </div>
      </Column>
    </div>
  )
}

const Description = ({ icon, label, value }) => {
  const classes = useStyles();

  return (
    <Row justifyContent='space-between' className={classes.description}>
      <span>{label}</span>
      <span>{value}</span>
    </Row>
  )
}

export default Visit;