// Must be the first import
import "preact/debug";
import "preact/devtools";
import "rsuite/dist/rsuite.min.css";

import { useContext, useEffect, useState } from "preact/hooks";
import { Router, route } from "preact-router";
import moment from "moment/min/moment-with-locales";
import "moment/locale/zh-tw";
import Context from "context";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

// icon
import WebIcon from "@material-ui/icons/Web";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import WorkOutlineOutlinedIcon from "@material-ui/icons/WorkOutlineOutlined";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import PersonPinCircleIcon from "@material-ui/icons/PersonPinCircle";
import WhatshotIcon from "@material-ui/icons/Whatshot";
import LayersIcon from '@material-ui/icons/Layers';
import BusinessCardIcon from '@material-ui/icons/CreditCard';

// routes
import Dashboard from "routes/Dashboard";
import SalesTransactionRate from "routes/sales/TransactionRate";
import PunchOverview from 'routes/punch/Overview';
import PunchByEmployee from "routes/punch/ByEmployee";
import SalesNewOverview from "routes/sales/NewOverview";
import SalesObjective from "routes/sales/Objective";
import SalesPredictions from "routes/sales/Prediction";
import SalesStatistics from "routes/sales/Statistics";
import SalesPlan from "routes/sales/Plan";
import SalesOrderInfo from "routes/sales/OrderInfo";
import Accomplish from 'routes/sales/Accomplish';
import LatestReportsOverview from "routes/latestReports/Overview";
import Client from "routes/client/Client";
import User from "routes/employee/User";
import UserProfile from "routes/employee/UserProfile";
import Announcement from "routes/employee/Announcement";
import MachineOverview from "routes/machines/Overview";
import Region from "routes/employee/Region";
import Competitor from "routes/competitors/Competitor";
import CompetitorScaleCompare from "routes/competitors/ScaleCompare";
import BusinessCardExhibitionList from 'routes/businessCard/ExhibitionList';
import BusinessCardList from 'routes/businessCard/BusinessCardList';
import { Page } from "./organisms/page/Page";
import { Login } from "components/organisms/login/Login";
import { getUserList, getAbbreviation, getUser, getFirstIndustryLevel, getSecondIndustryLevel } from "api";
import I18n from "i18n/i18n";
import { getToken, removeToken } from "helper/token";

// error pages
import Page400 from "../routes/error/400";
import Page401 from "../routes/error/401";
import Page403 from "../routes/error/403";
import Page404 from "../routes/error/404";
import Page500 from "../routes/error/500";
import Page502 from "../routes/error/502";

const languages = [
	{ key: "zh-TW", name: "繁體中文" },
	{ key: "en", name: "English" },
];

export const pages = [
	{ icon: <WebIcon />, to: "/page/dashboard", name: "Dashboard" },
	{
		icon: <PersonPinCircleIcon />,
		to: "/page/punch/overview",
		name: "PunchRecord",
		children: [
			{ id: 'overview', text: 'Overview', to: '/page/punch/overview' },
			{ id: 'byEmployee', text: 'ByEmployee', to: '/page/punch/byEmployee' },
		],
	},
	{
		icon: <AttachMoneyIcon />,
		to: "/page/sales/statistics",
		name: "Sales",
		children: [
			{ id: "statistics", text: "SubmenuStatistics", to: '/page/sales/statistics' },
			{ id: "newOverview", text: "NewOverview", to: '/page/sales/newOverview' },
			{ id: "rate", text: "TransactionRate", to: '/page/sales/rate' },
			{ id: "predict", text: "Prediction", to: '/page/sales/predict' },
			{ id: "plan", text: "Plan", to: '/page/sales/plan' },
			{ id: "objective", text: "Goal", to: '/page/sales/objective' },
			{ id: "accomplish", text: "accomplish", to: '/page/sales/accomplish' },
		],
	},
	{ icon: <ReportProblemOutlinedIcon />, 
		to: "/page/latestReport/salesreport", 
		name: "LatestReports",
		children:[
			{ id: "salesreport", text: "Salesreport", to: '/page/latestReport/salesreport' },
			{ id: "quotation", text: "Quotation", to: '/page/latestReport/quotation' },
		] },
		{ icon: <WorkOutlineOutlinedIcon />, to: "/page/client", name: "Client" },
	{
		icon: <WhatshotIcon />,
		to: "/page/competitor/list",
		name: "competitors",
		children: [
			{ id: 'list', text: 'CompetitorList', to: '/page/competitor/list' },
			{ id: 'scale', text: 'ScaleCompare', to: '/page/competitor/scale' },
		],
	},
	{
		icon: <PeopleOutlineIcon />,
		to: "/page/employee/user",
		name: "Employees",
		children: [
			{ id: "user", text: "Overview", to: '/page/employee/user' },
			{ id: "annoucement", text: "announcement", to: '/page/employee/annoucement' },
			{ id: "region", text: "Region", to: '/page/employee/region' },
		],
	},
	{
		icon: <LayersIcon />,
		to: "/page/machines/overview",
		name: "machines",
		children: [
			{ id: "overview", text: "Overview", to: '/page/machines/overview' },
		],
	},
	{
		icon: <BusinessCardIcon />,
		to: '/page/businessCard/exhibitionList',
		name: 'BusinessCardManagement',
		children: [
			{ id: 'exhibitionList', text: 'ExhibitionList', to: '/page/businessCard/exhibitionList' },
			{ id: 'businessCardList', text: 'BusinessCardList', to: '/page/businessCard/businessCardList' },
		]
	}
];

const Main = () => {
	const { setToken } = useContext(Context);

	const logout = () => {
		removeToken()
		setToken(null);
		route("/login");
	};

	return (
		<Page
			pages={pages}
			languages={languages}
			logout={logout}
		>
			<Router>
				<Dashboard path="/page/dashboard" />
				<SalesTransactionRate path="/page/sales/rate" />
				<SalesNewOverview path="/page/sales/newOverview" />
				<SalesPredictions path="/page/sales/predict" />
				<SalesStatistics path="/page/sales/statistics" />
				<SalesPlan path="/page/sales/plan" />
				<Accomplish path="/page/sales/accomplish" />
				<SalesObjective path="/page/sales/objective" />
				<LatestReportsOverview path="/page/latestReport/salesreport" />
				<SalesOrderInfo path="/page/latestReport/quotation" />
				<Client path="/page/client" />
				<User path="/page/employee/user" />
				<UserProfile path="/page/employee/UserProfile" />
				<Announcement path="/page/employee/annoucement" />
				<Region path="/page/employee/region" />
				<MachineOverview path="/page/machines/overview" />
				<PunchByEmployee path="/page/punch/byEmployee" />
				<PunchOverview path="/page/punch/overview" />
				<Competitor path="/page/competitor/list" />
				<CompetitorScaleCompare path="/page/competitor/scale" />
				<BusinessCardExhibitionList path='/page/businessCard/exhibitionList' />
				<BusinessCardList path='/page/businessCard/businessCardList' />
			</Router>
		</Page>
	);
};

const PrivateRoute = ({ children }) => {
	const { token } = useContext(Context);
	const [auth, setAuth] = useState();

	useEffect(() => {
		if (token !== undefined && token !== null) {
			getUserList()
				.then(() => setAuth(true))
				.catch(() => setAuth(false));
		}
	}, [token]);

	if (auth === true) {
		return children;
	} else if (auth === false) {
		return <Redirect to="/login" />;
	} else {
		return <div />;
	}
};

const Redirect = ({ to }) => {
	useEffect(() => {
		route(to, true);
	}, []);
	return null;
};

const App = () => {
	const {
    setAbbreviation,
    setUser,
    token,
    setToken,
    error,
    setError,
    message,
    setMessage,
    setFirstIndustryLevelList,
    setSecondIndustryLevelList,
  } = useContext(Context)

	useEffect(() => {
		const _token = getToken();
		if (_token) {
			setToken(_token);
			getAbbreviation().then((res) => setAbbreviation(res.data))
		} else {
			setToken(null);
            route("/login");
        }
    }, []);

    useEffect(() => {
		if (localStorage.getItem("locale")) {
			I18n.locale = localStorage.getItem("locale");
		} else {
			I18n.locale = navigator.language;
			localStorage.setItem("locale", I18n.locale);
		}
		moment.locale(I18n.locale.toLowerCase());
	}, []);

	useEffect(() => {
		if(token){
			getUser().then((res) => setUser(res.data))
			getFirstIndustryLevel().then((res) => setFirstIndustryLevelList(res.data))
			getSecondIndustryLevel().then((res) => setSecondIndustryLevelList(res.data))
		}
	}, [token]);

	const handleClose = () => {
		setError();
		setMessage();
	};

	return (
		<div style={{ width: "100%", height: "100%" }}>
			<Router>
				<Login path="/login" page="login" />
				<PrivateRoute path="/">
					<Redirect to="/page/dashboard" />
				</PrivateRoute>
				<PrivateRoute path="/page">
					<Redirect to="/page/dashboard" />
				</PrivateRoute>
				<PrivateRoute path="/page/:name">
					<Main />
				</PrivateRoute>
				<PrivateRoute path="/page/:name/:sub">
					<Main />
				</PrivateRoute>
				<Page400 path="/page/400" />
				<Page401 path="/page/401" />
				<Page403 path="/page/403" />
				<Page404 path="/page/404" />
				<Page500 path="/page/500" />
				<Page502 path="/page/502" />
			</Router>

			<Snackbar open={error !== undefined} autoHideDuration={6000} onClose={handleClose}>
				<MuiAlert elevation={6} variant="filled" onClose={handleClose} severity="error">
					{error}
				</MuiAlert>
			</Snackbar>
			<Snackbar open={message !== undefined} autoHideDuration={6000} onClose={handleClose}>
				<MuiAlert elevation={6} variant="filled" onClose={handleClose} severity="success">
					{message}
				</MuiAlert>
			</Snackbar>
		</div>
	);
};

export default App;
