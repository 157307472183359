import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  card: {
    display: 'block',
    padding: theme.spacing(2),
    border: '1px solid #E0E0E0',
    borderRadius: theme.spacing(1),
  },
  title: {
    display: 'block',
    fontSize: '20px',
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 'bold'
  },
  content: {
    fontSize: '48px',
    textAlign: 'center',
    fontWeight: 400,
    padding: theme.spacing(2),
    color: '#2E65C9'
  }
}))

export default useStyles;