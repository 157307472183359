import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  select: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    height: 40,
    minWidth: "12.5rem",
    maxWidth: "21.875rem",
    backgroundColor: "white",
    borderRadius: "8px",
    transition: "all 300ms ease",
    whiteSpace: "nowrap",
    overflowY: "hidden",
    textOverflow: "ellipsis",
  },
});

export default useStyles;
