import React from 'react';
import { useState, useEffect, useMemo } from 'preact/hooks';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Row } from 'components/atoms/row/Row';
import I18n from 'i18n/i18n';
import { DatePicker } from 'rsuite';
import CubeBtn from 'components/atoms/cubeButton/CubeBtn';
import { MultiSelect } from 'components/atoms/multiSelect/MultiSelect';
import { SortableTable } from 'components/molecules/sortableTable/SortableTable';
import { getCountryList, getExhibitionList, getLandList } from 'api';
import moment from 'moment';
import { useUpdateEffect } from 'hooks';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4),
    },
    DatePicker: {
        '& .rs-picker-toggle': {
            backgroundColor: '#FFFFFF',
            padding: '9px 12px',
            paddingRight: 36,
            border: 'none',
            borderRadius: 8,
            fontSize: 15
        },
        '& .rs-picker-toggle-value': {
            color: '#0000008a !important',
        }
    }
}));

const STATUSES = [
    { status: 'online', color: '#00a700' },
    { status: 'upcoming', color: '#9e9e9e' },
    { status: 'offline', color: '#f44336' },
];

// Fake Data
const COLUMNS = [
    { id: 'status', sortable: true, cell: (row) => (
        <div>
            <div
                style={{
                    width: '1rem',
                    height: '1rem',
                    borderRadius: '50%',
                    backgroundColor: STATUSES.find(({ status }) => status === row.status).color,
                }}
            />
        </div>
    ) },
    { id: 'exhibitionName', sortable: true },
    { id: 'continent', sortable: true },
    { id: 'country', sortable: true },
    { id: 'start_date', sortable: true },
    { id: 'end_date', sortable: true },
];

const STATUS_PRIORITY = ['online', 'upcoming', 'offline']

const ExhibitionList = () => {
    const classes = useStyles();

    const [exhibitions, setExhibitions] = useState([]);
    const [landOptions, setLandOptions] = useState([]);
    const [countryOptions, setCountryOptions] = useState([]);

    const [date, setDate] = useState(null);
    const [status, setStatus] = useState([0]);
    const [land, setLand] = useState([0]);
    const [country, setCountry] = useState([0]);
    const [searchText, setSearchText] = useState('');

    const filteredExhibitions = useMemo(() => {
        const isDateInRange = (startDate, endDate) => {
            const momentDate = moment(date);
            return momentDate.isBetween(startDate, endDate, 'months', '[]');
        };

        const filter = (exhibition) => {
            return (
                (!status.length || status.includes(0) || status.includes(exhibition.status))
                && (!searchText || exhibition.exhibitionName.toLowerCase().search(searchText.toLowerCase()) !== -1)
                && (!land.length || land.includes(0) || land.includes(exhibition.LAND_ID))
                && (!country.length || country.includes(0) || country.includes(exhibition.COUNTRY_NO))
                && (
                    !date
                    || isDateInRange(
                        moment(exhibition.start_date, 'YYYY/MM/DD'),
                        moment(exhibition.end_date, 'YYYY/MM/DD')
                    )
                )
            );
        };
        return exhibitions.filter(filter);
    }, [exhibitions, date, status, land, country, searchText]);

    useEffect(() => {
        (async () => {
            try {
                let exhibitions = (await getExhibitionList()).data;
                const lands = (await getLandList()).data;
                const countrys = (await getCountryList()).data;

                const exhibitionHandler = (exhibition) => {
                    return {
                        ...exhibition,
                        status: exhibition.STATUS,
                        exhibitionName: exhibition[I18n.locale === 'en' ? 'SHOW_ENAME' : 'SHOW_CNAME'],
                        continent: exhibition[I18n.locale === 'en' ? 'LAND_ENAME' : 'LAND_NAME'],
                        country: exhibition[I18n.locale === 'en' ? 'COUNTRY_ENAME' : 'COUNTRY_NAME'],
                        start_date: moment(exhibition.SHOW_BEGDATE.toString()).format('YYYY/MM/DD'),
                        end_date: moment(exhibition.SHOW_ENDDATE.toString()).format('YYYY/MM/DD'),
                    };
                };
                const landHandler = (land) => {
                    return {
                        ...land,
                        text: land[I18n.locale === 'en' ? 'LAND_ENAME' : 'LAND_NAME'],
                        id: land.LAND_ID,
                    };
                };
                const countryHandler = (country) => {
                    return {
                        ...country,
                        text: country[I18n.locale === 'en' ? 'COUNTRY_ENAME' : 'COUNTRY_NAME'],
                        id: country.COUNTRY_ID,
                    };
                };
                const sortedExhibitions = exhibitions.sort((a, b) => {
                    if (a.SHOW_CNAME === '客訪') return -1;
                    if (b.SHOW_CNAME === '客訪') return 1;
                    return STATUS_PRIORITY.indexOf(a.STATUS) - STATUS_PRIORITY.indexOf(b.STATUS);
                });
                setExhibitions(sortedExhibitions.map(exhibitionHandler));
                setLandOptions(lands.map(landHandler));
                setCountryOptions(countrys.map(countryHandler));
            } catch (error) {
                console.log('Get Exhibition Error: ', error);
                if (!!error.response) console.log(error.response.data);
            }
        })();
    }, []);

    useUpdateEffect(() => { setCountry([0]); }, [land]);

    return (
        <div className={classes.root}>
            <Row
                justifyContent='space-between'
                alignItems='center'
                className={classes.marginBottom2}
            >
                <Typography variant='h5' color='inherit'>
                    {I18n.t('ExhibitionList')}
                </Typography>
                <Row style={{ gap: 10 }} alignItems='center'>
                    <DatePicker
                        size="lg"
                        format='yyyy-MM'
                        oneTap
                        ranges={[]}
                        className={classes.DatePicker}
                        placeholder={I18n.t('start_date')}
                        value={date}
                        onChange={(value) => {
                            setDate(value);
                        }}
                    />
                    <MultiSelect
                        data={STATUSES.map(({ status }) => ({ text: I18n.t(status), id: status }))}
                        value={status}
                        labelKey="text"
                        valueKey="id"
                        title="status"
                        placeholder={I18n.t('status')}
                        onChange={(value) => setStatus(value)}
                    />
                    <MultiSelect
                        data={landOptions}
                        value={land}
                        labelKey="text"
                        valueKey="id"
                        title="continent"
                        placeholder={I18n.t('continent')}
                        onChange={(value) => setLand(value)}
                    />
                    <MultiSelect
                        data={countryOptions.filter(({ LAND_ID }) => !land.length || land.includes(0) || land.includes(LAND_ID))}
                        value={country}
                        labelKey="text"
                        valueKey="id"
                        title="country"
                        placeholder={I18n.t('country')}
                        onChange={(value) => setCountry(value)}
                    />
                    <CubeBtn
                        iconType='search'
                        expandable
                        searchText={searchText}
                        placeholder={I18n.t("exhibitionName")}
                        onChange={(text) => {
                            setSearchText(text);
                        }}
                    />
                </Row>
            </Row>
            <Row justifyContent='flex-end' style={{ marginBlock: '1rem' }}>
                {STATUSES.map(({ status, color }) => (
                    <Row alignItems='center'>
                        <div
                            style={{
                                width: '1rem',
                                height: '1rem',
                                borderRadius: '50%',
                                backgroundColor: color,
                            }}
                        />
                        <div style={{ marginInline: '1rem' }}>
                            {I18n.t(status)}
                        </div>
                    </Row>
                ))}
            </Row>
            <SortableTable
                columns={COLUMNS}
                data={filteredExhibitions}
                isFetched={true}
                striped
                hovered
            />
        </div>
    );
};

export default ExhibitionList;