/** @jsx h */
import { h, cloneElement } from "preact";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  card: {
    width: 250,
    height: 250,
    cursor: "pointer",
    fontWeight: "bold",
    borderRadius: 10,
    backgroundColor: "#fafafa",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  iconLarge: {
    fontSize: 100,
  },
  label: {
    marginTop: theme.spacing(1),
    fontWeight: "bold",
  },
}));

export const IconBlock = ({
  onClick,
  icon,
  label,
  borderWidth,
  borderStyle,
  borderRadius,
  borderColor,
  backgroundColor,
  color,
}) => {
  const classes = useStyles();
  return (
    <Card
      variant="outlined"
      className={classes.card}
      style={{
        borderWidth,
        borderStyle,
        borderColor,
        borderRadius,
        backgroundColor,
      }}
      onClick={onClick}
    >
      <div className={classes.content}>
        {icon && cloneElement(icon, { className: classes.iconLarge })}
        {label && (
          <Typography className={classes.label} style={{ color }}>
            {label}
          </Typography>
        )}
      </div>
    </Card>
  );
};

IconBlock.propTypes = {
  icon: PropTypes.element,
  label: PropTypes.string,
  borderWidth: PropTypes.string,
  borderStyle: PropTypes.string,
  borderRadius: PropTypes.string,
  borderColor: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
};

IconBlock.defaultProps = {
  icon: undefined,
  label: undefined,
  borderWidth: undefined,
  borderStyle: undefined,
  borderRadius: undefined,
  borderColor: undefined,
  color: "inherit",
  onClick: () => {},
};
