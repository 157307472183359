/** @jsx h */
import { h } from 'preact';
import { useEffect, useState } from 'react';
import I18n from "i18n-js";

import Typography from '@material-ui/core/Typography';
import { Form, SelectPicker } from 'rsuite';

import BlueBtn from 'components/atoms/blueButton/BlueBtn';
import { Row } from "components/atoms/row/Row";
import { Column } from "components/atoms/column/Column";
import { useStyles } from './SetYearObj.style';
import { enUS, zhTW } from 'rsuite/esm/locales';

const SetYearObj = ({ data }) => {
	const [formValues, setFormValues] = useState(data);
	const classes = useStyles()

	const currencyOption = [
		{
			label: '$ TWD',
			value: 'twd'
		},
		{
			label: '$ USD',
			value: 'usd'
		}
	];

	const unitOption = [
		{
			label: I18n.t('dollar'),
			value: 'dollar'
		},
		{
			label: I18n.t('tenthousand'),
			value: 'tenthousand'
		},
		{
			label: I18n.t('hundredmillion'),
			value: 'hundredmillion'
		}
	];

	return (
		<Column justifyContent='center' alignItems='center' style={{ height: '100%' }}>
			<Form className={classes.form} formValue={formValues} onChange={(e) => setFormValues(e)}>
				<Row justifyContent='space-between' alignItems='center'>
					<Form.Control
						size="lg"
						name='currency'
						className={classes.input}
						accepter={SelectPicker}
						searchable={false}
						cleanable={false}
						data={currencyOption}
						locale={localStorage.getItem('locale') === 'en' ? enUS.Picker : zhTW.Picker}
					/>
					<Form.Control
						size="lg"
						name='unit'
						className={classes.input}
						accepter={SelectPicker}
						searchable={false}
						cleanable={false}
						data={unitOption}
						locale={localStorage.getItem('locale') === 'en' ? enUS.Picker : zhTW.Picker}
					/>
					<Form.Control
						size="lg"
						name='value'
						className={classes.input}
					/>
				</Row>
				<BlueBtn label='main' text={I18n.t('SetYearObjective').toUpperCase()} style={{ margin: '16px' }} onClick={() => console.log('submit')} />
			</Form>
		</Column>
	)
}

export default SetYearObj;