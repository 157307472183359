/** @jsx h */
import { h } from "preact";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
  button: {
    // margin: theme.spacing(1),
  },
}));

export const AddButton = ({ style, className, ...props }) => {
  const classes = useStyles();

  return (
    <IconButton
      {...props}
      className={clsx(classes.button, className)}
      style={{ backgroundColor: "#2E65C9", ...style }}
      size="small"
    >
      <AddIcon style={{ color: "white" }} />
    </IconButton>
  );
};
