export default {
	Pagination: `%{from}-%{to} 共 %{count}`,
	Action: "操作",

	// Login
	Login: "登入",
	email: "電子郵件",
	password: "密碼",
	employNum: "工號",
	RememberMe: "記住我",
	SALESMAN_BLOCKING: "權限：業務員帳號無法進入後台頁面",

	// Home
	Language: "語言",
	Logout: "登出",
	Employee: "人員管理",
	LatestReports: "最新報告",
	Sales: "銷售",
	CollapseMenu: "隱藏選單",
	Dashboard: "儀錶板",
	crm: "業務",
	syncErp: "同步ERP",
	syncClient: "同步ERP客戶",

	//Punch In/Out Record
	ByEmployee: '員工總覽',
	Employees: '員工',
	PunchRecord: "打卡記錄",
	startOfDay: "一天的開始",
	endOfDay: '一天的結束',
	DayView: '單日檢視',
	WeekView: '一週檢視',
	Now: '現在',
	at: "於 ",
	noPunch: "無打卡紀錄",
	mapModalTitle: "行程從 %{start} 至 %{end}",
	visitModalTitleLocation: "拜訪 %{location}",
	from: ' 從 ',
	visitModalTitleTime: "%{start} 至 %{end}",
	abnormalBehavior: "打卡異常",
	reason: '拜訪原因',
	empty: '無',
	upcoming: '未開始',
	working: '執行中',
	alreadyLeft: '已離開',
	noPunchRecord: '無打卡記錄',
	'ratingText_1': '購機機會渺茫',
	'ratingText_2': '發展性低，購機意願低',
	'ratingText_3': '發展性普通，購機意願不明，擇日再拜訪',
	'ratingText_4': '互動良好，購機意願高，加強互動',
	'ratingText_5': '近期有購機可能',
	quotationChance: '報價機會',
	yes: '是',
	no: '否',
	'Routine visiting': '例行拜訪',
	'Quotation negotiating': '訂單洽談',
	'Bill collecting': '收款',
	'Customer service': '客戶處理',
	'Exchange activities': '產業活動',
	StartOfDay: '一天的開始',

	// Dashboard
	monthlySales: "每月銷售額",
	salesFromLastMonth: "上個月的銷售額",
	reportsRatings: "報告評級",
	totalReports: "報告總數",
	averageRating: "平均評分",
	quotationsEfficiency: "報價效率",
	quotationsRateAbove: "報價率A及以上",
	totalPaid: "總支付",
	paymentLeft: "未付金額",
	late: "遲到",
	sales: "銷售量",
	ratio: "比率",
	salesmenEfficiencyRankAll: "業務員效率排名（全部）",
	name: "名稱",
	quotations: "報價單",
	machinesBestSellerRank: "機台暢銷排名（數量）",
	latestUpdate: "最新狀態",
	ranking: "排名",
	progress: "進度說明",
	clients: "客戶",

	//Sales
	TransactionRate: "潛在訂單筆數",
	MonthlyTrend: "月份趨勢",
	MonthlyStatistics: "新增訊息統計",
	Prediction: "潛在訂單金額統計",
	SubmenuStatistics: "新增訊息統計",
	NewOverview: "訊息總覽",
	Plan: "機台預估接單計畫",
	OrderInfo: "訂單訊息表",
	Goal: "目標設定",
	Export: "匯出",
	newOrderCounts: '新增訊息筆數',
	customerCountry: '客戶國家',
	totalValue: '總計',
	expect_buy_date: '預計購買日',
	clientName: '客戶名稱',
	Download: "下載",

	// Sales > 訊息總覽
	regionManager: "區域管理人",
	country: "國家",
	updateProgess: "更新進度",
	updateDate: "更新日期",
	unitPrice: "台幣單價",
	subTotalPrice: "台幣小計",
	quotationMachines: "報價機種",
	source: "消息來源",
	expectProduct: "欲製產品",
	paymentMethod: "付款方式",
	actualPrice: "現金底價(價格表)",
	factors: "接單關鍵因素或瓶頸",
	factory: "廠別",
	machineQuantity: "總共%{quantity}種",
	percentagesLower: "低底價",
	transactionMonth: "成交月份",
	currency_symbol: "幣別",
	exchange_rate: "匯率",
	exchange_negotiation_price: "原幣單價",

	// Sales > Objective(Goal)
	year: '年度',
	currentMonth: '當月',
	month: '月份',
	unit: '單位',
	thousandDollar: '仟台幣',
	area: '區域',
	percentage: '達成率',
	firstHalf: '%{year} 上半年',
	secondHalf: '%{year} 下半年',

	//LatestReportsOverview
	report_number: "單號",
	salesman: "負責業務",
	client: "客戶",
	branch: "子公司",
	deal: "有報價",
	noDeal: "未報價",
	date: "日期",
	rate: "成交率",
	dealStatus: "報價狀態",
	reportType: "類別",

	//LatestReportsReport
	stars: "星",
	seeQuotation: "查看報價單",
	byUser: "參與人",
	details: "詳細資料",
	actualMachines: "機台",
	machinetype: "機台類型",
	brand: "品牌",
	quantity: "數量",
	competitors: "競爭對手",
	overall: "總結",
	dateOfCreation: "建立時間",
	challengeToSell: "出售挑戰",
	dealIssue: "接單關鍵因素或瓶頸",
	seeNote: "查看備註",
	to: "至",
	image: "圖片",

	// Client
	Client: "客戶管理",
	Group: "母公司",
	Branch: "子公司",
	seeMore: "查看更多",
	seeGroup: "查看母公司",
	bestSeller: "最佳商品",
	yearStatistic: "年度統計",
	monthStatistic: "月份統計",
	contactsList: "聯絡人列表",
	firstIndustryLevel: "產業別",
	secondBusinessLevel: "行業別",

	competitor: "競爭對手",
	clientType: "客戶類型",
	associatedGroup: "母公司",
	optional: "選填",
	companyName: "公司名稱",
	industry: "行業",
	sizeOfCompany: "公司大小",
	ceo: "CEO",
	revenue: "收入",
	machine: "機台",
	machines: "機台",
	contact: "聯絡人",
	contacts: "聯絡人",
	topFifty: '前五十大',
	VisitCounts: "拜訪次數",
	QuotationCounts: "報價次數",
	SuccessfulQuotations: "成功報價",
	rank: '排名',

	// Business Card
	uploader: "上傳者",
	uploadDate: "名片日期",
	uploaderRegion: "上傳者區域",
	CardList: "名片列表",
	CardInformation: "%{name}之名片",
	CommonInformation: "基本資訊",
	DetailInformation: "詳細資訊",
	address: "地址",
	companyAddress: "地址",
	fax: "傳真",
	website: "網址",
	attribute: "經銷商別",
	cardFront: "正面",
	cardBack: "反面",
	attribute_1: "製造商",
	attribute_2: "貿易商",
	attribute_3: "代理商",
	attribute_4: "其他",
	opportunity_1: "A (成交)",
	opportunity_2: "B (待成交)",
	opportunity_3: "C (爭取)",
	opportunity_4: "D (培養)",
	opportunity_5: "E (存檔)",

	// Employee
	EmployeeList: "員工列表",
	username: "員工名稱",
	phone: "電話號碼",
	group: "組別",
	group_role: "組別/職位",
	Overview: "總覽",
	TeamsManagement: "組別管理",
	RegionsManagement: "區域管理",
	users: "成員",
	transport: "運輸種類",
	announcement: "訊息公佈",
	Team: "組別",
	TeamName: "組別名稱",
	Division: "部門",
	Salesman: "業務員",
	AreaCode: "區域碼",
	ZipCode: "郵遞區號",
	City: "城市",
	Country: "鄉鎮",
	WorkPosition: "職位",
	EmployeeRole: "身份",
	ManagingRegion: "管理區域",
	ChangePic: "更改圖片",
	Visit: "拜訪",
	GroupName: "母公司名稱",
	BranchName: "子公司名稱",
	AvaliableTime: "預約時間",
	VisitReason: "拜訪原因(選填)",
	VisitReasonPlaceholder: "填入原因",
	IDERP: "工號",
	ERPSymbol: "系統代號",

	// Employee profile
	UserProfile: "的檔案",
	Information: "基本資料",
	FirstName: "名字",
	LastName: "姓氏",
	FullName: "姓名",
	Address: "地址",
	JoinedDate: "加入日期",
	Experience: "加入時間",
	NextVisits: "即將拜訪",
	Statistics: "統計數據",
	SalesHistory: "銷售紀錄",
	LastReports: "最新報告",
	// Employee profile modals
	SCHEDULE_DELETED: "拜訪已刪除",
	SCHEDULE_CREATED: "拜訪已建立",
	SCHEDULE_UPDATED: "拜訪已更新",
	START_TIME_AFTER_END_TIME: "結束時間不能早於開始時間或與開始時間相同",
	MISSING_BRANCH: "您沒有輸入子公司名字",
	MISSING_TIME: "您並沒有輸入開始時間",

	// OrderInfo
	MonthObjective: "%{month}訂單目標",
	Objective: "%{quater} 目標",
	Accomplish: "達成率 :",
	id_quotation: "報價單編號",

	// OrderDetails
	order: "訂單 %{value}",
	quotation: "報價",
	totalPaid: "已付金額",
	paymentLeft: "未付金額",
	actualDiff: "實際報價差異",
	dealRate: "成交率",
	paid: "已付金額",
	total: "總額",
	originalPrice: "原價",
	expectedProduct: "欲製產品",
	sources: "消息來源",
	priceCondition: "價格條件",
	paymentMethod: "付款方式",
	expectedBuyingDate: "預計購買日期",
	expectedOrderMonth: "預計購買日",
	createdDate: "建立日期",
	noteForOption: '%{optionId} 的備註',
	additionalOptions: '額外選配',
	'Phone': '電話',
  'Normal': '見面',
  'Old': '舊客戶',
  'New': '新客戶',
  'factor': '因素',
  'Price': '價格',
  'Connections': '人脈',
  'Quality': '品質',
  'Reputation': '口碑',
  'FactorySpace': '廠房空間',
  'Service': '服務',
  'Specification': '規格',
  'Special process': '特殊製程',
	'Taiwan machine': '台灣機',
	'China machine': '大陸機',

	// Sources
	'Self-developed': '自行開發',
	'Internet platform': '網路平台',
	Distributors: '通路商',
	'Industry introduction': '業者介紹',
	Exhibition: '展會',
	Others: '其他',

	// Factors
	PRICE: '價格',
	CONNECTIONS: '人脈',
	QUALITY: '品質',
	REPUTATION: '口碑',
	SERVICE: '服務',
	SPECIFICATION: '規格',
	'SPECIAL PROCESS': '特殊製程',

	//Gauge
	target: "目標",
	EST: "報價總金額",

	// User
	UploadA: "上傳%{name}",
	CreateA: "新增%{name}",
	AddA: "新增%{name}",
	AddAn: "新增%{name}",
	EditA: "編輯%{name}",
	EditAn: "編輯%{name}",
	SelectA: "請選擇%{name}",
	SelectAn: "請選擇%{name}",
	InsertA: "請輸入%{name}",
	InsertAn: "請輸入%{name}",
	InsertThe: "請輸入%{name}",
	Create: "新增",
	Cancel: "取消",
	Confirm: "確認",
	DeleteConfirmTitle: "刪除",
	DeleteConfirm: "確定要刪除",
	Delete: "刪除",
	DisableConfirmAccount: '帳號停用後將無法重啟，',
	DisableConfirmTitle: "停用",
	DisableConfirm: "確定要停用",
	Disable: "停用",
	RowsPerPage: "每頁行數：",
	Questionmark: "嗎？",
	All: "全部%{name}",
	SeeDetails: "顯示更多",
	Edit: "編輯",
	EditThe: "編輯%{name}",
	Region: "區域",
	RegionName: "區域名稱",
	region: "區域",
	allRegions: "全部區域",
	regionAddType: "類別",
	allGroups: "全部群組",
	N: "北區",
	C: "中區",
	S: "南區",
	Taiwan: "台灣區",
	International: "外貿",
	Nation: '國家',

	TOKEN_NOT_FOUND: "連結已失效，請重新傳送郵件",

	// Orderdetails > form
	machineModel: "機台型號",
	totalPrice: "總金額",
	productManufactured: "營業項目",
	others: "其他",
	thirtyPercentDeposit: "30%訂金",
	notesOptional: "備註 （選填）",
	inputYourNote: "輸入您的備註",
	saveChanges: "儲存變更",
	othersCompetitors: "其他競爭對手",

	//HTTP status texts
	Successful: "成功",
	BadRequest: "錯誤請求",

	//Sales > Quotations > Orders
	orderNum: "訂單編號",
	price: "價格",
	status: "狀態",
	allStatus: '全部狀態',
	closed: '已結案',
	notClosed: '未結案',
	ErrSelectedTimeOverlap: "輸入的時間不能超過當前日期",
	clientDeleted: "[客戶已不存在]",
	sum: "合計",
	cost: "金額",

	//WeeklyStatistics
	reportCounts: '拜訪回報筆數',
	StatisticSalesman: "業務",
	StatisticTotal: "訊息總數",
	avgTransactions: '平均報價',
	averageWeeklyTotal: '每週平均筆數',
	fromTo: '從 %{start} 到 %{end}',
	quotationCount: '訊息筆數',
	reportCounts: '拜訪回報筆數',
	quotationPrice: '訊息金額',

	//Latest Reports
	Salesreport: "拜訪回報",
	Quotation: "報價單",
	SeeQuotations: "查看報價單",

	//Sales > ABC Rate
	totalA: "A以上評分總數",
	bySalesman: "以業務排列",
	comparePreviousWeek: "與上週比較",

	// Order Status
	filterBy: '依 %{text}',
	department: '部門',
	objective: '目標',
	objectivePrice: '目標金額(NTD)',
	expectedPrice: '預估金額(NTD)',
	accomplish: "達成率",
	accomplish2: "達成率", // need this for same header in one table
	paidAmount: '已下單金額',
	unpaidAmount: '未下單金額',
	total: '總數',

	// Sales > Accomplish
	AccomplishReports: '接單出車金額彙總表',
	Type: '類型',
	monthAccumulate: '月累計',
	yearAccumulate: '年累計',
	actual: '實績',
	salesIn: '接單',
	salesOut: '出車',

	// Competitors
	CompetitorList: '競爭列表',
	ScaleCompare: '規格比較表',
	competitorTitle: "競爭對手列表",
	competitorName: "競爭者名稱",
	machineType: "機種",
	addMachine: "新增機台",
	editMachine: "編輯機台",
	colorType: '單/多色',
	series: '系列',
	machineTypeAndPound: '機種/噸數',
	pounds: '噸數',
	pound: '噸數',
	addCompare: '加入比較表',
	addedToCompare: '已加入比較表',
	'INJECTION UNIT': '射出單元',
	'MOULD CLAMPING UNIT': '夾模單元',
	'ELECTRICAL EQUIOMENT': '電氣單元',
	'OTHERS': '其他',
	DATA_TYPE: '類型',
	CLAMPING_NAME: '夾模型式',
	POWER_NAME: '驅動型式',
	INDUSTRY_NAME: '應用行業',
	DRIVE_NAME: '驅動型式',
	'SCREW DIAMETER': '螺桿直徑',
	'SCREW STROKE': '射出行程',
	'THEORETICAL SHOT VOLUME': '理論射出容積',
	'SHOT WEIGHT OF INJECTION (PS)': '理論射出量',
	'INJECTION PRESSURE': '射出壓力',
	'INJECTION SPEED': '射出速度',
	'INJECTION RATE': '射出率',
	'PLASTICIZING CAPACITY(PS)': '熔膠量',
	'SCREW ROTATION SPEED': '螺桿轉速',
	'MOULD CLAMPING FORCE': '閉模力',
	'MOULD CLAMPING STROKES': '夾模行程',
	'MOULD THICKNESS': '模厚',
	'SUGGESTED MINIMUM MOLD DIM (H×V)': '建議最小模具尺寸',
	'DISTANCE BETWEEN TIE BAR': '大柱內距',
	'MOULD PLATEN': '模盤尺寸',
	'EJECTOR STROKE': '頂出行程',
	'EJECTOR FORCE': '頂出力',
	'PUMP DRIVING MOTOR': '電機馬達',
	'TEMPERTURE CONTROLLER': '溫度控制器',
	'Heater capacity': '電熱容量',
	'MACHINE DIMENSION  ( L×W×H )': '機械尺寸',
	'OIL TANK CAPACITY': '油箱容量',
	'MACHINE WEIGHT': '機械重量',
	'Max system pressure': '最高系統壓力',

	// Announcement
	Announcement: '公告',
	SelectBroadcastRecipient: '選擇公佈對象',
	SelectBroadcaseLastDay: "選擇公佈天數",
	AnnouncementPlaceholder: '請輸入您的公佈內容...',
	Announce: '公佈',
	all: '全部',
	day: "%{number}天",
	days: "%{number}天",
	MISSING_ANNOUNCEMENT_CONTENT: "您並沒有輸入公佈內容",
	FILE_SIZE_OVER_LIMIT: "檔案大小超過限制(50MB)",
	FILE_TYPE_NOT_ACCEPTED: "檔案類型僅支援以下幾種：.HEIC .jpeg .jpg .png .gif .svg .pdf .doc .docx .csv .xls .xlsx .ppt .pptx .txt .zip .odp",
	FILE_NUM_OVER_LIMIT: "檔案數量超過限制(5個)",
	AnnouncementDate: "公佈日期",
	AnnouncementContent: "公佈內容",
	File: "檔案",

	//Form Validation
	required: "必填",
	notEmail: "非正確電郵",
	atleastNineDigits: "至少九位數",
	notNumber: "必須純數字",

	YearObjective: '年度目標',
	SetYearObjective: '設定年度目標',
	dollar: '元',
	tenthousand: '萬',
	hundredmillion: '億',

	//machine
	equipment: '配備',
	compare: '差異',
	machineSeries: '機台系列',
	originalprice: '原價',
	additionalCharge: '額外收費',
	standard: '標配',
	option: '選配',
	free: '免費',
	none: '無',
	options: '選配',
	import: '匯入',
	category: '分類',
	statement: '說明',
	machineName: '機台名稱',
	seeOptions: "查看選配",
	optionsFor: '%{name}的選配',
	data: '資料',
	or: '或',
	noOption: '沒有選配',
	optionsForSomeMachines: '其中%{count}台的選配',
	equipment_A: '標準',
	equipment_B: '選配',
	equipment_C: '無',

	// BusinessCardManagement
	BusinessCardManagement: '名片管理',
	ExhibitionList: '展會列表',
	BusinessCardList: '名片列表',
	offline: '離線',
	online: '上線',
	exhibitionName: '展會名稱',
	land: '區域',
	start_date: '開始日期',
	end_date: '結束日期',
	meetingType: '洽訪目的',
	opportunity: '客戶等級',
	customerName: '客戶名',
	company: '公司名',
	exhibition: '訊息類別',
	cell_phone: '手機',
	visit_purpose: '拜訪原因',
	remark: '註記',
	continent: '洲別',
	Synchronized: "同步",

	// Abbreviation
	challenge_1: '原因 1',
	challenge_2: '原因 2',
	client_type_1: '舊客戶',
	client_type_2: '新客戶',
	factor_1: '價格',
	factor_2: '名聲',
	factor_3: '廠區大小',
	factor_4: '服務',
	machine_option_1: "標配",
	machine_option_2: "選配",
	machine_option_3: "免費",
	machine_option_4: "無",
	meeting_type_1: '通話會議',
	meeting_type_2: '正常會議',

	//HTTP status texts
	Successful: "成功",
	BadRequest: "錯誤請求",

	// API CALL SUCCESS MESSAGES
	requestSuccessful: "%{name}已成功%{verb}!",

	// SUCCESS MESSAGE NOUNS
	user: "成員",
	team: "組別",
	ROLE_BIGBOSS: "BIGBOSS",
	ROLE_MANAGER: "Manager",
	ROLE_LEADER: "Leader",
	ROLE_SALES: "Salesman",

	// SUCCESS MESSAGE VERBS
	created: "建立",
	sent: "送出",
	updated: "更新",
	deleted: "移除",
	disabled: "停用",

	//ERRORS
	// ERROR TYPES
	Invalid: "您輸入的%{name}並不正確",
	NOT_FOUND: "該%{name}並不存在",
	ALREADY_FOUND: "該%{name}已存在",
	WRONG_PASSWORD: "您輸入的密碼不正確",

	// ERROR ROUTES
	ROUTE_USER: "用戶",
	ROUTE_CLIENT: "公司",
	ROUTE_GROUP: "組別",
	ROUTE_CLIENT_BRANCH_MACHINE: "廠區機台",
	ROUTE_CLIENT_BRANCH: "子公司",
	ROUTE_CLIENT_CONTACT: "聯絡人",
	ROUTE_COMPETITOR_MACHINE: "競爭者機台",
	ROUTE_COMPETITOR: "競爭對手",
	ROUTE_GPS: "GPS",
	ROUTE_IMAGE: "圖片",
	ROUTE_NOTIFICATION: "通知",
	ROUTE_PUNCH: "打卡紀錄",
	ROUTE_QUOTATION: "報價",
	ROUTE_REGION: "地區",
	ROUTE_REPORT: "報告",
	ROUTE_ROLE: "身份",
	ROUTE_SCHEDULE: "拜訪",
	ROUTE_TEMPLATE: "模板",
	ROUTE_TRIP_TYPE: "交通模式",

	// ERROR FIELDS
	FIELD_email: "電子郵件",
	FIELD_address: "地址",
	FIELD_zip_code: "郵遞區號",
	FIELD_city: "城市",
	FIELD_id_land: "州別",
	FIELD_id_country: "國家",
	FIELD_type: "經銷商別",
	FIELD_custom_type: "其他屬性",
	FIELD_REQUIRED: "請填寫所有必填欄位",

	//ERROR PAGES
	goBack: "回上一頁",
	errorCode: "%{code}錯誤",
	//400
	badRequest: "錯誤的請求",
	badRequestDesc: "您送出了一個不正確的請求",
	description: "敘述",
	//401
	unauthorizedAccess: "缺少權限",
	unauthorizedAccessDesc: "您送出的請求沒有提供一個驗證令牌，或者該令牌已過期。",
	pleaseVerify: "請重新驗證你的令牌然後再試一次。",
	//403
	noPermission: "您並沒有存取這個頁面的權限",
	//404
	notFound: "拍謝！您正在尋找的頁面並不存在",
	notFoundListTitle: "造成這個問題可能的原因為：",
	notFoundList1: "我們遇到技術上的問題",
	notFoundList2: "我們在重新設計此網站的時候刪除了此頁面",
	notFoundList3: "您輸入的網址太過老舊，且已失去其效用。",
	notFoundList4: "您可能輸入了錯誤的網址。",
	//500
	unexpectedError: "伺服器端出現了一個突發錯誤",
	unexpectedErrorDesc: "我們正在處理中, 請稍後再試",
	// map charts
	KLU: "基隆市",
	TPH: "新北市",
	TPE: "臺北市",
	TYC: "桃園市",
	HSH: "新竹縣",
	HSC: "新竹市",
	MAL: "苗栗縣",
	TXG: "臺中市",
	CWH: "彰化縣",
	NTO: "南投縣",
	YLH: "雲林縣",
	CHY: "嘉義縣",
	CHI: "嘉義市",
	TNN: "台南市",
	KHH: "高雄市",
	IUH: "屏東縣",
	ILN: "宜蘭縣",
	HWA: "花蓮縣",
	TTT: "台東縣",
	PEH: "澎湖縣",
	KIN: "金門縣",
};
