import { useEffect, useState, useContext } from 'preact/hooks'

import Context from 'context'

import { getUser } from '../api'

export const useRole = () => {
  const { user } = useContext(Context)
  const role = user.id_role

  const [auth, setAuth] = useState({
    isSalesman: Number(role) === 4,
    isTeamLeader: Number(role) === 3,
    isManager: Number(role) === 2,
    isBigBoss: Number(role) === 1,
  })

  const { isSalesman, isTeamLeader, isManager, isBigBoss } = auth

  useEffect(() => {
    const hasNoContextRole = !role
    if (hasNoContextRole) {
      loadRole()
    }
  }, [role])

  const loadRole = async () => {
    try {
      const { data: user } = await getUser()
      const roleId = user.id_role
      setAuth({
        isSalesman: roleId === 4,
        isTeamLeader: roleId === 3,
        isManager: roleId === 2,
        isBigBoss: roleId === 1,
      })
    } catch (e) {
      console.log(e.response.data)
    }
  }

  const permissionUndefined = !(
    isSalesman ||
    isTeamLeader ||
    isManager ||
    isBigBoss
  )
  return {
    isLoading: permissionUndefined,
    isSalesman,
    isTeamLeader,
    isManager,
    isBigBoss,
    roleId: role,
    hasHighPermission: isManager || isBigBoss,
    hasLowPermission: isSalesman || isTeamLeader,
  }
}