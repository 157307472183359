/** @jsx h */
import { h } from "preact";
import { forwardRef } from "preact/compat";

export const Column = forwardRef(
  (
    {
      justifyContent,
      alignItems,
      flex,
      style,
      children,
      ...props
    },
    ref
  ) => {
    return (
      <div
        {...props}
        ref={ref}
        style={{
          ...style,
          display: "flex",
          flexDirection: "column",
          justifyContent,
          alignItems,
          flex,
        }}
      >
        {children}
      </div>
    );
  }
);
