/** @jsx h */
import React from "react";
import { h } from "preact";
import I18n from "i18n-js";
import { Jdenticon } from "components/atoms/Jdenticon";
import { Table } from "rsuite";
import Grid from '@material-ui/core/Grid';
import { Typography, Divider } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import TimeIcon from '@material-ui/icons/AccessTime';
import CarIcon from '@material-ui/icons/DirectionsCar';
import QuotationIcon from '@material-ui/icons/Assignment';
import MoneyIcon from '@material-ui/icons/MonetizationOnOutlined';
import { Row } from 'components/atoms/row/Row';
import { Column } from 'components/atoms/column/Column';
import { useStyles } from './ClientRightInfo.style';
// SKELETON
import { Skeleton } from "@material-ui/lab";
import { zeroArray } from "../../../util";
import { useEffect, useState } from "preact/hooks";
import { getOrder, getQuotation, getSchedule } from "api";
import moment from "moment";
import { useRole } from "hooks";

const bestSellerColumns = [
  { key: "bestSeller", flex: 3, align: 'left' },
  { key: "name", flex: 5, align: 'left' },
  { key: "quantity", flex: 1, align: 'left' },
];

const ClientRightInfo = ({ data, type = 'branch', showHeader, bestSeller = [] }) => {
  const locale = localStorage.getItem('locale');
  const classes = useStyles();
  const { roleId } = useRole()

  const initialMonthStats = [
    {
      title: I18n.t('VisitCounts'),
      value: 0,
      icon: <CarIcon />,
      color: 'rgba(54, 189, 42, 0.1)',
      iconColor: '#36BD2A'
    },
    {
      title: I18n.t('QuotationCounts'),
      value: 0,
      icon: <TimeIcon />,
      color: 'rgba(59, 121, 212, 0.1)',
      iconColor: '#1890FF'
    },
  ];
  const initialYearStats = [
    {
      title: I18n.t('VisitCounts'),
      value: 0,
      icon: <CarIcon />,
      color: 'rgba(54, 189, 42, 0.1)',
      iconColor: '#36BD2A'
    },
    {
      title: I18n.t('quotations'),
      value: 0,
      icon: <QuotationIcon />,
      color: 'rgba(59, 121, 212, 0.1)',
      iconColor: '#4FAFCD'
    },
    {
      title: I18n.t('SuccessfulQuotations'),
      value: 0,
      icon: <MoneyIcon />,
      color: 'rgba(160, 103, 217, 0.1)',
      iconColor: '#A067D9'
    },
  ];

  const [monthStats, setMonthStats] = useState(initialMonthStats);
  const [yearStats, setYearStats] = useState(initialYearStats);

  const getIndustryLabel = (data) => {
    if (!data) return '-';
    return locale === 'en' ? data.industry_name_eng : data.industry_name_ch;
  }

  const getStats = async (from, time) => {
    let scheduleCount;
    let quotationCount;
    let orderCount;

    const payload = {
      from,
      to: moment(new Date()).format('YYYY-MM-DD'),
      is_punch: true,
    };

    try {
      // Fetch scheduleCount
      if (type === 'group') {
        const allScheduleLengths = await Promise.all(data?.branches?.map(async (branch) => {
          return (await getSchedule({ ...payload, id_client_branch: branch.id })).data.length;
        }));
        scheduleCount = allScheduleLengths.reduce((prev, current) => prev + current, 0);
      } else {
        scheduleCount = (await getSchedule({ ...payload, id_client_branch: data.branch_id })).data.length;
      }

      // Fetch quotationCount
      delete payload.is_punch;
      if (type === 'group') {
        const allQuotationLengths = await Promise.all(data.branches.map(async (branch) => {
          return (await getQuotation({ ...payload, id_client_branch: branch.id }, roleId)).data.length;
        }));
        quotationCount = allQuotationLengths.reduce((prev, current) => prev + current, 0);
      } else {
        quotationCount = (await getQuotation({ ...payload, id_client_branch: data.branch_id }, roleId)).data.length;
      }

      if (time === 'year') {
        if (type === 'group') {
          const allOrderLengths = await Promise.all(data.branches.map(async (branch) => {
            return (await getOrder({ ...payload, id_client_branch: branch.id })).data.length;
          }));
          orderCount = allOrderLengths.reduce((prev, current) => prev + current, 0);
        } else {
          orderCount = (await getOrder({ ...payload, id_client_branch: data.branch_id })).data.length;
        }
      }
    } catch (error) {
      console.log(`Get ${time} Stats Error: `, error);
      if (!!error.response) console.log(error.response.data);
    }

    return { scheduleCount, quotationCount, ...(time === 'year' ? { orderCount } : {}) };
  };

  // Fetch Stats
  useEffect(() => {
    if (!data?.id && !data?.branch_id) return;

    // Fetch Month Stats
    (async () => {
      const { scheduleCount, quotationCount } = await getStats(moment(new Date()).format('YYYY-MM').concat('-01'), 'month');
      setMonthStats(prev => {
        return [
          { ...prev[0], value: scheduleCount },
          { ...prev[1], value: quotationCount },
        ];
      })
    })();

    // Fetch Year Stats
    (async () => {
      const { scheduleCount, quotationCount, orderCount } = await getStats(moment(new Date()).format('YYYY').concat('-01-01'), 'year');
      setYearStats(prev => {
        return [
          { ...prev[0], value: scheduleCount },
          { ...prev[1], value: quotationCount },
          { ...prev[2], value: orderCount },
        ];
      })
    })();
  }, [data]);

  return (
    data ?
      <>
        <div>
          {/* logo */}
          <Row className={classes.logoArea} style={{ marginBottom: 20 }}>
            {/* <div className='logo'>
              {
                data?.imgUrl ?
                  <div className={classes.logo}>
                    <Avatar style={{ width: 80, height: 80 }} src={data.imgUrl} />
                  </div>
                  :
                  <div className={classes.logo}>
                    <Jdenticon size="80" value={data.originalName || data.name} />
                  </div>
              }
            </div> */}
            <Typography variant='h6' color='textPrimary'>{data.name || data.branch}</Typography>
          </Row>
          {/* detail */}
          <Column style={{ backgroundColor: '#FAFAFA', borderRadius: 8 }}>
            <DetailRow title="Salesman" value={data?.name_of_salesman} isFirst />
            <DetailRow title="firstIndustryLevel" value={getIndustryLabel(data?.info_first_industry_level)} />
            <DetailRow title="secondBusinessLevel" value={getIndustryLabel(data?.info_second_industry_level)} />
            <DetailRow title="productManufactured" value={data?.product_manufactured} isBottom />
          </Column>
        </div>
        <Grid container spacing={3}>
          {/* best products */}
          <Grid item xs={12} style={{ marginTop: 15, marginBottom: 15 }}>
            <ExpandableArea
              expand={() => console.log('expand')}
              // style={{ height: '100%', padding: 10 }}
            >
              <Table autoHeight showHeader={showHeader} data={bestSeller} bordered={false} hover rowHeight={67}>
                {bestSellerColumns.map((column, index) => {
                  return (
                    <Table.Column key={index} align={column.align} flexGrow={column.flex}>
                      <Table.HeaderCell>{I18n.t(column.key)}</Table.HeaderCell>
                      <Table.Cell dataKey={column.key} verticalAlign='middle' />
                    </Table.Column>
                  );
                })}
              </Table>
            </ExpandableArea>
          </Grid>
          {/* anaylsis */}
          <Grid item xs={5}>
            <ExpandableArea
              title={I18n.t("monthStatistic")}
              expand={() => console.log('expand')}
            >
              <Row flex='1 1 auto' justifyContent='space-around' alignItems='center'>
                {monthStats.map((item, key) => (
                  <StatisticItem key={key} data={item} />
                ))}
              </Row>
            </ExpandableArea>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item style={{ flex: 1 }}>
            <ExpandableArea
              title={I18n.t("yearStatistic")}
              expand={() => console.log('expand')}
            >
              <Row flex={1} justifyContent='space-around' alignItems='center'>
                {yearStats.map((item, key) => (
                  <StatisticItem key={key} data={item} />
                ))}
              </Row>
            </ExpandableArea>
          </Grid>
        </Grid>
      </>
      :
      <SkeletonContent />
  )
};

const SkeletonContent = () => {
  const classes = useStyles();

  return (
    <>
      <Row className={classes.logoArea} style={{ marginBottom: 20 }}>
        {/* <div className='logo'>
          <Skeleton variant="rect" width={80} height={80} />
        </div> */}
        <Typography variant='h6' color='textPrimary'>
          <Skeleton variant="text" style={{ width: "5rem" }} />
        </Typography>
      </Row>
      <Column className={classes.logoArea}>
        {
          zeroArray(4).map((_) =>
            <Skeleton variant="text" width="100%" height={35} />
          )
        }
      </Column>
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ marginTop: 15, marginBottom: 15 }}>
          <ExpandableArea
            expand={() => console.log('expand')}
            style={{ height: '100%', padding: 10 }}
          >
            {I18n.t("bestSeller")}
            {
              zeroArray(3).map((_) =>
                <Skeleton variant="text" height={50} />
              )
            }
          </ExpandableArea>
        </Grid>
        <Grid item xs={5}>
          <ExpandableArea
            title={I18n.t("monthStatistic")}
            expand={() => console.log('expand')}
          >
            <Row flex='1 1 auto' justifyContent='space-around' alignItems='center'>
              <Skeleton
                variant="text"
                width="100%"
                height={200}
              />
            </Row>
          </ExpandableArea>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item style={{ flex: 1 }}>
          <ExpandableArea
            title={I18n.t("yearStatistic")}
            expand={() => console.log('expand')}
          >
            <Row flex={1} justifyContent='space-around' alignItems='center'>
              <Skeleton
                variant="text"
                width="100%"
                height={200}
              />
            </Row>
          </ExpandableArea>
        </Grid>
      </Grid>
    </>
  )
}

const ExpandableArea = ({ title, expand, style, children }) => {
  const classes = useStyles();

  return (
    <Column className={classes.ExpandableArea} style={style}>
      <Row justifyContent='space-between' alignItems='center'>
        <Typography variant='body1'>{title}</Typography>
        {/* <IconButton
          color='inherit'
          onClick={expand}
          style={{
            margin: -12
          }}
        >
          <FullscreenIcon />
        </IconButton> */}
      </Row>
      {children}
    </Column>
  )
}

const DetailRow = ({ title, value, isFirst = false, isBottom = false }) => {
  const classes = useStyles({ isFirst, isBottom });

  return (
    <Row className={classes.logoArea}>
      <div style={{ flex: 1, textAlign: 'left' }}>
        <Typography variant="body2" color="textSecondary">
          {I18n.t(title)}
        </Typography>
      </div>
      <div style={{ flex: 1, textAlign: 'right', paddingRight: 16 }}>
        <Typography variant="body2" color="textPrimary">
          {value || '-'}
        </Typography>
      </div>
    </Row>
  );
}

const StatisticItem = ({ data: { title, value, icon, color, iconColor } }) => {
  const classes = useStyles({ color, iconColor });
  return (
    <Column alignItems="center">
      <Column className={classes.statisticItem}>
        {icon}
      </Column>
      <Typography variant="h5" color="textPrimary" align="center">
        {value}
      </Typography>
      <Typography variant="subtitle1" color="textSecondary" align="center">
        {title}
      </Typography>
    </Column>
  )
}

export default ClientRightInfo;
