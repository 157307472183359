/** @jsx h */
import { h } from "preact";
import { useState, useEffect, useMemo, useContext } from "preact/hooks";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import I18n from "i18n-js";
import moment from "moment";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import RemoveIcon from "@material-ui/icons/Remove";
import { Row } from "components/atoms/row/Row";
import { Column } from "components/atoms/column/Column";
import { SortableTable } from "components/molecules/sortableTable/SortableTable";
import CubeBtn from "components/atoms/cubeButton/CubeBtn";
import Select from "components/atoms/select/Select";
import { MultiSelect } from "components/atoms/multiSelect/MultiSelect";
import YearPicker from "components/atoms/yearPicker/YearPicker";

import { formatPrice } from 'helper/format';
import { getOrderPrevision } from "api";
import ExportExcelButton from "components/atoms/exportExcelButton/ExportExcelButton";
import { getMonthWithZero } from "../../util";
import Context from "context";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(4),
		width: "100%",
		color: "rgba(0, 0, 0, 0.54)",
	},
	mb2: {
		marginBottom: theme.spacing(2),
	},
	mr2: {
		marginRight: theme.spacing(2),
	},
	padding0: {
		padding: 0,
	},
	marginArea: {
		'& > div + div': {
			marginLeft: theme.spacing(2)
		}
	},
	icon: {
		color: "rgba(0, 0, 0, 0.54)",
		cursor: "pointer",
		marginRight: theme.spacing(1),
		"&:hover": {
			color: "#2e65c9",
		},
		"&:last-child": {
			marginRight: 0,
		},
	},
	iconActive: {
		color: "#2e65c9",
	},
	card: {
		flex: 1,
		cursor: "default",
		height: "fit-content",
		padding: "16px 16px",
		backgroundColor: "white",
		border: "1px solid #E0E0E0",
		borderRadius: "8px",
		"& ::-webkit-scrollbar": {
			backgroundColor: "transparent",
			width: 0,
		},
		"& ::-webkit-scrollbar-track": {
			backgroundColor: "transparent",
		},
	},
	cardBody: {
		overflowY: "scroll",
	},
	cardTitle: {
		marginBottom: "16px",
		fontSize: "20px",
		fontWeight: " 500",
		color: "rgba(0,0,0,.54)",
	},
	selectedRow: {
		backgroundColor: "#EFF5FF !important",
		"& > td": {
			color: "#2E65C9",
		},
	},
}));

const availableFilter = ["department", "salesman"];
const compareIcon = {
	up: <ArrowUpwardIcon color="primary" />,
	down: <ArrowDownwardIcon color="error" />,
	same: <RemoveIcon />,
};

const Prediction = () => {
	const classes = useStyles();
	const { statusOptions } = useContext(Context);
	const [filterOptions, setFilterOptions] = useState([
		{ id: "department", text: I18n.t("filterBy", { text: I18n.t("department")}) },
		{ id: "salesman", text: I18n.t("filterBy", { text: I18n.t("salesman")}) },
	]);
	const [currFilter, setCurrFilter] = useState(availableFilter[0]);
	const [currYear, setCurrYear] = useState(moment().utc().year());
	const [selectedRow, setSelectedRow] = useState(null);
	const [columns, setColumns] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [ isFetched, setFetched ] = useState(false);
	const [ originSalesmanData, setOriginSalesmanData ] = useState([]);
	const [ originDepartmentData, setOriginDepartmentData ] = useState([]);
	const [ departmentFilter, setDepartmentFilter ] = useState([0]);
	const [ departmentOption, setDepartmentOption ] = useState([]);
	const [ changedFilter, setChangedFilter ] = useState(null)
	const [searchText, setSearchText] = useState('');
	const [status, setStatus] = useState(2);

	const departmentColumns = [
		{
			id: "department",
			sortable: true,
		},
		{
			id: "YearObjective",
			sortable: true,
		},
		{
			id: "A+",
			sortable: true,
		},
		{
			id: "A",
			sortable: true,
		},
		{
			id: "B",
			sortable: true,
		},
		{
			id: "total",
			label: I18n.t("sum"),
			sortable: true,
		},
		{
			id: "compare",
			label: I18n.t("comparePreviousWeek"),
			sortable: false,
		},
		{
			id: "diff",
			label: I18n.t("compare"),
			sortable: true
		}
	];
	const salesmanColumns = [
		{
			id: "salesman",
			label: I18n.t("Salesman"),
			sortable: true,
		},
		...departmentColumns.slice(1),
	];

	const formatTableData = (data, department, region) => {
		const diff = Number(data.total) - Number(data.totalPreviousWeek);
		let obj = {
			department,
			region,
			eachMonth: data.eachMonth,
			YearObjective: formatPrice(data.YearObjective),
			'A+': formatPrice(data['A+']),
			A: formatPrice(data['A']),
			B: formatPrice(data['B']),
			total: formatPrice(data['total']),
			compare: diff > 0 ? compareIcon.up : diff === 0 ? compareIcon.same : compareIcon.down,
			diff: formatPrice(diff),
		}

		if (data.salesman) {
			obj = { ...obj, salesman: data.salesman }
		}

		return obj;
	}

	useEffect(async () => {
		let departmentData = new Array()
		let salesmanData = new Array()
		let departmentName = new Array()
		let params = { from: currYear }
		if (status === 1 || status === 2) {
			params = { ...params, is_finish: status === 1 }
		}
		await getOrderPrevision(params)
			.then(res => {
				Object.keys(res.data).map((item, index) => {
					Object.keys(res.data[item]).map((nested, i) => {
						departmentName.push({ id: i, text: nested, region: item})
						const element = res.data[item][nested];
						departmentData.push(formatTableData(element, nested, item))
					})
				})
				Object.keys(res.data).map(item => {
					Object.keys(res.data[item]).map(nested => {
						res.data[item][nested]['sales'].map(sales => {
							salesmanData.push(formatTableData(sales, nested, item))
						})
					})
				})
				// Remove duplicate id in departmentName array
				departmentName = departmentName.map((department, index) => {
					return { id: department.text, text: department.text, region: department.region }
				})
				setDepartmentOption(departmentName)
				setOriginDepartmentData(departmentData);
				setOriginSalesmanData(salesmanData);
			})
			.catch(err => {
				setDepartmentOption([])
				setOriginDepartmentData([]);
				setOriginSalesmanData([]);
			})
		const next = {
			[availableFilter[0]]: () => {
				setColumns(departmentColumns);
				setTableData(departmentData);
				setFetched(true);
			},
			[availableFilter[1]]: () => {
				setColumns(salesmanColumns);
				setTableData(salesmanData);
				setFetched(true);
			},
		};
		next[currFilter]();
	}, [currYear, status]);

	useEffect(async () => {
		if (currFilter === 'salesman') {
			if (departmentFilter.includes(0) || !departmentFilter.length) {
				setColumns(salesmanColumns);
				setTableData(originSalesmanData)
			} else {
				let data = originSalesmanData
				if (!departmentFilter.includes(0) || !!departmentFilter.length) {
					data = data.filter(item => departmentFilter.includes(item.department))
				}
				setColumns(salesmanColumns);
				setTableData(data)
			}
		} else if (currFilter === 'department') {
			if (departmentFilter.includes(0) || !departmentFilter.length) {
				setColumns(departmentColumns);
				setTableData(originDepartmentData)
			} else {
				let data = originDepartmentData
				if (!departmentFilter.includes(0) || !!departmentFilter.length) {
					data = data.filter(item => departmentFilter.includes(item.department))
				}
				setColumns(departmentColumns);
				setTableData(data)
			}
		}
	}, [currFilter])

	const onFilterType = (data) => {
		setCurrFilter(data.id);
	};

	const onSelectYear = (data) => {
		setCurrYear(data);
	};

	const onChangeDepartment = (values) => {
		let data = currFilter === 'salesman' ? originSalesmanData : originDepartmentData
		if (!!values.length) {
			data = data.filter(item => values.includes(item.department))
		}
		setDepartmentFilter(values);
		setChangedFilter('department');
		setColumns(currFilter === 'salesman' ? salesmanColumns : departmentColumns);
		setTableData(data);
	};

	const getTableRowProps = ({ data, className }) => {
		return {
			onClick: () => setSelectedRow(data),
			className: `${className} ${
				selectedRow?.[currFilter] === data?.[currFilter] ? classes.selectedRow : ""
			}`,
		};
	};

	const filteredData = tableData.filter((item) => {
		if (currFilter === 'salesman') {
			return item.salesman?.toLowerCase().includes(searchText.toLowerCase());
		} else if (currFilter === 'department') {
			return item.department?.toLowerCase().includes(searchText.toLowerCase());
		}
	});

	const exportColumns = useMemo(() => {
		const mainColumns = columns.map((column) => {
			return {
				id: column.id,
				text: column.label || I18n.t(column.id),
			};
		});
		const monthColumns = Array(12).fill(0).map((_, index) => ({ id: index, text: `${currYear % 100}/${getMonthWithZero(index + 1)}` }));
		return mainColumns.concat(monthColumns);
	}, [columns, currYear]);
	const exportData = filteredData.map((data) => {
		const diff = Number(data.diff);
		const formatedEachMonth = data.eachMonth.map(formatPrice);
		return {
			...data,
			compare: diff > 0 ? '▲' : diff === 0 ? '-' : '▼',
			...formatedEachMonth,
		};
	});

	return (
		<div className={classes.root}>
			<Row justifyContent="space-between" alignItems="center" className={classes.mb2}>
				<Typography variant="h5" color="inherit">
					{I18n.t("Prediction")}
				</Typography>
				<Row alignItems="center" className={classes.marginArea}>
					<ExportExcelButton
						sheetName={`${currYear} ${I18n.t("Prediction")}`}
						columns={exportColumns}
						data={exportData}
						fileType="xlsx"
					/>
					<Select
						width=""
						data={statusOptions}
            value={status}
						onClick={(data) => setStatus(data.id)}
					/>
					<YearPicker calendarIcon onChange={onSelectYear} />
					<CubeBtn
						iconType="search"
						expandable
						searchText={searchText}
						placeholder={currFilter === 'salesman' ? I18n.t("Salesman") : I18n.t("department")}
						onChange={(text) => setSearchText(text)}
					/>
					<MultiSelect
						data={departmentOption}
						value={departmentFilter}
						title="department"
						labelKey="text"
						valueKey="id"
						onChange={onChangeDepartment}
					/>
					<Select data={filterOptions} filterIcon onClick={onFilterType} />
				</Row>
			</Row>
			<Column className={classes.mb2}>
				<SortableTable
					columns={columns}
					data={filteredData}
					striped
					hovered
					getRowProps={getTableRowProps}
					isFetched={isFetched}
					pageResetKey={!!searchText}
				/>
			</Column>
			{ originDepartmentData.length !== 0 && originSalesmanData.length !== 0 && (
				<Column className={classes.mb2}>
					<Row className={classes.mb2}>
						<HalfYearTableCard
							year={currYear}
							filter={currFilter}
							selectedRow={selectedRow}
							setSelectedRow={setSelectedRow}
							filterDepartment={departmentFilter}
							originDepartmentData={originDepartmentData}
							originSalesmanData={originSalesmanData}
							changedFilter={changedFilter}
							departmentOption={departmentOption}
							half={"first"}
							searchText={searchText}
						/>
					</Row>
					<Row className={classes.mb2}>
						<HalfYearTableCard
							year={currYear}
							filter={currFilter}
							selectedRow={selectedRow}
							setSelectedRow={setSelectedRow}
							filterDepartment={departmentFilter}
							originDepartmentData={originDepartmentData}
							originSalesmanData={originSalesmanData}
							changedFilter={changedFilter}
							departmentOption={departmentOption}
							half={"second"}
							searchText={searchText}
						/>
					</Row>
				</Column>
			)}
		</div>
	);
};

const HalfYearTableCard = ({ year, half, filter, filterDepartment, originDepartmentData, originSalesmanData, selectedRow, setSelectedRow, changedFilter, departmentOption, searchText = '' }) => {
	const classes = useStyles();
	const [columns, setColumns] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [title, setTitle] = useState("");
	const [ isFetched, setFetched ] = useState(false);

	const firstColumn = {
		[availableFilter[0]]: { id: "department" },
		[availableFilter[1]]: { id: "salesman" },
	};

	const halfYearColumns1 = [
		{
			id: firstColumn[filter].id,
			label: firstColumn[filter].label,
			sortable: true,
		},
		{
			id: "1",
			label: `${year.toString().slice(-2)}/01`,
			sortable: true,
		},
		{
			id: "2",
			label: `${year.toString().slice(-2)}/02`,
			sortable: true,
		},
		{
			id: "3",
			label: `${year.toString().slice(-2)}/03`,
			sortable: true,
		},
		{
			id: "4",
			label: `${year.toString().slice(-2)}/04`,
			sortable: true,
		},
		{
			id: "5",
			label: `${year.toString().slice(-2)}/05`,
			sortable: true,
		},
		{
			id: "6",
			label: `${year.toString().slice(-2)}/06`,
			sortable: true,
		},
	];

	const halfYearColumns2 = [
		{
			id: firstColumn[filter].id,
			label: firstColumn[filter].label,
			sortable: true,
		},
		{
			id: "1",
			label: `${year.toString().slice(-2)}/07`,
			sortable: true,
		},
		{
			id: "2",
			label: `${year.toString().slice(-2)}/08`,
			sortable: true,
		},
		{
			id: "3",
			label: `${year.toString().slice(-2)}/09`,
			sortable: true,
		},
		{
			id: "4",
			label: `${year.toString().slice(-2)}/10`,
			sortable: true,
		},
		{
			id: "5",
			label: `${year.toString().slice(-2)}/11`,
			sortable: true,
		},
		{
			id: "6",
			label: `${year.toString().slice(-2)}/12`,
			sortable: true,
		},
	];

	useEffect(() => {
		let departmentFirstHalfData = [];
		let departmentSecondHalfData = [];
		let salesmanFirstHalfData = [];
		let salesmanSecondHalfData = [];
		if (filter === 'department') {
			Object.keys(originDepartmentData).map(key => {
				let firstHalfData = new Object()
				let secondHalfData = new Object()
				firstHalfData.department = originDepartmentData[key].department;
				secondHalfData.department = originDepartmentData[key].department;
				firstHalfData.region = originDepartmentData[key].region;
				secondHalfData.region = originDepartmentData[key].region;
				let halfIndex = Math.floor(originDepartmentData[key]['eachMonth'].length / 2);
				let firstHalf = originDepartmentData[key]['eachMonth'].slice(0 , halfIndex)
				let secondHalf = originDepartmentData[key]['eachMonth'].slice(halfIndex, originDepartmentData[key]['eachMonth'].length)
				firstHalf.map((item, index) => {
					firstHalfData[index + 1] = formatPrice(item)
				})
				secondHalf.map((item, index) => {
					secondHalfData[index + 1] = formatPrice(item)
				})
				departmentFirstHalfData.push(firstHalfData)
				departmentSecondHalfData.push(secondHalfData)
			})
			if (!!filterDepartment.length && !filterDepartment.includes(0)) {
				departmentFirstHalfData = departmentFirstHalfData.filter(item => filterDepartment.includes(item.department))
				departmentSecondHalfData = departmentSecondHalfData.filter(item => filterDepartment.includes(item.department))
			} else {
				departmentFirstHalfData = departmentFirstHalfData.filter(item => { return departmentOption.filter(i => i.region === item.region).length > 0 })
				departmentSecondHalfData = departmentSecondHalfData.filter(item => { return departmentOption.filter(i => i.region === item.region).length > 0 })
			}
		} else if (filter === 'salesman') {
			Object.keys(originSalesmanData).map(key => {
				let firstHalfData = new Object()
				let secondHalfData = new Object()
				firstHalfData.salesman = originSalesmanData[key].salesman;
				firstHalfData.department = originSalesmanData[key].department;
				secondHalfData.salesman = originSalesmanData[key].salesman;
				secondHalfData.department = originSalesmanData[key].department;
				firstHalfData.region = originSalesmanData[key].region;
				secondHalfData.region = originSalesmanData[key].region;
				let halfIndex = Math.floor(originSalesmanData[key]['eachMonth'].length / 2);
				let firstHalf = originSalesmanData[key]['eachMonth'].slice(0 , halfIndex)
				let secondHalf = originSalesmanData[key]['eachMonth'].slice(halfIndex, originSalesmanData[key]['eachMonth'].length)
				firstHalf.map((item, index) => {
					firstHalfData[index + 1] = formatPrice(item)
				})
				secondHalf.map((item, index) => {
					secondHalfData[index + 1] = formatPrice(item)
				})
				salesmanFirstHalfData.push(firstHalfData)
				salesmanSecondHalfData.push(secondHalfData)
			})
			if (!!filterDepartment.length && !filterDepartment.includes(0)) {
				salesmanFirstHalfData = salesmanFirstHalfData.filter(item => filterDepartment.includes(item.department))
				salesmanSecondHalfData = salesmanSecondHalfData.filter(item => filterDepartment.includes(item.department))
			} else {
				salesmanFirstHalfData = salesmanFirstHalfData.filter(item => { return departmentOption.filter(i => i.region === item.region).length > 0 })
				salesmanSecondHalfData = salesmanSecondHalfData.filter(item => { return departmentOption.filter(i => i.region === item.region).length > 0 })
			}
		}
		const next = {
			[availableFilter[0]]: {
				first: () => {
					setColumns(halfYearColumns1);
					setTableData(departmentFirstHalfData);
					setFetched(true);
				},
				second: () => {
					setColumns(halfYearColumns2);
					setTableData(departmentSecondHalfData);
					setFetched(true);
				},
			},
			[availableFilter[1]]: {
				first: () => {
					setColumns(halfYearColumns1);
					setTableData(salesmanFirstHalfData);
					setFetched(true);
				},
				second: () => {
					setColumns(halfYearColumns2);
					setTableData(salesmanSecondHalfData);
					setFetched(true);
				},
			},
		};
		next[filter][half]();
	}, [year, filter, filterDepartment]);

	useEffect(() => {
		const next = {
			first: () => {
				setTitle(`From ${year}/1 ~ ${year}/6`);
			},
			second: () => {
				setTitle(`From ${year}/7 ~ ${year}/12`);
			},
		};
		next[half]();
	}, [year, half]);

	const getTableRowProps = ({ data, className }) => {
		return {
			onClick: () => setSelectedRow(data),
			className: `${className} ${
				selectedRow?.[filter] === data?.[filter] ? classes.selectedRow : ""
			}`,
		};
	};

	const filteredData = tableData.filter((item) => {
		return Object.keys(item).some((key) => {
			return item[key]?.toString().toLowerCase().includes(searchText.toLowerCase());
		});
	});

	return (
		<div className={classes.card}>
			<div className={`${classes.cardTitle} jc-space-btw al-center`}>
				<span>{title}</span>
			</div>
			<div className={`column ${classes.body}`}>
				<SortableTable
					columns={columns}
					data={filteredData}
					striped
					hovered
					getRowProps={getTableRowProps}
					isFetched={isFetched}
					pageResetKey={!!searchText}
				/>
			</div>
		</div>
	);
};

export default Prediction;
