/** @jsx h */
import { h, Fragment } from "preact";
import { useState } from "preact/hooks";

import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableBase from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Collapse from "@material-ui/core/Collapse";

import EditIcon from "@material-ui/icons/Edit";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import PublicIcon from "@material-ui/icons/Public";

import { Table } from "components/atoms/table/Table";
import { Icon } from "components/atoms/icon/Icon";
import I18n from "i18n/i18n";

const useStyles = makeStyles(() => ({
  icon: {
    marginRight: 10,
  },
  td: {
    display: "flex",
    alignItems: "center",
  },
}));

const subColumns = [{ id: "name" }, { id: "area" }];

const Row = ({
  row,
  index,
  columns,
  selectRow,
  setSelectRow,
  border,
  handleIconClick = () => { },
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    const newOpen = !open;
    setOpen(newOpen);
  };

  const backgroundColor =
      index !== undefined && index % 2 === 0
      ? "#fafafa"
      : undefined;

  const heads = {
    name: open ? (
      <ExpandMoreIcon color="action" className={classes.icon} />
    ) : (
      <ChevronRightIcon color="action" className={classes.icon} />
    ),
    area: <PublicIcon color="action" className={classes.icon} />,
  };

  return (
    <Fragment>
      <TableRow onClick={handleClick} style={{ backgroundColor }}>
        {columns
          .filter(({ sortable }) => sortable)
          .map(({ id }) => (
            <TableCell
              key={id}
              style={{
                border: open ? "none" : border,
                width: "25%",
              }}
            >
              <div className={classes.td}>
                {heads[id]}
                {row[id]}
              </div>
            </TableCell>
          ))}
        <TableCell
          component="th"
          style={{
            border: open ? "none" : border,
            width: "25%",
          }}
        >
          <Icon>
            <EditIcon
              onClick={(e) => handleIconClick(e, "edit", row)}
              style={{ marginRight: 20 }}
            />
          </Icon>
          <Icon>
            <DeleteOutlineIcon
              onClick={(e) => handleIconClick(e, "delete", row)}
            />
          </Icon>
        </TableCell>
      </TableRow>
      {row.children && (
        <TableRow style={{ backgroundColor }}>
          <TableCell
            colSpan={columns.length + 1}
            style={{ padding: 0, border: "none" }}
          >
            <Collapse in={open}>
              <TableBase>
                <TableBody>
                  {row.children.map((item, i) => (
                    <SubRow
                      row={item}
                      handleIconClick={handleIconClick}
                      selectRow={selectRow}
                      setSelectRow={setSelectRow}
                      border={i === row.children.length - 1 ? border : "none"}
                    />
                  ))}
                </TableBody>
              </TableBase>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </Fragment>
  );
};

const SubRow = ({ row, selectRow, setSelectRow, handleIconClick, border }) => {
  const classes = useStyles();
  const handleClick = () => {
    if (selectRow === row.id) {
      setSelectRow();
    } else {
      setSelectRow(row.id);
    }
  };

  const backgroundColor = selectRow === row.id ? "#EFF5FF" : undefined;

  return (
    <TableRow onClick={handleClick} style={{ backgroundColor }}>
      {subColumns.map(({ id }) => (
        <TableCell
          key={id}
          style={{
            border,
            width: "33.33%",
          }}
        >
          <div className={classes.td}>
            {id == "area"
              ? row.area
              : row[id]}
          </div>
        </TableCell>
      ))}
      <TableCell
        component="th"
        style={{
          border,
          width: "33.33%",
        }}
      >
        <Icon>
          <EditIcon
            onClick={(e) => handleIconClick(e, "edit", row)}
            style={{ marginRight: 20 }}
          />
        </Icon>
        <Icon>
          <DeleteOutlineIcon
            onClick={(e) => handleIconClick(e, "delete", row)}
          />
        </Icon>
      </TableCell>
    </TableRow>
  );
};

export const RegionTable = ({ data, isFetched, handleOpen }) => {
  const [selectRow, setSelectRow] = useState();

  const handleIconClick = (e, modal, item) => {
    e?.stopPropagation();
    handleOpen(modal, item);
  };

  const columns = [
    { id: "name", sortable: true, label: I18n.t("RegionName") },
    { id: "area", sortable: true, label: I18n.t("group") },
    { id: "Action", sortable: false },
  ];

  return (
    <Table data={data} columns={columns} skeletonColumns={3} isFetched={isFetched}>
      <Row
        columns={columns}
        selectRow={selectRow}
        setSelectRow={setSelectRow}
        handleIconClick={handleIconClick}
      />
    </Table>
  );
};

