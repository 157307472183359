import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  dataList: {
    padding: '16px 32px',
    '& > div': {
        marginBottom: '16px',
        fontSize: '14px',
        textAlign:'right',
        '& span:nth-child(1)': {
            color: 'rgba(0,0,0,.38)'
        }
    }
  },
  bgSection: ({ isDetail }) => ({
    borderRadius: theme.spacing(1),
    backgroundColor: '#FAFAFA',
    border: '1px solid #E0E0E0',
    padding: theme.spacing(2),

    '& > .sectionTitle': {
      margin: theme.spacing(2, 0),
      color: isDetail && theme.palette.text.disabled,


      '&:first-child': {
        marginTop: isDetail && theme.spacing(0)
      }
    }
  }),

  logoArea: ({ isDetail }) => ({
    padding: 10,
    marginBottom: isDetail && theme.spacing(1),

    '& > div.logo': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 71,
      width: 71,
      // backgroundColor: '#CCCCCC'
    },

    // This is for isDetail
    '& > div > div.logo ': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 71,
      width: 71,
      backgroundColor: '#CCCCCC'
    },

    '& > div.description': {
      marginLeft: theme.spacing(3),
    },

    '& .blue': {
      color: '#2E65C9'
    },

    '& .gray': {
      color: theme.palette.text.disabled
    }
  })
}));