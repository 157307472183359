/* eslint-disable object-shorthand */
import { useState } from 'preact/hooks'
import I18n from 'i18n-js'
import moment from 'moment'

import {
  getQuotation,
  getReport,
  getCompetitor,
} from 'api'
import { formatPrice } from 'helper/format'
import { useRole } from './useRole'
import { useCurrency } from './useCurrency'

export const useNewSalesInfo = () => {
  const locale = localStorage.getItem('locale')
  const { roleId } = useRole()
  const { getCurrencyName } = useCurrency()
  const [data, setData] = useState([])
  const [status, setStatus] = useState({
    isLoading: false,
    isError: false,
  })

  const loadQuotation = async (params) => {
    setStatus((prev) => ({ ...prev, isLoading: true }))
    try {
      const { data: quotations } = await getQuotation({ ...params, offset: 0, limit: -1 }, roleId)
      let computedData = await computeAllData(quotations)
      computedData = computedData.sort((a, b) => moment(b.updateDate).unix() - moment(a.updateDate).unix())
      setData(computedData)
      setStatus((prev) => ({ ...prev, isLoading: false }))
    } catch (e) {
      console.log(e.response?.data)
      setStatus({ isError: true, isLoading: false })
    }
  }
  const computeReports = async (quotationId) => {
    try {
      const { data: reports } = await getReport({ id_quotation: quotationId }, roleId)
      const report = reports[0]
      if (!report) {
        return {
          clientType: '-',
          competitorSummary: [],
        }
      }
      const clientType = `${I18n.t(`client_type_${report.client_type}`)}`

      const additionalCompetitor = report.additional_competitor ?? []
      let competitorArray = report.id_erp_competitor?.length ? await Promise.all(
        report.id_erp_competitor.map(async (competitorId) => {
          const { data } = await getCompetitor({ id: competitorId })
          return data?.[0]?.name
        })
      ) : [];
      const competitorSummary = [...competitorArray, ...additionalCompetitor].filter((item) => !!item)
      return {
        clientType,
        competitorSummary,
      }
    } catch (e) {
      console.log(e.response ? e.response.data : e)
    }
  }

  const computeAllData = async (quotations) => {
    const computedPromises = quotations.map(async (item) => {
      try {
        const { competitorSummary } = await computeReports(item.id_quotation)
        const clientBranch = item.client_branch;
        const country = clientBranch?.country
        const clientName = clientBranch?.abbreviation ?? clientBranch?.name

        const creator = item.user;
        const creatorName = `${creator?.last_name}${creator?.first_name}`

        let formattedMachines = item.machines.map((machine) => {
          const isOther = machine.id_series === 99
          const exchange_negotiation_price = `$${!isNaN(machine.negotiation_price) ? formatPrice(machine.negotiation_price) : 0}`
          const unitPrice = !!item.currency_symbol ? `$${!isNaN(machine.exchange_negotiation_price) ? formatPrice(Math.round(machine.exchange_negotiation_price)) : 0}` : exchange_negotiation_price
          return {
            quotationMachines: machine.machine_name,
            quantity: machine.quantity,
            actualPrice: isOther ? '' : `$${!isNaN(machine.original_price) ? formatPrice(machine.original_price) : 0}`, // 現金底價
            unitPrice, // 台幣單價
            subTotalPrice: `$${!isNaN(machine.exchange_total_price_without_options) ? formatPrice(Math.round(machine.exchange_total_price_without_options)) : 0}`, // 台幣小計
            percentagesLower: !isOther && !isNaN(Number(machine.percentages_lower)) ? `${(Number(machine.percentages_lower) * 100).toFixed(1)}` : '', // 低底價
            exchange_negotiation_price, // 原幣單價
            exchange_rate: item.exchange_rate ?? 1,
            currency_symbol: getCurrencyName(item.currency_symbol),
          }
        });
        formattedMachines[0] = {
          ...formattedMachines[0],
          rate: item.rate,
          transactionMonth: moment(item.expect_buy_date).format("MM"),
        }

        const hasProgessRemarks = item.progress_remark.length !== 0
        const latestRemark = hasProgessRemarks
          ? item.progress_remark[item.progress_remark.length - 1].remark
          : ''

        return {
          id: item.id,
          id_quotation: item.id_quotation,
          salesman: creatorName,
          createdDate: moment(item.created_at).format('YYYY-MM-DD'),
          updateProgess: latestRemark,
          updateDate: moment(item.updated_at).format('YYYY-MM-DD'),
          customerCountry: country,
          clientName: clientName,
          secondBusinessLevel: locale === 'en' ? clientBranch?.info_second_industry_level?.industry_name_eng : clientBranch?.info_second_industry_level?.industry_name_ch,
          productManufactured: clientBranch?.product_manufactured,
          competitors: competitorSummary,
          totalPrice: `$${formatPrice(item.total_price)}`,
          user: creator,
          isFinish: item.is_finish,
          factory: item.factory ? I18n.t(item.factory) : '',
          children: formattedMachines
        }
      } catch (e) {
        console.log('error while handling: ', item)
        console.log('error msg: ', e.response ? e.response.data : e)
        return null
      }
    })
    const computedData = await Promise.all([...computedPromises])
    return computedData.filter((item) => !!item)
  }
  return {
    data: data,
    isLoading: status.isLoading,
    isError: status.isError,
    refetch: loadQuotation,
  }
}
