/* eslint-disable object-shorthand */
import { useEffect, useState, useContext } from 'preact/hooks'
import I18n from 'i18n-js'
import moment from 'moment';
import { DatePicker } from "rsuite";
import { enUS, zhTW } from "rsuite/esm/locales";
import NewOverviewTable from 'components/molecules/newOverviewTable/NewOverviewTable'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Row } from 'components/atoms/row/Row'
import CubeBtn from 'components/atoms/cubeButton/CubeBtn'
import Select from "components/atoms/select/Select";
import { MultiSelect } from 'components/atoms/multiSelect/MultiSelect';
import { useNewSalesInfo } from 'hooks'
import ExportExcelButton from 'components/atoms/exportExcelButton/ExportExcelButton';
import Context from 'context';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    width: '100%',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  marginBottom2: {
    marginBottom: theme.spacing(2),
  },
  DateRangePicker: {
    '& .rs-picker-toggle': {
			backgroundColor: '#FFFFFF',
			padding: '9px 12px',
			paddingRight: 36,
      border: 'none',
      borderRadius: 8,
      fontSize: 15
		},
    '& .rs-picker-toggle-value': {
      color: '#0000008a !important',
    }
	},
  marginArea: {
    '& > div + div': {
      marginLeft: theme.spacing(2),
    },
  },
}))

const COLUMNS = [
  'id_quotation',
  'updateDate',
  'createdDate',
  'salesman',
  'clientName',
  'secondBusinessLevel',
  'customerCountry',
  'updateProgess',
  'productManufactured',
  'competitors',
  'rate',
  'transactionMonth',
  'factory',
  'quotationMachines',
  'quantity',
  'currency_symbol',
  'exchange_rate',
  'exchange_negotiation_price', // 原幣單價
  'unitPrice', // 台幣單價
  'subTotalPrice',
  'actualPrice',
  'percentagesLower',
];

const NewOverview = () => {
  const classes = useStyles()
  const { groupList, statusOptions } = useContext(Context)
  const newSalesInfo = useNewSalesInfo()
  const [filters, setFilters] = useState({
    date: undefined,
    searchText: '', // 業務員 客戶名稱 報價機種
    department: [0],
    status: 2
  })

  useEffect(() => {
    let payload = {}
    if (!!filters.date) {
      payload = {
        from: moment(filters.date).startOf("M").format("YYYY-MM-DD HH:mm:ss"),
        to: moment(filters.date).endOf("M").format("YYYY-MM-DD HH:mm:ss"),
      }
    }
    if (filters.status !== 0) {
      payload = { ...payload, is_finish: filters.status === 2 ? false : true }
    }
    newSalesInfo.refetch(payload)
  }, [filters.date, filters.status]);

  const onChange = (key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value }))
  }

  const filteredData = newSalesInfo.data.filter((item) => {
    let flag = true
    const searchKeys = ['clientName', 'regionManager', 'salesman', 'quotationMachines'];
    if (!!filters.searchText) {
      const machineList = item.children.map((machine) => machine.quotationMachines);
      const value = filters.searchText.toLowerCase();
      flag = searchKeys.some((key) => {
        if (key === 'quotationMachines') {
          return machineList.some(item => item?.toLowerCase().includes(value))
        } else if (item[key]) {
          return item[key]?.toString()?.toLowerCase().includes(value);
        }
        return false
      });
    }

    const { department } = filters;
    if (!department.includes(0) && department.length && !department.includes(item.user?.id_group)) {
      flag = false
    }
    return flag;
  });

  const exportData = filteredData?.reduce((acc, data) => {
    const detailData = data.children[0];
    const competitors = data.competitors.join(',');
    const newData = {
      ...data,
      ...detailData,
      competitors,
    };
    return [...acc, newData, ...data.children.slice(1)];
  }, [])

  return (
    <div className={classes.root}>
      <Row
        justifyContent="space-between"
        alignItems="center"
        className={classes.marginBottom2}
      >
        <Typography variant="h5" color="inherit">
          {I18n.t('NewOverview')}
        </Typography>
        <Row style={{ gap: 10 }} alignItems="center">
          <ExportExcelButton
            sheetName={`${filters.date ? moment(filters.date).format('YYYY-MM') : ''} ${I18n.t('NewOverview')}`}
            columns={COLUMNS.map((column) => ({ id: column, text: I18n.t(column) }))}
            data={exportData}
            fileType="xlsx"
          />
          <Select
						width=""
						data={statusOptions}
            value={filters.status}
						onClick={(data) => onChange('status', data.id)}
					/>
          <DatePicker
            size="lg"
            placement="bottomEnd"
            format="yyyy/MM"
            cleanable={false}
            className={classes.DateRangePicker}
            defaultValue={undefined}
            value={filters.date}
            onChange={(value) => onChange('date', value)}
            locale={
              localStorage.getItem('locale') === 'en'
                ? enUS.DatePicker
                : zhTW.DatePicker
            }
          />
          <MultiSelect
            data={groupList}
            title="department"
            labelKey="name"
            valueKey="id"
            placeholder={I18n.t('department')}
            value={filters.department}
            onChange={(val) => onChange('department', val)}
          />
          <CubeBtn
            iconType="search"
            expandable
            searchText={filters.searchText}
            placeholder={`${I18n.t("clientName")}, ${I18n.t("salesman")}, ${I18n.t("quotationMachines")}`}
            onChange={(e) => setFilters((prev) => ({ ...prev, searchText: e }))}
          />
        </Row>
      </Row>
      <NewOverviewTable fetched={!newSalesInfo.isLoading} data={filteredData} />
    </div>
  )
}
export default NewOverview
