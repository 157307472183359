/** @jsx h */
import { h } from 'preact';
import { useEffect, useState, useRef } from "preact/hooks";
import I18n from "i18n/i18n";

import 'rsuite/dist/rsuite.min.css';
import { Form } from 'rsuite';

import MuiButton from '@material-ui/core/Button';

import BlueSubmitButton from 'components/atoms/blueSubmitButton/blueSubmitButton';
import { makeStyles } from '@material-ui/core/styles';

// FORM VALIDATION
import { Schema } from "rsuite";

const useStyles = makeStyles((theme) => ({
  root: ({ standalone }) => ({
    width: standalone ? '60%' : '100%',
    margin: 'auto',

    '& > form': {
      margin: standalone && '0 -16px'
    }
  }),

  avatar: {
    background: 'rgba(0,0,0,.16)',
    borderRadius: '50%',
    overflow: 'hidden',
    width: '8vw',
    height: '8vw',
    marginRight: '32px'
  },

  inputLabel: {
    color: '#B0B0B0',
    fontWeight: 'bold'
  },

  list: {
    border: 'none',

    '& > div.column > div': {
      borderBottom: 'none!important',
      paddingLeft: 0,
      paddingRight: 0,

      '& span': {
        color: theme.palette.text.secondary,
        fontWeight: 400
      }
    },

    '&.deepList': {
      background: 'transparent',
      backgroundColor: 'transparent',

      '& > div.column > div': {
        background: 'transparent!important',
        backgroundColor: 'transparent!important'
      },
    }
  },

  innerForm: ({ standalone }) => ({
    backgroundColor: standalone ? '#FFFFFF' : '#FAFAFA',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    border: standalone ? '1px solid transparent' : '1px solid #E0E0E0',
    marginTop: !standalone && theme.spacing(2)
  }),

  blueBtn: {
    fontWeight: 500,
    padding: '16px 0',
    background: '#2E65C9',
    color: 'white',
    borderRadius: '4px'
  },

  textBtn: {
    fontWeight: 500,
    letterSpacing: 0.3,
    fontSize: 14,
    '&.MuiButton-colorInherit:not(.Mui-disabled)': {
      color: theme.palette.text.disabled
    },
    '&.MuiButton-textPrimary:not(.Mui-disabled)': {
      color: '#2E65C9'
    }
  }
}))

const { NumberType, StringType } = Schema.Types;
const Contact = ({ data, type, setShow, standalone, loading, selectIndex, contacts, setContacts, addContact, editContact, select, parentStateFn }) => {
  const [formValues, setFormValues] = useState(data, contacts);
  const classes = useStyles({ standalone: standalone });

  // edit contact in client detail
  useEffect(() => {
    if (select) {
      setFormValues(select);
    }
  }, []);

  const addContacts = () => {
    if (formValues) {
      setContacts((prev) => ([...prev, {
        id: contacts.length + 1,
        first_name: formValues.first_name,
        last_name: formValues.last_name,
        name: formValues.first_name + formValues.last_name,
        icon: formValues.first_name + formValues.last_name,
        email: formValues.email,
        areaCode: formValues.areaCode,
        phone: formValues.phone,
        position: formValues.position,
      }]));
      setShow(false);
      parentStateFn(false);
      clearContact();
    }
  }

  const saveEdit = () => {
    if (!standalone) {
      let contact = [...contacts];
      contact[selectIndex] = {
        id: formValues.id,
        id_client_branch: formValues.id_client_branch,
        first_name: formValues.first_name,
        last_name: formValues.last_name,
        name: formValues.first_name + formValues.last_name,
        icon: formValues.first_name + formValues.last_name,
        email: formValues.email,
        phone: formValues.phone,
        position: formValues.position,
      }
      setContacts(contact);
    } else {
      editContact(formValues);
    }
    setShow(false);
    parentStateFn(false);
  }

  const clearContact = () => {
    formValues.id = "";
    formValues.first_name = "";
    formValues.last_name = "";
    formValues.phone = "";
    formValues.email = "";
    formValues.position = "";
    formValues.areaCode = "";
  }

  // FORM VALIDATION VARIABLES
  const isRequired = I18n.t("required");
  const notEmail = I18n.t("notEmail");
  const notNumber = I18n.t("notNumber");
  const nineDigits = I18n.t("atleastNineDigits");

  const initModel = {
    first_name: StringType().isRequired(isRequired),
    last_name: StringType().isRequired(isRequired),
    position: StringType().isRequired(isRequired),
    email: StringType().isRequired(isRequired).isEmail(notEmail),
    phone: NumberType(notNumber).isRequired(isRequired).pattern(/^.{9,}$/, nineDigits),
  }
  const formRef = useRef(null);

  const onSubmit = () => {
    if (formRef.current.check()) {
      type === "add" ?
        standalone ?
          addContact()
          :
          addContacts()
        :
        (async () => {
          await saveEdit();
          clearContact();
        })()
    }
  }

  return (
    <div className={classes.root}>
      <Form fluid formValue={formValues} onChange={(e) => setFormValues(e)} className={`column ${classes.innerForm}`} ref={formRef} model={Schema.Model(initModel)}>
        <Form.Group>
          <Form.ControlLabel className={classes.inputLabel}>{I18n.t('LastName')}：</Form.ControlLabel>
          <Form.Control
            name="last_name"
            placeholder={I18n.t("InsertA", { name: I18n.t('LastName') })} />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel className={classes.inputLabel}>{I18n.t('FirstName')}：</Form.ControlLabel>
          <Form.Control
            name="first_name"
            placeholder={I18n.t("InsertA", { name: I18n.t('FirstName') })} />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel className={classes.inputLabel}>{I18n.t('email')}：</Form.ControlLabel>
          <Form.Control name="email" placeholder={I18n.t("InsertA", { name: I18n.t('email') })} />
        </Form.Group>
      	{/*<div className='d-flex' >
          <Form.Group style={{ marginRight: 16, width: 90 }}>
            <Form.ControlLabel className={classes.inputLabel}>{I18n.t('AreaCode')}：</Form.ControlLabel>
            <Form.Control accepter={SelectPicker} data={areaCode} />
          </Form.Group>*/}
          <Form.Group >
            <Form.ControlLabel className={classes.inputLabel}>{I18n.t('phone')}：</Form.ControlLabel>
            <Form.Control name="phone" placeholder={I18n.t("InsertA", { name: I18n.t('phone') })} />
          </Form.Group>
        <Form.Group>
          <Form.ControlLabel className={classes.inputLabel}>{I18n.t('WorkPosition')}：</Form.ControlLabel>
          <Form.Control
            name="position"
            placeholder={I18n.t("InsertA", { name: I18n.t('WorkPosition') })} />
        </Form.Group>
        {
          standalone ?
            <BlueSubmitButton
              loading={loading}
              onClick={type === "add"
                ? () => {
                  addContact(formValues);
                }
                : () => {
                  saveEdit();
                  clearContact();
                }
              }
            >
              {I18n.t(type === "add" ? "AddA" : "EditA", { name: I18n.t("contact") })?.toUpperCase()}
            </BlueSubmitButton>
            :
            <div className='jc-end'>
              <MuiButton type="submit" className={classes.textBtn} variant='text' color='inherit' onClick={() => {
                setShow(false);
                parentStateFn(false);
              }}>{I18n.t("Cancel").toUpperCase()}</MuiButton>
              <MuiButton type="submit" className={classes.textBtn} variant='text' color='primary' onClick={onSubmit}>
                {I18n.t(type === "add" ? "AddA" : "EditA", { name: I18n.t("contact") })?.toUpperCase()}
              </MuiButton>
            </div>
        }
      </Form>
    </div>
  );
}

export default Contact;
