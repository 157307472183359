/** @jsx h */
import { h } from "preact";
import emptyIcon from "assets/empty.svg";

const EmptyIcon = ({ style }) => (
    <div style={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...style
    }}>
        <img src={emptyIcon} />
    </div>
);

export default EmptyIcon;