/** @jsx h */
import React from 'react';
import { Fragment, h } from "preact";
import { useState, useEffect, useContext, useMemo } from "preact/hooks";
import I18n from "i18n-js";
import moment from "moment/min/moment-with-locales";
import Context from "context";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { Row } from "components/atoms/row/Row";
import { Column } from "components/atoms/column/Column";
import { Card } from "components/atoms/card/Card";
import CubeBtn from "components/atoms/cubeButton/CubeBtn";
import Select from "components/atoms/select/Select";
import { MultiSelect } from 'components/atoms/multiSelect/MultiSelect';
import YearPicker from "components/atoms/yearPicker/YearPicker";
import { getOrderStatus, getOrderSummaryExcel } from 'api';
import { CollapseTable } from 'components/molecules/collapseTable/CollapseTable';
import { formatPrice } from 'helper/format';
import { getMonthWithZero, downloadXlsx } from '../../util';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    width: "100%",
    color: "rgba(0, 0, 0, 0.54)",
  },
  mb2: {
    marginBottom: theme.spacing(2),
  },
  mr2: {
    marginRight: theme.spacing(2),
  },
  icon: {
    color: "rgba(0, 0, 0, 0.54)",
    cursor: "pointer",
    marginRight: theme.spacing(1),
    "&:hover": {
      color: "#2e65c9",
    },
    "&:last-child": {
      marginRight: 0,
    },
  },
  tableContainer: {
    marginBottom: theme.spacing(2),


    '& tr.borderBtm': {
      borderBottom: '1px solid rgba(224, 224, 224, 1)'
    },

    '& th.minWidth': {
      minWidth: 175
    },

    '& td.MuiTableCell-root': {
      borderBottom: 'none',
    }
  }
}));

const getActualAndAccomplish = (teamOrEmployee) => {
  return {
    actual: {
      order: formatPrice(teamOrEmployee.orderAmount),
      paid: formatPrice(teamOrEmployee.paidAmount),
    },
    accomplish: {
      order: teamOrEmployee.objectiveAccomplished,
      paid: teamOrEmployee.paidAccomplished,
    },
  };
};

const columnWidthEqualizer = (col, _, array) => ({ ...col, width: `calc(100% / ${array.length})` });

const shouldPercentageShowRed = (percentage) => {
  const number = parseInt(percentage.split('%').join(''));
  return number < 50;
};

const PercentageCell = ({ active, percentage }) => {
  const color = active && shouldPercentageShowRed(percentage) ? 'red' : undefined;

  return (
    <div style={{ color }}>
      {percentage}
    </div>
  );
};

const TypeCell = ({ row, isFirstRow }) => isFirstRow ? row.type : (
  <Fragment>
    <Row style={{ width: '100%' }} justifyContent='space-between' alignItems='center'>
      <div>{row.type}</div>
      <div>
        <div>{I18n.t('salesIn')}</div>
        <div>{I18n.t('salesOut')}</div>
      </div>
    </Row>
  </Fragment>
);

const AccumulateCell = ({ row, isFirstRow, propToGet }) => {
  const isAccomplish = row.type === I18n.t('accomplish');
  return (
    <div style={{ color: isFirstRow ? '#2E65C9' : undefined }}>
      {isFirstRow ? row[propToGet] : (
        <>
          <PercentageCell active={isAccomplish} percentage={row[propToGet].order} />
          <PercentageCell active={isAccomplish} percentage={row[propToGet].paid} />
        </>
      )}
    </div>
  );
};

const Accomplish = () => {
  const classes = useStyles();
  const { regionList, groupList } = useContext(Context);
  // Top select state
  const [filter, setFilter] = useState([
    { id: "department", text: I18n.t("filterBy", { text: I18n.t("department") }) },
    { id: "salesman", text: I18n.t("filterBy", { text: I18n.t("salesman") }) },
  ]);
  const [filteredGroup, setFilteredGroup] = useState(groupList);
  // Selected
  const [selectedYear, setSelectedYear] = useState(moment().utc().year());
  const [selectedRegion, setSelectedRegion] = useState([0]);
  const [selectedGroup, setSelectedGroup] = useState([0]);
  const [selectedFilter, setSelectedFilter] = useState(filter[0]);
  const [searchText, setSearchText] = useState("");
  // Table state
  const [isFetched, setIsFetched] = useState(false);
  const [teamViewData, setTeamViewData] = useState([]);
  const [salesmanViewData, setSalesmanViewData] = useState([]);

  useEffect(() => {
    if (!selectedRegion.length || selectedRegion.includes(0)) setFilteredGroup(groupList);
    else setFilteredGroup(groupList.filter(g => selectedRegion.includes(g.id_region)));
    setSelectedGroup([0]);
  }, [selectedRegion, groupList]);

  // Get data from API when year changed
  useEffect(() => {
    (async () => {
      setIsFetched(false);

      try {
        const allMonthsOrderData = await Promise.all(Array(13).fill(0).map(async (_, index) => {
          const from = selectedYear.toString() + (index === 0 ? '' : `-${getMonthWithZero(index)}`);
          const orderStatus = (await getOrderStatus({ from })).data;
          const allGroupData = Object.entries(orderStatus).map(([region, groups]) => {
            return Object.entries(groups).map(([key, value]) => ({ team: key, region, ...value }));
          });
          return allGroupData.flat().sort((a, b) => a.team.localeCompare(b.team));
        }));

        const formatActualAndAccomplish = (teams, names) => {
          const getDataByProp = (propName) => {
            return names.reduce((prev, current, index) => {
              return { ...prev, [current]: teams[index][propName] };
            }, { type: I18n.t(propName) });
          };
          const actualData = getDataByProp('actual');
          const accomplishData = getDataByProp('accomplish');
          return [actualData, accomplishData];
        };
        const teamData = allMonthsOrderData[0].map((team) => {
          const getTeam = (innerTeam) => innerTeam.team === team.team;
          const firstMonthTeam = allMonthsOrderData[1].find(getTeam);
          const firstMonthData = getActualAndAccomplish(firstMonthTeam);
          const yearData = getActualAndAccomplish(team);
          const yearTableData = {
            team: team.team,
            type: I18n.t('objective'),
            month: formatPrice(firstMonthTeam.objective),
            year: formatPrice(team.objective),
            children: formatActualAndAccomplish([firstMonthData, yearData], ['month', 'year']),
          };
          const onlyAllMonthsData = allMonthsOrderData.filter((_, index) => index !== 0).map((data) => {
            return data.find(getTeam);
          });
          const eachMonthData = {
            team: team.team,
            type: I18n.t('objective'),
            ...onlyAllMonthsData.map(({ objective }) => formatPrice(objective)),
            children: formatActualAndAccomplish(onlyAllMonthsData.map(getActualAndAccomplish), onlyAllMonthsData.map((_, index) => index)),
          };
          return {
            team: team.team,
            region: team.region,
            yearTableData,
            eachMonthData,
          };
        });

        const salesOrderDataHandler = (monthData) => {
          return monthData.reduce((prev, current) => {
            return [...prev, ...current.sales.map((salesman) => ({ ...salesman, team: current.team, region: current.region }))];
          }, [])
        };
        const allMonthsSalesOrderData = allMonthsOrderData.map(salesOrderDataHandler);

        const salesData = allMonthsSalesOrderData[0].map((salesman) => {
          const getEmployee = (innerEmployee) => innerEmployee.id === salesman.id;
          const firstMonthEmployee = allMonthsSalesOrderData[1].find(getEmployee);
          const firstMonthData = getActualAndAccomplish(firstMonthEmployee);
          const yearData = getActualAndAccomplish(salesman);

          const yearTableData = {
            salesman: salesman.salesman,
            team: salesman.team,
            type: I18n.t('objective'),
            month: formatPrice(firstMonthEmployee.objective),
            year: formatPrice(salesman.objective),
            children: formatActualAndAccomplish([firstMonthData, yearData], ['month', 'year']),
          };

          const onlyAllMonthsData = allMonthsSalesOrderData.filter((_, index) => index !== 0).map((data) => {
            return data.find(getEmployee);
          });
          const eachMonthData = {
            salesman: salesman.salesman,
            team: salesman.team,
            type: I18n.t('objective'),
            ...onlyAllMonthsData.map(({ objective }) => formatPrice(objective)),
            children: formatActualAndAccomplish(onlyAllMonthsData.map(getActualAndAccomplish), onlyAllMonthsData.map((_, index) => index)),
          };
          return {
            salesman: salesman.salesman,
            team: salesman.team,
            region: salesman.region,
            yearTableData,
            eachMonthData,
          };
        });

        setTeamViewData(teamData);
        setSalesmanViewData(salesData);
      } catch (error) {
        console.log('Get Accomplish Error: ', error);
        if (!!error.response) console.log(error.response.data);
      }

      setIsFetched(true);
    })();
  }, [selectedYear]);

  const filteredSalesmanViewData = useMemo(() => {
    const isSelectedRegionNull = selectedRegion.includes(0) || !selectedRegion.length;
    const isSelectedGroupNull = selectedGroup.includes(0) || !selectedGroup.length;
    const isSearchTextEmpty = searchText === '';

    const filter = (salesman) => {
      const regionID = regionList.find(region => region.name === salesman.region)?.id;
      const teamID = groupList.find(group => group.name === salesman.team)?.id;
      return (isSelectedRegionNull || selectedRegion.includes(regionID))
        && (isSelectedGroupNull || selectedGroup.includes(teamID))
        && (isSearchTextEmpty || salesman.salesman.toLowerCase().includes(searchText))
    };

    return salesmanViewData.filter(filter);
  }, [salesmanViewData, selectedRegion, selectedGroup, searchText]);

  const filteredTeamViewData = useMemo(() => {
    const isSelectedRegionNull = selectedRegion.includes(0) || !selectedRegion.length;
    const isSearchTextEmpty = searchText === '';

    const filter = (team) => {
      const regionID = regionList.find(region => region.name === team.region)?.id;
      return (
        (isSelectedRegionNull || selectedRegion.includes(regionID))
        && (isSearchTextEmpty || team.team.toLowerCase().includes(searchText))
      );
    }
    return teamViewData.filter(filter)
  }, [teamViewData, selectedRegion, searchText]);

  const onExport = async () => {
    try {
      const res = await getOrderSummaryExcel({ year: selectedYear, sortBy: selectedFilter.id });
      downloadXlsx(res.data, `${selectedYear} ${I18n.t("AccomplishReports")}`);
    } catch (error) {
      console.log('Export Accomplish Error: ', error);
    }
  }

  return (
    <div className={classes.root}>
      <Row justifyContent="space-between" alignItems="center" className={classes.mb2}>
        <Row justifyContent="flex-start" alignItems="center">
          <Typography variant="h5" color="inherit">
            {I18n.t("AccomplishReports")}
          </Typography>
        </Row>
        <Row style={{ gap: 10 }} alignItems="center">
          <CubeBtn
            iconType='export'
            text={I18n.t('Export')}
            bgColor='#2e65c9'
            textColor='#fff'
            disabled={selectedFilter.id === 'salesman' ? !filteredSalesmanViewData.length : !filteredTeamViewData.length}
            onClick={onExport}
          />
          <YearPicker calendarIcon onChange={setSelectedYear} />
          <CubeBtn
            iconType="search"
            expandable
            searchText={searchText}
            onChange={(e) => setSearchText(e.toLowerCase())}
          />
          <MultiSelect
            data={regionList.map(item => ({ ...item, name: I18n.t(item.name) }))}
            title="region"
            labelKey="name"
            valueKey="id"
            placeholder={I18n.t('region')}
            value={selectedRegion}
            onChange={setSelectedRegion}
          />
          {selectedFilter.id === 'salesman' &&
            <MultiSelect
              data={filteredGroup}
              title="team"
              labelKey="name"
              valueKey="id"
              placeholder={I18n.t('team')}
              value={selectedGroup}
              onChange={setSelectedGroup}
            />
          }
          <Select data={filter} filterIcon onClick={setSelectedFilter} />
        </Row>
      </Row>
      {
        selectedFilter.id === 'salesman' ? (
          <SalesmanView
            year={selectedYear}
            data={filteredSalesmanViewData}
            isFetched={isFetched}
          />
        ) : (
          <TeamView
            year={selectedYear}
            data={filteredTeamViewData}
            isFetched={isFetched}
          />
        )
      }

    </div>
  )
};

const TeamView = ({ data, year, isFetched }) => {
  const classes = useStyles();

  // All type of columns
  const columns = [
    {
      id: "team",
      label: I18n.t("Team"),
      sortable: true,
    },
    {
      id: "type",
      label: I18n.t("Type"),
      sortable: true,
      Cell: (row) => <TypeCell row={row} isFirstRow={!!row.team} />,
    },
    {
      id: "month",
      label: I18n.t("monthAccumulate"),
      sortable: true,
      Cell: (row) => <AccumulateCell row={row} isFirstRow={!!row.team} propToGet={'month'} />,
    },
    {
      id: "year",
      label: I18n.t("yearAccumulate"),
      sortable: true,
      Cell: (row) => <AccumulateCell row={row} isFirstRow={!!row.team} propToGet={'year'} />,
    },
  ];

  const columns_1 = [
    {
      id: 'team',
      label: I18n.t('Team'),
      sortable: true,
    },
    {
      id: 'type',
      label: I18n.t('Type'),
      sortable: true,
      Cell: (row) => <TypeCell row={row} isFirstRow={!!row.team} />,
    },
    ...Array(6).fill(0).map((_, index) => ({
      id: index,
      label: `${year % 100}/${getMonthWithZero(index + 1)}`,
      sortable: true,
      Cell: (row) => <AccumulateCell row={row} isFirstRow={!!row.team} propToGet={index} />,
    })),
  ];

  const columns_2 = [
    {
      id: 'team',
      label: I18n.t('Team'),
      sortable: true,
    },
    {
      id: 'type',
      label: I18n.t('Type'),
      sortable: true,
      Cell: (row) => <TypeCell row={row} isFirstRow={!!row.team} />,
    },
    ...Array(6).fill(0).map((_, index) => ({
      id: index + 6,
      label: `${year % 100}/${getMonthWithZero(index + 7)}`,
      sortable: true,
      Cell: (row) => <AccumulateCell row={row} isFirstRow={!!row.team} propToGet={index + 6} />,
    })),
  ];

  return (
    <>
      <Column className={classes.tableContainer}>
        <CollapseTable
          columns={columns.map(columnWidthEqualizer)}
          data={data.map(({ yearTableData }) => yearTableData)}
          canDropDrag={false}
          skeletonColumns={4}
          isFetched={isFetched}
          alwaysOpen
          defaultRowsPerPage={3}
          rowsPerPageOptions={[3, 5, 10]}
        />
      </Column>

      <Column className={classes.tableContainer}>
        <Card title={I18n.t('firstHalf', { year })}>
          <CollapseTable
            columns={columns_1.map(columnWidthEqualizer)}
            data={data.map(({ eachMonthData }) => eachMonthData)}
            canDropDrag={false}
            skeletonColumns={8}
            isFetched={isFetched}
            alwaysOpen
            defaultRowsPerPage={3}
            rowsPerPageOptions={[3, 5, 10]}
          />
        </Card>
      </Column>

      <Column className={classes.tableContainer}>
        <Card title={I18n.t("secondHalf", { year: year })}>
          <CollapseTable
            columns={columns_2.map(columnWidthEqualizer)}
            data={data.map(({ eachMonthData }) => eachMonthData)}
            canDropDrag={false}
            skeletonColumns={8}
            isFetched={isFetched}
            alwaysOpen
            defaultRowsPerPage={3}
            rowsPerPageOptions={[3, 5, 10]}
          />
        </Card>
      </Column>
    </>
  )
}

const SalesmanView = ({ data, year, isFetched }) => {
  const classes = useStyles();

  // All type of columns
  const columns = [
    {
      id: "salesman",
      label: I18n.t("salesman"),
      sortable: true,
    },
    {
      id: "team",
      label: I18n.t("Team"),
      sortable: true,
    },
    {
      id: "type",
      label: I18n.t("Type"),
      sortable: true,
      Cell: (row) => <TypeCell row={row} isFirstRow={!!row.salesman} />,
    },
    {
      id: "month",
      label: I18n.t("monthAccumulate"),
      sortable: true,
      Cell: (row) => <AccumulateCell row={row} isFirstRow={!!row.salesman} propToGet={'month'} />,
    },
    {
      id: "year",
      label: I18n.t("yearAccumulate"),
      sortable: true,
      Cell: (row) => <AccumulateCell row={row} isFirstRow={!!row.salesman} propToGet={'year'} />,
    },
  ];

  const columns_1 = [
    {
      id: "salesman",
      label: I18n.t("salesman"),
      sortable: true,
    },
    {
      id: "team",
      label: I18n.t("Team"),
      sortable: true,
    },
    {
      id: "type",
      label: I18n.t("Type"),
      sortable: true,
      Cell: (row) => <TypeCell row={row} isFirstRow={!!row.salesman} />,
    },
    ...Array(6).fill(0).map((_, index) => ({
      id: index,
      label: `${year % 100}/${getMonthWithZero(index + 1)}`,
      sortable: true,
      Cell: (row) => <AccumulateCell row={row} isFirstRow={!!row.salesman} propToGet={index} />,
    })),
  ];

  const columns_2 = [
    {
      id: "salesman",
      label: I18n.t("salesman"),
      sortable: true,
    },
    {
      id: "team",
      label: I18n.t("Team"),
      sortable: true,
    },
    {
      id: "type",
      label: I18n.t("Type"),
      sortable: true,
      Cell: (row) => <TypeCell row={row} isFirstRow={!!row.salesman} />,
    },
    ...Array(6).fill(0).map((_, index) => ({
      id: index + 6,
      label: `${year % 100}/${getMonthWithZero(index + 7)}`,
      sortable: true,
      Cell: (row) => <AccumulateCell row={row} isFirstRow={!!row.salesman} propToGet={index + 6} />,
    })),
  ];

  return (
    <>
      <Column className={classes.tableContainer}>
        <CollapseTable
          columns={columns.map(columnWidthEqualizer)}
          data={data.map(({ yearTableData }) => yearTableData)}
          canDropDrag={false}
          skeletonColumns={4}
          isFetched={isFetched}
          alwaysOpen
          defaultRowsPerPage={3}
          rowsPerPageOptions={[3, 5, 10]}
        />
      </Column>

      <Column className={classes.tableContainer}>
        <Card title={I18n.t("firstHalf", { year: year })}>
          <CollapseTable
            columns={columns_1.map(columnWidthEqualizer)}
            data={data.map(({ eachMonthData }) => eachMonthData)}
            canDropDrag={false}
            skeletonColumns={9}
            isFetched={isFetched}
            alwaysOpen
            defaultRowsPerPage={3}
            rowsPerPageOptions={[3, 5, 10]}
          />
        </Card>
      </Column>

      <Column className={classes.tableContainer}>
        <Card title={I18n.t("secondHalf", { year: year })}>
          <CollapseTable
            columns={columns_2.map(columnWidthEqualizer)}
            data={data.map(({ eachMonthData }) => eachMonthData)}
            canDropDrag={false}
            skeletonColumns={9}
            isFetched={isFetched}
            alwaysOpen
            defaultRowsPerPage={3}
            rowsPerPageOptions={[3, 5, 10]}
          />
        </Card>
      </Column>
    </>
  )
};

export default Accomplish;