/** @jsx h */
import { h } from "preact";
import { useState, useMemo, useRef, useEffect } from "preact/hooks";
import { Form, SelectPicker, Schema } from "rsuite";
import { enUS, zhTW } from 'rsuite/esm/locales';
import I18n from "i18n/i18n";
import BlueSubmitButton from "components/atoms/blueSubmitButton/blueSubmitButton";
import useStyles from "./RegionInfo.style";
import "rsuite/dist/rsuite.min.css";

// FORM VALIDATION
const { NumberType, StringType } = Schema.Types;

const RegionInfo = ({ data, label, style, loading, regionList, divisionList, onClick }) => {
    const classes = useStyles();
    const formRef = useRef(null);
    const [formValues, setFormValues] = useState(data);
    // FORM VALIDATION
    const isRequired = I18n.t("required");
    const initModel = { name: StringType().isRequired(isRequired) };
    const regionModel = {
        ...initModel,
        id_division: NumberType().isRequired(isRequired),
    }
    const groupModel = {
        ...initModel,
        id_region: NumberType().isRequired(isRequired),
    }
    const [model, setModel] = useState(regionModel);

    // NAME
    const isAdd = label === "add";
    const isGroup = isAdd ? formValues.type === 1 : !!data?.id_region;
    const fieldName = useMemo(() => isGroup ? I18n.t("TeamName") : I18n.t("RegionName"), [isGroup]);
    const fieldPlaceholder = useMemo(() => I18n.t("InsertThe", { name: fieldName }), [fieldName]);
    const buttonName = useMemo(() => I18n.t(isAdd ? "AddA" : "EditA", { name: I18n.t(isGroup ? "Team" : "Region") }), [isAdd, isGroup]);

    useEffect(() => {
        setModel(isAdd ? (formValues.type === 1 ? groupModel : regionModel) : initModel);
    }, [isAdd, formValues.type]);

    return (
        <div className="column al-center" style={{ marginTop: '24px' }}>
            <div className="w-60" style={style}>
                <Form fluid formValue={formValues} model={Schema.Model(model)} ref={formRef} onChange={(e) => setFormValues(e)}>
                    {isAdd && (
                        <Form.Group>
                            <Form.ControlLabel className={classes.inputLabel}>
                                {I18n.t("regionAddType")}：
                            </Form.ControlLabel>
                            <Form.Control
                                name="type"
                                accepter={SelectPicker}
                                data={[{ label: I18n.t("region"), value: 0 }, { label: I18n.t("group"), value: 1 }]}
                                style={{ width: '100%' }}
                                locale={localStorage.getItem('locale') === 'en' ? enUS.Picker : zhTW.Picker}
                            />
                        </Form.Group>
                    )}
                    {formValues.type === 0 && (
                        <Form.Group>
                            <Form.ControlLabel className={classes.inputLabel}>
                                {I18n.t("Division")}：
                            </Form.ControlLabel>
                            <Form.Control
                                name="id_division"
                                accepter={SelectPicker}
                                data={divisionList}
                                style={{ width: '100%' }}
                                locale={localStorage.getItem('locale') === 'en' ? enUS.Picker : zhTW.Picker}
                            />
                        </Form.Group>
                    )}
                    {formValues.type === 1 && (
                        <Form.Group>
                            <Form.ControlLabel className={classes.inputLabel}>
                                {I18n.t("Region")}：
                            </Form.ControlLabel>
                            <Form.Control
                                name="id_region"
                                accepter={SelectPicker}
                                data={regionList}
                                style={{ width: '100%' }}
                                locale={localStorage.getItem('locale') === 'en' ? enUS.Picker : zhTW.Picker}
                            />
                        </Form.Group>
                    )}
                    <Form.Group>
                        <Form.ControlLabel className={classes.inputLabel}>
                            {fieldName}：
                        </Form.ControlLabel>
                        <Form.Control
                            name="name"
                            placeholder={fieldPlaceholder}
                            style={{ width: '100%' }}
                            menuStyle={{ zIndex: 10000 }}
                        />
                    </Form.Group>
                    <Form.Group style={{ marginTop: 24 }}>
                        <BlueSubmitButton
                            loading={loading}
                            onClick={() => formRef.current.check() ? onClick(formValues) : null}
                        >
                            {buttonName}
                        </BlueSubmitButton>
                    </Form.Group>
                </Form>
            </div>
        </div>
    );
}
export default RegionInfo;

