/** @jsx h */
import { h } from "preact";
import { makeStyles } from "@material-ui/core/styles";
import CSSBaseLine from "@material-ui/core/CssBaseline";
import { Header } from "components/molecules/header/Header";
import BlueButton from "components/atoms/blueButton/BlueBtn";
import I18n from "i18n-js";
import { route } from "preact-router";

const useStyles = makeStyles((theme) => ({
    base: {
        height: "100vh",
    },
    container: {
        paddingTop: 48,
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        columnGap: "10rem",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            columnGap: "2rem",
        }
    },
    flexItem: {
        width: "50%",
        height: "500px",
        display: "flex",
        alignItems: "flex-start"
    },
    flexItemRight: {
        justifyContent: "left",
        [theme.breakpoints.down("md")]: {
            justifyContent: "center",
        }
    },
    flexItemLeft: {
        justifyContent: "right",
        [theme.breakpoints.down("md")]: {
            justifyContent: "center",
        }
    },
    contentBox: {
        height: "484px",
        minWidth: "35%",
        maxWidth: "650px"
    },
    img: {
    },
    title: {
        fontWeight: 400,
        [theme.breakpoints.down("md")]: {
            fontSize: "2rem",
        }
    },
}));

const ErrorPage = ({
    statusCode,
    icon,
    children,
    back,
}) => {
    const classes = useStyles();

    return (
        <div className={classes.base}>
            <CSSBaseLine />
            <Header empty />
            <div className={classes.container}>
                <div className={`${classes.flexItem} ${classes.flexItemLeft}`}>
                    <img src={icon} className={classes.img}/>
                </div>
                <div className={`${classes.flexItem} ${classes.flexItemRight}`}>
                    <div className={classes.contentBox}>
                        <h1 className={classes.title}>{I18n.t("errorCode", { code: statusCode })}</h1>
                        {children}
                        {
                            back ?
                                <BlueButton
                                    className={classes.blueBtn}
                                    label="main"
                                    text={I18n.t("goBack").toUpperCase()}
                                    onClick={() => route("/page/dashboard")}
                                    style={{
                                        margin: "2rem auto 0 0",
                                        width: "100%",
                                    }}
                                />
                                :
                                null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ErrorPage;