/** @jsx h */
import { h } from "preact";
import { useState, useEffect, useContext } from "preact/hooks";
import I18n from "i18n-js";
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import Modal from "components/atoms/modal/Modal";
import { Row } from 'components/atoms/row/Row';
import Button from 'components/atoms/cubeButton/CubeBtn';
import { List } from 'components/molecules/list/List';
import ClientRightInfo from "components/molecules/clientRightInfo/ClientRightInfo";
import { DeleteModal } from 'components/molecules/deleteModal/DeleteModal';
import ClientInfo from 'components/organisms/modalContent/client/ClientInfo';
import ContactForm from 'components/organisms/modalContent/client/ContactForm';
import ClientDetail from "routes/client/ClientDetail";
import Context from "../../context";
import { useClients } from "hooks/useClients";
import { errHandler, removeEmptyFields } from "../../util";
import { route } from "preact-router";

import {
	getClient, 
	postClient, 
	putClient, 
	deleteClient, 
	getClientBranch, 
	putClientBranch, 
	postClientBranch, 
	deleteClientBranch,
	postClientContact,
	deleteClientContact, 
	putClientContact,
} from "api";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(4),
		width: "100%",
		color: "rgba(0, 0, 0, 0.54)",
	},

	listContainer: {
		'& > div + div': {
			marginTop: theme.spacing(3)
		}
	},

	container: {
		flex: 1,
		cursor: 'default',
		height: 'fit-content',
		padding: '16px 16px',
		backgroundColor: 'white',
		border: '1px solid #E0E0E0',
		borderRadius: '8px',

		'& .title': {
			fontSize: '20px',
			fontWeight: ' bold',
			color: 'rgba(0,0,0,.54)'
		},

        
		'& .textButton': {
			color: '#2E65C9',
			padding: theme.spacing(0, 2)
		},

		'& ::-webkit-scrollbar': {
			backgroundColor: 'transparent',
			width: 0
		},

		'& ::-webkit-scrollbar-track': {
			backgroundColor: 'transparent'
		}
	},

	'mb2': {
		marginBottom: theme.spacing(2)
	},

	'ml2': {
		marginLeft: theme.spacing(2)
	}
}));

const machine = [
	{
		name: 'Devlock Machine 1',
		brand: "Devlock",
		machinetype: 'HT-50',
		quantity: 5
	},
	{
		name: 'Devlock Machine 2',
		brand: "Devlock",
		machinetype: 'D72B',
		quantity: 1
	}
]

const Client = () => {
	const classes = useStyles();
	const { setError, setMessage } = useContext(Context);
	const [client, setClient] = useState(null);
	const [loading, setLoading] = useState(false);
	const [modal, setModal] = useState(false);
	const [popModal, setPopModal] = useState(false);
	const [refreshClient, setRefreshClient] = useState(false);
	const [selectType, setSelectType] = useState(""); // which type user select in form (group or branch)
	const [select, setSelect] = useState({});
	const [showHeader, setShowHeader] = useState(true);
	const [isTopFifty, setIsTopFifty] = useState(false);
	const [editDeleteAllow, setEditDeleteAllow ] = useState(false);
	const [clientSearchText, setClientSearchText] = useState('');
	const [branchSearchText, setBranchSearchText] = useState('');
	// Clients
	const clientEvents = useClients({ route, isTopFifty });
	const { clientGroup, clientBranch, selectedDataIndex, clientData, contactsData, groupPage, branchPage } = clientEvents;

	useEffect(() => {
		clientEvents.setSelectedDataIndex({
			group: 0,
			branch: null,
		});
	}, []);

	useEffect(() => {
		if (clientData === undefined) {
			setEditDeleteAllow(false)
		} else if (!clientData.added_by || !clientData.added_by.length){
			setEditDeleteAllow(false)
		} else {
			setEditDeleteAllow(true)
		}
	}, [clientData])

	useEffect(() => {
		clientEvents.reload();
	}, [refreshClient]);

	const handleSwitch = () => {
		setIsTopFifty(!isTopFifty);
	}

	const handleOpen = (modal, select = { children: [] }) => {
		setSelect(select);
		if (modal.includes("delete")) {
			setPopModal(modal);
		} else {
			setModal(modal);
		}
	};

	const handleClose = () => {
		setSelect({});
		setShowHeader(true);
		setLoading(false);
		if (popModal) {
			setPopModal(false);
		} else {
			setModal(false);
		}
	};

	/* Group */
	const getOptionalParams = (formValues) => {
		return removeEmptyFields({
			id_second_industry_level: formValues.secondBusinessLevel,
			id_salesman: formValues.salesman,
			product_manufactured: formValues.productManufactured,
		});
	};

	const addGroup = async (formValues) => {
		// let formBranch = formValues
		setLoading(true);
		// Add group
		const optionalParams = getOptionalParams(formValues);
		await postClient({ name: formValues.group, ...optionalParams })
			.then(() => setMessage(I18n.t("requestSuccessful", { name: I18n.t("client"), verb: I18n.t("created") })))
			.catch((err) => errHandler(err, setError, route));
		// If the API is not called again, the current latest group id cannot be obtained correctly
		const newClient = (await getClient({ name: formValues.group }));
		// clientEvents.setClientGroupList(newClients);
		// Add branches when adding group
		if (formValues.branches?.length) {
			const groupID = newClient.data[0].id;
			formValues.branches.map((item) => {
				const optionalParams = getOptionalParams(item);
				postClientBranch({
					name: item.branch,
					id_client: groupID,
					address: item.address || " ",
					zip_code: item.zip_code || " ",
					city: item.city || " ",
					country: item.country || " ",
					...optionalParams
				})
					.catch((err) => {
						setLoading(false);
						errHandler(err, setError, route);
					});
			})
		}
		setLoading(false);
		setModal(false);
		setRefreshClient(!refreshClient);
	}

	const editGroup = async(formValues) => {
		setLoading(true);
		const optionalParams = getOptionalParams(formValues);
		putClient(clientData.group_id, { name: formValues.group, ...optionalParams })
			.then(() => setMessage(I18n.t("requestSuccessful", { name: I18n.t("client"), verb: I18n.t("updated") })))
			.then(() => {
				let branchAPIAction = "";
				if (formValues.branches?.length > 0) {
					formValues.branches.map((item) => {
						const existing = clientBranch.findIndex((d) => d.id == item.id);
						const optionalParams = getOptionalParams(item);
						if (existing > -1) { // update an existing branch
							putClientBranch(item.id, {
								name: item.branch,
								address: item.address || " ",
								zip_code: item.zip_code || " ",
								city: item.city || " ",
								country: item.country || " ",
								...optionalParams
							})
								.then(() => branchAPIAction = "updated")
								.catch((err) => {
									setLoading(false);
									errHandler(err, setError, route)
								});
						} else { // create a new branch
							postClientBranch({
								name: item.branch,
								id_client: clientData.group_id,
								address: item.address || " ",
								zip_code: item.zip_code || " ",
								city: item.city || " ",
								country: item.country || " ",
								...optionalParams
							})
								.then(() => branchAPIAction = "created")
								.catch((err) => {
									setLoading(false);
									errHandler(err, setError, route);
								});
						}
						setMessage(I18n.t("requestSuccessful", { name: I18n.t("client"), verb: I18n.t(branchAPIAction) }));
					})
				}
				if (formValues.deleteBranch?.length > 0) { // delete existing branch
					formValues.deleteBranch.map((item) => {
						deleteClientBranch(item)
							.catch((err) => {
								setLoading(false);
								errHandler(err, setError, route);
							});
					})
				}
				setLoading(false);
				setModal(false);
				setRefreshClient(!refreshClient);
			})
			.catch((err) => {
				setLoading(false);
				errHandler(err, setError, route);
			});
	}

	/* Branch */
	const addBranch = async (formValues) => {
		setLoading(true);
		try {
			const { name, branch, group_id, address, zip_code, city, country, branch_secondBusinessLevel, branch_productManufactured, branch_salesman } = formValues;
			const optionalParams = removeEmptyFields({
				id_second_industry_level: branch_secondBusinessLevel,
				product_manufactured: branch_productManufactured,
				id_salesman: branch_salesman
			});

			postClientBranch({
				name: branch,
				id_client: group_id,
				address,
				zip_code: zip_code,
				city,
				country,
				...optionalParams
			})
				.then(async () => {
					const newBranches = (await getClientBranch({ name: branch })).data;
					// setClientBranchList(newBranches);
					
					let clientContactCreated = false;
					if (formValues.contacts?.length > 0) {
						const branchID = newBranches[0].id;
						await Promise.all(formValues.contacts.map(async(item) => {
							await postClientContact({
								id_client_branch: branchID,
								full_name: `${item.last_name}${item.first_name}`,
								position: item.position || "",
								email: item.email ? [item.email] : [],
								phone: item.phone ? [item.phone] : [],
							})
								.then((res) => {})
								.catch(() => {
									setLoading(false);
									errHandler(err, setError, route); 
								});
						}))
						clientContactCreated = true;
					}
					const nouns = clientContactCreated ? `${I18n.t("contacts")}, ${I18n.t("branch")}` : I18n.t("branch");
					setMessage(I18n.t("requestSuccessful", { name: nouns, verb: I18n.t("created") }));
					setLoading(false);
					setModal(false);
					setRefreshClient(!refreshClient);
				})
				.then(async() => {})
		} catch(err) {
			setLoading(false);
			errHandler(err, setError, route); 
		}
	}

	const editBranch = async(formValues) => {
		setLoading(true);
		const { branch, branch_id, address, zip_code, city, country, branch_secondBusinessLevel, branch_productManufactured, branch_salesman } = formValues;
		const optionalParams = removeEmptyFields({
			id_second_industry_level: branch_secondBusinessLevel,
			product_manufactured: branch_productManufactured,
			id_salesman: branch_salesman
		});
		putClientBranch(branch_id, {
			name: branch,
			address,
			zip_code,
			city,
			country,
			...optionalParams
		})
			.then(() => {
				if (formValues.contacts?.length > 0) {
					formValues.contacts.map((item) => {
						if (item.id_client_branch) { // update existing contacts
							putClientContact(item.id, {
								full_name: `${item.last_name}${item.first_name}`,
								email: item.email ? [item.email] : [],
								phone: item.phone ? [item.phone] : [],
							})
								.then(() => {})
								.catch((err) => errHandler(err, setError, route));
						} else { // create new contact
							postClientContact({
								id_client_branch: clientData.branch_id,
								full_name: `${item.last_name}${item.first_name}`,
								position: item.position || "",
								email: item.email ? [item.email] : [],
								phone: item.phone ? [item.phone] : [],
							})
								.then((res) => {})
								.catch((err) => errHandler(err, setError, route));
						}
					})
				}
				if (formValues.deleteContact?.length > 0) { // delete existing contact
					formValues.deleteContact.map((item) => {
						deleteClientContact(item)
							.catch((err) => errHandler(err, setError, route));
					})
				}
				setRefreshClient(!refreshClient);
				// clientEvents.setSelectedDataIndex({ ...selectedDataIndex, client_id: formValues.branch_id });
				setLoading(false);
				setModal(false);
			})
			.catch((err) => {
				setLoading(false);
				errHandler(err, setError, route);
			});
	}

	const submitDelete = () => {
		const obj = {
			deleteGroup: deleteClient,
			deleteBranch: deleteClientBranch,
		};
		obj[popModal](select.id || select.branch_id).then(({ data }) => {
			const noun = popModal == "deleteGroup" ? "Group" : "branch";
			setMessage(I18n.t("requestSuccessful", { name: I18n.t(noun), verb: I18n.t("deleted") }));
			setRefreshClient(!refreshClient);
		})
		setClient(null)
	}

	const onSearch = (type, value) => {
		setSearchText({ ...searchText, [type]: value });
	}

	return (
		<div className={classes.root}>
			{
				// if (client != null) show client detail
				client ?
					<ClientDetail
						isFetched={clientEvents.setClientDataFetched}
						data={clientData}
						setClient={setClient}
						machine={machine}
						clientGroup={clientGroup}
						clientBranch={clientBranch}
						contactsData={contactsData}
						setContactsData={clientEvents.setContactsData}
						setModalOrigin={setModal}
						open={handleOpen}
						refresh={refreshClient}
						setRefresh={setRefreshClient}
						loading={loading}
						setLoading={setLoading}
						editDeleteAllow={editDeleteAllow}
						seeGroup={() => {
							setClient(null);
							clientEvents.onChangeType('group', selectedDataIndex.group);
						}}
					/>
					:
					<Grid container spacing={3}>
						<Grid item xs={12} md={5} className={classes.listContainer}>
							<List
								noAvatar
								label={isTopFifty ? "topFiftyClient" : "client"}
								isFetched={clientEvents.groupFetched}
								data={clientGroup}
								add={true}
								search={true}
								open={() => {
									handleOpen("add_group")
									setShowHeader(false)
								}}
								title="Group"
								width='100%'
								contentHeight='300px'
								selectable
								hasSwitch
								switchTitle={I18n.t("topFifty")}
								isChecked={isTopFifty}
								handleSwitch={handleSwitch}
								overideSelect={selectedDataIndex.group}
								onClick={(i) => clientEvents.onChangeType('group', i)}
								searchId='GroupSearch'
								searchText={clientSearchText}
								placeholder={I18n.t("GroupName")}
								onEndReached={() => clientEvents.onEndReached('group', () => clientEvents.setGroupPage(groupPage + 1))}
								onChange={(searchValue) => {
									setClientSearchText(searchValue)
									clientEvents.onSearch('group', searchValue)
								}}
							/>
							<List
								noAvatar
								label={isTopFifty ? "topFiftyClient" : "client_branch"}
								isFetched={clientEvents.branchFetched}
								data={clientBranch}
								search={true}
								add={true}
								open={() => {
									handleOpen("add_branch")
									setShowHeader(false)
								}}
								title="Branch"
								width='100%'
								contentHeight='300px'
								selectable
								overideSelect={selectedDataIndex.branch}
								onClick={(i) => clientEvents.onChangeType('branch', i)}
								searchId='BranchSearch'
								searchText={branchSearchText}
								placeholder={I18n.t("BranchName")}
								onEndReached={() => clientEvents.onEndReached('branch', () => clientEvents.setBranchPage(branchPage + 1))}
								onChange={(searchValue) => {
									setBranchSearchText(searchValue);
									clientEvents.onSearch('branch', searchValue);
								}}
							/>
						</Grid>
						<Grid item xs={12} md={7}>
							{
								selectedDataIndex.branch === null ?
									<Container
										title={I18n.t('Information')}
										doEdit={() => setModal('edit_group')}
										doDelete={() => handleOpen("deleteGroup", clientGroup[selectedDataIndex.group])}
										editDeleteAllow={editDeleteAllow}
									>
										<ClientRightInfo
											data={clientGroup[selectedDataIndex.group]}
											type='group'
											showHeader={showHeader}
											bestSeller={clientEvents.machineRanking}
										/>
									</Container>
									:
									<Container
										title={I18n.t('Information')}
										doEdit={() => setModal('edit_branch')}
										doDelete={() => handleOpen("deleteBranch", clientBranch[selectedDataIndex.branch])}
										seeMore={() => setClient(clientData)}
										editDeleteAllow={editDeleteAllow}
									>
										<ClientRightInfo
											data={clientData}
											type='branch'
											showHeader={showHeader}
											bestSeller={clientEvents.machineRanking}
										/>
									</Container>
							}
						</Grid>
					</Grid>
			}
			<Modal
				title={["AddA", "client"]}
				open={modal === "add_group" || modal === "add_branch"}
				close={handleClose}
				style={{ paddingBottom: '64px' }}
			>
				<ClientInfo
					label="add"
					submit={() => console.log('add client')}
					onClick={selectType === "group" ? addGroup : addBranch}
					defaultClientType={modal && modal.split('_')[1]}
					setSelectType={setSelectType}
					clientGroup={clientGroup}
					machine={machine}
					contactsData={[]}
					loading={loading}
				/>
			</Modal>
			<Modal
				title={["EditA", "client"]}
				open={modal === "edit_group" || modal === "edit_branch"}
				close={handleClose}
				style={{ paddingBottom: '64px' }}
			>
				<ClientInfo
					label="edit"
					submit={() => console.log('edit client')}
					onClick={selectType === "group" ? editGroup : editBranch}
					defaultClientType={modal && modal.split('_')[1]}
					data={clientData}
					setSelectType={setSelectType}
					clientGroup={clientGroup}
					machine={machine}
					contactsData={contactsData}
					loading={loading}
				/>
			</Modal>
			<Modal
				title={["AddA", "contact"]}
				open={modal === "addContact"}
				close={() => setModal(false)}
				style={{ paddingBottom: '64px' }}
			>
				<ContactForm
					// add standalone affect UI (gray -> white), this components was extracted from ClientInfo
					standalone
					setShow={setModal}
					loading={loading}
				/>
			</Modal>
			<DeleteModal
				open={popModal.length > 0}
				handleClose={handleClose}
				name={select.name || select.branch}
				submit={() => {
					submitDelete()
					setPopModal(false)
				}}
			/>
		</div>
	)
}

const Container = ({ title, doEdit, doDelete, seeMore, children, editDeleteAllow }) => {
	const classes = useStyles();
	return (
		<div className={classes.container}>
			<Row justifyContent='space-between' alignItems='center' className={classes.mb2}>
				<Row>
					<Typography variant="h6" color="textSecondary" className='title'>{title}</Typography>
				</Row>
				{
					editDeleteAllow &&
					<Row>
						<Button iconType="edit" bgColor="#F5F5F5" text="Edit" onClick={doEdit} />
						<div style={{ width: 24 }} />
						<Button iconType="delete" bgColor="red" textColor="white" onClick={doDelete} />
					</Row>
				}
			</Row>
			{children}
		</div>
	)
}

export default Client;
