/** @jsx h */
import React from 'react';
import { h } from "preact";
import { useState, useEffect, useRef, useContext } from "preact/hooks";
import I18n from "i18n-js";
import moment from "moment/min/moment-with-locales";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { Row } from "components/atoms/row/Row";
import { Column } from "components/atoms/column/Column";
import { Card } from "components/atoms/card/Card";
import CubeBtn from "components/atoms/cubeButton/CubeBtn";
import Select from "components/atoms/select/Select";
import { MultiSelect } from 'components/atoms/multiSelect/MultiSelect';
import YearPicker from "components/atoms/yearPicker/YearPicker";
import ExportExcelButton from 'components/atoms/exportExcelButton/ExportExcelButton';
import { SortableTable } from "components/molecules/sortableTable/SortableTable";
import { CollapseTable } from "components/molecules/collapseTable/CollapseTable";

import { getObjectiveGroup, getObjectiveSalesman, setObjective, setObjectiveGroup, setObjectiveSalesman } from "api";
import { formatPrice } from 'helper/format';
import Context from 'context';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    width: "100%",
    color: "rgba(0, 0, 0, 0.54)",
  },
  areaViewColumn: {
    marginBottom: theme.spacing(2),

    "& .MuiPaper-root > div > .MuiTable-root > .MuiTableBody-root >.MuiTableRow-root:first-child > .MuiTableCell-body div": {
      fontWeight: 700
    }
  },
  mb2: {
    marginBottom: theme.spacing(2),
  },
  mr2: {
    marginRight: theme.spacing(2),
  },
  icon: {
    color: "rgba(0, 0, 0, 0.54)",
    cursor: "pointer",
    marginRight: theme.spacing(1),
    "&:hover": {
      color: "#2e65c9",
    },
    "&:last-child": {
      marginRight: 0,
    },
  },
}));

const Objective = () => {
  const classes = useStyles();
  const { regionList, groupList } = useContext(Context);
  // Top select state
  const [period, setPeriod] = useState([
    { id: "year", text: I18n.t("filterBy", { text: I18n.t("year").toLowerCase() }) },
    { id: "month", text: I18n.t("filterBy", { text: I18n.t("month").toLowerCase() }) },
  ]);
  const [searchText, setSearchText] = useState("");
  // Selected
  const [selectedYear, setSelectedYear] = useState(moment().utc().year());
  const [selectedGroup, setSelectedGroup] = useState([0]);
  const [selectedPeriod, setSelectedPeriod] = useState(period[0]);
  // Table state
  const [areaViewData, setAreaViewData] = useState(null);
  const [salesmanViewData, setSalesmanViewData] = useState(null);
  const [filteredSalesman, setFilteredSalesman] = useState(null);

  const inputFile = useRef(null);

  const monthsShortArr = moment.monthsShort();

  // Get data for table
  useEffect(() => {
    if (!groupList.length) return;
    let filteredUser = salesmanViewData?.map((row, i) => {
      // get region of the user by id_group from group
      const groupDetail = groupList.find(g => g.id === row.id_group);

      // convert month data to wanted format
      const monthsData = Object.values(row.monthly_objective).reduce((a, v, i) => (
        { ...a, [monthsShortArr[i]]: v }
      ), {});

      return {
        id: row.id_salesman,
        id_region: groupDetail?.id_region,
        id_group: row.id_group,
        name: row.name,
        team: groupDetail?.name,
        objective: row.year_objective,
        ...monthsData
      }
    }) || [];

    if (!selectedGroup.includes(0) && !!selectedGroup.length) {
      filteredUser = filteredUser.filter(user => selectedGroup.includes(user.id_group));
    }
    setFilteredSalesman(filteredUser);
  }, [salesmanViewData, groupList, selectedGroup]);

  const fetchData = async () => {
    // Get data from APIs
    const [obj_group, obj_salesman] = await Promise.all([
      getObjectiveGroup({ year: selectedYear }), getObjectiveSalesman({ year: selectedYear })
    ]);
    // Preprocess obj for groups
    let totalRowData = { id: 'total', area: 'Total' };
    let obj_group_data = obj_group.data.reduce((acc, row) => {
      // get region name by id_region
      const regionDetail = regionList.find(r => r.id === row.id_region);
      // convert month data to wanted format
      const monthsData = Object.values(row.monthly_objective).reduce((a, v, i) => (
        { ...a, [monthsShortArr[i]]: v }
      ), {});

      // check for concurrent of id_region 
      let obj = acc.find((o) => o.id === row.id_region);
      // Not exist, add one with region
      if (!obj) {
        acc.push({
          id: row.id_region,
          area: regionDetail.name,
          children: []
        });
        obj = acc[acc.length - 1];
      }
      // If exist, push this row to it's children
      obj.children.push({
        id: row.id_group,
        area: row.name,
        objective: row.year_objective,
        percentage: row.year_accomplish, // NOTE: percentage not sure how to do
        progress: row.year_progress,
        ...monthsData
      });
      // After pushed, update the parent row 
      obj.objective = ((obj.objective || 0) + parseInt(row.year_objective));
      obj.progress = ((obj.progress || 0) + parseInt(row.year_progress));
      obj.percentage = obj.objective !== 0 ?
        ((obj.progress / obj.objective * 100).toFixed(2) + '%') : '100%';

      totalRowData.objective = ((totalRowData.objective || 0) + parseInt(row.year_objective));
      totalRowData.progress = ((totalRowData.progress || 0) + parseInt(row.year_progress));
      totalRowData.percentage = totalRowData.objective !== 0 ?
        ((totalRowData.progress / totalRowData.objective * 100).toFixed(2) + '%') : '100%';
      // Loop thru monthData and update
      monthsShortArr.forEach(mon => {
        obj[mon] = ((obj[mon] || 0) + parseInt(obj.children[obj.children.length - 1][mon]));
        totalRowData[mon] = ((totalRowData[mon] || 0) + parseInt(obj.children[obj.children.length - 1][mon]));
      });

      return acc;
    }, []);
    // After all data in region/group is done, unshift total row in front of data array
    if (obj_group_data.length > 0) obj_group_data.unshift(totalRowData);
    setAreaViewData(obj_group_data);
    setSalesmanViewData(obj_salesman.data);
  }

  const uploadObjective = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    formData.append('year', selectedYear);
    const { data } = await setObjective(formData);
    if (data.msg === 'ALL_OBJECTIVE_UPDATED') {
      fetchData();
    }
    else {
      alert("Upload failed", data.err);
    }
  }

  useEffect(() => {
    // Ensure data is loaded
    if (selectedYear && regionList.length > 1) fetchData();
  }, [selectedYear, regionList]);

  const filteredData = filteredSalesman?.filter((item) => {
    return item.name?.toLowerCase().includes(searchText.toLowerCase());
	});

  return (
    <div className={classes.root}>
      <Row justifyContent="space-between" alignItems="center" className={classes.mb2}>
        <Row justifyContent="flex-start" alignItems="center">
          <Typography variant="h5" color="inherit" className={classes.mr2}>
            {I18n.t("Goal")}
          </Typography>
          <span>({I18n.t("unit")}: {I18n.t("thousandDollar")})</span>
        </Row>
        <Row style={{ gap: 10 }} alignItems="center">
          <YearPicker calendarIcon onChange={setSelectedYear} />
          <CubeBtn
            iconType="search"
            expandable
            searchText={searchText}
            placeholder={I18n.t("salesman")}
            onChange={(text) => setSearchText(text)}
          />
          <MultiSelect
            data={groupList}
            title="team"
            labelKey="name"
            valueKey="id"
            placeholder={I18n.t('team')}
            value={selectedGroup}
            onChange={setSelectedGroup}
          />
          <Select width='auto' data={period} filterIcon onClick={setSelectedPeriod} />
          <input type='file' id='csv_file' accept=".xlsx" ref={inputFile} style={{ display: 'none' }} onChange={(e) => { uploadObjective(e) }} />
        </Row>
      </Row>
      <AreaView
        data={areaViewData}
        period={selectedPeriod.id}
        year={selectedYear}
        fetchData={fetchData}
        searchText={searchText}
      />
      <SalesmanView
        data={filteredData}
        period={selectedPeriod.id}setObjectiveGroup
        year={selectedYear}
        fetchData={fetchData}
        searchText={searchText}
      />
    </div>
  )
}

const AreaView = ({ data, period, fetchData, searchText }) => {
  const classes = useStyles();
  const [editing, setEditing] = useState(null);
  const [fetched, setFetched] = useState(false);
  const inputYearObjective = useRef(null);
  const inputFirstMonthObjective = useRef(new Array(6));
  const inputSecondMonthObjective = useRef(new Array(6));
  // CollapseTable will trigger expand when edit onclick, stopPropagation to prevent
  const edit = (e, row) => {
    e.preventDefault();
    e.stopPropagation();
    setEditing(row);
  }

  useEffect(() => {
    if (data !== null) setFetched(true);
  }, [data])

  const modify = (e, row, year) => {
    setFetched(false);
    let firstMonthObjective = new Array();
    let secondMonthObjective = new Array();
    if (typeof inputFirstMonthObjective.current[0] === "string") {  
      inputFirstMonthObjective.current.map(i => {
        firstMonthObjective.push(Number(i))
      })
    }
    if (typeof inputSecondMonthObjective.current[0] === "string") {  
      inputSecondMonthObjective.current.map(i => {
        secondMonthObjective.push(Number(i))
      })
    }
    if (inputYearObjective.current !== null) { // Setting group's year objective
      editing.objective = Number(inputYearObjective.current.value)
      for (var obj in data) {
        if (obj.id === 'total') continue;
        for (var child in obj.children) {
          if (child.id === editing.id && child.area===editing.area) {
            child = editing;
            break;
          }
        }
      }
      setFetched(true);
    }
    else if (firstMonthObjective.length === 6) { // Setting group's month objective (Jan - June)
      let promise = new Promise((res, _) => res());
      firstMonthObjective.map(async (i, index) => {
        let date = moment(year).month(index).format('YYYY-MM')
        promise = promise.then(() => {
          return setObjectiveGroup({
            name: row.area,
            date,
            objective: Number(i),
          })
        })
      })
      promise = promise.then(() => {
        alert(`Completed set group's month objective for ${row.area}`);
        fetchData()
      })
    }
    else if (secondMonthObjective.length === 6) { // Setting group's month objective (July - Dec)
      let promise = new Promise((res, _) => res());
      secondMonthObjective.map(async (i, index) => {
        let date = moment(year).month(index + 6).format('YYYY-MM')
        promise = promise.then(() => {
          return setObjectiveGroup({
            name: row.area,
            date,
            objective: Number(i),
          })
        })
      })
      promise = promise.then(() => {
        alert(`Completed set group's month objective for ${row.area}`);
        fetchData()
      })
    }
  }

  const year_columns = [
    {
      id: "area",
      label: I18n.t("area"),
      sortable: true,
    },
    {
      id: "objective",
      label: I18n.t("Goal"),
      sortable: true,
      Cell: (data) => editing?.id === data.id && editing?.area === data.area ?
        <input ref={inputYearObjective} defaultValue={data.objective} onKeyPress={(event) => {
          if (!/\d/.test(event.key)) {
            event.preventDefault();
          }
        }}/> : formatPrice(data.objective)
    },
    {
      id: "percentage",
      label: I18n.t("percentage"),
      sortable: true,
    },
  ];

  const month_columns_1 = [
    {
      id: "area",
      label: I18n.t("area"),
      sortable: true,
    },
    ...[
      ...moment.monthsShort().slice(0, 6).map((mon, index) => ({
        id: mon,
        label: mon,
        sortable: true,
        Cell: (data) => editing?.id === data.id && editing?.area === data.area && editing.half === 'first' ?
          <input id={`input_first_${mon}`} ref={el => { inputFirstMonthObjective.current[index] = el?.value ?? data[mon] }} defaultValue={data[mon]} onKeyPress={(event) => {
            if (!/\d/.test(event.key)) {
              event.preventDefault();
            }
          }}
          onKeyUp={event => {
            if (/\d/.test(event.key)) {
              inputFirstMonthObjective.current[index] = event.target.value;
            }
          }}/> : formatPrice(data[mon])
      }))
    ],
  ];

  const month_columns_2 = [
    {
      id: "area",
      label: I18n.t("area"),
      sortable: true,
    },
    ...[
      ...moment.monthsShort().slice(6, 12).map((mon, index) => ({
        id: mon,
        label: mon,
        sortable: true,
        Cell: (data) => editing?.id === data.id && editing?.area === data.area && editing.half === 'second' ?
          <input id={`input_second_${mon}`} ref={el => inputSecondMonthObjective.current[index] = el?.value ?? data[mon]} defaultValue={data[mon]} onKeyPress={(event) => {
            if (!/\d/.test(event.key)) {
              event.preventDefault();
            }
          }}
          onKeyUp={event => {
            if (/\d/.test(event.key)) {
              inputSecondMonthObjective.current[index] = event.target.value;
            }
          }}/> : formatPrice(data[mon])
      }))
    ],
  ];

  return (
    <>
      {
        period === 'year' ?
          <Column className={classes.areaViewColumn}>
            <CollapseTable
              columns={year_columns}
              data={data || []}
              canDropDrag={false}
              skeletonColumns={4}
              isFetched={fetched}
              disableSelection
              pageResetKey={!!searchText}
            />
          </Column>
          :
          <>
            <Column className={classes.areaViewColumn}>
              <CollapseTable
                columns={month_columns_1}
                data={data || []}
                canDropDrag={false}
                skeletonColumns={8}
                isFetched={fetched}
                disableSelection
                pageResetKey={!!searchText}
              />
            </Column>
            <Column className={classes.areaViewColumn}>
              <CollapseTable
                columns={month_columns_2}
                data={data || []}
                canDropDrag={false}
                skeletonColumns={8}
                isFetched={fetched}
                disableSelection
                pageResetKey={!!searchText}
              />
            </Column>
          </>
      }
    </>
  )
}

const SalesmanView = ({ data, period, year, fetchData, searchText }) => {
  const classes = useStyles();
  const [editing, setEditing] = useState(null);
  const [fetched, setFetched] = useState(false);
  const inputYearObjective = useRef(null);
  const inputFirstMonthObjective = useRef(new Array(6));
  const inputSecondMonthObjective = useRef(new Array(6));

  useEffect(() => {
    if (data !== null) setFetched(true);
  }, [data])

  const year_columns = [
    {
      id: "name",
      label: I18n.t("salesman"),
      sortable: true,
    },
    {
      id: "team",
      label: I18n.t("Team"),
      sortable: true,
    },
    {
      id: "objective",
      label: I18n.t("Goal"),
      sortable: true,
      cell: (data) => editing?.id === data.id ?
        <input ref={inputYearObjective} defaultValue={formatPrice(data.objective)} /> : formatPrice(data.objective)
    },
  ];

  const month_columns_1 = [
    {
      id: "name",
      label: I18n.t("salesman"),
      sortable: true,
    },
    {
      id: "team",
      label: I18n.t("Team"),
      sortable: true,
    },
    ...[
      ...moment.monthsShort().slice(0, 6).map((mon, index) => ({
        id: mon,
        label: mon,
        sortable: true,
        cell: (data) => editing?.id === data.id && editing.half === 'first' ?
        <input id={`input_first_sales_${mon}`} ref={el => { inputFirstMonthObjective.current[index] = el?.value ?? data[mon] }} defaultValue={data[mon]} onKeyPress={(event) => {
          if (!/\d/.test(event.key)) {
            event.preventDefault();
          }
        }}
        onKeyUp={event => {
          if (/\d/.test(event.key)) {
            inputFirstMonthObjective.current[index] = event.target.value;
          }
        }}/> : formatPrice(data[mon])
      }))
    ],
  ];

  const month_columns_2 = [
    {
      id: "name",
      label: I18n.t("salesman"),
      sortable: true,
    },
    {
      id: "team",
      label: I18n.t("Team"),
      sortable: true,
    },
    ...[
      ...moment.monthsShort().slice(6, 12).map((mon, index) => ({
        id: mon,
        label: mon,
        sortable: true,
        cell: (data) => editing?.id === data.id && editing.half === 'second' ?
        <input id={`input_second_sales_${mon}`} ref={el => inputSecondMonthObjective.current[index] = el?.value ?? data[mon]} defaultValue={data[mon]} onKeyPress={(event) => {
          if (!/\d/.test(event.key)) {
            event.preventDefault();
          }
        }}
        onKeyUp={event => {
          if (/\d/.test(event.key)) {
            inputSecondMonthObjective.current[index] = event.target.value;
          }
        }}/> : formatPrice(data[mon])
      }))
    ]
  ];
  
  const getExportData = (data) => {
    if (!data?.length) return [];
    return structuredClone(data)?.map(row => {
        const keys = Object.keys(row).filter(key => key.includes('月'));
        keys.forEach(key => {
            row[key] = formatPrice(row[key]);
        });
        return row;
    }) ?? []
  }

  return (
    <>
      {
        period === 'year' ? (
            <Card
                title={year.toString()}
                wrapStyle={{ marginTop: 16 }}
                actionComponent={(
                    <ExportExcelButton
                        sheetName={`${year} ${I18n.t("Goal")}`}
                        columns={year_columns.map(column => ({ ...column, text: column.label }))}
                        data={data?.map(row => ({ ...row, objective: formatPrice(row.objective) })) ?? []}
                        headerColor='EBF1DE'
                        fileType="xlsx"
                    />
                )}
            >
                <Column className={classes.mb2}>
                    <SortableTable
                        columns={year_columns}
                        data={data ?? []}
                        skeletonColumns={4}
                        isFetched={fetched}
                        striped
                        pageResetKey={!!searchText}
                    />
                </Column>
            </Card>    
        ) :
          <>
            <Column className={classes.mb2}>
              <Card
                title={I18n.t("firstHalf", { year: year })}
                actionComponent={(
                    <ExportExcelButton
                        sheetName={`${I18n.t("firstHalf", { year: year })} ${I18n.t("Goal")}`}
                        columns={month_columns_1.map(column => ({ ...column, text: column.label }))}
                        data={getExportData(data)}
                        headerColor='EBF1DE'
                        fileType="xlsx"
                    />
                )}
              >
                <SortableTable
                  columns={month_columns_1}
                  data={data ?? []}
                  skeletonColumns={9}
                  isFetched={fetched}
                  striped
                  pageResetKey={!!searchText}
                />
              </Card>
            </Column>
            <Column className={classes.mb2}>
              <Card
                title={I18n.t("secondHalf", { year: year })}
                actionComponent={(
                    <ExportExcelButton
                        sheetName={`${I18n.t("secondHalf", { year: year })} ${I18n.t("Goal")}`}
                        columns={month_columns_2.map(column => ({ ...column, text: column.label }))}
                        data={getExportData(data)}
                        headerColor='EBF1DE'
                        fileType="xlsx"
                    />
                )}
              >
                <SortableTable
                  columns={month_columns_2}
                  data={data ?? []}
                  skeletonColumns={9}
                  isFetched={fetched}
                  striped
                  pageResetKey={!!searchText}
                />
              </Card>
            </Column>
          </>
      }
    </>
  )
}

export default Objective;