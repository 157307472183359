import { makeStyles } from '@material-ui/core/styles'; 

const useStyles = makeStyles({
    avatar: {
        background:'rgba(0,0,0,.16)',
        borderRadius:'50%',
        overflow:'hidden',
        width: '8vw',
        height: '8vw',
        marginRight: '32px'
    },
    inputLabel: {
        color:'#B0B0B0',
        fontWeight: 'bold'
    },
    select:{
        cursor: 'pointer',
        display: 'inline-block'
    },
    visibleIcon: {
        position: 'absolute',
        right: '10px',
        top: '50%',
        transform: 'translateY(-50%)'
    }
})
export default useStyles;