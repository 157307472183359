/* eslint-disable no-else-return */
import { useEffect, useState, useContext, useRef } from 'preact/hooks'

import { useRole } from './useRole'
import Context from 'context'

import { getUserSorting, putUserSorting, getGroup } from 'api'

//目前組別預設排序 1.台灣區北、2.中、3.南
const GROUPS_SEQUENCE_COLOR_PALETTE = [
  '#fffde7',
  '#e8f5e9',
  '#e8eaf6',
  '#fafafa', //其他區
]
const DEFAUT_GROUP_DATA = (id) => ({
  id: id,
  sequence: [],
})

export const useUserSequence = () => {
  const [sequence, setSequence] = useState([])
  const flattendSequence = useRef([])
  const { user, groupList } = useContext(Context)
  const role = useRole()

  const groupSequence = useRef([])

  useEffect(() => {
    if (!role.isLoading) {
      getGroupSequence()
      loadSequenceByRole()
    }
  }, [role.isLoading])

  const getGroupSequence = () => {
    const sorttedGroupList = groupList?.sort(
      (a, b) => a.id_sorting - b.id_sorting
    )
    groupSequence.current = sorttedGroupList.map(({ id }) => id)
  }

  const loadSequenceByRole = () => {
    if (role.isBigBoss) {
      loadBigBossData()
    } else if (role.isManager) {
      loadManagerData()
    } else if (role.isTeamLeader) {
      loadTeamLeaderData()
    }
  }

  const loadBigBossData = async () => {
    if (!groupList?.length) return 
    try {
      const { data } = await getUserSorting()
      const formattedData = data.map(({ id_group, sequence }) => ({
        id: id_group,
        region: groupList.find(item => item.id === id_group)?.id_region,
        sequence,
      }))
      const computedSequence = groupSequence.current.map(
        (groupId) =>
          formattedData.find(({ id }) => groupId === id) ||
          DEFAUT_GROUP_DATA(groupId)
      )
      flattendSequence.current = computedSequence
        .map(({ sequence }) => sequence)
        .flat()
      setSequence(computedSequence)
    } catch (e) {
      console.log(e.response?.data)
    }
  }

  const loadManagerData = async () => {
    const userRegion = user.id_region
    const visibleGroup = groupList?.filter(
      ({ id_region }) => id_region === userRegion
    )

    const sequencePromises = visibleGroup?.map(async ({ id }) => {
      try {
        const { data } = await getUserSorting({ id_group: id })
        return data.map(({ id_group, sequence }) => ({
          id: id_group,
          sequence,
        }))[0]
      } catch (e) {
        console.log(e.response?.data)
      }
    })
    const sequenceArray = await Promise.all(sequencePromises)

    const computedSequence = groupSequence.current.map(
      (groupId) =>
        sequenceArray.find(({ id }) => groupId === id) ||
        DEFAUT_GROUP_DATA(groupId)
    )

    ///

    flattendSequence.current = computedSequence
      .map(({ sequence }) => sequence)
      .flat()
    setSequence(computedSequence)
  }

  const loadTeamLeaderData = async () => {
    const userGroup = user.id_group
    const { data } = await getUserSorting({ id_group: userGroup })
    const formattedData = data.map(({ id_group, sequence }) => ({
      id: id_group,
      sequence,
    }))
    const computedSequence = groupSequence.current.map(
      (groupId) =>
        formattedData.find(({ id }) => groupId === id) ||
        DEFAUT_GROUP_DATA(groupId)
    )

    flattendSequence.current = [...formattedData[0].sequence]
    setSequence(computedSequence)
  }

  /**
   * @typedef {Object} DndResult
   * @property {number} groupId required, current group id
   * @property {number} from required, user id which is dragged
   * @property {number} to required, user id which is dropped
   *
   * @param {DndResult} result
   */
  const handleReorder = (result) => {
    let newSequence = []
    const newData = sequence.map((group) => {
      if (group.id === result.groupId) {
        const fromIndex = group.sequence.findIndex((id) => id === result.from)
        const toIndex = group.sequence.findIndex((id) => id === result.to)
        newSequence = [...group.sequence]

        const [sourceItem] = newSequence.splice(fromIndex, 1)
        newSequence.splice(toIndex, 0, sourceItem)

        return { ...group, sequence: newSequence }
      } else {
        return group
      }
    })

    flattendSequence.current = newData.map(({ sequence }) => sequence).flat()
    setSequence(newData)

    //write back to db
    putUserSorting({ id_group: result.groupId, sequence: newSequence }).catch(
      (e) => console.log(e.response?.data)
    )
  }

  return {
    data: sequence,
    flattened: flattendSequence.current,
    groupSequence: groupSequence.current,
    groupColorPalette: GROUPS_SEQUENCE_COLOR_PALETTE,
    reorder: handleReorder,
  }
}
