/** @jsx h */
import { h } from "preact";
import { useState, useEffect } from "preact/hooks";
import I18n from "i18n-js";

import MobileStepper from '@material-ui/core/MobileStepper';
import { Typography, IconButton } from '@material-ui/core';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import { Row } from 'components/atoms/row/Row';
import { Column } from 'components/atoms/column/Column';
import Calculation from "components/molecules/calculation/Calculation";

const useStyles = makeStyles((theme) => ({
  root: {
    '& > div': {
      padding: theme.spacing(2)
    }
  },

  stepper: {
    justifyContent: 'center',
    backgroundColor: '#FFFFFF'
  },

  iconButton: {
    backgroundColor: '#F5F5F5',
    borderRadius: theme.spacing(1),
    padding: 5,

    '& svg': {
      fontSize: 35
    },

    '&:hover': {
      backgroundColor: '#EEEEEE'
    }
  },

  img: {
    maxWidth: 'calc(100% - 90px)'
  }
}));

const MachineInfo = ({ orderData, seeMachineNote }) => {
    const [machineIndex, setMachineIndex] = useState(0);
    const [uniqueMachine, setUniqueMachine] = useState([]);
    const theme = useTheme();
    const classes = useStyles();

    useEffect(() => {
        let Machine = [];
        for (var j=0;j<orderData?.machines?.length;j++) {
          const filteredData = orderData.machines.filter((machine) => machine.label === orderData.machines[j].label)
          let machineData = {
            label: orderData.machines[j].label,
            value: orderData.machines[j].value,
            quantity: 0,
            total: 0,
            options: [],
          }
          filteredData.forEach(item => {
            machineData.quantity += item.quantity;
            machineData.total += item.value * item.quantity + item.optionsPrice + item.extra_price;
            machineData.options.push({
              ...item,
              label: orderData.machines[j].label
            });
          });
          Machine.push(machineData)
        }
        const uniqueMachine = Machine.filter((machine, index) => {
          return Machine.findIndex(unique => unique.label === machine.label) === index;
        })
        const mergeMachine = uniqueMachine.map((machine) => {
          let newOptions = [];
          machine.options.map((option) => {
            let newOption = newOptions.find(newOption => {
              return newOption.extra_price === option.extra_price && newOption.optionsPrice === option.optionsPrice && newOption.otherOptions.length === option.otherOptions.length && newOption.options.length === option.options.length;
            })
            if (newOption) {
              newOption.quantity = newOption.quantity + option.quantity
            }
            else {
              newOptions.push(option)
            }
          })
          return {
            ...machine,
            options: newOptions,
          }
        })
        setUniqueMachine(mergeMachine)
    }, []);

    return (
      <Column className={classes.root}>
        <Row justifyContent='space-between' alignItems='center'>
          <IconButton
            className={classes.iconButton}
            onClick={() => setMachineIndex(prev => prev - 1)}
            disabled={machineIndex === 0}
          >
            <KeyboardArrowLeft />
          </IconButton>
          <Typography variant='h5' color='textPrimary'>
            {I18n.t(orderData.machines[machineIndex].label)}
          </Typography>
          <IconButton
            className={classes.iconButton}
            onClick={() => setMachineIndex(prev => prev + 1)}
            disabled={machineIndex === uniqueMachine.length - 1}
          >
            <KeyboardArrowRight />
          </IconButton>
        </Row>
        <MobileStepper
          variant="dots"
          steps={uniqueMachine.length}
          position="static"
          activeStep={machineIndex}
          className={classes.stepper}
        />
        <Calculation data={uniqueMachine[machineIndex]} discount={orderData.machines[machineIndex].discount} />
      </Column>
    )
}

export default MachineInfo;