import { makeStyles } from '@material-ui/core/styles'; 

const useStyles = makeStyles({
    blueBtn:{
        cursor: 'pointer',
        fontSize: '14px'
    },
    main: {
        fontWeight: 500,
        padding: '16px 0',
        background: '#2E65C9',
        color: 'white',
        borderRadius: '4px'
    },
    outline: {
        fontSize: '16px',
        lineHeight: 1.5,
        padding: '16px',
        background: 'transparent',
        border: '1px solid #2E65C9',
        color: '#2E65C9',
        borderRadius: '8px'
    }
})
export default useStyles;
