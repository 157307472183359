import { makeStyles } from '@material-ui/core/styles'; 

const useStyles = makeStyles({
    cubeBtn: {
        cursor: 'pointer',
        width: 'fit-content',
        padding: '8px',
        borderRadius: '8px',
        background: 'white',
        transition: 'all 300ms ease',
        '& input': {
            padding: 0,
            height: '24px',
            width: 0,
            fontWeight:'normal',
            float: 'right',
            outline: 'none',
            border: 0,
            borderRadius: '5px',
            backgroundColor: 'rgba(255, 255, 255, .1)',
            transition: 'all 300ms ease',
        },
        '& span': {
            fontWeight:'normal',
            color: 'rgba(0,0,0, .54)',
            marginLeft: '8px',
            verticalAlign: 'middle',
            fontSize:"16px"
        }
    }
})
export default useStyles;