/** @jsx h */
import { h } from "preact";
import { useState, useEffect, useContext } from "preact/hooks";
import I18n from "i18n-js";

import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import TableRow from "@material-ui/core/TableRow";

import SearchIcon from "@material-ui/icons/Search";

import { Table } from "components/atoms/table/Table";
import Context from "context";

import { getRoleString } from "api";

const useStyles = makeStyles(() => ({
  button: {
    textTransform: "none",
    backgroundColor: "#2E65C9",
    borderRadius: 10,
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
  },
}));

// WE NEED TO FETCH NAME FROM APi AS i18N VARIABLE
const roleNames = [
  { name: "Bigboss", color: "#FF2C2C" },
  { name: "Manager", color: "#A067D9" },
  { name: "Leader", color: "#4FAFCD" },
  { name: "", color: "black" }
];

const columns = [
  { id: "username", sortable: true },
  { id: "email", sortable: true },
  { id: "phone", sortable: true },
  { id: "group_role", sortable: true },
  { id: "Action", sortable: false },
];

const Row = ({ row, index, border, setUserInfo }) => {
  const classes = useStyles();

  return (
    <TableRow
      key={row.id}
      align="center"
      style={{
        backgroundColor: index % 2 === 0 ? "#fafafa" : undefined,
      }}
    >
      {columns
        .filter(({ sortable }) => sortable)
        .map(({ id }) => (
          <TableCell
            key={id}
            style={{
              padding: 10,
              color: !!row.is_obselete ? "#aaa" : row[`${id}_color`],
              textTransform: row[`${id}_textTransform`],
              border,
            }}
          >
            {row[id]}
          </TableCell>
        ))}
      <TableCell style={{ padding: 10, border }}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          style={{ minWidth: "unset", padding: 6 }}
          disabled={!!row.is_obselete}
          onClick={() => setUserInfo(row)}
        >
          <SearchIcon />
        </Button>
      </TableCell>
    </TableRow>
  );
};

export const UserTable = ({ setUserInfo, group, isFetched, userList = [], pageResetKey }) => {
  const [list, setList] = useState([]);
  const { groupList, roleList, setRoleList } = useContext(Context);

  useEffect(async () => {
    if (roleList.length === 0) {
      const roles = (await getRoleString()).data.map((n) => n.name);
      setRoleList(roles);
    }
  }, []);

  useEffect(() => {
    if (groupList?.length > 0 && roleList.length != 0) {
      setList(
        userList?.map((item, i) => {
          //IF GROUP TO FILTER IS DEFINE AND NOT EQUAL TO THIS GROUP
          if (!!group.length && !group.includes(0) && !group.includes(item?.id_group)) {
            return undefined
          }
          item.username = item.last_name + item.first_name;
          item.group = groupList.find((g) => g.id === item.id_group)?.name || I18n.t("NoTeam").toUpperCase();
          item.role = roleNames[item.id_role - 1].name;
          item.group_role_color = roleNames[item.id_role - 1]?.color;

          if (roleList[item.id_role - 1].name !== "ROLE_SALES") {
            item.group_role_textTransform = "uppercase";
          }
          //IF BIGBOSS NOT SHOW GROUP
          if (roleList[item.id_role - 1].name == "ROLE_BIGBOSS") {
            item.group_role = `${item.role}`
          }
          // SHOW REGION IF MANAGER
          else if (roleList[item.id_role - 1].name == "ROLE_MANAGER") {
            item.group_role = `${item.role}`
          }
          else {
            item.group_role = `${item.group} ${item.role}`
          }
          return item;
        }).filter(n => n !== undefined)
      );
      //});
    }
  }, [roleList, groupList, userList, group]);

  return (
    <Table pageResetKey={pageResetKey} data={list} columns={columns} isFetched={isFetched}>
      <Row setUserInfo={setUserInfo} />
    </Table>
  );
};
