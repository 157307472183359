/** @jsx h */
import { Fragment, h } from "preact";
import React from 'react';
import { useState, useEffect } from "preact/hooks";
import I18n from "i18n-js";
import moment from "moment";

import Button from "components/atoms/cubeButton/CubeBtn";
import { Card } from "components/atoms/card/Card";
import ByUser from "components/molecules/byUser/ByUser";
import ClientBranchInfo from "components/molecules/clientBranchInfo/ClientBranchInfo";
import OverallData from "components/molecules/overallData/OverallData";
import Modal from "components/atoms/modal/Modal";
import ReportNote from "components/organisms/modalContent/reportNote/ReportNote";
import ResponsiveImage from "components/atoms/responsiveImage/ResponsiveImage";
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos'
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { getCompetitor, getReport } from "api";
import { useUserImage, useRole } from "hooks";
import { route } from "preact-router";


const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(4),
		width: "100%",
		color: "rgba(0, 0, 0, 0.54)",

		"& .container": {
			marginTop: theme.spacing(0.5),
		},
	},

	leftGrid: {
		"& > div + div": {
			marginTop: theme.spacing(3),
		},

		"& .info": {
			"& > .center": {
				height: "64px",
				width: "64px",
				borderRadius: "50%",
				overflow: "hidden",
				color: "white",
				backgroundColor: "#ccc",
				marginRight: "1.1vw",

				"& > svg": {
					fontSize: 35,
				},
			},

			"& > .column": {
				"& > span": {
					fontSize: theme.spacing(2),
					color: theme.palette.text.primary,

					"& + span": {
						color: theme.palette.text.secondary,
					},
				},
			},
		},

		"& .nextVisit > span": {
			fontSize: theme.spacing(3),
			color: "#2E65C9",
			textAlign: "center",
		},
	},
	rateContent: {
		textAlign: "center",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",

		"& .MuiRating-root": {
			fontSize: "2.5rem",
		},

		"& hr": {
			margin: theme.spacing(2),
			alignSelf: "stretch",
		},

		"& .rateLabel": {
			color: theme.palette.text.disabled,
			fontWeight: "bold",
			fontSize: 18,
			padding: theme.spacing(2),
		},
	},
	deal: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
	},
	dealContent: {
		textAlign: "center",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		padding: theme.spacing(3),
	},
	dealCircle: {
		border: "8px solid",
		borderRadius: "50%",
		display: "inline-block",
		marginRight: theme.spacing(2),
	},
	dealSuccess: {
		fontSize: 20,
		color: theme.palette.success.main,
	},
	dealFailed: {
		fontSize: 20,
		color: theme.palette.error.main,
	},
}));

const ToolBar = ({ title, back }) => {
	return (
		<div className="d-flex jc-space-btw">
			<div className="d-flex al-center">
				<Button iconType="back" onClick={back} />
				<div style={{ width: "24px" }} />
				<Typography variant="h5" color="inherit">
					{title}
				</Typography>
			</div>
		</div>
	);
};

const LatestReportsDetails = ({ data: reportDetails, imageData, urlParams, back }) => {
	const locale = localStorage.getItem("locale");
	const classes = useStyles();
	const { roleId } = useRole()
	const { getUserImage } = useUserImage()
	const [data, setData] = useState(reportDetails);
	const [clientBranch, setClientBranch] = useState(null);
	const [showImage, setShowImage] = useState(false);
	const [salesmanData, setSalesmanData] = useState({});
	const [competitorNameList, setCompetitorNameList] = useState([])
    const [clientData, setClientData] = useState({});
    const [overallData, setOverallData] = useState([]);

    useEffect(() => {
        loadData();
    },[])

    const getOtherData = async (data) => {
        if (data.id_erp_competitor?.length) {
            let competitorNameList = await Promise.all(
                data.id_erp_competitor.map(async (id) => {
                    const competitorName = (await getCompetitor({ id })).data
                    return competitorName?.[0]?.name
            }))
						competitorNameList = competitorNameList.filter((item) => !!item)
            setCompetitorNameList(competitorNameList);
        }

        let userImageUrl = await getUserImage(data.id_user)
        setSalesmanData({
            name: data.salesman || "[name]",
            team: data.group || "Not joined group",
            imgUrl: userImageUrl,
        })
    }

    const loadData = async () => {
        if (data === undefined) {
            if (urlParams.id !== undefined) {
                const reportData = (await getReport({ id : urlParams.id }, roleId)).data[0];
                const branchData = reportData.client_branch;
                const clientData = reportData.client_branch?.client;
                const userData = reportData.user;

                const reportDetail = {
                    ...reportData,
                    client: clientData?.name,
                    branch: branchData?.name,
                    address: branchData?.address,
                    salesman: `${userData.last_name}${userData.first_name}`,
                    first_industry_level: locale === 'en' ? branchData?.info_first_industry_level?.industry_name_eng : branchData?.info_first_industry_level?.industry_name_ch,
                    second_industry_level: locale === 'en' ? branchData?.info_second_industry_level?.industry_name_eng : branchData?.info_second_industry_level?.industry_name_ch,
                    product_manufactured: branchData?.product_manufactured,
                    date: moment(reportData.updated_at).local().format("YYYY-MM-DD, HH:mm"),
                    rate: reportData.star,
                    deal: !!reportData.id_quotation,
                    group: userData.group?.name,
                };
                setClientBranch(branchData);
                setData(reportDetail);
                getOtherData(reportDetail);
            }
        } else {
            setClientBranch(data.getClientBranch());
            getOtherData(data);
        }
    }

    useEffect(() => {
        if (data && clientBranch) {
			const branchData = clientBranch.length ? clientBranch[0] : clientBranch;
            setClientData({
                group: data.client,
                branch: data.branch,
                address: branchData.address,
                name_of_salesman: branchData.name_of_salesman,
                first_industry_level: locale === 'en' ? branchData.info_first_industry_level?.industry_name_eng : branchData.info_first_industry_level?.industry_name_ch,
                second_industry_level: locale === 'en' ? branchData.info_second_industry_level?.industry_name_eng : branchData.info_second_industry_level?.industry_name_ch,
                product_manufactured: branchData.product_manufactured,
            });

            setOverallData([
                {
                    label: "dateOfCreation",
                    value: data.date,
                },
                {
                    label: "reportType",
                    value: `${I18n.t(`meeting_type_${data.meeting_type}`)}-${I18n.t(`client_type_${data.client_type}`)}`,
                },
                {
                    label: "rate",
                    value: data.rate,
                    isRating: true,
                },
                {
                    label: "competitors",
                    value: competitorNameList,
                },
                
                {
                    label: "othersCompetitors",
                    value: data.additional_competitor?.filter((item) => !!item) ?? [],
                },
                {
                    key: 'note',
                    label: I18n.t('image'),
                    custom: (d = data) => (
                        <>
                            <label>{I18n.t('image')}</label>
                            {imageData && <CameraAltIcon style={{ color: "#2E65C9", cursor: 'pointer' }} onClick={() => setShowImage(true)} />}  
                        </>
                    )
               },
            ]);
        }
    }, [data, clientBranch, competitorNameList]);

	const noteData = {
		salesman: salesmanData.name,
		team: salesmanData.team,
		note: data?.note,
	};

	return (
		<div className={classes.root}>
			<ToolBar
				title={data?.report_number}
				back={back}
				doEdit={() => console.log("edit")}
				doDelete={() => console.log("delete")}
			/>
			<Grid container spacing={3} className="container">
				<Grid item xs={12} sm={3} className={classes.letfGrid}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<ByUser name={salesmanData.name} team={salesmanData.team} photo={salesmanData.imgUrl} />
						</Grid>
						<Grid item xs={12}>
							<Card noTitle>
								<div className={classes.dealContent}>
									{data?.deal ? (
										<div className={classes.deal}>
											<span className={classes.dealSuccess}>
												<span className={classes.dealCircle} />
												{I18n.t("deal")}
											</span>
											<ArrowForwardIcon
												style={{ cursor: 'pointer', fontSize: 18 }}
												onClick={() => { route(`/page/latestReport/quotation?qid=${data?.id_quotation}&bid=${data?.id_client_branch}`) }}
											/>
										</div>
									) : (
										<span className={classes.dealFailed}>
											<span className={classes.dealCircle} />
											{I18n.t("noDeal")}
										</span>
									)}
								</div>
							</Card>
						</Grid>
						<Grid item xs={12}>
							<Card title={I18n.t("seeNote")}>
								<ReportNote data={noteData} />
							</Card>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} sm={9}>
					<Card title={"details"}>
						<ClientBranchInfo data={clientData} isFetched />
						<OverallData
							data={overallData}
							title={"overall"}
							note={data?.note}
						/>
					</Card>
				</Grid>
			</Grid>
			<Modal
				title={["Edit", "Report"]}
				open={false}
				close={() => { }}
				style={{ paddingBottom: "64px" }}
			>
				123
			</Modal>
			<Modal
				title={[""]}
				open={showImage}
				close={() => setShowImage(false)}
				style={{ 
				padding: "10px", paddingRight: "10px", paddingBottom: "10px" }}
				dialog
			>
			{imageData ?
				<ResponsiveImage src={imageData.url} /> : null }
			</Modal>
		</div>
	);
};
export default LatestReportsDetails;
