import 'rsuite/dist/rsuite.min.css';
import './style/index.css';
import './style/style.js';
import 'moment/dist/locale/zh-tw';

import App from './components/app';
import ThemeCustomProvider from "provider/ThemeProvider";
import ContextProvider from "provider/ContextProvider";

const Root = ()=>{
  return (
    <ThemeCustomProvider>
      <ContextProvider>
        <App />
      </ContextProvider>
    </ThemeCustomProvider>
  )
}

export default Root;
