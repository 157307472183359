/** @jsx h */
import { h } from "preact";
import I18n from "i18n/i18n";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';

import { Row } from 'components/atoms/row/Row';
import { Column } from 'components/atoms/column/Column';

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.spacing(1),
    backgroundColor: '#FAFAFA',
    border: '1px solid #E0E0E0',
    padding: theme.spacing(4, 6),

    '& > div + div': {
      marginTop: theme.spacing(2)
    }
  },
  colorDisabled: {
    color: theme.palette.text.disabled,
    marginRight: theme.spacing(1)
  },
}));

const MachineOptionsModal = ({ open, close, data }) => {
  const classes = useStyles();

  const total = data?.reduce((prev, machine) => prev + (machine.value * machine.quantity) + machine.optionsPrice, 0);

  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="md">
      <DialogTitle style={{ color: "rgba(0, 0, 0, 0.58)" }}>
        <div className={`jc-space-btw al-center`}>
          <Typography variant="h6" color="textSecondary">
            {I18n.t("optionsFor", { name: data && data[0].label })}
          </Typography>
          <IconButton
            onClick={() => close()}
            style={{ color: "rgba(0,0,0,.54)", margin: -12, cursor: "pointer" }}
          >
            <CloseIcon style={{ fontSize: "32px" }} />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <Column className={classes.root}>
          {
            data?.map((row) =>
              <>
                <Row justifyContent='space-between'>
                  <Typography variant='h6' className={classes.colorDisabled}>
                    {row.label}
                    <Typography display="inline" variant='h6' color='textPrimary'> x{row.quantity}</Typography>
                  </Typography>
                  <Typography variant='h6' color='textPrimary'>$ {row.value * row.quantity}</Typography>
                </Row>
                {
                  row.options.map(r =>
                    <Row justifyContent='space-between' style={{ marginTop: 5 }}>
                      <Typography variant='body2' className={classes.colorDisabled}>
                        {r.label}
                        <Typography display="inline" variant='body2' color='textPrimary'> {r.description} </Typography>
                      </Typography>
                      <Typography variant='body2' color='textPrimary'>$ {r.value}</Typography>
                    </Row>
                  )
                }
              </>
            )
          }
          <Row justifyContent='space-between' style={{ borderTop: '1px dashed #ABC1E9', paddingTop: 16 }}>
            <Typography variant='h5' className={classes.colorDisabled}>{I18n.t('total')}</Typography>
            <Typography variant='h5' color='textPrimary'>$ {total}</Typography>
          </Row>
        </Column>
      </DialogContent>
    </Dialog>
  );
};
export default MachineOptionsModal;