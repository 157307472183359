import { h } from "preact";
import I18n from "i18n-js";

import { Card } from "components/atoms/card/Card";
import { makeStyles } from "@material-ui/core/styles";
import { Jdenticon } from "components/atoms/Jdenticon"; 
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
	info: {
		paddingBottom: theme.spacing(2),

		"& > .center": {
			height: "64px",
			width: "64px",
			borderRadius: "50%",
			overflow: "hidden",
			color: "white",
			// backgroundColor: "#ccc",
			marginRight: "1.1vw",
		},

		"& > .column": {
			"& > span": {
				fontSize: theme.spacing(2),
				color: theme.palette.text.primary,

				"& + span": {
					fontSize: 14,
					color: theme.palette.text.secondary,
				},
			},
		},
	},
	avatar: {
		marginRight: theme.spacing(2),
	},
	box:{
		height: '40px!important',
		width: '40px!important',
		borderRadius: '50%',
		overflow: 'hidden',
		marginRight: '1.1vw',
		background: 'rgba(0,0,0,.05)',
	},
}));

const ByUser = ({ name, team, photo }) => {
	const classes = useStyles();

	return (
		<Card wrapStyle={{ height: '100%' }} title={I18n.t("byUser")}>
			<div className={"al-center jc-center " + classes.info}>
				{
					photo ?
						<Avatar src={photo} style={{ width: 40, height: 40, marginRight: '1.1vw' }} />
						:
						<div className={`center ${classes.box}`}>
							<Jdenticon size="38" value={name} />
						</div>
				}
				<div className="column">
					<span>{name}</span>
					<span>{team}</span>
				</div>
			</div>
		</Card>
	);
};

export default ByUser;
