import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
	row: {
		display: "flex",
		justifyContent: "space-around",
		width: "100%",
		paddingTop: "16px",
		paddingBottom: "16px",

		"&.byRow": {
			flexDirection: "column",
			padding: "16px",
			justifyContent: "center",

			"& > div": {
				flexDirection: "row",
				justifyContent: "space-between",
				padding: 0,

				"& label": {
					paddingBottom: 0,
				},

				"& + div": {
					borderLeft: "none",
					paddingTop: "16px",
				},
			},
		},
	},
	column: {
		display: "flex",
		flexDirection: "column",
		paddingLeft: "16px",
		paddingRight: "16px",
		justifyContent: "space-between",

		"& + div": {
			borderLeft: "1px solid #E0E0E0",
		},

		"& label": {
			paddingBottom: "16px",
			//fontWeight: "bold",
			fontSize: "1rem",
		},

		"& label + span": {
			wordBreak: "break-word",
			//fontWeight: "bold",
			fontSize: "1rem",
		},
	},
	label: {
		borderRadius: 5,
		background: "rgb(247, 247, 247)",
		padding: "5px",
		color: "black",
		marginLeft: 5,
		border: "solid 1px",
		borderColor: "rgb(219, 219, 219)"
	},
	others: {
		backgroundColor: '#e0e0e0!important', 
		fontSize: '13px!important', 
		padding: '2px!important',
	},
	othersLabel: {
		// padding: "5px",
		color: "black",
		marginLeft: 10,
	},
	title: {
		color: 'rgba(0, 0, 0, 0.38)'
	},
	value: {
		color: "rgba(0, 0, 0, 0.87)"
	},
	rating: {
		fontSize: '25px'
	}
});

export default useStyles;
