import { useEffect, useContext } from "preact/hooks"
import I18n from "i18n-js"
import { getCurrencies } from "../api"
import Context from "../context"

export const useCurrency = () => {
  const { currencies, setCurrencies } = useContext(Context)

  useEffect(() => {
    if (!currencies.length) {
      fetchCurrency()
    }
  }, [])

  const fetchCurrency = async () => {
    try {
      const { data } = await getCurrencies()
      setCurrencies(data)
    } catch (error) {
      console.log(error);
    }
  }

  const getCurrencyName = (currency) => {
    if (!currency) currency = 'NT'
    const data = currencies.find((item) => item.symbol === currency)
    const name = I18n.locale === 'en' ? data?.englishName : data?.chineseName
    return name ?? currency
  }

  return {
    currencies,
    fetchCurrency,
    getCurrencyName
  }
}