/** @jsx h */
import { h } from "preact";
import React from "react";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import { Row } from "components/atoms/row/Row";
import { LabelData } from "components/atoms/labelData/LabelData";
import { Gauge } from "components/atoms/gauge/Gauge";
import useStyles from "./DataCard.style";

export const DataCard = ({ type, data, title, content, width, action, byRow, className }) => {
	const classes = useStyles();

	if (!className) className = "";

	let dataContent = {
		label: (
			<>
				<Typography variant="h3" className={classes.content}>
					{content}
				</Typography>
				{data.length < 1 ? null : <LabelData data={data} byRow={byRow} isFetched={true} />}
			</>
		),
		gauge: <Gauge data={data} />,
		labelGauge: (
			<>
				<Gauge data={data.gauge} />
				{data?.label?.length && data.label.length < 1 ? null : <LabelData data={data.label} byRow={byRow} isFetched={true} />}
			</>
		),
	};

	return (
		<Paper variant="outlined" className={classes.card + " " + className} style={{ width: width }}>
			<Row justifyContent="space-between" alignItems="center">
				<Typography variant="h6" className={classes.title}>
					{title}
				</Typography>
				{action}
			</Row>
			{dataContent[type]}
		</Paper>
	);
};
