/** @jsx h */
import { h } from "preact";
import { makeStyles } from "@material-ui/core/styles";
import OutlinedInputBase from "@material-ui/core/OutlinedInput";

const useStyles = makeStyles(() => ({
  focused: {
    borderColor: "#2E65C9",
  },
}));

export const OutlinedInput = ({ classes, ...props }) => {
  const _classes = useStyles();
  return (
    <OutlinedInputBase
      {...props}
      classes={{ focused: _classes.focused, ...classes }}
    />
  );
};
