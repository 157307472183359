/** @jsx h */
import { h } from "preact";
import { useState, useEffect, useContext, useMemo, useCallback } from "preact/hooks";
import { route } from "preact-router";
import I18n from "i18n/i18n";
import moment from "moment";
import { DatePicker } from "rsuite";
import { enUS, zhTW } from "rsuite/esm/locales";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from '@material-ui/styles';

import { Row } from "components/atoms/row/Row";
import { MultiSelect } from "components/atoms/multiSelect/MultiSelect";
import CubeBtn from "components/atoms/cubeButton/CubeBtn";
import ExportExcelButton from "components/atoms/exportExcelButton/ExportExcelButton";
import { CardTable } from "components/molecules/cardTable/CardTable";
import { DeleteModal } from "components/molecules/deleteModal/DeleteModal";
import CardDetail from "../businessCard/CardDetail";

import { getBusinessCard, deleteBusinessCard, getImage, getLandList } from "api";
import { errHandler } from "../../util";
import { useStyles } from "style/style";
import Context from "../../context";

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
const COLUMNS = ['exhibition', 'customerName', 'company', 'email', 'phone', 'cell_phone', 'clientType', 
'continent', 'country', 'attribute', 'firstIndustryLevel', 'secondBusinessLevel', 'opportunity', 'visit_purpose', 
'fax', 'address', 'website',  'uploader', 'uploadDate', 'remark'];

const _useStyles = makeStyles((theme) => ({
    DatePicker: {
        '& .rs-picker-toggle': {
            backgroundColor: '#FFFFFF',
            padding: '9px 12px',
            paddingRight: 36,
            border: 'none',
            borderRadius: 8,
            fontSize: 15
        },
        '& .rs-picker-toggle-value': {
            color: '#0000008a !important',
        }
    }
}));

const BusinessCardList = () => {
    const locale = localStorage.getItem('locale');
    const classes = useStyles();
    const styles = _useStyles();
    const { setError, landList, setLandList } = useContext(Context);
    const [state, setState] = useState({
        refresh: false,
        date: null,
        exhibition: [0],
        inputFilter: '',
        cardList: [],
        modal: false,
        showDetail: false,
    });
    const [selectedCard, setSelectedCard] = useState(null);
    const [exhibitionList, setExhibitionList] = useState([]);

    useEffect(() => {
        loadData();
        if (!landList.length) {
            getLands();
        }
    }, [state.refresh, state.date]);

    const getLands = useCallback(async () => {
        const lands = (await getLandList()).data;
        setLandList(lands);
    }, [setLandList]);

    const loadData = async () => {
        try {
            let payload = { limit: -1 }
            if (!!state.date) {
                payload = {
                    ...payload,
                    from: moment(state.date).startOf('M').format(DATE_FORMAT),
                    to: moment(state.date).endOf('M').format(DATE_FORMAT),
                }
            }
            const cards = (await getBusinessCard(payload)).data;
            const cardList = await Promise.all(cards.map(async (card) => {
                const image = (await getImage('business_card', card.id)).data;
                return {
                    ...card,
                    company: typeof card.company === 'object' ? card.company : [card.company],
                    address: typeof card.address === 'object' ? card.address : [card.address],
                    uploadDate: moment(card.created_at).format(DATE_FORMAT),
                    clientType: card.is_new,
                    uploader: `${card.creator?.last_name || ''}${card.creator?.first_name || ''}`,
                    continent: card.id_land,
                    country: card.id_country,
                    ori_country: card.country,
                    attribute: card.type,
                    customAttribute: card.custom_type,
                    firstIndustryLevel: card.second_industry_level?.id_first_industry_level,
                    secondBusinessLevel: card.second_industry_level?.id,
                    region: card.creator?.group?.region?.name,
                    image: image?.url,
                    customerName: card.full_name
                }
            }));
            setState({ ...state, cardList: cardList.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) });
            // get exhibition list
            let exhibitions = [...new Set(cardList.map(item => item.exhibition))].filter(item => item);
            exhibitions.sort((a, b) => {
                if (a === '客訪') return -1;
                if (b === '客訪') return 1;
                return a - b;
            })
            setExhibitionList(exhibitions);
        } catch (err) {
            errHandler(err, setError, route);
        }
    }

    const removeCard = async (row) => {
        try {
            await deleteBusinessCard(row.id);
            setState({ ...state, refresh: !state.refresh, modal: false });
        } catch (err) {
            errHandler(err, setError, route);
        }
    }

    const onClick = async (type, row) => {
        await new Promise(resolve => {
            setSelectedCard(row);
            resolve();
        });
        if (type === 'detail') {
            setState({ ...state, showDetail: true });
        } else if (type === 'delete') {
            setState({ ...state, modal: true });
        }
    }

    const goBack = () => {
        setSelectedCard(null);
        setState({ ...state, showDetail: false });
    }

    const filtercardList = state.cardList.filter((row) => {
        return [
            !state.inputFilter 
                || row.customerName?.toUpperCase()?.includes(state.inputFilter.toUpperCase()) 
                || row.company?.join(' ')?.toUpperCase()?.includes(state.inputFilter.toUpperCase()),
            !state.exhibition.length 
                || state.exhibition.includes(0) 
                || state.exhibition.includes(row.exhibition),
        ].every(Boolean);
    });

    const exportData = useMemo(() => {
        const isEnglish = locale === 'en';
        const list = JSON.parse(JSON.stringify(filtercardList));
        return list.map((item) => {
                Object.keys(item).forEach(key => {
                        if (Array.isArray(item[key])) {
                                item[key] = item[key].join(',');
                        }
                });
                const continent = landList.find(land => land.LAND_ID == item.id_land);
                item.continent = isEnglish ? continent?.LAND_ENAME : continent?.LAND_NAME;
                item.country = isEnglish ? item.ori_country?.COUNTRY_ENAME : item.ori_country?.COUNTRY_NAME;
                item.clientType = item.is_new ? I18n.t('client_type_2') : I18n.t('client_type_1');
                item.firstIndustryLevel = isEnglish ? item.first_industry_level?.industry_name_eng : item.first_industry_level?.industry_name_ch;
                item.secondBusinessLevel = isEnglish ? item.second_industry_level?.industry_name_eng : item.second_industry_level?.industry_name_ch;
                return item;
        });
    }, [filtercardList, landList, locale]);

    return (
        <div className={classes.root}>
            {state.showDetail
                ? <CardDetail
                    data={selectedCard}
                    goBack={goBack}
                    reload={() => setState({ ...state, refresh: !state.refresh })}
                />
                : <>
                    <Row
                        justifyContent="space-between"
                        alignItems="center"
                        className={classes.marginBottom2}
                    >
                        <Typography variant="h5" color="inherit">
                            {I18n.t("CardList")}
                        </Typography>
                        <Row style={{ gap: 10 }} alignItems="center">
                            <DatePicker
                                format="yyyy-MM"
                                defaultValue={null}
                                value={state.date}
                                preventOverflow
                                size="lg"
                                className={styles.DatePicker}
                                locale={locale === 'en' ? enUS.DatePicker : zhTW.DatePicker}
                                onChange={(date) => setState({ ...state, date })}
                                onClean={() => setState({ ...state, date: null })}
                            />
                            <MultiSelect
                                data={exhibitionList.map(item => ({ label: item, value: item }))}
                                title="exhibition"
                                placeholder={I18n.t('exhibition')}
                                value={state.exhibition}
                                onChange={(value) => setState({ ...state, exhibition: value })}
                            />
                            <CubeBtn
                                iconType='search'
                                expandable
                                searchText={state.inputFilter}
                                placeholder={`${I18n.t("customerName")}, ${I18n.t("company")}, ${I18n.t("exhibition")}`}
                                onChange={(data) => setState({ ...state, inputFilter: data })}
                            />
                            <ExportExcelButton
                                sheetName={moment(state.date).format('YYYY-MM') + I18n.t("CardList")}
                                columns={COLUMNS.map((column) => ({ id: column, text: I18n.t(column) }))}
                                data={exportData}
                                fileType="xlsx"
                            />
                        </Row>
                    </Row>
                    <CardTable pageResetKey={state.inputFilter} data={filtercardList} onClick={onClick} />
                </>}
            <DeleteModal
                open={state.modal}
                handleClose={() => setState({ ...state, modal: false })}
                name={selectedCard?.customerName}
                submit={() => removeCard(selectedCard)}
            />
        </div>
    );
};

export default BusinessCardList;