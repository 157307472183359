import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	avatar: {
		background: 'rgba(0,0,0,.16)',
		borderRadius: '50%',
		overflow: 'hidden',
		width: '100px',
		height: '100px',
		marginRight: '32px'
	},

	inputLabel: {
		color: '#B0B0B0',
		fontWeight: 'bold'
	},

	list: {
		border: 'none',

		'& > div.column > div': {
			borderBottom: 'none!important',
			paddingLeft: 0,
			paddingRight: 0,

			'& span': {
				color: theme.palette.text.secondary,
				fontWeight: 400
			}
		},

		'&.deepList': {
			background: 'transparent',
			backgroundColor: 'transparent',

			'& > div.column > div': {
				background: 'transparent!important',
				backgroundColor: 'transparent!important'
			},
		}
	},

	innerForm: {
		backgroundColor: '#FAFAFA',
		padding: theme.spacing(2),
		borderRadius: theme.spacing(1),
		border: '1px solid #E0E0E0',
		marginTop: theme.spacing(2)
	},

	blueBtn: {
		fontWeight: 500,
		padding: '16px 0',
		background: '#2E65C9',
		color: 'white',
		borderRadius: '4px'
	},

	textBtn: {
		fontWeight: 500,
		letterSpacing: 0.3,
		fontSize: 14,

		'&.MuiButton-colorInherit:not(.Mui-disabled)': {
			color: theme.palette.text.disabled
		},
		
		'&.MuiButton-textPrimary:not(.Mui-disabled)': {
			color: '#2E65C9'
		}
	}
}))
export default useStyles;