/** @jsx h */
import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks'; 
import clsx from "clsx";
import useStyles from './BlueBtn.style';
import I18n from "i18n/i18n";

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const BlueBtn = ({ arrow, label, style, text, outline, onClick, width }) => {
    const classes = useStyles();
    return(
        <div className={`center ${classes.blueBtn} ${label == 'main' ? classes.main : classes.outline}`} style={style} onClick={onClick}>
            <div className="al-center">
                {I18n.t(text)}
                {arrow && <ArrowForwardIosIcon style={{fontSize: '16px',color:'#2E65C9', marginLeft: '4px'}} />}
            </div>
        </div>
    )
}
export default BlueBtn;
