import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paddingVertical2: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  padding1: {
    padding: theme.spacing(1),
  },
  padding2: {
    padding: theme.spacing(2),
  },
  padding3: {
    padding: theme.spacing(3),
  },
  paddingLeft1: {
    paddingLeft: theme.spacing(1),
  },
  paddingLeft2: {
    paddingLeft: theme.spacing(2),
  },
  marginTop2: {
    marginTop: theme.spacing(2),
  },
  marginBottom2: {
    marginBottom: theme.spacing(2),
  },
  marginBottom3: {
    marginBottom: theme.spacing(3),
  },
  marginBottom4: {
    marginBottom: theme.spacing(4),
  },
  marginRight1: {
    marginRight: theme.spacing(1),
  },
  marginBottom4: {
    marginBottom: theme.spacing(4),
  },
  marginRight2: {
    marginRight: theme.spacing(2),
  },
  marginLeft1: {
    marginLeft: theme.spacing(1),
  },
  marginLeft2: {
    marginLeft: theme.spacing(2),
  },
  marginVertical2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  border: {
    border: "1px solid #e0e0e0",
  },
  borderRight: {
    borderRight: "1px solid #e0e0e0",
  },
  borderLeft: {
    borderLeft: "1px solid #e0e0e0",
  },
  borderBottom: {
    borderBottom: "1px solid #e0e0e0",
  },
  root: {
    padding: theme.spacing(4),
    width: "100%",
  },
  icon: {
    color: "rgba(0, 0, 0, 0.54)",
    cursor: "pointer",
    marginRight: theme.spacing(1),
    "&:hover": {
      color: "rgba(0, 0, 0, 0.7)",
    },
    "&:last-child": {
      marginRight: 0,
    },
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  justifyContentSpaceBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  alignItemsCenter: {
    display: "flex",
    alignItems: "center",
  },
}));

export { useStyles };
