/** @jsx h */
import { h } from "preact";
import { useState, useEffect, useContext, useMemo } from "preact/hooks";
import I18n from "i18n-js";
import moment from "moment/min/moment-with-locales";
import { DateRangePicker } from "rsuite";
import { route } from "preact-router";
import { enUS, zhTW } from "rsuite/esm/locales";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import { Row } from "components/atoms/row/Row";
import CubeBtn from "components/atoms/cubeButton/CubeBtn";
import Modal from "components/atoms/modal/Modal";
import Select from "components/atoms/select/Select";
import { MultiSelect } from "components/atoms/multiSelect/MultiSelect";
import ExportExcelButton from "components/atoms/exportExcelButton/ExportExcelButton";
import OrderDetails from "components/molecules/orderDetails/OrderDetails";
import OrdersTable from "components/molecules/ordersTable/OrdersTable";
import SetYearObj from "components/organisms/modalContent/sales/SetYearObj";

import { getClientBranch, getClient, getQuotation, getUserList } from 'api';
import context from "context";
import { formatPrice, formatAddress } from "helper/format";
import { compareDate } from "../../util";
import { useRole } from "hooks";
import columns from 'components/molecules/ordersTable/columns.json'

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(4),
		width: "100%",
		color: "rgba(0, 0, 0, 0.54)",
	},
	marginBottom2: {
		marginBottom: theme.spacing(2),
	},
	link: {
		marginLeft: theme.spacing(2),
		fontWeight: 600
	},
	datePicker: {
		'& .rs-picker-toggle': {
			backgroundColor: '#FFFFFF',
			padding: '9px 12px',
			paddingRight: 36,
      border: 'none',
      borderRadius: 8,
      fontSize: 15
		},
    '& .rs-picker-toggle-value': {
      color: '#0000008a !important',
    }
	},
}));

const yearObj = {
	currency: 'twd',
	value: '5000',
	unit: 'tenthousand'
}

const DEFAULT_DATE_RANGE = {
	start: moment().subtract(7, 'd').toDate(),
	end: moment().toDate()
}

const OrderInfo = (props) => {
	const locale = localStorage.getItem('locale');
	const classes = useStyles();
	const { roleId } = useRole();
	// set context data
	const { setClientGroupList, setClientBranchList, setUserList, groupList, statusOptions, dealRateOptions } = useContext(context);
	// Order details state
	const [orderDetails, setOrderDetails] = useState(null);
	// Orders table states
	const [quotationData, setQuotationData] = useState([]);
	const [data, setData] = useState([]);
	const [filterData, setFilterData] = useState([]);
	const [searchText, setSearchText] = useState('');
	const [dateRange, setDateRange] = useState(DEFAULT_DATE_RANGE);
	const [status, setStatus] = useState(0);
    const [selectedGroup, setSelectedGroup] = useState([0]);
	const [selectedRate, setSelectedRate] = useState([0]);
	const [fetched, setFetched] = useState(false);

	// modal state
	const [modal, setModal] = useState(null);
	const urlParams = new URLSearchParams(location.search);

	useEffect(() => {
        if (!urlParams.has('isDetail')) {
            setOrderDetails(null);
        }
	}, [urlParams.has('isDetail')]);

	useEffect(() => {
		getClient().then(res => setClientGroupList(res.data));
		getClientBranch().then(res => setClientBranchList(res.data));
		getUserList({ avoid_brilltek_testing: true }).then(res => setUserList(res.data));
	}, []);

	// get quotation
	useEffect(() => {
		setFetched(false);
		let payload = {
			from: moment(dateRange.start).format("YYYY-MM-DD"),
			to: moment(dateRange.end).add(1, 'd').format("YYYY-MM-DD"),
			limit: -1,
		}
		if (status !== 0) {
			payload = { ...payload, is_finish: status === 2 ? false : true }
		}
		getQuotation(payload, roleId)
			.then((res) => setQuotationData(res.data))
			.catch((e) => console.log(e.response?.data))
			.finally(() => setFetched(true));
	}, [dateRange, status]);

	// loading data
	useEffect(() => {
		handleData();
	}, [quotationData]);

	const handleData = async () => {
		setFetched(false);
		let newData = structuredClone(quotationData);
		if (newData.length) {
			newData = quotationData.map(data => {
                const clientData = data.client_branch?.client;
                const branchData = data.client_branch;
                const salesmanData = data.user;
                const machinesRelateData = data.machines.map((m => {
                    return {
                        machine: m.machine_name,
                        price: formatPrice(m.negotiation_price),
												quantity: m.quantity,
                    }
                }));
								const salesmanInfo = !!salesmanData ? {
									salesman: `${salesmanData.last_name}${salesmanData.first_name}`,
									id_region: salesmanData.id_region,
									id_group: salesmanData.id_group,
								} : {}

								const branchInfo = !!branchData ? {
									branch: branchData.name ?? ' ',
									country: branchData.country ?? ' ',
									clientAddress: formatAddress(branchData) ?? '',
									name_of_salesman: branchData.name_of_salesman ?? '',
									first_industry_level: locale === 'en' ? branchData.info_first_industry_level?.industry_name_eng : branchData.info_first_industry_level?.industry_name_ch,
									second_industry_level: locale === 'en' ? branchData.info_second_industry_level?.industry_name_eng : branchData.info_second_industry_level?.industry_name_ch,
									product_manufactured: branchData.product_manufactured ?? '',
								} : {}

								const obj = {
                    ...data,
                    ...salesmanInfo,
										...branchInfo,
                    orderNum: data.id_quotation,
                    date: moment(data.created_at).format("YY/MM/DD, HH:mm"),
                    rate: data.rate || 'N/A',
                    cost: formatPrice(data.total_price) || ' ',
                    expect_buy_date: moment(data.expect_buy_date).format("YYYY/MM"),
                    client: `${clientData?.name ?? '-'}(${branchData?.name ?? '-'})` || 'clientDeleted',
                    children: machinesRelateData,
                    original: data,
                    group: !!clientData ? clientData?.name : ' ',
										status: data.is_finish === 0 ? I18n.t('notClosed') : I18n.t('closed'),
                }

                const correspondIndex =  quotationData.findIndex(object => {
                    return object.id === data.id
                });
                quotationData[correspondIndex] = obj;
                return obj;
            });
		}
		setData(newData);
		setFilterData(newData);
    setFetched(true);
	}

	const filteredData = useMemo(() => {
		let data = filterData;

		if (!selectedGroup.includes(0) && !!selectedGroup.length) {
            data = data.filter(item => selectedGroup.includes(item.id_group));
        }

		if (!selectedRate.includes(0) && !!selectedRate.length) {
			data = data.filter(item => selectedRate.includes(item.rate));
		}

		if (searchText !== '') {
			const searchKey = ['name_of_salesman', 'salesman', 'machine', 'client', 'orderNum'];
			data = data.filter((item) => {
				let isFound = false;
				searchKey.forEach((key) => {
					if (item[key] && item[key].toLowerCase().includes(searchText.toLowerCase())) {
						isFound = true;
					}
				});
				return isFound;
			});
    }

		return data;
  }, [filterData, selectedGroup, selectedRate, status, searchText]);

    const goBack = () => {
        route(location.pathname);
        const isParamsExist = localStorage.getItem('quotation_detail')
        const params = !!isParamsExist ? JSON.parse(isParamsExist) : {}

				if (!!isParamsExist) {
						if (params.searchText !== searchText) {
							setSearchText(params?.searchText ?? '')
						}
            if (!compareDate(params?.dateRange?.start, dateRange.start) || !compareDate(params?.dateRange?.end, dateRange.end)) {
							setDateRange({
								start: moment(params?.dateRange?.start).toDate() ?? DEFAULT_DATE_RANGE.start,
								end: moment(params?.dateRange?.end).toDate() ?? DEFAULT_DATE_RANGE.end
							})
						}
            setStatus(params?.status ?? null)
            setSelectedGroup(params?.group ?? null)
            setSelectedRate(params?.rate ?? null)
            localStorage.removeItem('quotation_detail')
        }
    }

    const handleOpenDetails = (orderDetails) => {
        const filterParams = {
            searchText,
            dateRange,
            status,
            group: selectedGroup,
            rate: selectedRate,
				}
        localStorage.setItem('quotation_detail', JSON.stringify(filterParams))
        setOrderDetails(orderDetails);
    }

    const getExportData = (data) => {
        if (!data?.length) return []
        let newData = structuredClone(data).reduce((acc, cur) => {
            let { children, ...rest } = cur;
            children = children.map((child) => ({ ...child, expect_buy_date: moment(child.expect_buy_date).format("YYYY/MM") }))
            return [...acc, rest, ...children];
        }, [])
        return newData
    }

	if (orderDetails) return <OrderDetails orderDetails={orderDetails} back={goBack} />;
	else if (props.qid && props.bid) return <OrderDetails urlParams={props} back={goBack} />;
	return (
		<div className={classes.root}>
			<Row
				justifyContent="space-between"
				alignItems="center"
				className={classes.marginBottom2}
			>
				<Row alignItems="center" >
					<Typography variant="h5" color="inherit">
						{I18n.t("Quotation")}
					</Typography>
				</Row>
				<Row style={{ columnGap: '1rem' }}>
                    <ExportExcelButton
                        sheetName={`${moment(dateRange.start).format('YYYY-MM-DD')}~${moment(dateRange.end).format('YYYY-MM-DD')} ${I18n.t("Quotation")}`}
                        columns={columns.map(col => ({ ...col, text: I18n.t(col.id) }))}
                        data={getExportData(filteredData)}
                        headerColor='EBF1DE'
                        fileType="xlsx"
                    />
					<DateRangePicker
						format="yyyy-MM-dd"
						defaultValue={[dateRange.start, dateRange.end]}
						size="lg"
						preventOverflow
						className={classes.datePicker}
						onChange={(e) => {
							if (e) {
								setDateRange({ start: e[0], end: e[1] });
							}else{
								setDateRange(DEFAULT_DATE_RANGE);
							}
						}}
						locale={localStorage.getItem('locale') === 'en' ? enUS.DateRangePicker : zhTW.DateRangePicker}
					/>
                    <MultiSelect
                        data={groupList}
                        title="team"
                        value={selectedGroup}
                        placeholder={I18n.t("team")}
                        labelKey="name"
                        valueKey="id"
                        onChange={setSelectedGroup}
                    />
                    <MultiSelect
                        data={dealRateOptions}
                        title="rate"
                        value={selectedRate}
                        placeholder={I18n.t("dealRate")}
                        labelKey="text"
                        valueKey="id"
                        onChange={setSelectedRate}
                    />
                    <Select
						width=''
						data={statusOptions}
						onClick={(data) => setStatus(data.id)}
					/>
					<CubeBtn
						iconType="search"
						bgColor="white"
						expandable
						searchText={searchText}
						placeholder={`${I18n.t("orderNum")}, ${I18n.t("salesman")}, ${I18n.t("client")}, ${I18n.t("machine")}`}
						onChange={(text) => {
							setSearchText(text)
						}}
						onClick={() => console.log("search")}
					/>
				</Row>
			</Row>

			<OrdersTable
				filteredData={filteredData}
				setOrderDetails={handleOpenDetails}
				fetched={fetched}
				pageResetKey={searchText}
			/>
			<Snackbar
				open={modal === "error"}
				autoHideDuration={3000}
				onClose={(e, r) => setModal(null)}
			>
				<MuiAlert
					severity="error"
					variant="filled"
					elevation={6}
				>
					{I18n.t("ErrSelectedTimeOverlap").toUpperCase()}
				</MuiAlert>
			</Snackbar>
			<Modal title={I18n.t('YearObjective')} open={modal === "SetYearObjective"} close={() => setModal(false)}>
				<SetYearObj data={yearObj} />
			</Modal>
		</div>
	);
};

export default OrderInfo;
