import { h } from "preact";
import { makeStyles } from "@material-ui/core/styles";
import ErrorPage  from "./errorPage";
import Icon from "../../assets/Frame.svg";
import I18n from "i18n-js";

const useStyles = makeStyles({
    message: {
        fontSize: "24px",
        color: "rgba(0,0,0,0.54)",
        height: "130px",
    },
    description: {
        fontSize: "20px"
    },
    bold: {
        fontWeight: 600,
    },
});

const Page500 = ({
    errorDescription,
}) => {
    const classes = useStyles();

    return (
        <ErrorPage
            statusCode="500"
            icon={Icon}
            back={() => console.log("back")}
        >
            <p className={classes.message}>
                {I18n.t("unexpectedError")}
            </p> 
            <p className={classes.description}>
                <span className={classes.bold}>
                    {I18n.t("unexpectedErrorDesc")}
                </span>
            </p>
        </ErrorPage>
    );   
}
export default Page500;