/** @jsx h */
import { h } from "preact";
import Match from "preact-router/match";
import { route } from "preact-router";

import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import { Header } from "components/molecules/header/Header";
import { Menu } from "components/molecules/menu/Menu";
import { SubMenu } from "components/molecules/subMenu/SubMenu";
import I18n from "i18n/i18n";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#EDF1F5",
    color: "rgba(0, 0, 0, 0.54)",
  },
  content: {
    height: "calc(100% - 64px)",
    width: "100%",
    overflow: "auto",
    marginTop: 64,
  },
  contentWrap: {
    flexGrow: 1,
    position: "relative",
    height: "100vh",
    width: "calc(100% - 70px)",
    paddingTop: 48,
  },
}));

export const Page = ({
  pages,
  languages,
  logout,
  children,
  handleViewAllClick,
}) => {
  const classes = useStyles();

  const handleClick = (path, val) => {
    const arr = path.split("/");
    arr[arr.length - 1] = val;
    route(arr.join("/"));
  };

  const getPage = (path) => {
    return pages.find(({ to, children }) => {
      return (
        path === to ||
        children?.findIndex(({ to }) => to === path) > -1
      );
    });
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header
        languages={languages}
        logout={logout}
        handleViewAllClick={handleViewAllClick}
      />
      <Menu pages={pages} />
      <main className={classes.contentWrap}>
        <Match>
          {({ path }) => (
            <SubMenu
              data={getPage(path)?.children || []}
              title={I18n.t(getPage(path)?.name)}
              onClick={(val) => handleClick(path, val)}
            />
          )}
        </Match>
        <div className={classes.content}>{children}</div>
      </main>
    </div>
  );
};
