/** @jsx h */
import React from "react";
import { h } from "preact";
import Rating from "@material-ui/lab/Rating";
import useStyles from "./LabelData.style";
import { Skeleton } from "@material-ui/lab";
import I18n from "i18n-js";

export const LabelData = ({ data, style, byRow, isFetched, skeletonColumns }) => {
  const classes = useStyles();

  return (
    <div className={`${classes.row} ${byRow ? "byRow" : ""}`} style={style}>
      {isFetched
        ? data.map((row, index) => {
          const needTranslation = (
            row.label === 'sources'
            || row.label === 'Nation'
            || row.label === 'dealIssue'
          );
        
          return (
            <div
              className={classes.column}
              style={!byRow && { width: 100 / data.length + "%" }}
              key={index}
            >
              {row.label === "rate" && row.isRating ? (
                <>
                  <label className={classes.title}>{I18n.t(row.label)}</label>
                  <Rating
                    disabled
                    name="read-only"
                    classes={{ icon: classes.rating }}
                    value={row.value}
                    size="large"
                  />
                </>
              ) : (row.label === "competitors" || row.label === "dealIssue") ? (
                <>
                  <label className={classes.title}>{I18n.t(row.label)}</label>
                  <div>
                  {
                    row.value?.map((item) => {
                      return (
                        <span className={`${classes.label} ${classes.value}`} style={row.valueStyle || {}}>
                          {needTranslation ? I18n.t(item) : item}
                        </span>
                      )
                    })
                  }</div>
                </>
              ) : (row.label === "others" && row.value?.length) ? (
                  <div style={{width: '100%', textAlign: "right"}}>
                    <label className={`${classes.others} ${classes.title}`}>{I18n.t(row.label)}</label>
                    {
                      row.value?.map((item) => {
                        return ( 
                          <span className={`${classes.othersLabel} ${classes.value}`} style={row.valueStyle || {}}>{item}</span>
                        ) 
                      })
                    }
                  </div>
              ) : row.custom ? (
                    row.custom()
                  ) : (
                    <>
                      <label className={classes.title}>{I18n.t(row.label)}</label>
                      <span className={classes.value} style={row.valueStyle || {}}>
                        {needTranslation ? I18n.t(row.value) : row.value}
                      </span>
                    </>
                  )
              }
            </div>
          );
        })
        : <div style={{ overflow: 'hidden' }}>
          {[...Array(skeletonColumns || 3).keys()].map(() => (
            <Skeleton variant="text" style={{ height: 35, paddingTop: 16 }} />
          ))}
        </div>
      }
    </div>
  );
};
