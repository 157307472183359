/** @jsx h */
import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import useStyles from './Card.style'
import I18n from "i18n/i18n";

export const Card = ({ contentStyle, children, expand, title, wrapStyle, noTitle, actionComponent }) => {
    const classes = useStyles();

    return (
        <div className={classes.wrap} style={wrapStyle}>
            {
                !noTitle &&
                <div className={'jc-space-btw al-center ' + classes.title}>
                    {title && <span>{I18n.t(title)}</span>}
                    <div className="d-flex" style={{ height: '40px' }}>
                        {expand && <FullscreenIcon onClick={expand} />}
                        {actionComponent || null}
                    </div>
                </div>
            }
            <div className={`column ${classes.body}`} style={contentStyle}>
                {children}
            </div>
        </div>
    )
}