/** @jsx h */
import { h } from "preact";
import { useEffect, useState, useContext } from "preact/hooks";
import { forwardRef } from "preact/compat";
import I18n from "i18n-js";

import Modal from "../../../atoms/modal/Modal";
import ItemPicker from "../../../atoms/itemPicker/itemPicker";
import { List } from "../../../molecules/list/List";
import { AddButton } from "../../../atoms/addButton/AddButton";
import { Row } from "../../../atoms/row/Row";

import { Form, SelectPicker, Input, DatePicker } from "rsuite";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "rsuite/dist/rsuite.min.css";

import { getClientBranch, getGroup } from "../../../../api";

import context from "context";
import { enUS, zhTW } from "rsuite/esm/locales";

const { ControlLabel, Group, Control } = Form;

const machineListData = [
    {
        name: "machine 1",
    },
    {
        name: "machine 2",
    },
    {
        name: "machine 3"
    }
];

const groupOptionsList = [
    { value: 0, label: "option" },
];

const useStyles = makeStyles((theme) => ({
    textBtn: {
        fontWeight: 500,
        letterSpacing: 0.3,
        fontSize: 14,
    },
    inputLabel: {
        color: "#B0B0B0",
        fontWeight: "bold",
    },
    innerForm: {
        backgroundColor: "#FAFAFA",
        padding: theme.spacing(2),
        border: "1px solid #E0E0E0",
        marginTop: theme.spacing(2),
        borderRadius: theme.spacing(1)
    },
    blueBtn: {
        fontWeight: 500,
        padding: "16px 0",
        color: "white",
        borderRadius: "4px"
    },
    list: {
        border: 'none',
        '& > div.column > div': {
            borderBottom: 'none!important',
            paddingLeft: 0,
            paddingRight: 0,

            '& span': {
                color: theme.palette.text.secondary,
                fontWeight: 400
            }
        },

        '&.deepList': {
            background: 'transparent',
            backgroundColor: 'transparent',

            '& > div.column > div': {
                background: 'transparent!important',
                backgroundColor: 'transparent!important'
            },
        }
    },
}))

const TextArea = forwardRef((props, ref) => <Input {...props} as="textarea" ref={ref} />);
const FormDatePicker = forwardRef((props, ref) => <DatePicker
    format="yyyy-MM-dd"
    defaultValue={new Date()}
    style={{
        width: "100%"
    }}
    ref={ref}
    {...props}
/>);

const EditModal = (props) => {
    const [ options, setOptions ] = useState({});

    const [list, setList] = useState(machineListData);
    
    const [innerForm, setInnerForm] = useState(false);
    const [formData, setFormData] = useState(null);

    const classes = useStyles();
    const { groupList, setGroupList, clientBranchList, setClientBranchList } = useContext(context);

    useEffect(async () => {
        let groups = (await getGroup({ avoid_brilltek_testing: true })).data;
        let branches = (await getClientBranch()).data;
        
        setGroupList(groups);
        setClientBranchList(branches);
    }, []);

    useEffect(() => {
        if(groupList) {
            setOptions({ groupList: groupList.map((n) => ({ value: n.id, label: n.name }))});
        }
    }, [ groupList ])

    // when user selects a group
    useEffect(() => {
        if(formData?.group) {
            const branches = clientBranchList.filter((n) => n.id_client === formData.group);
            setOptions({...options, ...{ branchList: branches.map((n) => ({ value: n.id, label: n.name }))}});
        }
    }, [formData?.group]);

    return (
        <Modal {
            ...props
        }>
            <div className="column al-center">
                <div className="w-60">
                    <Form
                        fluid
                        formValue={{}}
                        onChange={(val) => {
                            setFormData({ ...formData, ...val })
                        }}
                        style={{
                            paddingTop: "1rem"
                        }}
                    >
                        <Group>
                            <ControlLabel>
                                {I18n.t("GroupName")}:
                            </ControlLabel>
                            <Control
                                placeholder={I18n.t("SelectA", { name: I18n.t("Group") })}
                                name="group"
                                accepter={SelectPicker}
                                data={options.groupList || []}
                                style={{ width: "100%" }}
                                valueKey="value"
                                labelKey="label"
                                locale={localStorage.getItem('locale') === 'en' ? enUS.Picker : zhTW.Picker}
                            />
                        </Group>
                        <Group>
                            <ControlLabel>
                                {I18n.t("BranchName")}:
                            </ControlLabel>
                            <Control
                                placeholder={I18n.t("SelectA", { name: I18n.t("Branch") })}
                                name="branch"
                                accepter={SelectPicker}
                                data={options.branchList || []}
                                style={{ width: "100%" }}
                                valueKey="value"
                                labelKey="label"
                                locale={localStorage.getItem('locale') === 'en' ? enUS.Picker : zhTW.Picker}
                            />
                        </Group>
                        <Group>
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    paddingBottom: "1rem",
                                }}
                            >
                                <ControlLabel>
                                    {I18n.t("machines")}:
                                </ControlLabel>
                                <AddButton
                                    onClick={(e) => setInnerForm(true)}
                                    style={{
                                        visibility: innerForm ? "hidden" : "visible"
                                    }}
                                />
                            </div>
                            {
                                innerForm ?
                                    <div
                                        className={`column ${classes.innerForm}`}
                                    >
                                        <Group>
                                            <ControlLabel className={classes.inputLabel}>
                                                {I18n.t("machinetype")}:
                                            </ControlLabel>
                                            <Control
                                                placeholder={I18n.t("InsertA", { name: I18n.t("machinetype") })}
                                                name="machineType"
                                                accepter={SelectPicker}
                                                data={groupOptionsList}
                                                style={{ width: "100%" }}
                                                valueKey="value"
                                                labelKey="label"
                                                locale={localStorage.getItem('locale') === 'en' ? enUS.Picker : zhTW.Picker}
                                            />
                                        </Group>
                                        <Group>
                                            <ControlLabel className={classes.inputLabel}>
                                                {I18n.t("machineModel")}:
                                            </ControlLabel>
                                            <Control
                                                placeholder={I18n.t("SelectA", { name: I18n.t("machineModel") })}
                                                name="machineModel"
                                                accepter={SelectPicker}
                                                data={groupOptionsList}
                                                style={{ width: "100%" }}
                                                valueKey="value"
                                                labelKey="label"
                                                locale={localStorage.getItem('locale') === 'en' ? enUS.Picker : zhTW.Picker}
                                            />
                                        </Group>
                                        <Group>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    columnGap: "1rem"
                                                }}
                                            >
                                                <Group
                                                    style={{
                                                        margin: 0
                                                    }}
                                                >
                                                    <ControlLabel className={classes.inputLabel}>
                                                        {I18n.t("quantity")}:
                                                    </ControlLabel>
                                                    <Control placeholder="5" name="machineQuantity" />
                                                </Group>
                                                <Group
                                                    style={{
                                                        flexGrow: 2
                                                    }}
                                                >
                                                    <ControlLabel className={classes.inputLabel}>
                                                        {I18n.t("totalPrice")}:
                                                    </ControlLabel>
                                                    <Control placeholder="$4.5" name="machine" />
                                                </Group>
                                            </div>
                                        </Group>
                                        <Group>
                                            <ControlLabel className={classes.inputLabel}>
                                                {I18n.t("expectedBuyingDate")}
                                            </ControlLabel>
                                            <Control
                                                name="machineExpectedBuyingDate"
                                                accepter={FormDatePicker}
                                                style={{ width: "100%" }}
                                                locale={localStorage.getItem('locale') === 'en' ? enUS.DatePicker : zhTW.DatePicker}
                                            />
                                        </Group>
                                        <Group>
                                            <ControlLabel className={classes.inputLabel}>
                                                {I18n.t("paymentMethod")}:
                                            </ControlLabel>
                                            <Control
                                                placeholder={I18n.t("others")}
                                                name="machinePayment"
                                                accepter={SelectPicker}
                                                data={groupOptionsList}
                                                style={{ width: "100%" }}
                                                valueKey="value"
                                                labelKey="label"
                                                locale={localStorage.getItem('locale') === 'en' ? enUS.Picker : zhTW.Picker}
                                            />
                                        </Group>
                                        <Group>
                                            <ControlLabel className={classes.inputLabel}>
                                                {I18n.t("priceCondition")}:
                                            </ControlLabel>
                                            <Control
                                                placeholder={I18n.t("thirtyPercentDeposit")}
                                                name="machinePriceConditions"
                                                accepter={SelectPicker}
                                                data={groupOptionsList}
                                                style={{ width: "100%" }}
                                                valueKey="value"
                                                labelKey="label"
                                                locale={localStorage.getItem('locale') === 'en' ? enUS.Picker : zhTW.Picker}
                                            />
                                        </Group>
                                        <Group>
                                            <ControlLabel className={classes.inputLabel}>
                                                {I18n.t("notesOptional")}:
                                            </ControlLabel>
                                            <Control
                                                placeholder={I18n.t("inputYourNote")}
                                                name="machineNote"
                                                accepter={TextArea}
                                                style={{ width: "100%" }}
                                            />
                                        </Group>
                                        <Group>
                                            <ControlLabel className={classes.inputLabel}>
                                                {I18n.t("rate")}:
                                            </ControlLabel>
                                            <Row
                                                justifyContent="space-between"
                                                alignItems="center"
                                                style={{
                                                    width: "25%",
                                                    color: "#2e65c9"
                                                }}
                                            >
                                                <ItemPicker setState={(index) => setFormData({ ...formData, ...{ index } })}>
                                                    {
                                                        [
                                                            "A+", "A", "B", "C"
                                                        ].map((rate) =>
                                                            <div style={{ fontSize: "50px", color: "#2e65c9" }}>{rate}</div>
                                                        )
                                                    }
                                                </ItemPicker>
                                            </Row>
                                        </Group>
                                        <Group>
                                            <Row
                                                justifyContent="flex-end"
                                                alignItems="center"
                                                style={{
                                                    columnGap: "1rem",
                                                }}
                                            >
                                                <Button
                                                    color="inherit"
                                                    onClick={() => setInnerForm(false)}
                                                    classes={classes.textBtn}
                                                >
                                                    {I18n.t("Cancel")}
                                                </Button>
                                                <Button
                                                    color="primary"
                                                    classes={classes.textBtn}
                                                    onClick={() => {
                                                        if (formData) {
                                                            setList([...list, { name: `machine ${list.length + 1}` }]);
                                                            setInnerForm(false);
                                                            setFormData(null);
                                                        } else {
                                                            alert("placeholder warning");
                                                        }
                                                    }}
                                                >
                                                    {I18n.t("AddA", { name: I18n.t("machine") })}
                                                </Button>
                                            </Row>
                                        </Group>
                                    </div>
                                    :
                                    null
                            }
                        </Group>
                        <List
                            isFetched={true}
                            className={classes.list}
                            data={list}
                            noAvatar
                            title=""
                            width="100%"
                            editIcon={!innerForm}
                            deleteIcon={!innerForm}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={innerForm}
                            onClick={() => props.close()}
                            className={classes.blueBtn}
                            fullWidth
                        >{I18n.t("saveChanges")}</Button>
                    </Form>
                </div>
            </div>
        </Modal >
    )
}

export default EditModal;