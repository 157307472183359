import { h } from "preact";
import { useState, useEffect, useContext } from "preact/hooks";
import I18n from "i18n-js";
import Context from "context";

import emptyIcon from "assets/empty.svg";

import { Skeleton } from "@material-ui/lab";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import { makeStyles } from "@material-ui/core/styles";

import { Jdenticon } from "components/atoms/Jdenticon"; 
import { Row } from "components/atoms/row/Row";
import { zeroArray } from "../../../util";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  flag: {
    width: 30,
    marginRight: 10,
  },
  icon: {
    marginRight: 5
  },
  label: {
    display: 'block',
    marginBottom: theme.spacing(2)
  },
  listItem: {
    height: 56,
    backgroundColor: '#F5F5F5',
    borderRadius: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    cursor: 'pointer',

    '& > .status': {
      height: '100%',
      width: 10,
      borderTopRightRadius: theme.spacing(1),
      borderBottomRightRadius: theme.spacing(1),
    },

    '& > div': {
      width: '33.33%'
    }
  },
}));

const AbnormalList = ({ selectedTeam, scheduleList, salesmanList, onClick }) => {
  const classes = useStyles();
  const [data, setData] = useState(null);
  const {
    groupList
  } = useContext(Context);

  const handleOnClick = (item) => {
    // use onClick from Punch.js as callback, trigger setSelectedData / setSelectedDataIndex there
    if (selectedTeam) {
      // if selectedTeam exist, onClick need to show salesman(user) punch timeline
      const index = salesmanList.findIndex(r => r.id === item.id);
      onClick({
        salesman: salesmanList[index],
      }, {
        salesman: index,
      }, true)
    } else {
      // else onClick means select that team
      const index = groupList.findIndex(r => r.id === item.id);
      onClick({
        team: groupList[index].name,
        salesman: "",
      }, {
        team: index,
        salesman: null,
      })
    }
  }

  useEffect(() => {
    if (scheduleList && groupList) {
      if (selectedTeam) {
        // if selectedTeam exist, show abnormal salesman(user)
        let temp = scheduleList.filter((s) => !s.is_punch)
          .reduce((acc, row) => {
            // get salesman detail by id
            const salesman = salesmanList.find(s => s.id === row.id_user);
            // check for concurrent of salesman (exist++, else new one)
            let obj = acc.find((obj) => obj.id === row.id_user);
            if (obj) obj.count++;
            else if (salesman) {
              acc.push({
                id: salesman.id,
                name: salesman.last_name + salesman.first_name,
                count: 1,
                // type: 0 = 'nopunch', 1 = late (no idea how to determine 'late' so currently all is 'nopunch')
                type: 0,
                color: '#FF9800',
              });
            }
            return acc;
          }, []);
        setData(temp);
      } else {
        //  else show abnormal team
        let temp = scheduleList.filter((s) => !s.is_punch)
          .reduce((acc, row) => {
            // get group detail by id
            const group = groupList?.find(g => g.id === row.id_group);
            // check for concurrent of group (exist++, else new one)
            let obj = acc.find((obj) => obj.id === row.id_group);
            if (obj) obj.count++;
            else {
              acc.push({
                id: group?.id,
                region_name: group?.sub,
                name: group?.name,
                count: 1,
                color: '#E31B0C'
              });
            }
            return acc;
          }, []);
        setData(temp);
      }
    }
  }, [selectedTeam, scheduleList, groupList]);

  return (
    <div className={classes.root}>
      {selectedTeam &&
        <span className={classes.label}>
          {I18n.t("Team")} : {selectedTeam.name}
        </span>
      }
      <span className={classes.label}>{I18n.t("abnormalBehavior")} :</span>
      <div>
        {
          // loading
          !data || !scheduleList ?
            zeroArray(7).map((_) => (
              <AbnormalItem type='skeleton' />
            ))
            :
            // no data
            data.length === 0 ?
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 500
                }}
              >
                <img src={emptyIcon} style={{ width: 250, height: 250 }} />
              </div>
              :
              // normal view
              data.map((row, index) => {
                return <AbnormalItem data={row} key={index} type={selectedTeam && 'employee'} onClick={handleOnClick} />
              })
        }
      </div>
    </div>
  );
};

const AbnormalItem = ({ data = {}, type = 'default', onClick }) => {
  const classes = useStyles();

  const abnormalType = {
    default:
      <Row justifyContent='space-between' alignItems='center' className={classes.listItem} onClick={() => onClick(data)}>
        <Row justifyContent='flex-start' alignItems='center'>
          <span>{data.name}</span>
        </Row>
        <Row justifyContent='flex-start' alignItems='center'>
          <PersonOutlineIcon className={classes.icon} />
          <span>{data.count}</span>
        </Row>
        <div className="status" style={{ backgroundColor: data.color }}></div>
      </Row>,
    employee:
      <Row justifyContent='space-between' alignItems='center' className={classes.listItem} onClick={() => onClick(data)}>
        <Row justifyContent='flex-start' alignItems='center'>
          <Jdenticon size="40" value={data && data.name} />
          <span style={{ marginLeft: 10 }}>{data.name}</span>
        </Row>
        <Row justifyContent='flex-start' alignItems='center' style={{ color: data.color }}>
          <span>{data.type === 0 ? I18n.t(`noPunch`).toUpperCase() : I18n.t(`late`).toUpperCase()}</span>
        </Row>
        <div className="status" style={{ backgroundColor: data.color }}></div>
      </Row>,
    skeleton:
      <Row justifyContent='space-between' alignItems='center'
        className={classes.listItem}
        style={{ backgroundColor: 'transparent', cursor: 'initial' }}>
        <Row justifyContent='flex-start' alignItems='center'>
          <Skeleton
            variant="circle"
            style={{
              width: "40px",
              height: "40px",
            }}
          />
          <Skeleton variant="text" style={{ width: "10rem", marginLeft: 10 }} />
        </Row>
        <Row justifyContent='flex-start' alignItems='center'>
          <Skeleton variant="text" style={{ width: "5rem" }} />
        </Row>
        <Skeleton className="status"></Skeleton>
      </Row>
  }

  return abnormalType[type];
};

export default AbnormalList;
