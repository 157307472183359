/** @jsx h */
import { h } from 'preact';
import { useState, useEffect } from "preact/hooks";
import useStyles from './ReportNote.style';
import { Column } from "components/atoms/column/Column";
import { Row } from "components/atoms/row/Row";
import I18n from "i18n-js";

const ReportNote = ({ data }) => {
    const classes = useStyles()
    
    return(
        <Column>
            <Row className={classes.info}>
                <div className={classes.infoItem}>
                    {I18n.t("Team")}: <span>{data?.team}</span>
                </div>
                <div className={classes.infoItem}>
                    {I18n.t("Salesman")}: <span>{data?.salesman}</span>
                </div>
            </Row>
            <div className={classes.note}>
                <div class="content">
                    {/* <div class="left">()</div> */}
                    <div class="message">{data?.note}</div>
                    {/* <div class="right">()</div> */}
                </div>
            </div>
        </Column>
    )
}
export default ReportNote;