import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    listItemWrap: {
        padding: '14.5px 16px',
        // borderBottom: '1px solid #E0E0E0',
    },
    box: (props) => ({
        height: '40px',
        width: '40px',
        borderRadius: '50%',
        overflow: 'hidden',
        marginRight: '1.1vw',
        background: 'rgba(0,0,0,.05)',
    }),
    boxNV: {
        // height: '40px',
        // width: '40px',
        // borderRadius: '50%',
        // overflow: 'hidden',
        // color: 'white',
        // margin: '10px'
    },
    main: {
        color: theme.palette.text.primary,
        fontSize: '14px',
        lineHeight: 1.4,
        fontWeight: 500
    },
    sd: {
        fontSize: '14px',
        color: '#2E65C9'
    },
    sub: {
        fontSize: '14px',
        color: 'rgba(0,0,0,.54)'
    },
    subContact: {
        fontSize: '14px',
        color: theme.palette.text.disabled,
    },
    center: {
	    justifyContent: 'center',
	    display: 'flex',
    }
}))
export default useStyles;