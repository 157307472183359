/** @jsx h */
import { h } from "preact";
import { makeStyles } from "@material-ui/core/styles";
import { useState, useMemo } from "preact/hooks";

import { LoginBlock } from "components/molecules/login/Login";
import { Column } from "components/atoms/column/Column";
import { Row } from "components/atoms/row/Row";
import logo from "assets/logo.svg";
import waveBg from "assets/waveBg.svg";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Public from "@material-ui/icons/Public";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Popover from "@material-ui/core/Popover";
import I18n from "i18n/i18n";

const useStyles = makeStyles((theme) => ({
  left: {
    zIndex: 1,
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  logo: {
    width: 300,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  right: {
    borderLeft: "2px solid #E0E0E0",
    height: "50vh",
    zIndex: 1,
    color: "rgba(0, 0, 0, 0.54)",
    [theme.breakpoints.down("sm")]: {
      borderLeft: "none",
    },
  },
  marginLeft: {
    marginLeft: 16,
  },
  toolbar: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingRight: 16,
    paddingLeft: 16,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: 48,
    minHeight: "initial",
  },
  icon: {
    height: 24,
  },
}));

const LANGUAGES = [
  { key: "zh-TW", name: "繁體中文" },
  { key: "en", name: "English" },
];

export const Login = ({ page }) => {
  const classes = useStyles();
  const pages = {
    login: <LoginBlock />,
  };
  const [popoverPosition, setPopoverPosition] = useState(false);
  const [type, setType] = useState([]);
  
  const handleDropDownClick = (e, type) => {
    setType(type);
    setPopoverPosition(e.target);
  };
  const setLanguage = (locale) => {
    localStorage.setItem("locale", locale);
    I18n.locale = locale;
    setPopoverPosition(null)
  };

  const PopoverList = useMemo(() => {
    return (
      <List component="nav">
        {localStorage.getItem("locale") != "zh-TW" && localStorage.getItem("locale") != "en"
        ?localStorage.setItem("locale", "en")
        :LANGUAGES.map(
          ({ key, name }) => (
            <ListItem
              button
              onClick={() =>
              setLanguage(key)
              }
            >
              <ListItemText primary={name} />
            </ListItem>
          )
        )}
      </List>
    );
  }, [type]);

  return (
    <div style={{ width: "100vw", height: "100vh", background: `linear-gradient(180deg, #58D473 0%, #82A3DF 0.01%, #2E65C9 100%)` }}>
      <img
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
        }}
        src={waveBg}
      />
      <div style={{
          // background: `linear-gradient(180deg, #58D473 0%, #82A3DF 0.01%, #2E65C9 100%)`,
          height: "100%",
          width: "100%",
      }}>
        <div className={classes.toolbar} >
              <Row style={{ fontSize: "20px", width: '100%' }}>
                <div style={{ textAlign: 'right', width: '100%' }}>
                  <Button
                    color="inherit"
                    endIcon={<ExpandMoreIcon />}
                    onClick={(e) => handleDropDownClick(e, "language")}
                    className={classes.marginLeft}
                  >
                    <Public />
                  </Button>
                  <Popover
                    open={Boolean(popoverPosition)}
                    anchorEl={popoverPosition}
                    onClose={() => setPopoverPosition(null)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    PaperProps={{ style: { borderRadius: 10 } }}
                  >
                    {PopoverList}
                  </Popover>
                </div>
              </Row>
        </div>
        <Row
          justifyContent="center"
          alignItems="center"
          style={{
            height: "90%",
            width: "100%",
          }}
        >
          <div className={classes.left}>
            <img src={logo} className={classes.logo} />
          </div>
          <Column
            justifyContent="center"
            alignItems="center"
            flex={1}
            className={classes.right}
          >
            {pages[page]}
          </Column>
        </Row>
      </div>
    </div>
  );
};
