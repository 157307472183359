/** @jsx h */
import { h } from 'preact';
import { Row } from 'components/atoms/row/Row';
import useStyles from './Gauge.style'
import { Tooltip, Whisper} from 'rsuite';
import I18n from "i18n-js";

export const Gauge = ({ data, style }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {
        data.map((row, index) => (
          <Row
            justifyContent='space-between'
            alignItems='center'
            key={index}
            className={`${classes.area} ${classes.border}`}
          >
            <label>{row.label}</label>
            <Whisper placement="bottom" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>{I18n.t("actual")} : {(row.value).toLocaleString("en-US")}/ {(row.total).toLocaleString("en-US")}   {Math.round((row.value/row.total)*100)}% <br></br> {I18n.t("EST")}: {(row.estvalue).toLocaleString("en-US")}/ {(row.total).toLocaleString("en-US")}   {Math.round((row.estvalue/row.total)*100)}%</Tooltip>}>
            <Row flex={1} className={`${classes.gauge} ${classes.border}`}>
              <div style={{ width: ((row.value /row.total)*100)  + '%'}} > </div>
              <div className={`${classes.grayGauge}`} style={{ width: row.grayBar() + '%'}} >
              <span >${Math.round(row.estvalue/1000000)}M</span>
              </div>
            </Row>
            </Whisper>
            <span>{Math.round(((row.value /row.total)*100))}%</span>
          </Row>
        ))
      }
    </div>
  )
}